import { Link } from "react-router-dom";
import bgImg from "../../../assets/img/cms/content/about-banner.jpg";

function InnerBanner({ data, language }) {
	return (
		<>
			<div
				className="banner banner-inner"
				style={{
					backgroundImage: `url(${bgImg})`,
				}}
			>
				<div className="container d-flex flex-wrap py-md-4 pt-4 pb-0">
					<div className="page-title ms-md-auto me-md-0 mx-auto d-inline-block text-md-right text-center py-3 w-100">
						{language === "ENGLISH" ? (
							<h1 className="f600 mb-0">Page not found</h1>
						) : (
							<h1 className="f600 mb-0">Seite nicht gefunden</h1>
						)}
					</div>
					<ul className="nav-breadcrumbs d-flex flex-wrap">
						{language === "ENGLISH" ? (
							<li>
								<Link className="fdark" to="/">
									Home
								</Link>
							</li>
						) : (
							<li>
								<Link className="fdark" to="/">
									Home
								</Link>
							</li>
						)}
						{language === "ENGLISH" ? (
							<li>
								<span className="fmain">Page not found</span>
							</li>
						) : (
							<li>
								<span className="fmain">Seite nicht gefunden</span>
							</li>
						)}
					</ul>
				</div>
			</div>
		</>
	);
}
export default InnerBanner;
