import {Link } from "react-router-dom";
//import {Helmet} from "react-helmet";

function InnerBanner({data,dataCms,language}) {
  return (
    <>
    
      {data?.our_security_section &&
      <div class="banner banner-inner" style={{ 
        backgroundImage: `url(${data?.our_security_section_innertop_banner[0]?.image})` 
      }}>
      <div class="container d-flex flex-wrap py-4">
          <div class="page-title ms-md-auto me-md-0 mx-auto d-inline-block text-md-right text-center py-3 w-100">
             
              <h1 class="f600 mb-0">{/*data?.inner_top_banner_contact_us_page[0]?.title*/}{dataCms?.title}</h1>
          </div>
          <ul class="nav-breadcrumbs d-flex flex-wrap position-absolute">
             {language=='ENGLISH' ?(
              <li><Link class="fdark" to="/">Home</Link></li>
              ):(
                <li><Link class="fdark" to="/">Home</Link></li>
              )}
             
             
              
              <li><span class="fmain">{dataCms?.title}</span></li>
          </ul>
      </div>
      </div>
     }
    </>
  );
}
export default InnerBanner;
