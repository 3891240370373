import { useEffect } from 'react';
import BkgImg from "../../assets/img/footer-img.jpg";
import LOGO from "../../assets/img/logo-white.png";
import {Link } from "react-router-dom";

import { NavHashLink } from 'react-router-hash-link';
//import $ from 'jquery'

//import {Link } from "react-router-dom";



function Footer({language}) {

    
       
        useEffect(() => {
            async function fetchData() {

               
               await window.extraScript();
              // await jQueryCode();
                
            }

            fetchData();
        }, []);

   /* useEffect(() => {

          const script = document.createElement('script');
          script.src = process.env.PUBLIC_URL+"/assets/js/app.js";
          script.async = true;
          document.body.appendChild(script);
      
         // alert(1);
      
       // window.extraScript();
      
      
        return () => {
         // document.body.removeChild(script);
            //document.body.removeChild(script2);
          }
        }, []); */


  return (
   <>
    <footer className="footer-min"
    style={{ 
        '--background': `url(${BkgImg})` 
      }}
    
   >
    <div className="container">
        <div className="footer-logo d-xl-none mb-md-4 mb-5 text-md-left text-center">
            <Link className="" to="">
                <img src={LOGO} alt=""/>
            </Link>
        </div>
        <div className="row">
            <div className="footer-col order-xl-5 order-md-4 mb-md-4">
            
            {language=='ENGLISH' ?(
                <h4 className="fwhite mb-xl-5 mb-lg-4 text-md-left text-center">Stay Up  to date</h4>
            ):(
                <h4 className="fwhite mb-xl-5 mb-lg-4 text-md-left text-center">Auf dem Laufenden bleiben</h4> 
            )}
                <form className="form form-newslatter" action="">
                {language=='ENGLISH' ?(
                    <NavHashLink smooth to="/contact"><input type="email" name="" id="" placeholder="Your EmailAdress" className="form-control" required/></NavHashLink>
                    ):(
                        <NavHashLink smooth to="/contact"> <input type="email" name="" id="" placeholder="Kontakt" className="form-control" required/></NavHashLink>
                    )}
                    <button className="form-newslatter-btn">
                        <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24"><path d="m20.665 3.717-17.73 6.837c-1.21.486-1.203 1.161-.222 1.462l4.552 1.42 10.532-6.645c.498-.303.953-.14.579.192l-8.533 7.701h-.002l.002.001-.314 4.692c.46 0 .663-.211.921-.46l2.211-2.15 4.599 3.397c.848.467 1.457.227 1.668-.785l3.019-14.228c.309-1.239-.473-1.8-1.282-1.434z"/></svg>
                    </button>
                </form>
            </div>
            {language=='ENGLISH' ?(
            <div className="footer-col order-xl-2 ps-xl-5 order-md-1 mb-md-4 accordion-footer">
                <input type="checkbox" name="" id="" className="d-md-none"/>
                <h4 className="fwhite mb-xl-5 mb-lg-4">Company</h4>
                <ul className="nav-footer">
                    <li><NavHashLink smooth to="/">Customer events</NavHashLink></li>
                    <li><NavHashLink smooth to="/">News & media</NavHashLink></li>
                    <li><NavHashLink smooth to="/about-us">Who we are</NavHashLink></li>
                    <li><NavHashLink smooth to="/innovation">Innovation</NavHashLink></li>
                    <li><NavHashLink smooth to="/">Careers</NavHashLink></li>
                    <li><NavHashLink smooth to="/about-us#s3">Sustainability</NavHashLink></li>
                    <li><NavHashLink smooth to="/about-us#our-values">Our values</NavHashLink></li>
                </ul>
            </div>
            ):(
                <div className="footer-col order-xl-2 ps-xl-5 order-md-1 mb-md-4 accordion-footer">
                <input type="checkbox" name="" id="" className="d-md-none"/>
                <h4 className="fwhite mb-xl-5 mb-lg-4">Unternehmen</h4>
                <ul className="nav-footer">
                    <li><NavHashLink smooth to="/">Kundenveranstaltungen</NavHashLink></li>
                   
                    <li><NavHashLink smooth to="/about-us">Wer wir sind</NavHashLink></li>
                    <li><NavHashLink smooth to="/innovation">Innovation</NavHashLink></li>
                 
                    <li><NavHashLink smooth to="/about-us#s3">Nachhaltigkeit</NavHashLink></li>
                    <li><NavHashLink smooth to="/about-us#our-values">Unsere Werte</NavHashLink></li>
                </ul>
            </div>

            )}
            {language=='ENGLISH' ?(
            <div className="footer-col order-xl-3 order-md-2 mb-md-4 accordion-footer">
                <input type="checkbox" name="" id="" className="d-md-none"/>
                <h4 className="fwhite mb-xl-5 mb-lg-4">Solutions</h4>
                <ul className="nav-footer">
                   
                    <li><NavHashLink smooth to={"/quality-management"}>Quality management</NavHashLink></li>
                    <li><Link to="/inspection-and-testing-companies">Inspection Management</Link></li>
                    <li><NavHashLink smooth to={"/technology-hub#th0"}>Asset Management</NavHashLink></li>
                    <li><Link to="/oil-and-gas-and-petrochemical">Oil & gas</Link></li>
                    <li><NavHashLink smooth to="/power-plants-and-utilities">Power generation</NavHashLink></li>
                </ul>
            </div>
             ):(
                <div className="footer-col order-xl-3 order-md-2 mb-md-4 accordion-footer">
                <input type="checkbox" name="" id="" className="d-md-none"/>
                <h4 className="fwhite mb-xl-5 mb-lg-4">Lösungen</h4>
                <ul className="nav-footer">
                   
                    <li><NavHashLink smooth to={"/quality-management"}>Qualitätsmanagement</NavHashLink></li>
                    <li><Link to="/inspection-and-testing-companies">Inspektionsmanagement</Link></li>
                    <li><NavHashLink smooth to={"/technology-hub#th0"}>Asset Management</NavHashLink></li>
                    <li><Link to="/oil-and-gas-and-petrochemical">Öl- und Gas Industrie</Link></li>
                    <li><NavHashLink smooth to="/power-plants-and-utilities">Energieerzeugung</NavHashLink></li>
                </ul>
            </div>
            )}
           
            <div className="footer-col footer-bio order-xl-1 order-md-5 text-xl-left text-center pt-md-0 pt-4">
                <Link className="footer-logo d-none d-xl-block mb-4" to="">
                    <img src={LOGO} alt=""/>
                </Link>
               
                <ul className="nav-social d-flex flex-wrap justify-content-xl-start justify-content-center">
                    <li className="me-lg-2 mx-2"><Link className="d-flex flex-wrap align-items-center justify-content-center fwhite" target="_blank" to="https://www.facebook.com/AstramTechnologies"><i className="fa-facebook-f"></i></Link></li>
                    <li className="me-lg-2 mx-2"><Link className="d-flex flex-wrap align-items-center justify-content-center fwhite"  target="_blank" to="https://www.instagram.com/astramtech/"><svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path fill="#ffffff" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg></Link></li>
                    <li className="me-lg-2 mx-2"><Link className="d-flex flex-wrap align-items-center justify-content-center fwhite"  target="_blank" to="https://www.linkedin.com/company/astram-technologies-pty-ltd/"><i className="fa-linkedin"></i></Link></li>
                    <li className="mx-2"><Link className="d-flex flex-wrap align-items-center justify-content-center fwhite"  target="_blank" to="https://za.pinterest.com/pin/991566042944220192"><i className="fa-pinterest"></i></Link></li>
                </ul>
            </div>
        </div>
    </div>
    <div className="footer-copy">
       ASTRAM &copy; 2023 copyright. all rights reserved
    </div>        
</footer>

</>


  );
}

export default Footer;
