
import {Link } from "react-router-dom";
import AboutImg3 from "../../assets/img/industry.webp";

function CmsContent({data,dataCms}) {
  return (
    <>
    {data?.industry_applications_details_page &&
      <div className="container cms">
        <img src={dataCms?.image} alt="" width={570} height={316} /> 
        <p dangerouslySetInnerHTML={{__html: dataCms?.description}}/>
      </div>
    }
  </>

  );
}
export default CmsContent;
