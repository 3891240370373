

//import { useEffect } from 'react';
import { BrowserRouter, Routes, Route,useParams } from "react-router-dom";




//import {Helmet} from "react-helmet";

import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";
import OurSolutions from "./pages/OurSolutions";
import TechnologyHub from "./pages/TechnologyHub";
//import CmsDetails from "./pages/CmsDetails";
import CmsDetailsWithOneSeg from "./pages/CmsDetailsWithOneSeg";
import SecurityDetailsWithOneSeg from "./pages/SecurityDetailsWithOneSeg";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";

function App() {




 

  
  


  return (
   
    <BrowserRouter>
      <Routes>
          <Route index element={<Home />} />
          <Route path="about-us" element={<AboutUs />} />
          <Route path="contact" element={<Contact />} />
          <Route path="our-solutions-and-business" element={<OurSolutions />} />
          <Route path="technology-hub" element={<TechnologyHub />} />
         

          <Route path="nuclear" element={<CmsDetailsWithOneSeg />} />
          <Route path="power-plants-and-utilities" element={<CmsDetailsWithOneSeg />} />
          <Route path="mining" element={<CmsDetailsWithOneSeg />} />
          <Route path="pharmaceutical" element={<CmsDetailsWithOneSeg />} />
          <Route path="laboratories" element={<CmsDetailsWithOneSeg />} />
          <Route path="international-trade" element={<CmsDetailsWithOneSeg />} />
          <Route path="accreditation-bodies" element={<CmsDetailsWithOneSeg />} />
          <Route path="oil-and-gas-and-petrochemical" element={<CmsDetailsWithOneSeg />} />
          <Route path="manufacturing" element={<CmsDetailsWithOneSeg />} />
          <Route path="health-and-safety" element={<CmsDetailsWithOneSeg />} />
          <Route path="hr-management" element={<CmsDetailsWithOneSeg />} />
          <Route path="environmental-management" element={<CmsDetailsWithOneSeg />} />
          <Route path="inspection-and-testing-companies" element={<CmsDetailsWithOneSeg />} />
          <Route path="food-and-beverage" element={<CmsDetailsWithOneSeg />} />
          <Route path="government-and-regulatory-bodies" element={<CmsDetailsWithOneSeg />} />
          <Route path="transportation-and-logistics" element={<CmsDetailsWithOneSeg />} />
          <Route path="quality-management" element={<CmsDetailsWithOneSeg />} />
          <Route path="construction" element={<CmsDetailsWithOneSeg />} />
          <Route path="innovation" element={<CmsDetailsWithOneSeg />} />

          <Route path="aws-well-architected-framework" element={<SecurityDetailsWithOneSeg />} />
          <Route path="gdpr-compliance" element={<SecurityDetailsWithOneSeg />} />
          <Route path="hippa-compliance" element={<SecurityDetailsWithOneSeg />} />
          <Route path="iso-27001-compliance" element={<SecurityDetailsWithOneSeg />} />
          <Route path="pci-dss-compliance" element={<SecurityDetailsWithOneSeg />} />
          <Route path="nist-cyber-security-compliance" element={<SecurityDetailsWithOneSeg />} />
         
          <Route path="blog" element={<Blog />} />
          <Route path="*" element={<BlogDetails />} />

          
      </Routes>
    </BrowserRouter>

  );
}

export default App;
