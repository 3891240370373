import {Link } from "react-router-dom";
//import industryImage01 from "../../assets/img/01.png";
//import industryImage02 from "../../assets/img/02.png";
//import industryImage03 from "../../assets/img/03.png";
//import industryImage04 from "../../assets/img/04.png";




function IndustryApplications({data,language}) {
  return (
   <>
     {data?.my_industry_home_page &&
    <div className="industryApplication-section">
        <div className="container pb-4">
            <div className="hdn-bg hdn-bg-main mb-5">
            {language=='ENGLISH' &&
                    <h2 className="h3 pe-3 bg-white position-relative f600 text-uppercase d-inline-block mb-0">Industry <span>Applications:</span></h2>
                    }
                    {language=='GERMAN' &&
                    <h2 className="h3 pe-3 bg-white position-relative f600 text-uppercase d-inline-block mb-0">INDUSTRIEANWENDUNGEN:</h2>
                    }
                
            </div>
            <div className="row">
            {data?.my_industry_home_page.slice(0, 4).map((row, index) => (
                <div className="col-md-6 industryApplication-col mb-4">
                    <img src={row?.image} width="570" height="316" alt={row?.alt}/>
                    <div className="industryApplication-col-text">
                        <h3 className="f600 text-uppercase">{row?.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: row?.description}}></p>
                    </div>
                </div>
            ))}
                
            </div>
        </div>
        <div className="hdn-bg hdn-bg-gry text-center">
            <div className="d-inline-block bg-white px-3 position-relative">
            
            {language=='ENGLISH' &&
                    <Link className="btn btn-sm btn-bg-main-light f500" to="/our-solutions-and-business">View All</Link>
                    }
                    {language=='GERMAN' &&
                     <Link className="btn btn-sm btn-bg-main-light f500" to="/our-solutions-and-business">Alle ansehen</Link>
                    }
               
            </div>
        </div>
    </div>
}
    </>

  );
}

export default IndustryApplications;
