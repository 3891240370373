

import ThhumbNail from "../../../assets/img/about-img2.png";

function AstramSoftWareTechnology({data,language}) {
  return (
    <>
     {(data?.astram_software_technology_home_page || data?.astram_software_technology_overview_technology_hub_page  ) &&
    <div className="container py-lg-5 py-4">
   
    {language=='ENGLISH' &&
                   <h2 className="text-center f600 mb-lg-5 mb-4">ASTRAM Software <span>Technology</span></h2>
                    }
                    {language=='GERMAN' &&
                  <h2 className="text-center f600 mb-lg-5 mb-4">ASTRAM-Software <span>Technology</span></h2>
                    }


    
    <div className="row technology-intro align-items-center pb-md-5 pb-4">
      <div className="col-lg-4 col-md-5 pe-lg-4 mb-md-0 mb-4">
        <img src={ThhumbNail} alt="" width={578} height={523} />
      </div>
      <div className="col-lg-8 col-md-7 cms ps-lg-4">
        <div className="hdn-bg hdn-bg-gry mb-3">

          
          {language=='ENGLISH' &&
                    <h3 className="h5 pe-3 bg-white position-relative f600 d-inline-block mb-0">Introduction</h3>
                    }
                    {language=='GERMAN' &&
                  <h3 className="h5 pe-3 bg-white position-relative f600 d-inline-block mb-0">Einleitung</h3>
                    }

        </div>
        <p dangerouslySetInnerHTML={{__html: data?.astram_software_technology_home_page[0]?.description}}></p>
      </div>
    </div>
    <div className="row">
      <div className="col-12 px-md-3 px-0">
        <div className="hdn-bg hdn-bg-gry text-center mb-4">
          <h3 className="h5 d-inline-block bg-white px-sm-5 px-4 mb-0 position-relative f600">{data?.astram_software_technology_overview_technology_hub_page[0]?.title}</h3>
        </div>
        <div className="cms px-3">
          <p dangerouslySetInnerHTML={{__html: data?.astram_software_technology_overview_technology_hub_page[0]?.description}}></p>
        </div>
      </div>
    </div>
  </div>
  }
  </>

  );
}
export default AstramSoftWareTechnology;
