

import React , { useState,useEffect} from 'react';
//import {useParams } from "react-router-dom";
import Nav from "../layouts/header";
import Footer from "../layouts/footer";
import InnerTopBanner from "../components/inner-page-banner/ban-06";
//import OurSolutionsAndBusiness from "../components/industry-section";
import OurSolutionsAndBusiness from "../components/our-solutions-and-business/module-02";
import SecuriryContent from "../components/security-aplications-details";


import AboutClient from "../components/about-client-testimonials";
import {Helmet} from "react-helmet";


function SecurityDetailsWithOneSeg() {

    const [isPageLoaded, setIsPageLoaded] =useState(false);
    const [dataSourceEn, setDataSourceEn] = useState({
        "pageName": "Security Page",
        "metaTitle": "Security Page",
        "metaDescription": " Security Page ",
        "metaKeyword": " Security Page ",
        "our_security_section_innertop_banner": [
            {
                "cmsId": "149",
                "cmsSlug": null,
                "pageId": "10",
                "sectionId": "48",
                "metaTitle": "",
                "metaDescriptions": " Our Security Section - (Innertop Banner)",
                "metaKeyword": " Our Security Section - (Innertop Banner)",
                "title": "Our Security Section - (Innertop Banner)",
                "description": "<p>Our Security Section - (Innertop Banner)</p>",
                "description2": "<p>Our Security Section - (Innertop Banner)</p>",
                "image": require('../assets/img/cms/content/about-banner5.jpg'),
                "iconClass": "",
                "heading": "Our Security Section - (Innertop Banner)",
                "alt": "Our Security Section - (Innertop Banner)",
                "additional_data": []
            }
        ],
        "our_security_section": [
            {
                "cmsId": "143",
                "cmsSlug": "aws-well-architected-framework",
                "pageId": "10",
                "sectionId": "47",
                "metaTitle": " AWS well architected Framework ",
                "metaDescriptions": " AWS well architected Framework ",
                "metaKeyword": " AWS well architected Framework ",
                "title": " AWS well architected Framework ",
                "description": "<p><strong>&nbsp;AWS well architected Framework:</strong> ASTRAM has implemented the AWS Well-Architected Framework, ensuring that our software solution is designed and built based on industry best practices. This framework enables us to create highly secure, reliable, efficient, and cost-effective systems. By leveraging AWS services and architectural principles, ASTRAM delivers a robust and scalable solution that meets the needs of our clients.</p>",
                "description2": "<p><strong>&nbsp;AWS well architected Framework:</strong> ASTRAM has implemented the AWS Well-Architected Framework, ensuring that our software solution is designed and built based on industry best practices. This framework enables us to create highly secure, reliable, efficient, and cost-effective systems. By leveraging AWS services and architectural principles, ASTRAM delivers a robust and scalable solution that meets the needs of our clients.</p>",
                "image": "",
                "iconClass": "",
                "heading": " AWS well architected Framework ",
                "alt": " AWS well architected Framework ",
                "additional_data": []
            },
            {
                "cmsId": "144",
                "cmsSlug": "gdpr-compliance",
                "pageId": "10",
                "sectionId": "47",
                "metaTitle": "GDPR Compliance",
                "metaDescriptions": " GDPR Compliance",
                "metaKeyword": " GDPR Compliance",
                "title": "GDPR Compliance",
                "description": "<p><strong>GDPR Compliance:</strong> We prioritize data protection and privacy by complying with the General Data Protection Regulation (GDPR). ASTRAM ensures that personal data is collected, processed, and stored securely, with proper consent and transparency. Our strict data protection measures safeguard individual rights, provide data subject access controls, and implement secure data transfer mechanisms.</p>",
                "description2": "<p><strong>GDPR Compliance:</strong> We prioritize data protection and privacy by complying with the General Data Protection Regulation (GDPR). ASTRAM ensures that personal data is collected, processed, and stored securely, with proper consent and transparency. Our strict data protection measures safeguard individual rights, provide data subject access controls, and implement secure data transfer mechanisms.</p>",
                "image": "",
                "iconClass": "",
                "heading": "GDPR Compliance",
                "alt": "GDPR Compliance",
                "additional_data": []
            },
            {
                "cmsId": "145",
                "cmsSlug": "hippa-compliance",
                "pageId": "10",
                "sectionId": "47",
                "metaTitle": "HIPAA Compliance",
                "metaDescriptions": " HIPAA Compliance",
                "metaKeyword": " HIPAA Compliance",
                "title": "HIPAA Compliance",
                "description": "<p><strong>HIPAA Compliance:</strong> ASTRAM is committed to maintaining the highest standards of data security and privacy for the healthcare industry. By adhering to the Health Insurance Portability and Accountability Act (HIPAA), we ensure the confidentiality, integrity, and availability of protected health information (PHI). Our comprehensive security controls and safeguards protect patient data, enabling healthcare organizations to confidently manage their processes while meeting HIPAA requirements.</p>",
                "description2": "<p><strong>HIPAA Compliance:</strong> ASTRAM is committed to maintaining the highest standards of data security and privacy for the healthcare industry. By adhering to the Health Insurance Portability and Accountability Act (HIPAA), we ensure the confidentiality, integrity, and availability of protected health information (PHI). Our comprehensive security controls and safeguards protect patient data, enabling healthcare organizations to confidently manage their processes while meeting HIPAA requirements.</p>",
                "image": "",
                "iconClass": "",
                "heading": "HIPAA Compliance",
                "alt": "HIPAA Compliance",
                "additional_data": []
            },
            {
                "cmsId": "146",
                "cmsSlug": "iso-27001-compliance",
                "pageId": "10",
                "sectionId": "47",
                "metaTitle": "ISO 27001 Compliance",
                "metaDescriptions": " ISO 27001 Compliance",
                "metaKeyword": " ISO 27001 Compliance",
                "title": "ISO 27001 Compliance",
                "description": "<p><strong>ISO 27001 Compliance:</strong> &nbsp;While we are not yet ISO 27001 certified, we rigorously comply with the requirements to ensure the highest level of information security management &nbsp;By adhering to the ISO 27001 standard, we ensure the confidentiality, integrity, and availability of data, providing our clients with peace of mind.</p>",
                "description2": "<p><strong>ISO 27001 Compliance:</strong> &nbsp;While we are not yet ISO 27001 certified, we rigorously comply with the requirements to ensure the highest level of information security management &nbsp;By adhering to the ISO 27001 standard, we ensure the confidentiality, integrity, and availability of data, providing our clients with peace of mind.</p>",
                "image": "",
                "iconClass": "",
                "heading": "ISO 27001 Compliance",
                "alt": "ISO 27001 Compliance",
                "additional_data": []
            },
            {
                "cmsId": "147",
                "cmsSlug": "pci-dss-compliance",
                "pageId": "10",
                "sectionId": "47",
                "metaTitle": "PCI DSS Compliance",
                "metaDescriptions": " PCI DSS Compliance",
                "metaKeyword": " PCI DSS Compliance",
                "title": "PCI DSS Compliance",
                "description": "<p><strong>PCI DSS Compliance:</strong> Although we have not yet integrated the payment gateway into astram for credit card payments, our system meets the Payment Card Industry Data Security Standard (PCI DSS) requirements to ensure secure handling of payment card information. This has been done to ensure security for such integrations in near future. By implementing strict security controls and maintaining PCI DSS compliance, we protect cardholder data and maintain a secure environment for processing payments. Our commitment to PCI DSS compliance reassures our clients that their financial transactions are handled securely.</p>",
                "description2": "<p><strong>PCI DSS Compliance:</strong> Although we have not yet integrated the payment gateway into astram for credit card payments, our system meets the Payment Card Industry Data Security Standard (PCI DSS) requirements to ensure secure handling of payment card information. This has been done to ensure security for such integrations in near future. By implementing strict security controls and maintaining PCI DSS compliance, we protect cardholder data and maintain a secure environment for processing payments. Our commitment to PCI DSS compliance reassures our clients that their financial transactions are handled securely.</p>",
                "image": "",
                "iconClass": "",
                "heading": "PCI DSS Compliance",
                "alt": "PCI DSS Compliance",
                "additional_data": []
            },
            {
                "cmsId": "148",
                "cmsSlug": "nist-cyber-security-compliance",
                "pageId": "10",
                "sectionId": "47",
                "metaTitle": "NIST Cyber Security Compliance",
                "metaDescriptions": " NIST Cyber Security Compliance ",
                "metaKeyword": " NIST Cyber Security  Compliance ",
                "title": "NIST Cyber Security  Compliance",
                "description": "<p><strong>NIST Cyber Security Compliance:</strong> ASTRAM adheres to the guidelines of the National Institute of Standards and Technology (NIST) Cybersecurity Framework, which provides a comprehensive approach to managing and mitigating cybersecurity risks. By following the NIST framework, we ensure that our systems are protected against potential threats, vulnerabilities are identified and addressed, and robust cybersecurity practices are in place.</p>",
                "description2": "<p><strong>NIST Cyber Security Compliance:</strong> ASTRAM adheres to the guidelines of the National Institute of Standards and Technology (NIST) Cybersecurity Framework, which provides a comprehensive approach to managing and mitigating cybersecurity risks. By following the NIST framework, we ensure that our systems are protected against potential threats, vulnerabilities are identified and addressed, and robust cybersecurity practices are in place.</p>",
                "image": "",
                "iconClass": "",
                "heading": "NIST Cyber Security  Compliance",
                "alt": "NIST Cyber Security  Compliance",
                "additional_data": []
            }
        ]
    })
    

const [dataSourceDe, setDataSourceDe] = useState({
    "pageName": "Security Page",
    "metaTitle": "",
    "metaDescription": "    ",
    "metaKeyword": "    ",
    "our_security_section_innertop_banner": [
        {
            "cmsId": "154",
            "cmsSlug": null,
            "pageId": "11",
            "sectionId": "50",
            "metaTitle": "Our Security Section - (Innertop Banner)",
            "metaDescriptions": " Our Security Section - (Innertop Banner)  ",
            "metaKeyword": " Our Security Section - (Innertop Banner)  ",
            "title": "Our Security Section - (Innertop Banner)",
            "description": "<p>Our Security Section - (Innertop Banner)</p>",
            "description2": "<p>Our Security Section - (Innertop Banner)</p>",
            "image": require('../assets/img/cms/content/about-banner4.jpg'),
            "iconClass": "",
            "heading": "Our Security Section - (Innertop Banner)",
            "alt": "Our Security Section - (Innertop Banner)",
            "additional_data": []
        }
    ],
    "our_security_section": [
        {
            "cmsId": "148",
            "cmsSlug": "aws-well-architected-framework",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "AWS Well-Architected Framework",
            "metaDescriptions": " AWS Well-Architected Framework  ",
            "metaKeyword": " AWS Well-Architected Framework  ",
            "title": "AWS Well-Architected Framework",
            "description": "<p><strong>AWS Well-Architected Framework:</strong> ASTRAM hat das AWS Well-Architected Framework implementiert, um sicherzustellen, dass unsere Softwarel&ouml;sung gem&auml;&szlig; bew&auml;hrten Industriestandards entworfen und entwickelt wird. Dieses Framework erm&ouml;glicht es uns, hochsichere, zuverl&auml;ssige, effiziente und kosteneffektive Systeme zu erstellen. Durch die Nutzung von AWS-Services und architektonischen Prinzipien liefert ASTRAM eine robuste und skalierbare L&ouml;sung, die den Anforderungen unserer Kunden gerecht wird.</p>",
            "description2": "<p><strong>AWS Well-Architected Framework:</strong> ASTRAM hat das AWS Well-Architected Framework implementiert, um sicherzustellen, dass unsere Softwarel&ouml;sung gem&auml;&szlig; bew&auml;hrten Industriestandards entworfen und entwickelt wird. Dieses Framework erm&ouml;glicht es uns, hochsichere, zuverl&auml;ssige, effiziente und kosteneffektive Systeme zu erstellen. Durch die Nutzung von AWS-Services und architektonischen Prinzipien liefert ASTRAM eine robuste und skalierbare L&ouml;sung, die den Anforderungen unserer Kunden gerecht wird.</p>",
            "image": "",
            "iconClass": "",
            "heading": "AWS Well-Architected Framework",
            "alt": "AWS Well-Architected Framework",
            "additional_data": []
        },
        {
            "cmsId": "149",
            "cmsSlug": "gdpr-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "DSGVO-Konformität",
            "metaDescriptions": " DSGVO-Konformität ",
            "metaKeyword": " DSGVO-Konformität ",
            "title": " DSGVO-Konformität",
            "description": "<p><strong>DSGVO-Konformit&auml;t:</strong> Wir legen gro&szlig;en Wert auf den Datenschutz und die Einhaltung der Datenschutzgrundverordnung (DSGVO). ASTRAM stellt sicher, dass personenbezogene Daten sicher erfasst, verarbeitet und gespeichert werden und dass dies unter Einhaltung von korrekter Einwilligung und Transparenz geschieht. Unsere strengen Datenschutzma&szlig;nahmen sch&uuml;tzen individuelle Rechte, bieten Kontrollm&ouml;glichkeiten f&uuml;r betroffene Personen und implementieren sichere Daten&uuml;bertragungsmechanismen.</p>",
            "description2": "<p><strong>DSGVO-Konformit&auml;t:</strong> Wir legen gro&szlig;en Wert auf den Datenschutz und die Einhaltung der Datenschutzgrundverordnung (DSGVO). ASTRAM stellt sicher, dass personenbezogene Daten sicher erfasst, verarbeitet und gespeichert werden und dass dies unter Einhaltung von korrekter Einwilligung und Transparenz geschieht. Unsere strengen Datenschutzma&szlig;nahmen sch&uuml;tzen individuelle Rechte, bieten Kontrollm&ouml;glichkeiten f&uuml;r betroffene Personen und implementieren sichere Daten&uuml;bertragungsmechanismen.</p>",
            "image": "",
            "iconClass": "",
            "heading": " DSGVO-Konformität",
            "alt": " DSGVO-Konformität",
            "additional_data": []
        },
        {
            "cmsId": "150",
            "cmsSlug": "hippa-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "HIPAA-Konformität",
            "metaDescriptions": " HIPAA-Konformität ",
            "metaKeyword": " HIPAA-Konformität ",
            "title": "HIPAA-Konformität",
            "description": "<p><strong>HIPAA-Konformit&auml;t:</strong> ASTRAM legt h&ouml;chsten Wert auf die Einhaltung der Datenschutz- und Datensicherheitsstandards in der Gesundheitsbranche. Durch die Einhaltung des Health Insurance Portability and Accountability Act (HIPAA) stellen wir die Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit gesch&uuml;tzter Gesundheitsinformationen (PHI) sicher. Unsere umfassenden Sicherheitskontrollen und Schutzmechanismen sch&uuml;tzen Patientendaten und erm&ouml;glichen es Gesundheitsorganisationen, ihre Prozesse sicher zu verwalten und gleichzeitig die Anforderungen der HIPAA einzuhalten.</p>\r\n<p></p>\r\n<p></p>",
            "description2": "<p><strong>HIPAA-Konformit&auml;t:</strong> ASTRAM legt h&ouml;chsten Wert auf die Einhaltung der Datenschutz- und Datensicherheitsstandards in der Gesundheitsbranche. Durch die Einhaltung des Health Insurance Portability and Accountability Act (HIPAA) stellen wir die Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit gesch&uuml;tzter Gesundheitsinformationen (PHI) sicher. Unsere umfassenden Sicherheitskontrollen und Schutzmechanismen sch&uuml;tzen Patientendaten und erm&ouml;glichen es Gesundheitsorganisationen, ihre Prozesse sicher zu verwalten und gleichzeitig die Anforderungen der HIPAA einzuhalten.</p>\r\n<p></p>\r\n<p></p>",
            "image": "",
            "iconClass": "",
            "heading": "HIPAA-Konformität",
            "alt": "HIPAA-Konformität",
            "additional_data": []
        },
        {
            "cmsId": "151",
            "cmsSlug": "iso-27001-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "ISO 27001-Konformität",
            "metaDescriptions": " ISO 27001-Konformität ",
            "metaKeyword": " ISO 27001-Konformität ",
            "title": "ISO 27001-Konformität",
            "description": "<p><strong>ISO 27001-Konformit&auml;t:</strong> Obwohl wir noch nicht ISO 27001 zertifiziert sind, erf&uuml;llen wir konsequent die Anforderungen, um das h&ouml;chste Niveau im Informations- Sicherheitsmanagement zu gew&auml;hrleisten. Durch die Einhaltung des ISO 27001 Standards stellen wir die Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten sicher und bieten unseren Kunden somit eine beruhigende Gewissheit.</p>",
            "description2": "<p><strong>ISO 27001-Konformit&auml;t:</strong> Obwohl wir noch nicht ISO 27001 zertifiziert sind, erf&uuml;llen wir konsequent die Anforderungen, um das h&ouml;chste Niveau im Informations- Sicherheitsmanagement zu gew&auml;hrleisten. Durch die Einhaltung des ISO 27001 Standards stellen wir die Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten sicher und bieten unseren Kunden somit eine beruhigende Gewissheit.</p>",
            "image": "",
            "iconClass": "",
            "heading": "ISO 27001-Konformität",
            "alt": "ISO 27001-Konformität",
            "additional_data": []
        },
        {
            "cmsId": "152",
            "cmsSlug": "pci-dss-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "PCI DSS-Konformität",
            "metaDescriptions": " PCI DSS-Konformität  ",
            "metaKeyword": " PCI DSS-Konformität  ",
            "title": "PCI DSS-Konformität",
            "description": "<p><strong>PCI DSS-Konformit&auml;t:</strong> Obwohl wir die Zahlungsplattform f&uuml;r Kreditkartenzahlungen noch nicht in ASTRAM integriert haben, erf&uuml;llt unser System die Anforderungen des Payment Card Industry Data Security Standard (PCI DSS), um eine sichere Handhabung von Zahlungskartendaten zu gew&auml;hrleisten. Dies wurde getan, um die Sicherheit f&uuml;r solche Integrationen in naher Zukunft zu gew&auml;hrleisten. Durch die Implementierung strenger Sicherheitskontrollen und die Aufrechterhaltung der PCI DSS-Konformit&auml;t sch&uuml;tzen wir Karteninhaberdaten und gew&auml;hrleisten eine sichere Umgebung f&uuml;r die Abwicklung von Zahlungen. Unsere Verpflichtung zur Einhaltung von PCI DSS gibt unseren Kunden die Gewissheit, dass ihre Finanztransaktionen sicher abgewickelt werden.</p>",
            "description2": "<p><strong>PCI DSS-Konformit&auml;t:</strong> Obwohl wir die Zahlungsplattform f&uuml;r Kreditkartenzahlungen noch nicht in ASTRAM integriert haben, erf&uuml;llt unser System die Anforderungen des Payment Card Industry Data Security Standard (PCI DSS), um eine sichere Handhabung von Zahlungskartendaten zu gew&auml;hrleisten. Dies wurde getan, um die Sicherheit f&uuml;r solche Integrationen in naher Zukunft zu gew&auml;hrleisten. Durch die Implementierung strenger Sicherheitskontrollen und die Aufrechterhaltung der PCI DSS-Konformit&auml;t sch&uuml;tzen wir Karteninhaberdaten und gew&auml;hrleisten eine sichere Umgebung f&uuml;r die Abwicklung von Zahlungen. Unsere Verpflichtung zur Einhaltung von PCI DSS gibt unseren Kunden die Gewissheit, dass ihre Finanztransaktionen sicher abgewickelt werden.</p>",
            "image": "",
            "iconClass": "",
            "heading": "PCI DSS-Konformität",
            "alt": "PCI DSS-Konformität",
            "additional_data": []
        },
        {
            "cmsId": "153",
            "cmsSlug": "nist-cyber-security-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "NIST Cybersecurity Framework",
            "metaDescriptions": " NIST Cybersecurity Framework ",
            "metaKeyword": " NIST Cybersecurity Framework ",
            "title": "NIST Cybersecurity Framework",
            "description": "<p><strong>NIST Cyber Security Compliance:</strong> ASTRAM folgt den Richtlinien des National Institute of Standards and Technology (NIST) Cybersecurity Framework, das einen umfassenden Ansatz zur Verwaltung und Minderung von Cybersecurity-Risiken bietet. Durch die Einhaltung des NIST-Frameworks stellen wir sicher, dass unsere Systeme vor potenziellen Bedrohungen gesch&uuml;tzt sind, Schwachstellen identifiziert und behoben werden und robuste Cybersecurity-Praktiken implementiert sind.</p>",
            "description2": "<p><strong>NIST Cyber Security Compliance:</strong> ASTRAM folgt den Richtlinien des National Institute of Standards and Technology (NIST) Cybersecurity Framework, das einen umfassenden Ansatz zur Verwaltung und Minderung von Cybersecurity-Risiken bietet. Durch die Einhaltung des NIST-Frameworks stellen wir sicher, dass unsere Systeme vor potenziellen Bedrohungen gesch&uuml;tzt sind, Schwachstellen identifiziert und behoben werden und robuste Cybersecurity-Praktiken implementiert sind.</p>",
            "image": "",
            "iconClass": "",
            "heading": "NIST Cybersecurity Framework",
            "alt": "NIST Cybersecurity Framework",
            "additional_data": []
        }
    ]
})

const [dataSource, setDataSource] = useState({
    "pageName": "Security Page",
    "metaTitle": "",
    "metaDescription": "    ",
    "metaKeyword": "    ",
    "our_security_section_innertop_banner": [
        {
            "cmsId": "154",
            "cmsSlug": null,
            "pageId": "11",
            "sectionId": "50",
            "metaTitle": "Our Security Section - (Innertop Banner)",
            "metaDescriptions": " Our Security Section - (Innertop Banner)  ",
            "metaKeyword": " Our Security Section - (Innertop Banner)  ",
            "title": "Our Security Section - (Innertop Banner)",
            "description": "<p>Our Security Section - (Innertop Banner)</p>",
            "description2": "<p>Our Security Section - (Innertop Banner)</p>",
            "image": require('../assets/img/cms/content/about-banner4.jpg'),
            "iconClass": "",
            "heading": "Our Security Section - (Innertop Banner)",
            "alt": "Our Security Section - (Innertop Banner)",
            "additional_data": []
        }
    ],
    "our_security_section": [
        {
            "cmsId": "148",
            "cmsSlug": "aws-well-architected-framework",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "AWS Well-Architected Framework",
            "metaDescriptions": " AWS Well-Architected Framework ",
            "metaKeyword": " AWS Well-Architected Framework ",
            "title": "AWS Well-Architected Framework",
            "description": "<p><strong>AWS Well-Architected Framework:</strong> Framework implementiert, um sicherzustellen, dassunsereSoftwarel&ouml;sunggem&auml;&szlig;bew&auml;hrtenIndustriestandardsentworfen und entwickeltwird. Dieses Framework erm&ouml;glicht es uns, hochsichere, zuverl&auml;ssige, effiziente und kosteneffektiveSystemezuerstellen. Durch die Nutzung von AWS-Services und architektonischenPrinzipienliefert ASTRAM einerobuste und skalierbareL&ouml;sung, die den Anforderungenunserer Kunden gerechtwird.</p>",
            "description2": "<p><strong>AWS Well-Architected Framework:</strong> Framework implementiert, um sicherzustellen, dassunsereSoftwarel&ouml;sunggem&auml;&szlig;bew&auml;hrtenIndustriestandardsentworfen und entwickeltwird. Dieses Framework erm&ouml;glicht es uns, hochsichere, zuverl&auml;ssige, effiziente und kosteneffektiveSystemezuerstellen. Durch die Nutzung von AWS-Services und architektonischenPrinzipienliefert ASTRAM einerobuste und skalierbareL&ouml;sung, die den Anforderungenunserer Kunden gerechtwird.</p>",
            "image": "",
            "iconClass": "",
            "heading": "AWS Well-Architected Framework",
            "alt": "AWS Well-Architected Framework",
            "additional_data": []
        },
        {
            "cmsId": "149",
            "cmsSlug": "gdpr-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "DSGVO-Konformität",
            "metaDescriptions": " DSGVO-Konformität",
            "metaKeyword": " DSGVO-Konformität",
            "title": " DSGVO-Konformität",
            "description": "<p><strong>DSGVO-Konformit&auml;t:</strong> Wir legengro&szlig;en Wert auf den Datenschutz und die Einhaltung der Datenschutzgrundverordnung (DSGVO). ASTRAM stelltsicher, dasspersonenbezogene Daten sichererfasst, verarbeitet und gespeichertwerden und dass dies unterEinhaltung von korrekterEinwilligung und Transparenzgeschieht. UnserestrengenDatenschutzma&szlig;nahmensch&uuml;tzenindividuelle Rechte, bietenKontrollm&ouml;glichkeiten f&uuml;r betroffenePersonen und implementierensichereDaten&uuml;bertragungsmechanismen.</p>",
            "description2": "<p><strong>DSGVO-Konformit&auml;t:</strong> Wir legengro&szlig;en Wert auf den Datenschutz und die Einhaltung der Datenschutzgrundverordnung (DSGVO). ASTRAM stelltsicher, dasspersonenbezogene Daten sichererfasst, verarbeitet und gespeichertwerden und dass dies unterEinhaltung von korrekterEinwilligung und Transparenzgeschieht. UnserestrengenDatenschutzma&szlig;nahmensch&uuml;tzenindividuelle Rechte, bietenKontrollm&ouml;glichkeiten f&uuml;r betroffenePersonen und implementierensichereDaten&uuml;bertragungsmechanismen.</p>",
            "image": "",
            "iconClass": "",
            "heading": " DSGVO-Konformität",
            "alt": " DSGVO-Konformität",
            "additional_data": []
        },
        {
            "cmsId": "150",
            "cmsSlug": "hippa-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "HIPAA-Konformität",
            "metaDescriptions": " HIPAA-Konformität",
            "metaKeyword": " HIPAA-Konformität",
            "title": "HIPAA-Konformität",
            "description": "<p><strong>HIPAA-Konformit&auml;t:</strong> ASTRAM legth&ouml;chsten Wert auf die Einhaltung der Datenschutz- und Datensicherheitsstandards in der Gesundheitsbranche. Durch die Einhaltung des Health Insurance Portability and Accountability Act (HIPAA) stellenwir die Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeitgesch&uuml;tzterGesundheitsinformationen (PHI) sicher. UnsereumfassendenSicherheitskontrollen und Schutzmechanismensch&uuml;tzenPatientendaten und erm&ouml;glichen es Gesundheitsorganisationen, ihreProzessesicherzuverwalten und gleichzeitig die Anforderungen der HIPAA einzuhalten.</p>",
            "description2": "<p><strong>HIPAA-Konformit&auml;t:</strong> ASTRAM legth&ouml;chsten Wert auf die Einhaltung der Datenschutz- und Datensicherheitsstandards in der Gesundheitsbranche. Durch die Einhaltung des Health Insurance Portability and Accountability Act (HIPAA) stellenwir die Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeitgesch&uuml;tzterGesundheitsinformationen (PHI) sicher. UnsereumfassendenSicherheitskontrollen und Schutzmechanismensch&uuml;tzenPatientendaten und erm&ouml;glichen es Gesundheitsorganisationen, ihreProzessesicherzuverwalten und gleichzeitig die Anforderungen der HIPAA einzuhalten.</p>",
            "image": "",
            "iconClass": "",
            "heading": "HIPAA-Konformität",
            "alt": "HIPAA-Konformität",
            "additional_data": []
        },
        {
            "cmsId": "151",
            "cmsSlug": "iso-27001-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "ISO 27001-Konformität",
            "metaDescriptions": " ISO 27001-Konformität",
            "metaKeyword": " ISO 27001-Konformität",
            "title": "ISO 27001-Konformität",
            "description": "<p><strong>ISO 27001-Konformit&auml;t:</strong> Obwohl wirnochnicht ISO 27001 zertifiziertsind, erf&uuml;llenwirkonsequent die Anforderungen, um das h&ouml;chsteNiveauimInformations-Sicherheitsmanagementzugew&auml;hrleisten. Durch die Einhaltung des ISO 27001 Standards stellenwir die Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten sicher und bietenunseren Kunden somiteineberuhigendeGewissheit.</p>",
            "description2": "<p><strong>ISO 27001-Konformit&auml;t:</strong> Obwohl wirnochnicht ISO 27001 zertifiziertsind, erf&uuml;llenwirkonsequent die Anforderungen, um das h&ouml;chsteNiveauimInformations-Sicherheitsmanagementzugew&auml;hrleisten. Durch die Einhaltung des ISO 27001 Standards stellenwir die Vertraulichkeit, Integrit&auml;t und Verf&uuml;gbarkeit von Daten sicher und bietenunseren Kunden somiteineberuhigendeGewissheit.</p>",
            "image": "",
            "iconClass": "",
            "heading": "ISO 27001-Konformität",
            "alt": "ISO 27001-Konformität",
            "additional_data": []
        },
        {
            "cmsId": "152",
            "cmsSlug": "pci-dss-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "PCI DSS-Konformität",
            "metaDescriptions": " PCI DSS-Konformität",
            "metaKeyword": " PCI DSS-Konformität",
            "title": "PCI DSS-Konformität",
            "description": "<p><strong>PCI DSS-Konformit&auml;t</strong>: Obwohl wir die Zahlungsplattform f&uuml;r Kreditkartenzahlungennochnicht in ASTRAM integrierthaben, erf&uuml;lltunser System die Anforderungen des Payment Card Industry Data Security Standard (PCI DSS), um einesichereHandhabung von Zahlungskartendatenzugew&auml;hrleisten. Dies wurdegetan, um die Sicherheit f&uuml;r solcheIntegrationen in naher Zukunft zugew&auml;hrleisten. Durch die ImplementierungstrengerSicherheitskontrollen und die Aufrechterhaltung der PCI DSS-Konformit&auml;tsch&uuml;tzenwirKarteninhaberdaten und gew&auml;hrleisteneinesichereUmgebung f&uuml;r die Abwicklung von Zahlungen. UnsereVerpflichtungzurEinhaltung von PCI DSS gibtunseren Kunden die Gewissheit, dassihreFinanztransaktionensicherabgewickeltwerden.</p>",
            "description2": "<p><strong>PCI DSS-Konformit&auml;t</strong>: Obwohl wir die Zahlungsplattform f&uuml;r Kreditkartenzahlungennochnicht in ASTRAM integrierthaben, erf&uuml;lltunser System die Anforderungen des Payment Card Industry Data Security Standard (PCI DSS), um einesichereHandhabung von Zahlungskartendatenzugew&auml;hrleisten. Dies wurdegetan, um die Sicherheit f&uuml;r solcheIntegrationen in naher Zukunft zugew&auml;hrleisten. Durch die ImplementierungstrengerSicherheitskontrollen und die Aufrechterhaltung der PCI DSS-Konformit&auml;tsch&uuml;tzenwirKarteninhaberdaten und gew&auml;hrleisteneinesichereUmgebung f&uuml;r die Abwicklung von Zahlungen. UnsereVerpflichtungzurEinhaltung von PCI DSS gibtunseren Kunden die Gewissheit, dassihreFinanztransaktionensicherabgewickeltwerden.</p>",
            "image": "",
            "iconClass": "",
            "heading": "PCI DSS-Konformität",
            "alt": "PCI DSS-Konformität",
            "additional_data": []
        },
        {
            "cmsId": "153",
            "cmsSlug": "nist-cyber-security-compliance",
            "pageId": "11",
            "sectionId": "49",
            "metaTitle": "NIST Cybersecurity Framework",
            "metaDescriptions": " NIST Cybersecurity Framework",
            "metaKeyword": " NIST Cybersecurity Framework",
            "title": "NIST Cybersecurity Framework",
            "description": "<p><strong>NIST Cybersecurity Framework:</strong> ASTRAM folgt den Richtlinien des National Institute of Standards and Technology (NIST) Cybersecurity Framework, das einenumfassenden Ansatz zurVerwaltung und Minderung von Cybersecurity-Risikenbietet. Durch die Einhaltung des NIST-Frameworks stellenwirsicher, dassunsereSystemevorpotenziellenBedrohungengesch&uuml;tztsind, Schwachstellenidentifiziert und behobenwerden und robuste Cybersecurity-Praktikenimplementiertsind.</p>",
            "description2": "<p><strong>NIST Cybersecurity Framework:</strong> ASTRAM folgt den Richtlinien des National Institute of Standards and Technology (NIST) Cybersecurity Framework, das einenumfassenden Ansatz zurVerwaltung und Minderung von Cybersecurity-Risikenbietet. Durch die Einhaltung des NIST-Frameworks stellenwirsicher, dassunsereSystemevorpotenziellenBedrohungengesch&uuml;tztsind, Schwachstellenidentifiziert und behobenwerden und robuste Cybersecurity-Praktikenimplementiertsind.</p>",
            "image": "",
            "iconClass": "",
            "heading": "NIST Cybersecurity Framework",
            "alt": "NIST Cybersecurity Framework",
            "additional_data": []
        }
    ]
})

const [language, setLanguage] = useState('ENGLISH');
const [dataSourceDetils, setDataSourceDetils] = useState();
 // let { id } = useParams();
  //alert(id);

  useEffect(() => {
    //alert(window.location.pathname);
    let urlPath=window.location.pathname;
    let urlPathSeg=urlPath.split("/");
    let id = urlPathSeg[1];
    //alert(id);
   
    var languageH = localStorage.getItem('language'); 
    if(languageH!=undefined && languageH!=null && languageH!=''){
        setLanguage(languageH);
    }

    if(languageH=='GERMAN'){
        const data = dataSourceDe?.our_security_section;
        const result = data.find(({ cmsSlug }) => cmsSlug === id);

        setDataSource(dataSourceDe) 
        setDataSourceDetils(result);
       
    }else{

        const data = dataSourceEn?.our_security_section;
        const result = data.find(({ cmsSlug }) => cmsSlug === id);
        setDataSource(dataSourceEn)
        setDataSourceDetils(result);

       
    }
    setIsPageLoaded(true);
   //alert(id);
  
   

   //console.log(result); // { id: 2, name: 'Jane' }
  
   //alert(JSON.stringify(result));
   window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    return () => {
      console.log("cleaned up");
    };
  }, [window.location.pathname])
 
  return (
   
   <>
    {isPageLoaded===true &&
   <>
   
     <Helmet>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js" type="text/javascript" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" type="text/javascript" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js" type="text/javascript" />
        {dataSourceDetils?.title &&
        <title>{'ASTRAM-'+dataSourceDetils?.title}</title>
        }
      </Helmet>
  
       
      {/* Header Navigation */}
      <Nav language={language} data=""/>
      {/* end Header Navigation */}

      <InnerTopBanner language={language} dataCms={dataSourceDetils} data={dataSource}/>
      
      <div class="industryApplication-page-innr py-md-5 py-4">
        <SecuriryContent language={language} dataCms={dataSourceDetils} data={dataSource}/>
       
     </div>

      <AboutClient language={language} data={dataSource}/>



       {/* Header Navigation */}
       <Footer language={language} data=""/>
      {/* end Header Navigation */}
 
  </>
}
</>

  );
}

export default SecurityDetailsWithOneSeg;
