import { Link } from "react-router-dom";

const BlogPagination = ({ currentPage, totalPages, onPageChange }) => {
	const pageNumbers = [];

	for (let i = 1; i <= totalPages; i++) {
		pageNumbers.push(i);
	}

	return (
		<div className="blog-pagination">
			<ul className="justify-content-center">
				<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
					<Link className="page-link" onClick={() => onPageChange(1)}>
						First
					</Link>
				</li>
				<li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
					<Link className="page-link" onClick={() => onPageChange(currentPage - 1)}>
						Prev
					</Link>
				</li>
				{pageNumbers.map((number) => (
					<li
						key={number}
						className={`page-item ${number === currentPage ? "active" : ""}`}
					>
						<Link className="page-link" onClick={() => onPageChange(number)}>
							{number}
						</Link>
					</li>
				))}
				<li
					className={`page-item ${
						currentPage === totalPages ? "disabled" : ""
					}`}
				>
					<Link className="page-link" onClick={() => onPageChange(currentPage + 1)}>
						Next
					</Link>
				</li>
				<li
					className={`page-item ${
						currentPage === totalPages ? "disabled" : ""
					}`}
				>
					<Link className="page-link" onClick={() => onPageChange(totalPages)}>
						Last
					</Link>
				</li>
			</ul>
		</div>
	);
};

export default BlogPagination;
