
//import ThumbImg from "../../assets/img/module-img.png";
import BgImage from "../../assets/img/module-bg.jpg";
///import Accordion from 'react-bootstrap/Accordion';
function AstramSolutionsAndMoule({data,language}) {
  return (
   
    <div className="servicesModule-section py-5" style={{ 
        backgroundImage: `url(${BgImage})` 
      }}>
    <div className="container">
       
        {language=='ENGLISH' &&
                     <h2 className="text-center f600 mb-4">ASTRAM Solutions and <span>Modules</span></h2>
                    }
                    {language=='GERMAN' &&
                      <h2 className="text-center f600 mb-4">ASTRAM-ST Lösungen und <span>Module</span></h2>
                    }
        <div id="servicesModuleCaptions" className="carousel slide carousel-fade"   data-bs-ride="carousel" data-bs-touch="true">
            <div className="carousel-inner">


           

             

            
          

            {data?.astram_solutions_and_modules_block_home_page && data?.astram_solutions_and_modules_block_home_page.slice(0, 6).map((row, index) => (

                index===0?(
                    <div class="carousel-item active">
                    <div class="row align-items-center">
                        <div class="col-md-6 order-lg-2 ps-xl-5">
                            <img src={row?.image} width="561" height="474" alt={row?.title}/>
                        </div> 
                        <div class="col-md-6 order-lg-1 pe-xl-5">
                            <div class="cms">
                                <p class="f300 mb-2"><strong class="flg">{row?.title}:</strong><br/>
                                <p dangerouslySetInnerHTML={{__html: row?.description}}></p>
                                </p>
                            </div>
                            <div class="accordion" id={"accordionExample"+index}>
                            {row?.additional_data?.listing && row?.additional_data?.listing.map((rowf, indexF) => (

                                    indexF===0?(
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={"headingOneAaccMod"+index+indexF}>
                                            <button class="accordion-button fxxl f500" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOneAaccMod"+index+indexF} aria-expanded="true" aria-controls={"collapseOneAaccMod"+index+indexF}>
                                            {rowf?.title}
                                            </button>
                                        </h2>
                                        <div id={"collapseOneAaccMod"+index+indexF} class="accordion-collapse collapse show" aria-labelledby={"headingOneAaccMod"+index+indexF} data-bs-parent={"#accordionExample"+index}>
                                            <div class="accordion-body cms">
                                            <p dangerouslySetInnerHTML={{__html: rowf?.description}}></p>
                                            </div>
                                        </div>
                                    </div>
                                    ):(
                                       
                                    <div class="accordion-item">
                                        <h2 class="accordion-header" id={"headingTwoAaccMod"+index+indexF}>
                                        <button class="accordion-button collapsed  fxxl f500" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseTwoAaccMod"+index+indexF} aria-expanded="false" aria-controls={"collapseTwoAaccMod"+index+indexF}>
                                        {rowf?.title}
                                        </button>
                                        </h2>
                                        <div id={"collapseTwoAaccMod"+index+indexF} class="accordion-collapse collapse" aria-labelledby={"headingTwoAaccMod"+index+indexF} data-bs-parent={"#accordionExample"+index}>
                                            <div class="accordion-body cms">
                                            <p dangerouslySetInnerHTML={{__html: rowf?.description}}></p>
                                            </div>
                                        </div>
                                    </div>

                                    )
                            ))}
                              
                            </div>
                        </div>                
                    </div>
                    </div>
                ):(

                    <div class="carousel-item">
                    <div class="row align-items-center">
                        <div class="col-md-6 order-lg-2 ps-xl-5">
                            <img src={row?.image} width="561" height="474" alt={row?.title}/>
                        </div> 
                        <div class="col-md-6 order-lg-1 pe-xl-5">
                            <div class="cms">
                            <p class="f300 mb-2"><strong class="flg">{row?.title}:</strong><br/>
                                <p dangerouslySetInnerHTML={{__html: row?.description}}></p>
                                </p>
                            </div>
                            <div class="accordion" id={"accordionExample"+index}>

                            {row?.additional_data?.listing && row?.additional_data?.listing.map((rowf, indexF) => (

                                    indexF===0?( 
                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id={"headingTwoAaccMod"+index+indexF}>
                                                <button class="accordion-button fxxl f500" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseTwoAaccMod"+index+indexF} aria-expanded="true" aria-controls={"collapseTwoAaccMod"+index+indexF}>
                                                {rowf?.title}
                                                </button>
                                            </h2>
                                            <div id={"collapseTwoAaccMod"+index+indexF} class="accordion-collapse collapse show" aria-labelledby={"#headingTwoAaccMod"+index+indexF} data-bs-parent={"#accordionExample"+index}>
                                                <div class="accordion-body cms">
                                                <p dangerouslySetInnerHTML={{__html: rowf?.description}}></p>
                                                </div>
                                            </div>
                                        </div>
                                    ):(

                                        <div class="accordion-item">
                                            <h2 class="accordion-header" id={"headingTwoAaccMod"+index+indexF}>
                                            <button class="accordion-button collapsed  fxxl f500" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseTwoAaccMod"+index+indexF} aria-expanded="false" aria-controls={"collapseTwoAaccMod"+index+indexF}>
                                            {rowf?.title}
                                            </button>
                                            </h2>
                                            <div id={"collapseTwoAaccMod"+index+indexF} class="accordion-collapse collapse" aria-labelledby={"#headingTwoAaccMod"+index+indexF} data-bs-parent={"#accordionExample"+index}>
                                                <div class="accordion-body cms">
                                                <p dangerouslySetInnerHTML={{__html: rowf?.description}}></p>
                                                </div>
                                            </div>
                                        </div>
                                    )

                                ))}
                              
                            </div>
                        </div>                
                    </div>
                </div>

                )


            ))}
               
           

                
           
            </div>
            <div className="carousel-indicators carousel-indicators-banner carousel-indicators-module">
            {data?.astram_solutions_and_modules_block_home_page && data?.astram_solutions_and_modules_block_home_page.slice(0, 6).map((row, index) => (

                index===0?(
                <button type="button" data-bs-target="#servicesModuleCaptions" data-bs-slide-to={index} className="active" aria-current="true" aria-label="Slide 1"></button>
                ):(
                <button type="button" data-bs-target="#servicesModuleCaptions" data-bs-slide-to={index} aria-label="Slide 2"></button>
                )
                ))}
            </div>
        </div>            
    </div>
</div>

  );
}

export default AstramSolutionsAndMoule;
