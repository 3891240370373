
//import {Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
//import ThumbImg from "../../assets/img/team.jpg";
function AboutTeam({data,language}) {
  return (
   
    <>
    {data?.astram_team_home_page &&
    <div className="about-team py-5">
    <div className="container py-lg-5 px-lg-5">
        <div className="row align-items-center">
            <div className="col-md-6">
                <img src={data?.astram_team_home_page[0]?.image} width={724} height={406} alt={data?.astram_team_home_page[0]?.title}/>
            </div>
            <div className="col-md-6 cms pt-4 pt-md-0 text-md-left text-center">
                <h4 className="text-uppercase f600">ASTRAM <span>Team</span></h4>
                <p dangerouslySetInnerHTML={{__html: data?.astram_team_home_page[0]?.description}}></p>

                {language=='ENGLISH' &&
                <HashLink className="btn btn-bg-main-light f500" to="/about-us#team">Discover More</HashLink>    
              }  
              {language=='GERMAN' &&
               <HashLink className="btn btn-bg-main-light f500" to="/about-us#team">Mehr lesen</HashLink>
             
              }  
               
            </div>
        </div>
    </div>
   </div>
   }
   </>

  );
}

export default AboutTeam;
