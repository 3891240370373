
import logo from "../../assets/img/logo.webp";
import deFL from "../../assets/img/germany-flag-icon.svg";
import enFL from "../../assets/img/united-kingdom-flag-icon.svg";
import {Link } from "react-router-dom";

import Dropdown from 'react-bootstrap/Dropdown';

import React , { useState,useEffect} from 'react';


function Header() {
    const [language, setLanguage] = useState('ENGLISH');
    const [isPageLoaded, setIsPageLoaded] = useState(false);


 const handleSelect = (e) => {
    //this.saySomething("element clicked");
    //alert(e);
   
    localStorage.setItem('language', e);
    setLanguage(e);
    window.location.reload();
    
  }


  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    let languageH = localStorage.getItem('language'); 
    if(languageH!=undefined && languageH!=null && languageH!=''){
        setLanguage(languageH);
    }
    
    setIsPageLoaded(true)
    
    return () => {
      console.log("login cleaned up");
    };
  }, [])




  return (
   
<>
{isPageLoaded==true &&
<nav id="navigazione" className="navbar navbar-expand-lg navbar-fixed">
   
    <div className="container justify-content-start">
        <Link className="navbar-brand" to="/">
            <img src={logo} alt=""/>
        </Link>                
        <div className="offcanvas offcanvas-start" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
                <div className="offcanvas-title">Menu</div>
                <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body ms-lg-auto">
            {language=='ENGLISH' ?(
                <ul className="nav-min d-lg-flex">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about-us">About Us</Link></li>
                    <li><Link to="/our-solutions-and-business">Our Solutions & Business Areas</Link></li>
                    <li><Link to="/technology-hub">Technology Hub</Link></li>
                    <li><Link to="/contact">Contact</Link></li>                    
                    <li><Link to="/blog">Blog</Link></li>
                </ul>     
                ):(
                    <ul className="nav-min d-lg-flex">
                    <li><Link to="/">Home</Link></li>
                    <li><Link to="/about-us">Über uns</Link></li>
                    <li><Link to="/our-solutions-and-business">Unsere Lösungen & Geschäftsfelder</Link></li>
                    <li><Link to="/technology-hub">Technologiezentrum</Link></li>
                    <li><Link to="/contact">Kontakt</Link></li>                    
                    <li><Link to="/blog">Blog</Link></li>
                   </ul> 
                )}               
            </div>
        </div>
        <Link className="btn btn-sm btn-radius text-uppercase btn-bg-main ms-auto f400 navbar-btn"  to="https://www.astram.tech/">Log in</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar">
            <span className="navbar-toggler-icon"></span>
        </button>
     
        <Dropdown  onSelect={handleSelect}  className="dropdown-navbar-lang">
            <Dropdown.Toggle id="dropdown-basic">
                {language=='ENGLISH' ?(
                    <>
                <img src={enFL} alt=""/> ENGLISH
                </>
                ):( <>
                    <img src={deFL} alt=""/> GERMAN
                    </>)}
            </Dropdown.Toggle>

            <Dropdown.Menu  id="langTtem">
                <Dropdown.Item  eventKey="ENGLISH"><img src={enFL} alt=""/> ENGLISH</Dropdown.Item>
                <Dropdown.Item  eventKey="GERMAN"><img src={deFL} alt=""/> GERMAN</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
       

                   
    </div>
</nav>

}

</>

  );
}

export default Header;
