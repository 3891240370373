import banBgImage from "../../assets/img/banner-bg.webp";
import Slider from "react-slick";
import { Link } from "react-router-dom";
//import Carousel from 'react-bootstrap/Carousel';

function Banner({ data }) {
	const settings = {
		dots: true,
		infinite: true,
		speed: 500,
		autoplay: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		fade: true,
	};

	return (
		<>
			{data?.slider_home_page && (
				<div
					className="banner banner-home"
					style={{
						backgroundImage: `url(${banBgImage})`,
					}}
				>
					<Slider {...settings}>
						{data?.slider_home_page.map((row, index) => (
							<div key={index}>
								<div className="container">
									<div className="row align-items-center">
										<div className="col-lg-5 order-lg-2">
											<img src={row?.image} alt={row?.alt} />
										</div>
										<div className="col-lg-7 order-lg-1">
											<p
												dangerouslySetInnerHTML={{ __html: row?.description }}
											></p>
											{row?.additional_data?.listing && (
												<ul className="d-flex flex-wrap">
													{row?.additional_data?.listing.map(
														(rowBpc, indexBpc) => (
															<li className="btn btn-sm btn-bg-main-light f500 d-flex align-items-center py-2 px-2 pe-1" key={index}>
																<Link
																	className="d-flex align-items-center"
																	to={"/" + rowBpc?.cmsSlug}
																>
																	<img
																		src={rowBpc?.image}
																		alt={rowBpc?.title}
																	/>
																	{rowBpc?.title}
																</Link>
															</li>
														)
													)}
												</ul>
											)}
										</div>
									</div>
								</div>
							</div>
						))}
					</Slider>
				</div>
			)}
		</>
	);
}
export default Banner;
