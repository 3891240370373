


import React , { useState,useEffect} from 'react';
import Nav from "../layouts/header";
import Footer from "../layouts/footer";
import InnerTopBanner from "../components/inner-page-banner/ban-04";
import AboutClient from "../components/about-client-testimonials";
import ContactInfoForm from "../components/contact-form";





import {Helmet} from "react-helmet";
function Contact() {

    const [dataSourceEn, setDataSourceEn] = useState({
        "pageName": "Contact Us Page ",
        "metaTitle": "NDT & Quality Management Partner India, South Africa, Germany.",
        "metaDescription": "Contact ASTRAM for Asset Tracking, Maintenance Management, Inspection Reports, Quality Control, NDT Inspection Software, Inventory Management, Compliance, Data Analytics, and more. Serving major cities.",
        "metaKeyword": "    ",
        "inner_top_banner_contact_us_page": [
            {
                "cmsId": "94",
                "pageId": "8",
                "sectionId": "43",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Contact Us",
                "description": "<p>Welcome Astram</p>",
                "description2": "<p>Welcome Astram</p>",
                "image": require('../assets/img/cms/content/about-banner3.jpg'),
                "iconClass": "",
                "heading": "Contact Us",
                "alt": "Contact Us",
                "additional_data": []
            }
        ],
        "contact_us_content_contact_us_page": [
            {
                "cmsId": "95",
                "pageId": "8",
                "sectionId": "44",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Please contact us with any Questions.",
                "description": "<p>We will answer you promptly. We also appreciate your feedback.</p>",
                "description2": "<p>We will answer you promptly. We also appreciate your feedback.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Please contact us with any Questions.",
                "alt": "Please contact us with any Questions.",
                "additional_data": {
                    "map_image": [
                        {
                            "cmsId": "95",
                            "pageId": "8",
                            "title": "Map Image",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "email": [
                        {
                            "cmsId": "95",
                            "pageId": "8",
                            "title": "Email",
                            "description": "info@astramtech.com",
                            "image": ""
                        }
                    ],
                    "Phone": [
                        {
                            "cmsId": "95",
                            "pageId": "8",
                            "title": "Call Now",
                            "description": "+27 71 898 3840",
                            "image": ""
                        }
                    ]
                }
            }
        ]
    })


    const [dataSourceDe, setDataSourceDe] = useState({
        "pageName": "Contact Us Page ",
        "metaTitle": "Contact ASTRAM | Your NDT & Quality Management Partner India, South Africa, Germany",
        "metaDescription": "Get in touch with ASTRAM for NDT, quality management, and inspection solutions in Pretoria, Johannesburg, Cape Town, Durban, Bengaluru, Chennai, Hyderabad, Pune, Mumbai, Kolkata, Berlin, Munich, Frankfurt, Hamburg, Cologne, Düsseldorf, and beyond.",
        "metaKeyword": "    ",
        "inner_top_banner_contact_us_page": [
            {
                "cmsId": "94",
                "pageId": "8",
                "sectionId": "43",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Contact Us",
                "description": "<p>Welcome Astram</p>",
                "description2": "<p>Welcome Astram</p>",
                "image": require('../assets/img/cms/content/about-banner3.jpg'),
                "iconClass": "",
                "heading": "Contact Us",
                "alt": "Contact Us",
                "additional_data": []
            }
        ],
        "contact_us_content_contact_us_page": [
            {
                "cmsId": "95",
                "pageId": "8",
                "sectionId": "44",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Please contact us with any Questions.",
                "description": "<p>We will answer you promptly. We also appreciate your feedback.</p>",
                "description2": "<p>We will answer you promptly. We also appreciate your feedback.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Please contact us with any Questions.",
                "alt": "Please contact us with any Questions.",
                "additional_data": {
                    "map_image": [
                        {
                            "cmsId": "95",
                            "pageId": "8",
                            "title": "Map Image",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "email": [
                        {
                            "cmsId": "95",
                            "pageId": "8",
                            "title": "Email",
                            "description": "info@astramtech.com",
                            "image": ""
                        }
                    ],
                    "Phone": [
                        {
                            "cmsId": "95",
                            "pageId": "8",
                            "title": "Call Now",
                            "description": "+27 71 898 3840",
                            "image": ""
                        }
                    ]
                }
            }
        ]
    })

    const [dataSource, setDataSource] = useState({
    "pageName": "Contact Us Page ",
    "metaTitle": "Contact",
    "metaDescription": "    ",
    "metaKeyword": "    ",
    "inner_top_banner_contact_us_page": [
        {
            "cmsId": "94",
            "pageId": "8",
            "sectionId": "43",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Contact Us",
            "description": "<p>Welcome Astram</p>",
            "description2": "<p>Welcome Astram</p>",
            "image": require('../assets/img/cms/content/about-banner3.jpg'),
            "iconClass": "",
            "heading": "Contact Us",
            "alt": "Contact Us",
            "additional_data": []
        }
    ],
    "contact_us_content_contact_us_page": [
        {
            "cmsId": "95",
            "pageId": "8",
            "sectionId": "44",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Please contact us with any Questions.",
            "description": "<p>We will answer you promptly. We also appreciate your feedback.</p>",
            "description2": "<p>We will answer you promptly. We also appreciate your feedback.</p>",
            "image": "",
            "iconClass": "",
            "heading": "Please contact us with any Questions.",
            "alt": "Please contact us with any Questions.",
            "additional_data": {
                "map_image": [
                    {
                        "cmsId": "95",
                        "pageId": "8",
                        "title": "Map Image",
                        "description": "",
                        "image": ""
                    }
                ],
                "email": [
                    {
                        "cmsId": "95",
                        "pageId": "8",
                        "title": "Email",
                        "description": "info@astramtech.com",
                        "image": ""
                    }
                ],
                "Phone": [
                    {
                        "cmsId": "95",
                        "pageId": "8",
                        "title": "Call Now",
                        "description": "+27 71 898 3840",
                        "image": ""
                    }
                ]
            }
        }
    ]
    })

const [language, setLanguage] = useState('ENGLISH');
const [isPageLoaded, setIsPageLoaded] =useState(false);
useEffect(() => {
     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
     var languageH = localStorage.getItem('language'); 
     if(languageH!=undefined && languageH!=null && languageH!=''){
         setLanguage(languageH);
     }

    if(languageH=='GERMAN'){
        setDataSource(dataSourceDe) ;
    }else{
        setDataSource(dataSourceEn);
    }

    const fetchMyAPI = async () => {
      try {

       
        await window.extraScript();
           /* var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
           

            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            const response = await fetch(
            'http://localhost/astram2/list-cms-content/4', requestOptions
            );
            const json = await response.json();
            setDataSource(json);*/
            //console.log(json);
            //console.log('Image Chinmoy',json['home-page-slider-background'][0]);
            
       
      }catch (error) {
        console.log(error);
      } 
    }
    setIsPageLoaded(true);
    //fetchMyAPI()
    return () => {
      console.log("login cleaned up");
    };
  }, [])




  return (
   
   <>
    {isPageLoaded===true &&
   <>
   <Helmet>


   <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js" type="text/javascript" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" type="text/javascript" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js" type="text/javascript" />
       
        <title>ASTRAM - {dataSource.metaTitle}</title>
      </Helmet>
      {/* Header Navigation */}
      <Nav data={dataSource}/>
     
      {/* end Header Navigation */}
      <InnerTopBanner language={language} data={dataSource}/>
      <ContactInfoForm language={language} data={dataSource}/>
      <AboutClient language={language} data={dataSource}/>
    
       {/* Header Navigation */}
       <Footer language={language} data={dataSource}/>
      {/* end Header Navigation */}

      

     
  </>
}
</>

  );
}

export default Contact;
