import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SkeletonLoader from "../skeleton-loader";
import noPostsFoundImg from "../../assets/img/no-posts-found.png";

const apiUrl = process.env.REACT_APP_API_URL;

const BlogTags = () => {
	const [language, setLanguage] = useState("ENGLISH");
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [postTags, setPostTags] = useState([]);

	useEffect(() => {
		var languageH = localStorage.getItem("language");
		if (languageH !== undefined && languageH !== null && languageH !== "") {
			setLanguage(languageH);
		}
		fetchTags();
	}, []);

	const fetchTags = async () => {
		try {
			const response = await fetch(`${apiUrl}/list-tags`);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			if (data) {
				setPostTags(data);
			} else {
				throw new Error("No data returned");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading ? (
				<div className="py-4">
					<SkeletonLoader />
				</div>
			) : error || typeof postTags === "undefined" ? (
				<div className="text-center">
					<img src={noPostsFoundImg} alt="No Categories Found" />
				</div>
			) : (
				<div className="sidebar-item tags">
					<h3 className="sidebar-title">Tags</h3>
					<ul className="mt-3">
						{language === "ENGLISH" && (
							<>
								{postTags.tags.map((category, index) => (
									<li key={index}>
										<Link>{category?.title || "NA"}</Link>
									</li>
								))}
							</>
						)}
						{language === "GERMAN" && (
							<>
								{postTags.germanTags.map((category, index) => (
									<li key={index}>
										<Link>{category?.germanTitle || "NA"}</Link>
									</li>
								))}
							</>
						)}
					</ul>
				</div>
			)}
		</>
	);
};

export default BlogTags;
