


import React , { useState,useEffect} from 'react';
import Nav from "../layouts/header";
import Footer from "../layouts/footer/footer01";

import Banner from "../components/banner";
import AboutUsSection from "../components/about-us-section";
import OurSolutionsAndBusiness from "../components/our-solutions-and-business";
import IndustrySection from "../components/industry-section";
import AstramSoftwareTechnology from "../components/astram-software-technology";
import AboutServices from "../components/about-services";


import AboutClientTestimonials from "../components/about-client-testimonials";
import TestimonialSection from "../components/testimonial-section";
import AboutTeam from "../components/about-team";
import AstramSolutionsAndMoule from "../components/astram-soluttions-and-module";



import {Helmet} from "react-helmet";



function Home() {


    const [dataSourceEn, setDataSourceEn] = useState({
        "pageName": "Home Page",
        "metaTitle": "NDT Software India, South Africa, Germany (Non-Destructive Testing)",
        "metaDescription": "Revolutionize NDT with ASTRAM: Inspection Management, Reporting, Tracking, and Quality Assurance across Pretoria, Johannesburg, Cape Town, Durban, Bengaluru, Chennai, Hyderabad, Pune, Mumbai, Kolkata.",
        "metaKeyword": "NDT (Non-Destructive Testing) Software Pretoria Johannesburg Pretoria Johannesburg Cape Town Durban Bengaluru Chennai Hyderabad Pune Mumbai Kolkata, Preventive Maintenance Johannesburg Pretoria Johannesburg Cape Town Durban Bengaluru Chennai Hyderabad Pune Mumbai Kolkata, Quality management system Johannesburg Pretoria Johannesburg Cape Town Durban Bengaluru Chennai Hyderabad Pune Mumbai Kolkata, Inspection management Johannesburg Pretoria Johannesburg Cape Town Durban Bengaluru Chennai Hyderabad Pune Mumbai Kolkata, Inspection reporting Johannesburg Pretoria Johannesburg Cape Town Durban Bengaluru Chennai Hyderabad Pune Mumbai Kolkata, Inspection Tracking Johannesburg Pretoria Johannesburg Cape Town Durban Bengaluru Chennai Hyderabad Pune Mumbai Kolkata, Quality Assurance Johannesburg Pretoria Johannesburg Cape Town Durban Bengaluru Chennai Hyderabad Pune Mumbai Kolkata",
        "slider_home_page": [
            {
                "cmsId": "30",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "15",
                "metaTitle": "",
                "metaDescriptions": "                         ",
                "metaKeyword": "                         ",
                "title": "Home Page Slider 1",
                "description": "<h2>A fully functional flexible <br />and futuristic application that can</h2>\r\n<h3>be tailored to suit your needs.</h3>\r\n<p>ASTRAM is a B2B platform developed to provide businesses the flexibility to create their solution in their own way. We believe that every organization is unique and has its own way of doing business. The platform is designed to help businesses introduce transparency and accountability across roles within and outside organizational boundaries.</p>\r\n<h4>Compliance with it best <span>Practices</span></h4> ",
                "description2": "<h2>A fully functional flexible <br />and futuristic application that can</h2>\r\n<h3>be tailored to suit your needs.</h3>\r\n<p>ASTRAM is a B2B platform developed to provide businesses the flexibility to create their solution in their own way. We believe that every organization is unique and has its own way of doing business. The platform is designed to help businesses introduce transparency and accountability across roles within and outside organizational boundaries.</p>\r\n<h4>Compliance with it best <span>Practices</span></h4> ",
                "image": require('../assets/img/cms/content/banner_11.png'),
                "iconClass": "",
                "heading": "Home Page Slider 1",
                "alt": "Home Page Slider 1",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "30",
                            "pageId": "4",
                            "cmsSlug": "aws-well-architected-framework",
                            "title": "AWS well architected Framework",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_1.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "pci-dss-compliance",
                            "pageId": "4",
                            "title": "PCI DSS Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/pvc.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "nist-cyber-security-compliance",
                            "pageId": "4",
                            "title": "NIST Cyber Security Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_3.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "iso-27001-compliance",
                            "pageId": "4",
                            "title": "ISO 27001 Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_5.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "HIPPA Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_9.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "gdpr-compliance",
                            "pageId": "4",
                            "title": "GDPR Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_10.png')
                        }
                    ]
                }
            },
            {
                "cmsId": "31",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "15",
                "metaTitle": "",
                "metaDescriptions": "            ",
                "metaKeyword": "            ",
                "title": "Home Page Slider 2",
                "description": "<h2>A fully functional flexible <br />and futuristic application that can</h2>\r\n<h3>be tailored to suit your needs.</h3>\r\n<p>ASTRAM is a B2B platform developed to provide businesses the flexibility to create their solution in their own way. We believe that every organization is unique and has its own way of doing business. The platform is designed to help businesses introduce transparency and accountability across roles within and outside organizational boundaries.</p>\r\n<h4>Compliance with it best <span>Practices</span></h4>",
                "description2": "<h2>A fully functional flexible <br />and futuristic application that can</h2>\r\n<h3>be tailored to suit your needs.</h3>\r\n<p>ASTRAM is a B2B platform developed to provide businesses the flexibility to create their solution in their own way. We believe that every organization is unique and has its own way of doing business. The platform is designed to help businesses introduce transparency and accountability across roles within and outside organizational boundaries.</p>\r\n<h4>Compliance with it best <span>Practices</span></h4>",
                "image": require('../assets/img/cms/content/banner_22.png'),
                "iconClass": "",
                "heading": "Home Page Slider 2",
                "alt": "Home Page Slider 2",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "30",
                            "pageId": "4",
                            "cmsSlug": "aws-well-architected-framework",
                            "title": "AWS well architected Framework",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_1.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "pci-dss-compliance",
                            "pageId": "4",
                            "title": "PCI DSS Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/pvc.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "nist-cyber-security-compliance",
                            "pageId": "4",
                            "title": "NIST Cyber Security Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_3.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "iso-27001-compliance",
                            "pageId": "4",
                            "title": "ISO 27001 Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_5.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "HIPPA Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_9.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "gdpr-compliance",
                            "pageId": "4",
                            "title": "GDPR Compliance",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_10.png')
                        }
                    ]
                }
            }
        ],
        "about_us_content_home_page": [
            {
                "cmsId": "32",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "16",
                "metaTitle": "",
                "metaDescriptions": "                    ",
                "metaKeyword": "                    ",
                "title": "About Us",
                "description": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">ASTRAM is a versatile software solution designed to enable businesses to optimize their processes and operations for a more sustainable and efficient future. With ASTRAM, businesses can connect their engineering expertise with software to streamline manufacturing, transportation, power generation, and other operations. Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better.</span></p>",
                "description2": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">ASTRAM is a versatile software solution designed to enable businesses to optimize their processes and operations for a more sustainable and efficient future. With ASTRAM, businesses can connect their engineering expertise with software to streamline manufacturing, transportation, power generation, and other operations. Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better.</span></p>",
                "image": "",
                "iconClass": "",
                "heading": "About Us",
                "alt": "About Us",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "32",
                            "pageId": "4",
                            "title": "",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_3.png')
                        },
                        {
                            "cmsId": "32",
                            "pageId": "4",
                            "title": "",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_1.png')
                        }
                    ]
                }
            }
        ],
        "about_us_block_home_page": [
            {
                "cmsId": "33",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "17",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Our Vision",
                "description": "<p>Our overall goal is to provide businesses with a flexible and adaptable software solution that can be tailored to their unique needs and..</p>",
                "description2": "<p>Our overall goal is to provide businesses with a flexible and adaptable software solution that can be tailored to their unique needs and..</p>",
                "image": require('../assets/img/cms/content/a_032.png'),
                "iconClass": "",
                "heading": "Our Vision",
                "alt": "Our Vision",
                "additional_data": []
            },
            {
                "cmsId": "34",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "17",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Technology and digital transformation",
                "description": "<p>Our world-class technology and domain-led digital capabilities are deeply embedded in our DNA. Maintaining and improving ..</p>",
                "description2": "<p>Our world-class technology and domain-led digital capabilities are deeply embedded in our DNA. Maintaining and improving ..</p>",
                "image": require('../assets/img/cms/content/a_01.png'),
                "iconClass": "",
                "heading": "Technology and digital transformation",
                "alt": "Technology and digital transformation",
                "additional_data": []
            },
            {
                "cmsId": "35",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "17",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Connectivity Collaboration and Innovation ",
                "description": "<p>ASTRAM's ability to communicate with machines through its API gateway is closely linked to the software's broader focus on connectivity..</p>",
                "description2": "<p>ASTRAM's ability to communicate with machines through its API gateway is closely linked to the software's broader focus on connectivity..</p>",
                "image": require('../assets/img/cms/content/a_02.png'),
                "iconClass": "",
                "heading": "Connectivity Collaboration and Innovation ",
                "alt": "Connectivity Collaboration and Innovation ",
                "additional_data": []
            },
            {
                "cmsId": "36",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "17",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Sustainability",
                "description": "<p>ASTRAM Team is committed to sustainability,which means balancing the needs of society, the environment,and the economy to create ..</p>",
                "description2": "<p>ASTRAM Team is committed to sustainability,which means balancing the needs of society, the environment,and the economy to create ..</p>",
                "image": require('../assets/img/cms/content/a_03.png'),
                "iconClass": "",
                "heading": "Sustainability",
                "alt": "Sustainability",
                "additional_data": []
            }
        ],
        "our_solution_business_areas_home_page": [
            {
                "cmsId": "37",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "18",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Our Solution & Business areas",
                "description": "<p>ASTRAM offers a comprehensive suite of modules to meet the diverse needs of industries modules that can be adapted to suit the specific requirements of these industries and businesses. These modules are designed to enable digitization, streamline processes, enhance collaboration, and optimize performance. These Modules can be configured to automate and streamline various aspects of operations, from asset management to quality control to health and safety management and more. Each of these modules are highly configurable and can be tailored to meet specific requirements. With its user-friendly interface, robust functionalities, and real-time data analytics, ASTRAM provides an end-to-end solution to help businesses improve efficiency, reduce costs, and drive growth</p>",
                "description2": "<p>ASTRAM offers a comprehensive suite of modules to meet the diverse needs of industries modules that can be adapted to suit the specific requirements of these industries and businesses. These modules are designed to enable digitization, streamline processes, enhance collaboration, and optimize performance. These Modules can be configured to automate and streamline various aspects of operations, from asset management to quality control to health and safety management and more. Each of these modules are highly configurable and can be tailored to meet specific requirements. With its user-friendly interface, robust functionalities, and real-time data analytics, ASTRAM provides an end-to-end solution to help businesses improve efficiency, reduce costs, and drive growth</p>",
                "image": "",
                "iconClass": "",
                "heading": "Our Solution & Business areas",
                "alt": "Our Solution & Business areas",
                "additional_data": []
            }
        ],
        "my_industry_title_home_page": [
            {
                "cmsId": "42",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "20",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Industry Applications",
                "description": "<h2 class=\"h3 pe-3 bg-white position-relative f600 text-uppercase d-inline-block mb-0\">Industry <span>Applications</span></h2>",
                "description2": "<h2 class=\"h3 pe-3 bg-white position-relative f600 text-uppercase d-inline-block mb-0\">Industry <span>Applications</span></h2>",
                "image": "",
                "iconClass": "",
                "heading": "Industry Applications",
                "alt": "Industry Applications",
                "additional_data": []
            }
        ],
        "my_industry_home_page": [
            {
                "cmsId": "38",
                "cmsSlug": "nuclear",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Nuclear",
                "description": "<p>Nuclear Power plants can implement ASTRAM to manage assets, control quality, manage risk, facilitate audits and manage training and certification. By using ASTRAM in the nuclear industry, companies can improve safety, reduce costs, and increase efficiency.</p> ",
                "description2": "<p>Nuclear Power plants can implement ASTRAM to manage assets, control quality, manage risk, facilitate audits and manage training and certification. By using ASTRAM in the nuclear industry, companies can improve safety, reduce costs, and increase efficiency.</p> ",
                "image": require('../assets/img/cms/content/01.png'),
                "iconClass": "",
                "heading": "Nuclear",
                "alt": "Nuclear",
                "additional_data": []
            },
            {
                "cmsId": "39",
                "cmsSlug": "power-plants-and-utilities",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Power Plants and Utilities",
                "description": "<p>ASTRAM can be helpful in all kinds of power plants and energy installations by managing maintenance, environmental compliance, safety, production optimization, and asset management. By using ASTRAM in power plants and energy installations, companies can improve safety, reduce downtime, extend the lifespan of equipment, and optimize production.</p>",
                "description2": "<p>ASTRAM can be helpful in all kinds of power plants and energy installations by managing maintenance, environmental compliance, safety, production optimization, and asset management. By using ASTRAM in power plants and energy installations, companies can improve safety, reduce downtime, extend the lifespan of equipment, and optimize production.</p>",
                "image": require('../assets/img/cms/content/02.png'),
                "iconClass": "",
                "heading": "Power Plants and Utilities",
                "alt": "Power Plants and Utilities",
                "additional_data": []
            },
            {
                "cmsId": "40",
                "cmsSlug": "mining",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Mining",
                "description": "<p>ASTRAM can be helpful in the mining sector by managing asset, safety, and environmental compliance, optimizing production, and managing the supply chain. By using ASTRAM in mining operations, companies can improve safety, reduce downtime, optimize production, and manage the supply chain more effectively.</p>",
                "description2": "<p>ASTRAM can be helpful in the mining sector by managing asset, safety, and environmental compliance, optimizing production, and managing the supply chain. By using ASTRAM in mining operations, companies can improve safety, reduce downtime, optimize production, and manage the supply chain more effectively.</p>",
                "image": require('../assets/img/cms/content/03.png'),
                "iconClass": "",
                "heading": "Mining",
                "alt": "Mining",
                "additional_data": []
            },
            {
                "cmsId": "41",
                "cmsSlug": "pharmaceutical",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Pharmaceutical",
                "description": "<p>ASTRAM can be used in the pharmaceutical sector to manage quality control, compliance, inventory, clinical trials, and production optimization. By using ASTRAM in the pharmaceutical industry, companies can improve quality, reduce compliance risk, optimize inventory management, improve clinical trial processes, and improve production efficiency.</p>",
                "description2": "<p>ASTRAM can be used in the pharmaceutical sector to manage quality control, compliance, inventory, clinical trials, and production optimization. By using ASTRAM in the pharmaceutical industry, companies can improve quality, reduce compliance risk, optimize inventory management, improve clinical trial processes, and improve production efficiency.</p>",
                "image": require('../assets/img/cms/content/04.png'),
                "iconClass": "",
                "heading": "Pharmaceutical",
                "alt": "Pharmaceutical",
                "additional_data": []
            },
            {
                "cmsId": "107",
                "cmsSlug": "laboratories",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Laboratories",
                "description": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
                "description2": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
                "image": require('../assets/img/cms/content/Laboratory_Management1.png'),
                "iconClass": "",
                "heading": "Laboratories",
                "alt": "Laboratories",
                "additional_data": []
            },
            {
                "cmsId": "108",
                "cmsSlug": "international-trade",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "International Trade",
                "description": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions</p>",
                "description2": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions</p>",
                "image": require('../assets/img/cms/content/International_Trade1.png'),
                "iconClass": "",
                "heading": "International Trade",
                "alt": "International Trade",
                "additional_data": []
            },
            {
                "cmsId": "109",
                "cmsSlug": "accreditation-bodies",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Accreditation Bodies",
                "description": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
                "description2": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
                "image": require('../assets/img/cms/content/Accreditation_Management2.png'),
                "iconClass": "",
                "heading": "Accreditation Bodies",
                "alt": "Accreditation Bodies",
                "additional_data": []
            },
            {
                "cmsId": "110",
                "cmsSlug": "oil-and-gas-and-petrochemical",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Oil and Gas and Petrochemical",
                "description": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
                "description2": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
                "image": require('../assets/img/cms/content/Oil_and_Gas_and_Petrochemical.png'),
                "iconClass": "",
                "heading": "Oil and Gas and Petrochemical",
                "alt": "Oil and Gas and Petrochemical",
                "additional_data": []
            },
            {
                "cmsId": "111",
                "cmsSlug": "manufacturing",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Manufacturing",
                "description": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
                "description2": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
                "image": require('../assets/img/cms/content/Manufacturing.png'),
                "iconClass": "",
                "heading": "Manufacturing",
                "alt": "Manufacturing",
                "additional_data": []
            },
            {
                "cmsId": "112",
                "cmsSlug": "health-and-safety",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Health and Safety",
                "description": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
                "description2": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
                "image": require('../assets/img/cms/content/Health_and_Safety_Management1.png'),
                "iconClass": "",
                "heading": "Health and Safety",
                "alt": "Health and Safety",
                "additional_data": []
            },
            {
                "cmsId": "113",
                "cmsSlug": "hr-management",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "HR management",
                "description": "<p>Astram can be used for HR management in a variety of ways. Here are some examples</p>",
                "description2": "<p>Astram can be used for HR management in a variety of ways. Here are some examples</p>",
                "image": require('../assets/img/cms/content/HR_Management2.png'),
                "iconClass": "",
                "heading": "HR management",
                "alt": "HR management",
                "additional_data": []
            },
            {
                "cmsId": "114",
                "cmsSlug": "environmental-management",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Environmental Management",
                "description": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
                "description2": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
                "image": require('../assets/img/cms/content/Environmental_Management2.png'),
                "iconClass": "",
                "heading": "Environmental Management",
                "alt": "Environmental Management",
                "additional_data": []
            },
            {
                "cmsId": "115",
                "cmsSlug": "inspection-and-testing-companies",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Inspection and Testing Companies",
                "description": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
                "description2": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
                "image": require('../assets/img/cms/content/Inspection_and_Testing1.png'),
                "iconClass": "",
                "heading": "Inspection and Testing Companies",
                "alt": "Inspection and Testing Companies",
                "additional_data": []
            },
            {
                "cmsId": "116",
                "cmsSlug": "food-and-beverage",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Food and Beverage",
                "description": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products</p>",
                "description2": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products</p>",
                "image": require('../assets/img/cms/content/Food_and_Beverage.png'),
                "iconClass": "",
                "heading": "Food and Beverage",
                "alt": "Food and Beverage",
                "additional_data": []
            },
            {
                "cmsId": "117",
                "cmsSlug": "government-and-regulatory-bodies",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Government and Regulatory Bodies",
                "description": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
                "description2": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
                "image": require('../assets/img/cms/content/Governance_and_Regulatory_Compliance2.png'),
                "iconClass": "",
                "heading": "Government and Regulatory Bodies",
                "alt": "Government and Regulatory Bodies",
                "additional_data": []
            },
            {
                "cmsId": "118",
                "cmsSlug": "transportation-and-logistics",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Transportation and Logistics",
                "description": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
                "description2": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
                "image": require('../assets/img/cms/content/Transportation_and_Logistics1.png'),
                "iconClass": "",
                "heading": "Transportation and Logistics",
                "alt": "Transportation and Logistics",
                "additional_data": []
            },
            {
                "cmsId": "119",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Construction",
                "description": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
                "description2": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
                "image": require('../assets/img/cms/content/Construction.png'),
                "iconClass": "",
                "heading": "Construction",
                "alt": "Construction",
                "additional_data": []
            }
        ],
        "astram_software_technology_home_page": [
            {
                "cmsId": "43",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "21",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "ASTRAM Software Technology",
                "description": "<p>ASTRAM is a versatile software technology hub designed to enable businesses to optimize their processes and operations fostering efficiency and profitability. With ASTRAM Software Technology, businesses can connect their assets and processes to streamline manufacturing, transportation, power generation and other operations. ASTRAM is a powerful tool for reducing a business's environmental impact, and ASTRAM is uniquely positioned to help businesses achieve their sustainability goals through flexible and customizable software solutions. Based on ASTRAM Software technology a set of dedicated solutions have already been developed and established together with our clients. You can choose those or go for your own dedicated solution!</p>",
                "description2": "<p>ASTRAM is a versatile software technology hub designed to enable businesses to optimize their processes and operations fostering efficiency and profitability. With ASTRAM Software Technology, businesses can connect their assets and processes to streamline manufacturing, transportation, power generation and other operations. ASTRAM is a powerful tool for reducing a business's environmental impact, and ASTRAM is uniquely positioned to help businesses achieve their sustainability goals through flexible and customizable software solutions. Based on ASTRAM Software technology a set of dedicated solutions have already been developed and established together with our clients. You can choose those or go for your own dedicated solution!</p>",
                "image": require('../assets/img/cms/content/about-img1.png'),
                "iconClass": "",
                "heading": "ASTRAM Software Technology",
                "alt": "ASTRAM Software Technology",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "43",
                            "pageId": "4",
                            "title": "Introduction",
                            "description": "ASTRAM is a versatile software technology hub designed to enable businesses to optimize their processes and operations fostering efficiency and profitability. With ASTRAM Software Technology, businesses can connect their assets and processes to streamline manufacturing, transportation, power generation and other operations. ASTRAM is a powerful tool for reducing a business's environmental impact, and ASTRAM is uniquely positioned to help businesses achieve their sustainability goals through flexible and customizable software solutions. Based on ASTRAM Software technology a set of dedicated solutions have already been developed and established together with our clients. You can choose those or go for your own dedicated solution!",
                            "image": require('../assets/img/cms/additional_field/about-img1.png')
                        }
                    ]
                }
            }
        ],
        "our_services_home_page": [
            {
                "cmsId": "44",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Legal Compliance",
                "description": "<p>Legal Compliance</p>",
                "description2": "<p>Legal Compliance</p>",
                "image": require('../assets/img/cms/content/icon_01.png'),
                "iconClass": "",
                "heading": "Legal Compliance",
                "alt": "Legal Compliance",
                "additional_data": []
            },
            {
                "cmsId": "45",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "REGULAR SOFTWARE UPGRADE",
                "description": "<p>REGULAR SOFTWARE UPGRADE</p>",
                "description2": "<p>REGULAR SOFTWARE UPGRADE</p>",
                "image": require('../assets/img/cms/content/icon_02.png'),
                "iconClass": "",
                "heading": "REGULAR SOFTWARE UPGRADE",
                "alt": "REGULAR SOFTWARE UPGRADE",
                "additional_data": []
            },
            {
                "cmsId": "46",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "INFORMATION AT YOUR FINGER TIPS",
                "description": "<p>INFORMATION AT YOUR FINGER TIPS</p>",
                "description2": "<p>INFORMATION AT YOUR FINGER TIPS</p>",
                "image": require('../assets/img/cms/content/icon_03.png'),
                "iconClass": "",
                "heading": "INFORMATION AT YOUR FINGER TIPS",
                "alt": "INFORMATION AT YOUR FINGER TIPS",
                "additional_data": []
            },
            {
                "cmsId": "47",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "FLEXI MAINTENANCE PLAN",
                "description": "<p>FLEXI MAINTENANCE PLAN</p>",
                "description2": "<p>FLEXI MAINTENANCE PLAN</p>",
                "image": require('../assets/img/cms/content/icon_04.png'),
                "iconClass": "",
                "heading": "FLEXI MAINTENANCE PLAN",
                "alt": "FLEXI MAINTENANCE PLAN",
                "additional_data": []
            },
            {
                "cmsId": "48",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "DATA SECCURITY",
                "description": "<p>DATA SECCURITY</p>",
                "description2": "<p>DATA SECCURITY</p>",
                "image": require('../assets/img/cms/content/icon_05.png'),
                "iconClass": "",
                "heading": "DATA SECCURITY",
                "alt": "DATA SECCURITY",
                "additional_data": []
            },
            {
                "cmsId": "49",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "MULTI PLATFORM ACCESS",
                "description": "<p>MULTI PLATFORM ACCESS</p>",
                "description2": "<p>MULTI PLATFORM ACCESS</p>",
                "image": require('../assets/img/cms/content/icon_06.png'),
                "iconClass": "",
                "heading": "MULTI PLATFORM ACCESS",
                "alt": "MULTI PLATFORM ACCESS",
                "additional_data": []
            },
            {
                "cmsId": "50",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "LOGICAL WORK ALLOCATION",
                "description": "<p>LOGICAL WORK ALLOCATION</p>",
                "description2": "<p>LOGICAL WORK ALLOCATION</p>",
                "image": require('../assets/img/cms/content/icon_07.png'),
                "iconClass": "",
                "heading": "LOGICAL WORK ALLOCATION",
                "alt": "LOGICAL WORK ALLOCATION",
                "additional_data": []
            },
            {
                "cmsId": "51",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "AGILE DEPLOYMENT",
                "description": "<p>AGILE DEPLOYMENT</p>",
                "description2": "<p>AGILE DEPLOYMENT</p>",
                "image": require('../assets/img/cms/content/icon_08.png'),
                "iconClass": "",
                "heading": "AGILE DEPLOYMENT",
                "alt": "AGILE DEPLOYMENT",
                "additional_data": []
            }
        ],
        "astram_team_home_page": [
            {
                "cmsId": "52",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "24",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "ASTRAM Team",
                "description": "<p>Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better. Building on our own and clients&rsquo; expertise in Inspection &amp; Auditing, Power, Petro Chemical, Laboratories, and manufacturing sectors, ASTRAM Team aims to create superior value for all stakeholders by advanced software solutions. With a focus on sustainability and cutting-edge technology, ASTRAM Team is committed to pushing the boundaries of what is possible, enabling businesses to optimize their operations and accelerate their digital transformation. Our team is dedicated to driving performance to new levels, operating in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. With a focus on market leadership, technology and digital capabilities, people, portfolio management, and sustainability, ASTRAM Team is uniquely positioned to deliver superior value and performance to businesses across a variety of industries.</p>",
                "description2": "<p>Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better. Building on our own and clients&rsquo; expertise in Inspection &amp; Auditing, Power, Petro Chemical, Laboratories, and manufacturing sectors, ASTRAM Team aims to create superior value for all stakeholders by advanced software solutions. With a focus on sustainability and cutting-edge technology, ASTRAM Team is committed to pushing the boundaries of what is possible, enabling businesses to optimize their operations and accelerate their digital transformation. Our team is dedicated to driving performance to new levels, operating in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. With a focus on market leadership, technology and digital capabilities, people, portfolio management, and sustainability, ASTRAM Team is uniquely positioned to deliver superior value and performance to businesses across a variety of industries.</p>",
                "image": require('../assets/img/cms/content/team.jpg'),
                "iconClass": "",
                "heading": "ASTRAM Team",
                "alt": "ASTRAM Team",
                "additional_data": []
            }
        ],
        "client_testimonials_home_page": [
            {
                "cmsId": "53",
                "cmsSlug": "roedie-botes",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Roedie Botes",
                "description": "<p>I can definitely recommend Astram to be used as the preferred program. It&rsquo;s easy to use, reliable and the back-up service is outstanding. My clients also find the program very useful.</p>",
                "description2": "<p>I can definitely recommend Astram to be used as the preferred program. It&rsquo;s easy to use, reliable and the back-up service is outstanding. My clients also find the program very useful.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Roedie Botes",
                "alt": "Roedie Botes",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "53",
                            "pageId": "4",
                            "title": "Roedie Botes",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "53",
                            "pageId": "4",
                            "title": "Tzaneen Inspection services",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "54",
                "cmsSlug": "benjamin-cooper",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Benjamin Cooper",
                "description": "<p>ASTRAM is a fully customizable tool that works on all devices. We can conduct audits and inspections on the go on a mobile or tablet. It syncs automatically, so we can continue the work on a computer if required. The capability to attach images and other files from the site with GPS tag adds lots of credibility to the inspections process and reports.</p>",
                "description2": "<p>ASTRAM is a fully customizable tool that works on all devices. We can conduct audits and inspections on the go on a mobile or tablet. It syncs automatically, so we can continue the work on a computer if required. The capability to attach images and other files from the site with GPS tag adds lots of credibility to the inspections process and reports.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Benjamin Cooper",
                "alt": "Benjamin Cooper",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "54",
                            "pageId": "4",
                            "title": "Benjamin Cooper",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "54",
                            "pageId": "4",
                            "title": "Kwa Zulu Inspection",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "55",
                "cmsSlug": "swys-kotze",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Swys Kotze",
                "description": "<p>Though our industry deals with highest level of technologies, yet we are heavy on paperwork. Introduction of digitization have been slow. ASTRAM has brought in a fresh air of digital technology to aid the removal of pen and paper and make things real time. Thanks to the team for considering the needs of the industry and coming up with a product that makes our work and life better. We are eagerly waiting for their Desktop offline system to be ready.</p>",
                "description2": "<p>Though our industry deals with highest level of technologies, yet we are heavy on paperwork. Introduction of digitization have been slow. ASTRAM has brought in a fresh air of digital technology to aid the removal of pen and paper and make things real time. Thanks to the team for considering the needs of the industry and coming up with a product that makes our work and life better. We are eagerly waiting for their Desktop offline system to be ready.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Swys Kotze",
                "alt": "Swys Kotze",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "55",
                            "pageId": "4",
                            "title": "Swys Kotze",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "55",
                            "pageId": "4",
                            "title": "SGS South Africa",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "56",
                "cmsSlug": "riaan-swarts",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Riaan Swarts",
                "description": "<p>The app safe me substantial amount of time on reporting, as most of the Client information is prepopulated. The drop down function has a great advantage when doing repetitive inspection of the same type of equipment. The security with the app being cloud based hold the advantage never losing information. The app is fairly user friendly once all the teething problems is sorted.</p>",
                "description2": "<p>The app safe me substantial amount of time on reporting, as most of the Client information is prepopulated. The drop down function has a great advantage when doing repetitive inspection of the same type of equipment. The security with the app being cloud based hold the advantage never losing information. The app is fairly user friendly once all the teething problems is sorted.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Riaan Swarts",
                "alt": "Riaan Swarts",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "56",
                            "pageId": "4",
                            "title": "Riaan Swarts",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "56",
                            "pageId": "4",
                            "title": "SGS South Africa",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "138",
                "cmsSlug": "nic-kruger",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Nic Kruger",
                "description": "<p>I can recommend Astram to be used as an inspection program. It is easy to use and it can be used on mobile phones or tablets. The capability to attach images and other files adds credibility to the inspections process and reports.</p>",
                "description2": "<p>I can recommend Astram to be used as an inspection program. It is easy to use and it can be used on mobile phones or tablets. The capability to attach images and other files adds credibility to the inspections process and reports.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Nic Kruger",
                "alt": "Nic Kruger",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "138",
                            "pageId": "4",
                            "title": "Nic Kruger",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "138",
                            "pageId": "4",
                            "title": "Indserve",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "139",
                "cmsSlug": "david-abbot",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "David Abbot",
                "description": "<p>The app is compatible and synced with smartphones and tablets and computers. Smart features and settings allow us to focus on the task at hand, while inspection data upload, report creation and device storage management happens quietly in the background.</p>",
                "description2": "<p>The app is compatible and synced with smartphones and tablets and computers. Smart features and settings allow us to focus on the task at hand, while inspection data upload, report creation and device storage management happens quietly in the background.</p>",
                "image": "",
                "iconClass": "",
                "heading": "David Abbot",
                "alt": "David Abbot",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "139",
                            "pageId": "4",
                            "title": "David Abbot",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "139",
                            "pageId": "4",
                            "title": "Kwa Zulu Inspection",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "140",
                "cmsSlug": "donovan-coleman",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Donovan Coleman",
                "description": "<p>ASTRAM cuts inspection time by up to 50% - helps to comply 100% with industry regulations, facilitates communication between team members, create custom forms within minutes.</p>",
                "description2": "<p>ASTRAM cuts inspection time by up to 50% - helps to comply 100% with industry regulations, facilitates communication between team members, create custom forms within minutes.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Donovan Coleman",
                "alt": "Donovan Coleman",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "140",
                            "pageId": "4",
                            "title": "Donovan Coleman",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "140",
                            "pageId": "4",
                            "title": "Indserve",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "141",
                "cmsSlug": "gerson-arroe",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Gerson Arroe",
                "description": "<p>ASTRAM is a very intuitive mobile app that allows you to efficiently conduct inspections and audits to improve quality and safety in your organization. It fast-tracked our migration from the old-school pen &amp; paper checks to a digitized process within one app.</p>",
                "description2": "<p>ASTRAM is a very intuitive mobile app that allows you to efficiently conduct inspections and audits to improve quality and safety in your organization. It fast-tracked our migration from the old-school pen &amp; paper checks to a digitized process within one app.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Gerson Arroe",
                "alt": "Gerson Arroe",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "141",
                            "pageId": "4",
                            "title": "Gerson Arroe",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "141",
                            "pageId": "4",
                            "title": "Kwa Zulu Inspection",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            }
           
        ],
        "about_partner_home_page": [
            {
                "cmsId": "57",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 1 ",
                "description": "<p>Partner 1&nbsp;</p>",
                "description2": "<p>Partner 1&nbsp;</p>",
                "image": require('../assets/img/cms/content/client-logo1.png'),
                "iconClass": "",
                "heading": "Partner 1 ",
                "alt": "Partner 1 ",
                "additional_data": []
            },
            {
                "cmsId": "58",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Partner 2",
                "description": "<p>Partner 2</p>",
                "description2": "<p>Partner 2</p>",
                "image": require('../assets/img/cms/content/client-logo2.png'),
                "iconClass": "",
                "heading": "Partner 2",
                "alt": "Partner 2",
                "additional_data": []
            },
            {
                "cmsId": "59",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 3",
                "description": "<p>Partner 3</p>",
                "description2": "<p>Partner 3</p>",
                "image": require('../assets/img/cms/content/client-logo3.png'),
                "iconClass": "",
                "heading": "Partner 3",
                "alt": "Partner 3",
                "additional_data": []
            },
            {
                "cmsId": "60",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 4",
                "description": "<p>Partner 4</p>",
                "description2": "<p>Partner 4</p>",
                "image": require('../assets/img/cms/content/client-logo4.png'),
                "iconClass": "",
                "heading": "Partner 4",
                "alt": "Partner 4",
                "additional_data": []
            },
            {
                "cmsId": "61",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 5",
                "description": "<p>Partner 5</p>",
                "description2": "<p>Partner 5</p>",
                "image": require('../assets/img/cms/content/client-logo5.png'),
                "iconClass": "",
                "heading": "Partner 5",
                "alt": "Partner 5",
                "additional_data": []
            }
        ],
        "astram_solutions_and_modules_block_home_page": [
            {
                "cmsId": "106",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Asset Management",
                "description": "<p>This module allows for tracking of assets throughout their lifecycle, from acquisition to disposal, with a focus on compliance in industries such as pressure equipment, lifts, and explosives.</p>",
                "description2": "<p>This module allows for tracking of assets throughout their lifecycle, from acquisition to disposal, with a focus on compliance in industries such as pressure equipment, lifts, and explosives.</p>",
                "image": require('../assets/img/cms/content/Asset_Management.png'),
                "iconClass": "",
                "heading": "Asset Management",
                "alt": "Asset Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "106",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p>Improved asset reliability and availability.</p>\r\n<p>Reduced maintenance costs and downtime.</p>\r\n<p>Improved safety and compliance with regulations.</p>\r\n<p>Better decision making through data analysis and reporting.</p>\r\n<p>Increased efficiency and productivity.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "106",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p>Automated tracking and monitoring of asset health and performance.</p>\r\n<p>Customizable dashboards and reports for data analysis and decision making.</p>\r\n<p>Integration with IoT sensors and other data sources for real-time monitoring.</p>\r\n<p>Centralized database for all asset-related information, including maintenance records, repair history, and other important data.</p>\r\n<p>User-friendly interface for easy access and use by multiple stakeholders.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "106",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "ASTRAM provides a comprehensive asset management solution that can help businesses streamline their operations and reduce costs while improving asset performance and utilization. With its customizable dashboards and reports, real-time tracking and automated maintenance scheduling, businesses can make informed decisions about their asset management and reduce the risk of downtime or compliance issues. ASTRAM's focus on automation and customization allows it to meet the unique needs of any industry, making it a versatile and valuable asset management tool.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "85",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "Inspection and Testing",
                "metaDescriptions": " Inspection and Testing                       ",
                "metaKeyword": " Inspection and Testing                       ",
                "title": "Inspection and Testing",
                "description": "<p>This module enables creation of custom calculation reports for fitness for service assessment on pressure vessels, tanks, and reactor vessels.</p>",
                "description2": "<p>This module enables creation of custom calculation reports for fitness for service assessment on pressure vessels, tanks, and reactor vessels.</p>",
                "image": require('../assets/img/cms/content/Inspection_and_Testing.png'),
                "iconClass": "",
                "heading": "Inspection and Testing",
                "alt": "Inspection and Testing",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "85",
                            "pageId": "7",
                            "title": "Benefits",
                            "description": "<p>Improved efficiency and accuracy of inspections and reporting.</p>\r\n<p>Reduced risk of human error and non-compliance.</p>\r\n<p>Increased visibility and transparency of inspection data.</p>\r\n<p>Streamlined audit management and scheduling.</p>\r\n<p>Enhanced risk management and decision-making.</p>\r\n<p>Improved customer satisfaction through timely and accurate reporting.</p>",
                            "image": require('../assets/img/cms/additional_field/Governance and Regulatory Compliance.png')
                        },
                        {
                            "cmsId": "85",
                            "pageId": "7",
                            "title": "Key Features",
                            "description": "<p>Automated reporting and customizable templates.</p>\r\n<p>Integration with NDT tools and other inspection equipment.</p>\r\n<p>Live updates and real-time access to inspection data.</p>\r\n<p>Risk-based inspection system.</p>\r\n<p>Audit management and scheduling.</p>\r\n<p>Compliance tracking and management.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "85",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "Astram's Inspection and Testing module provides a comprehensive solution for managing inspection processes and compliance tracking. The module's customizable reporting templates and integration with NDT tools and other inspection equipment help to streamline the inspection process and improve accuracy. The riskbased inspection system and audit management features provide enhanced risk management and decision-making capabilities. Overall, the module improves efficiency and accuracy, reduces risk, and enhances customer satisfaction.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "86",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Accreditation Management",
                "description": "<p>This module can be used by accreditation bodies to manage their entire accreditation workflow, including customer management, scheduling audits, and more.</p>",
                "description2": "<p>This module can be used by accreditation bodies to manage their entire accreditation workflow, including customer management, scheduling audits, and more.</p>",
                "image": require('../assets/img/cms/content/Accreditation_Management1.png'),
                "iconClass": "",
                "heading": "Accreditation Management",
                "alt": "Accreditation Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "86",
                            "pageId": "7",
                            "title": "Benefits",
                            "description": "<p><strong>Improved compliance:</strong> Astram provides a comprehensive solution for managing accreditation processes, ensuring compliance with industry standards and regulations.</p>\r\n<p><strong>Increased efficiency:</strong> The platform streamlines accreditation processes, reducing manual effort and increasing productivity.</p>\r\n<p><strong>Enhanced data accuracy:</strong> Astram ensures accurate and up-to-date accreditation data, reducing the risk of errors and compliance issues.</p>\r\n<p><strong>Improved customer satisfaction:</strong> Astram provides a user-friendly interface for managing accreditation processes, improving the customer experience.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "86",
                            "pageId": "7",
                            "title": "Key Features",
                            "description": "<p><strong>Customer management:</strong> Astram provides a centralized platform to manage customer information, including accreditation status, audit history, and documentation.</p>\r\n<p><strong>Audit scheduling:</strong> The platform allows scheduling of audits, including notifications and reminders to clients and auditors.</p>\r\n<p><strong>Audit management:</strong> Astram provides tools for conducting audits, managing audit data, and generating audit reports.</p>\r\n<p><strong>Document management:</strong> The platform allows document management, including version control, revision history, and document approval workflows.</p>\r\n<p><strong>Reporting:</strong> Astram enables real-time reporting and analysis of accreditation data, enabling organizations to track their performance and identify areas for improvement.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "86",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "Astram provides a powerful solution for managing accreditation processes, enabling organizations to streamline their operations and ensure compliance with industry standards and regulations. With its comprehensive set of features and benefits, Astram is an ideal solution for organizations seeking to enhance their accreditation management capabilities.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "90",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Job Management",
                "description": "<p>This module enables tracking of jobs through the entire process, from acquisition to execution to billing, providing a streamlined approach to project delivery.</p>",
                "description2": "<p>This module enables tracking of jobs through the entire process, from acquisition to execution to billing, providing a streamlined approach to project delivery.</p>",
                "image": require('../assets/img/cms/content/Job_Management.png'),
                "iconClass": "",
                "heading": "Job Management",
                "alt": "Job Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "90",
                            "pageId": "7",
                            "title": "Benefits",
                            "description": "<p>Improved efficiency and productivity in job management processes.</p>\r\n<p>Reduced errors and delays in job tracking and reporting.</p>\r\n<p>Increased visibility and control over job status and progress.</p>\r\n<p>Accurate and timely billing for job-related services.</p>\r\n<p>Improved communication and collaboration among team members and with clients.</p>\r\n<p>Streamlined document management and storage.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "90",
                            "pageId": "7",
                            "title": "Key Features",
                            "description": "<p>Automated tracking of jobs from acquisition to billing.</p>\r\n<p>Customizable job forms for specific industries and workflows.</p>\r\n<p>Real-time reporting and monitoring of job status.</p>\r\n<p>Integration with financial systems for accurate billing and revenue tracking.</p>\r\n<p>Automated notifications and reminders for job deadlines and requirements.</p>\r\n<p>Document management and storage for job-related files.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "90",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "Using ASTRAM for job management can significantly improve the effectiveness and efficiency of job-related processes. By providing customizable forms, automated tracking, and real-time reporting, ASTRAM helps streamline job management workflows and reduce errors and delays. With features like document management, financial integration, and automated notifications, ASTRAM can also help improve communication and collaboration among team members and with clients. Overall, ASTRAM's job management capabilities can lead to improved productivity, accuracy, and customer satisfaction.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "91",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Non-Destructive Testing",
                "description": "<p>This module offers automated forms to reduce human error in reporting, particularly useful in large projects where reporting is a bottleneck in project delivery.</p>",
                "description2": "<p>This module offers automated forms to reduce human error in reporting, particularly useful in large projects where reporting is a bottleneck in project delivery.</p>",
                "image": require('../assets/img/cms/content/Non-Destructive_Testing_(1).png'),
                "iconClass": "",
                "heading": "Non-Destructive Testing",
                "alt": "Non-Destructive Testing",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "91",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p>Increased accuracy and reliability of reporting.</p>\r\n<p>Time savings due to automation and streamlined processes.</p>\r\n<p>Improved communication and collaboration between inspectors, supervisors, and clients.</p>\r\n<p>More efficient management of large NDT projects.</p>\r\n<p>Improved compliance with industry standards and regulations.</p>\r\n<p>Greater visibility into the performance of individual inspectors and teams.</p>\r\n<p>Better data analysis and decision-making for ongoing improvements.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "91",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p>Automated forms for reporting that reduce human error.</p>\r\n<p>Integration with various NDT technologies for real-time data capture.</p>\r\n<p>Live MIS (Management Information System) reporting for immediate feedback on the status of testing.</p>\r\n<p>Customizable templates for various types of NDT tests.</p>\r\n<p>Digital signatures for easy approvals and faster processing.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "91",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "In conclusion, the use of ASTRAM in NDT can lead to significant improvements in the efficiency and accuracy of reporting, as well as better overall project management and compliance. By leveraging ASTRAM's automation and digital capabilities, NDT companies can enhance their processes and deliver higher quality results for their clients.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "92",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Risk Based Inspection",
                "description": "<p>This module can be used to create a risk-based inspection system in critical industries to manage risks and create inspection and test plans.</p>",
                "description2": "<p>This module can be used to create a risk-based inspection system in critical industries to manage risks and create inspection and test plans.</p>",
                "image": require('../assets/img/cms/content/Risk_Based_Inspection.png'),
                "iconClass": "",
                "heading": "Risk Based Inspection",
                "alt": "Risk Based Inspection",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "92",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p><strong>Improved efficiency:</strong> ASTRAM&rsquo;s risk-based approach and realtime data collection can help to identify potential issues before they become major problems, leading to improved efficiency and reduced downtime.</p>\r\n<p><strong>Better decision-making:</strong> ASTRAM&rsquo;s automated reporting and integration with other systems can provide a more comprehensive view of operations, leading to better decision-making.</p>\r\n<p><strong>Increased safety:</strong> ASTRAM&rsquo;s risk-based approach can help to identify potential safety issues and prevent accidents.</p>\r\n<p><strong>Reduced costs:</strong> ASTRAM&rsquo;s real-time data collection and analysis can help to identify potential issues early on, reducing the need for costly repairs or replacements.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "92",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p><strong>Customizable inspection plans:</strong> ASTRAM allows for the creation of customizable inspection plans that can be tailored to fit specific industries or equipment types.</p>\r\n<p><strong>Risk-based approach:</strong> ASTRAM uses a risk-based approach to inspections, which allows for more efficient and effective inspections of critical assets.</p>\r\n<p><strong>Real-time data:</strong> ASTRAM allows for real-time data collection and analysis, which helps to identify potential issues and prevent unplanned downtime.</p>\r\n<p><strong>Automated reporting:</strong> ASTRAM automates reporting, making it easier to generate reports and share information with stakeholders.</p>\r\n<p><strong>Integration with other systems:</strong> ASTRAM can integrate with other systems, such as asset management software or ERP systems, to provide a more comprehensive view of operations.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "92",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "ASTRAM is a powerful tool for Risk Based Inspections that provides real-time data collection, automated reporting, and a risk-based approach to inspections. By integrating with other systems, ASTRAM can provide a more comprehensive view of operations, leading to better decision-making and improved efficiency. With its ability to identify potential safety issues and prevent accidents, ASTRAM can help to reduce costs and improve the overall safety of operations.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "93",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Health and Safety Management",
                "description": "<p>This module allows for tracking of all health and safety-related parameters and reporting on LIVE MIS.</p>",
                "description2": "<p>This module allows for tracking of all health and safety-related parameters and reporting on LIVE MIS.</p>",
                "image": require('../assets/img/cms/content/Health_and_Safety_Management.png'),
                "iconClass": "",
                "heading": "Health and Safety Management",
                "alt": "Health and Safety Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "93",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p>Improved safety for personnel and equipment.</p>\r\n<p>Reduced risk of incidents and non-compliances.</p>\r\n<p>Increased efficiency in managing health and safety-related processes.</p>\r\n<p>Enhanced reporting capabilities for compliance and regulatory requirements.</p>\r\n<p>Improved visibility and transparency across the organization.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "93",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p>Ability to track and monitor all health and safety-related parameters in real-time.</p>\r\n<p>Automated alerts and notifications for critical events, such as incidents or non-compliances.</p>\r\n<p>Ability to create custom forms and checklists for inspections and audits.</p>\r\n<p>Easy access to historical data for trend analysis and reporting.</p>\r\n<p>Integration with other modules, such as asset management and job management, to ensure the safety of personnel and equipment.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "93",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "Using ASTRAM for health and safety management can significantly improve the safety and well-being of personnel and equipment while increasing efficiency and compliance. With the ability to track and monitor all health and safety-related parameters in real-time, organizations can quickly identify and address potential risks, preventing incidents and non-compliances. ASTRAM's integration with other modules also ensures that health and safety considerations are integrated into all aspects of the organization's operations.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "97",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "        ",
                "metaKeyword": "        ",
                "title": "Pharmaceutical Quality Control",
                "description": "<p>This module is specifically designed for the pharmaceutical industry, enabling quality control of products.</p>",
                "description2": "<p>This module is specifically designed for the pharmaceutical industry, enabling quality control of products.</p>",
                "image": require('../assets/img/cms/content/Pharmaceutical_Quality_Control.png'),
                "iconClass": "",
                "heading": "Pharmaceutical Quality Control",
                "alt": "Pharmaceutical Quality Control",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "97",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p><strong>Improved Efficiency:</strong> Astram improves the efficiency of testing procedures, ensuring that tests are performed accurately, and results are delivered in a timely manner.</p>\r\n<p><strong>Compliance:</strong> Astram ensures compliance with GMP requirements, providing audit trails, electronic signatures and validation protocols.</p>\r\n<p><strong>Data Integrity:</strong> Astram helps to maintain the integrity of data by providing secure access, traceability, and version control.</p>\r\n<p><strong>Reduced Errors:</strong> Astram reduces the likelihood of errors through the use of automated processes, pre-defined procedures and protocols.</p>\r\n<p><strong>Improved Traceability:</strong> Astram provides complete traceability of all testing procedures, samples, and results, making it easy to track the status of testing and ensure that everything is completed according to the correct procedures.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "97",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p><strong>Customizable workflows:</strong> ASTRAM allows users to create workflows that are tailored to their specific requirements, ensuring that all necessary steps are included in the quality control process.</p>\r\n<p><strong>Automated reporting:</strong> ASTRAM can generate automated reports that provide real-time updates on the status of quality control activities, helping to identify any issues and ensure that they are addressed promptly.</p>\r\n<p><strong>Document management:</strong> ASTRAM's document management system ensures that all relevant documents are stored in a centralized location and are easily accessible by authorized personnel.</p>\r\n<p><strong>Audit trails:</strong> ASTRAM's audit trails provide a complete record of all quality control activities, including who performed the activity, when it was performed, and what the results were.</p>\r\n<p><strong>Integration with existing systems:</strong> ASTRAM can be integrated with existing systems, such as laboratory information management systems (LIMS), to streamline the quality control process and improve efficiency.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "97",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "Astram is an effective solution for the management of quality control in the pharmaceutical industry. It provides a comprehensive range of features and benefits, ensuring that all aspects of the testing process are managed effectively and efficiently. By using Astram, pharmaceutical companies can improve their quality control procedures, ensure compliance with regulatory requirements, reduce errors and improve traceability.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "98",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "International Trade",
                "description": "<p>This module can be used to carry out inspection and report instantly with geotagged images to avoid delays in decision making.</p>",
                "description2": "<p>This module can be used to carry out inspection and report instantly with geotagged images to avoid delays in decision making.</p>",
                "image": require('../assets/img/cms/content/International_Trade.png'),
                "iconClass": "",
                "heading": "International Trade",
                "alt": "International Trade",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "98",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p>Reduced time and cost of inspections and assessments.</p>\r\n<p>Improved accuracy and consistency in reporting.</p>\r\n<p>Enhanced compliance with trade regulations and standards.</p>\r\n<p>Streamlined shipment management and tracking.</p>\r\n<p>Improved transparency and visibility across the supply chain.</p>\r\n<p>Increased productivity and efficiency in the trade process.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "98",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p>Automated forms and checklists for inspections and assessments.</p>\r\n<p>Real-time reporting with geotagged images for improved decision making.</p>\r\n<p>Compliance tracking for trade regulations and standards.</p>\r\n<p>Integration with third-party logistics providers for streamlined shipment management.</p>\r\n<p>Customizable workflows to fit the specific needs of the trade process.</p>\r\n<p>Cloud-based storage and access for easy collaboration and sharing.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "98",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "ASTRAM can significantly improve the efficiency and effectiveness of international trade operations by providing a streamlined and automated solution for inspections, assessments, and compliance tracking. With real-time reporting and geotagged images, decision making can be accelerated and accuracy can be improved. Additionally, integration with third-party logistics providers and customizable workflows further enhance the flexibility and usability of the system. By utilizing ASTRAM for international trade, businesses can improve productivity, reduce costs, and increase transparency and compliance across the supply chain.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "99",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Laboratory Management",
                "description": "<p>This module can be used to manage laboratory operations, including sample tracking, testing, and reporting.</p>",
                "description2": "<p>This module can be used to manage laboratory operations, including sample tracking, testing, and reporting.</p>",
                "image": require('../assets/img/cms/content/Laboratory_Management.png'),
                "iconClass": "",
                "heading": "Laboratory Management",
                "alt": "Laboratory Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "99",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p><strong>Increased efficiency:</strong> Astram's automation and streamlining of laboratory processes helps to increase efficiency, reduce errors, and save time.</p>\r\n<p><strong>Improved accuracy:</strong> With Astram's quality control and result management features, labs can ensure that their results are accurate and consistent.</p>\r\n<p><strong>Better customer service:</strong> Astram's sample tracking and result management features enable labs to provide better customer service by providing real-time updates and accurate reports.</p>\r\n<p><strong>Cost savings:</strong> By streamlining processes and reducing errors, Astram can help labs to save money on labor and retesting costs.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "99",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p><strong>Sample tracking:</strong> Astram's sample tracking feature enables labs to track samples from the moment they are received to the moment they are delivered, ensuring that no samples are lost or misplaced during the testing process.</p>\r\n<p><strong>Result management:</strong> Astram's result management feature enables labs to easily manage test results and generate reports for customers. The system can be customized to meet the needs of different labs and can handle both manual and automated testing processes.</p>\r\n<p><strong>Equipment management:</strong> With Astram's equipment management feature, labs can manage their equipment inventory, schedule maintenance, and track usage. This helps labs to ensure that their equipment is properly maintained and calibrated, reducing the risk of errors or incorrect results.</p>\r\n<p><strong>Quality control:</strong> Astram's quality control feature enables labs to manage their quality control procedures and track their performance over time. This helps labs to identify areas for improvement and ensure that their results are accurate and consistent.</p>\r\n<p><strong>Billing and invoicing:</strong> Astram's billing and invoicing feature enables labs to manage their billing and invoicing processes in a streamlined manner. This helps to reduce errors and delays in payment processing, ensuring that labs are paid promptly for their services.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "99",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "By leveraging ASTRAM for transportation and logistics management, companies can optimize their logistics operations, reduce costs, and improve overall customer satisfaction. The platform's real-time tracking, automated scheduling, and customizable reporting capabilities enable companies to streamline their logistics processes and ensure timely and accurate deliveries. Additionally, ASTRAM's compliance management features help companies stay on top of regulatory requirements and avoid costly penalties. Overall, ASTRAM provides a comprehensive solution for transportation and logistics companies looking to optimize their operations and improve customer satisfaction.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "100",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "HR Management",
                "description": "<p>This module enables HR management functions such as employee data management, performance evaluation, and training management.</p>",
                "description2": "<p>This module enables HR management functions such as employee data management, performance evaluation, and training management.</p>",
                "image": require('../assets/img/cms/content/HR_Management.png'),
                "iconClass": "",
                "heading": "HR Management",
                "alt": "HR Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "100",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p><strong>Increased efficiency:</strong> Astram's centralized database and automation of HR processes help save time and reduce administrative workload, allowing HR personnel to focus on more strategic tasks.</p>\r\n<p><strong>Improved accuracy:</strong> By eliminating manual data entry and paperbased processes, Astram helps reduce errors and ensure data accuracy.</p>\r\n<p><strong>Enhanced employee experience:</strong> Astram's performance management and training and development modules can help improve employee engagement and career development, leading to higher employee satisfaction and retention.</p>\r\n<p><strong>Better compliance:</strong> Astram's leave management module ensures compliance with labour laws and regulations, reducing the risk of legal liabilities.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "100",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p><strong>Employee database:</strong> Astram provides a centralized employee database where HR personnel can store all employee information, including personal details, contact information, work history, performance reviews, and more.</p>\r\n<p><strong>Recruitment management:</strong> Astram helps streamline the recruitment process by enabling HR personnel to post job openings, screen applications, and schedule interviews, all in one place.</p>\r\n<p><strong>Performance management:</strong> Astram's performance management module allows HR personnel to set goals, track employee progress, and provide feedback, helping to improve employee engagement and productivity.</p>\r\n<p><strong>Leave management:</strong> Astram's leave management module simplifies the process of managing employee leave requests, allowing HR personnel to view employee leave balances, approve or deny requests, and track absences.</p>\r\n<p><strong>Training and development:</strong> Astram's training and development module enables HR personnel to plan and track employee training, ensuring that employees receive the necessary skills and knowledge to excel in their roles.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "100",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "Astram's HR management module provides a comprehensive solution for managing employee data, recruitment, performance, leave, and training and development. By increasing efficiency, accuracy, and employee experience, Astram can help organizations improve their HR processes and achieve their strategic goals.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "101",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Environmental Management",
                "description": "<p>This module enables tracking and management of environmental parameters and reporting on LIVE MIS.</p>",
                "description2": "<p>This module enables tracking and management of environmental parameters and reporting on LIVE MIS.</p>",
                "image": require('../assets/img/cms/content/Environmental_Management1.png'),
                "iconClass": "",
                "heading": "Environmental Management",
                "alt": "Environmental Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "101",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p><strong>Streamlined Operations: </strong>ASTRAM provides a centralized platform to manage all environmental aspects, enabling streamlined operations and compliance management.</p>\r\n<p><strong>Risk Reduction: </strong>ASTRAM helps in identifying and managing potential environmental risks, reducing the risk of non-compliance and environmental incidents.</p>\r\n<p><strong>Cost Savings:</strong> By managing environmental parameters efficiently, ASTRAM can help in reducing energy consumption, waste generation, and emissions, leading to cost savings.</p>\r\n<p><strong>Improved Reputation: </strong>ASTRAM's sustainability reporting capabilities help organizations demonstrate their commitment to environmental sustainability, improving their reputation among stakeholders.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "101",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p><strong>Compliance Management:</strong> ASTRAM helps in tracking and managing environmental compliance requirements as per the regulatory norms.</p>\r\n<p><strong>Environmental Monitoring:</strong> ASTRAM enables the monitoring and tracking of environmental parameters and provides alerts in case of any deviation.</p>\r\n<p><strong>Emissions and Waste Management:</strong> ASTRAM provides a comprehensive solution to manage emissions and waste generated during the production process.</p>\r\n<p><strong>Audit Management:</strong> ASTRAM provides a platform to conduct environmental audits, track non-conformances, and take corrective actions.</p>\r\n<p><strong>Sustainability Reporting: </strong>ASTRAM helps in generating reports related to environmental sustainability, including carbon footprint, energy consumption, and water usage.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "101",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "ASTRAM provides a comprehensive solution for environmental  management, enabling organizations to comply with regulatory  requirements, monitor environmental parameters, manage emissions  and waste, conduct audits, and generate sustainability reports. By using ASTRAM, organizations can streamline their environmental  management operations, reduce potential risks, save costs, and  improve their reputation.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "102",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Governance and Regulatory Compliance",
                "description": "<p>This module can be used by government and regulatory bodies to manage compliance with regulations and standards.</p>",
                "description2": "<p>This module can be used by government and regulatory bodies to manage compliance with regulations and standards.</p>",
                "image": require('../assets/img/cms/content/Governance_and_Regulatory_Compliance.png'),
                "iconClass": "",
                "heading": "Governance and Regulatory Compliance",
                "alt": "Governance and Regulatory Compliance",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "102",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p>Improved compliance and reduced risk of penalties or fines.</p>\r\n<p>Streamlined compliance management processes, saving time and resources.</p>\r\n<p>Increased visibility into compliance status and potential issues.</p>\r\n<p>Improved data accuracy and consistency.</p>\r\n<p>Improved collaboration and communication across departments.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "102",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p>Customizable forms and workflows for compliance management.</p>\r\n<p>Automated reporting and documentation for regulatory submissions.</p>\r\n<p>Real-time monitoring and alerts for compliance violations.</p>\r\n<p>Audit trails to track compliance history.</p>\r\n<p>Integration with other systems for seamless data management.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "102",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "Using ASTRAM for governance and regulatory compliance can help companies to effectively manage their compliance obligations and reduce the risk of non-compliance. The customizable forms and workflows, real-time monitoring and automated reporting features help to streamline compliance management processes and provide greater visibility into compliance status. By integrating with other systems, ASTRAM enables seamless data management and improves collaboration and communication across departments. Ultimately, ASTRAM helps to improve compliance and reduce the risk of penalties or fines, while also saving time and resources.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "103",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Transportation and Logistics",
                "description": "<p>: This module can be used to manage logistics operations, including transportation management, scheduling, and reporting.</p>",
                "description2": "<p>: This module can be used to manage logistics operations, including transportation management, scheduling, and reporting.</p>",
                "image": require('../assets/img/cms/content/Transportation_and_Logistics.png'),
                "iconClass": "",
                "heading": "Transportation and Logistics",
                "alt": "Transportation and Logistics",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "103",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p>Improved operational efficiency and cost savings.</p>\r\n<p>Enhanced visibility and control over shipments and logistics.</p>\r\n<p>Reduced errors and delays through automation and real-time tracking.</p>\r\n<p>Increased customer satisfaction through accurate and timely deliveries.</p>\r\n<p>Simplified compliance management for regulatory requirements.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "103",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p>Real-time tracking of shipments and vehicles.</p>\r\n<p>Automated scheduling of delivery routes.</p>\r\n<p>Integration with third-party logistics providers.</p>\r\n<p>Customizable reporting and analytics.</p>\r\n<p>Electronic proof of delivery and signature capture.</p>\r\n<p>Compliance management for regulatory requirements.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "103",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "By leveraging ASTRAM for transportation and logistics management, companies can optimize their logistics operations, reduce costs, and improve overall customer satisfaction. The platform's real-time tracking, automated scheduling, and customizable reporting capabilities enable companies to streamline their logistics processes and ensure timely and accurate deliveries. Additionally, ASTRAM's compliance management features help companies stay on top of regulatory requirements and avoid costly penalties. Overall, ASTRAM provides a comprehensive solution for transportation and logistics companies looking to optimize their operations and improve customer satisfaction.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "104",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Clinical Trial Management",
                "description": "<p>Clinical trials are a critical aspect of the pharmaceutical industry to ensure the safety and efficacy of new drugs and treatments. Astram, a comprehensive software solution, can be used to manage all aspects of clinical trials management. Astram can help streamline the clinical trial process, reduce errors, and improve efficiency, ultimately leading to faster time-to-market for new drugs and treatments.</p>",
                "description2": "<p>Clinical trials are a critical aspect of the pharmaceutical industry to ensure the safety and efficacy of new drugs and treatments. Astram, a comprehensive software solution, can be used to manage all aspects of clinical trials management. Astram can help streamline the clinical trial process, reduce errors, and improve efficiency, ultimately leading to faster time-to-market for new drugs and treatments.</p>",
                "image": require('../assets/img/cms/content/Clinical_Trial_Management.png'),
                "iconClass": "",
                "heading": "Clinical Trial Management",
                "alt": "Clinical Trial Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "104",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p>Astram's clinical trial management system can help you save time and resources, reduce errors and inconsistencies, and ensure compliance with regulatory requirements.</p>\r\n<p>By using Astram, you can gain greater visibility into the clinical trial process, making it easier to monitor progress and identify potential issues early on.</p>\r\n<p>Astram can help you improve collaboration between stakeholders, including investigators, site staff, regulatory authorities, and sponsors.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "104",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p>Astram offers a suite of modules for clinical trial management, including patient enrolment, scheduling, protocol adherence, regulatory compliance, adverse event reporting, and data management.</p>\r\n<p>With Astram, you can automate many of the repetitive tasks involved in clinical trials, reducing the workload on staff and minimizing the risk of errors.</p>\r\n<p>Astram can help you manage clinical trial data securely and efficiently, with a powerful reporting engine that provides customizable, standardized reports for all stakeholders.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "104",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "<p>Astram is a powerful software solution that can be used to manage all aspects of clinical trials management, from patient enrolment to data management and reporting.</p>\r\n<p>By using Astram, you can streamline your clinical trial process, reduce errors, and ensure compliance with regulatory requirements, ultimately leading to faster time-to-market for new drugs and treatments.</p>",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "105",
                "cmsSlug": null,
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Production optimization",
                "description": "<p>ASTRAM Production Optimization in Astram is designed to enhance the production processes of manufacturing plants. By leveraging advanced data analytics and machine learning algorithms, ASTRAM can help businesses optimize their production processes, minimize downtime, and reduce waste. The solution offers real-time monitoring of critical production parameters, enabling businesses to quickly identify issues and take corrective actions. Additionally, ASTRAM provides comprehensive analytics and reporting features that allow businesses to gain insights into their production processes and make data-driven decisions. With ASTRAM Production Optimization, businesses can increase efficiency, reduce costs, and improve their overall competitiveness in the market.</p>",
                "description2": "<p>ASTRAM Production Optimization in Astram is designed to enhance the production processes of manufacturing plants. By leveraging advanced data analytics and machine learning algorithms, ASTRAM can help businesses optimize their production processes, minimize downtime, and reduce waste. The solution offers real-time monitoring of critical production parameters, enabling businesses to quickly identify issues and take corrective actions. Additionally, ASTRAM provides comprehensive analytics and reporting features that allow businesses to gain insights into their production processes and make data-driven decisions. With ASTRAM Production Optimization, businesses can increase efficiency, reduce costs, and improve their overall competitiveness in the market.</p>",
                "image": require('../assets/img/cms/content/Production_optimization.png'),
                "iconClass": "",
                "heading": "Production optimization",
                "alt": "Production optimization",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "105",
                            "pageId": "7",
                            "title": "Benifits",
                            "description": "<p>Another key feature of ASTRAM is its ability to automate key aspects of production, such as scheduling, quality control, and supply chain management.&nbsp;</p>\r\n<p>This reduces the potential for human error and allows companies to operate more efficiently, with fewer delays and disruptions.</p>\r\n<p>In addition to these core functions, ASTRAM also offers a range of specialized modules to address specific needs and challenges in the production process. For example, the predictive maintenance module can help companies anticipate and prevent equipment failures, while the inventory management module can help ensure that the right materials and components are available when they are needed.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "105",
                            "pageId": "7",
                            "title": "Key Feature",
                            "description": "<p>ASTRAM can help with production optimization is by providing real-time monitoring and control of equipment and processes.&nbsp;</p>\r\n<p>This allows companies to identify and address bottlenecks and inefficiencies as they occur, ensuring that production runs as smoothly as possible.&nbsp;</p>\r\n<p>ASTRAM can also provide detailed performance metrics and analytics to help companies identify areas for improvement and make data-driven decisions to&nbsp;<br />optimize their production processes.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "105",
                            "pageId": "7",
                            "title": "Conclusion",
                            "description": "ASTRAM offers a powerful suite of tools for production optimization, with features and modules that can be tailored to meet the specific needs and challenges of any business. By leveraging these tools and capabilities, companies can improve their efficiency, reduce waste and costs, and ultimately boost their bottom line.",
                            "image": ""
                        }
                    ]
                }
            }
        ],
        "astram_solutions_and_modules_title_home_page": [
            {
                "cmsId": "83",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "38",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "ASTRAM Solutions and Modules",
                "description": "<p>ASTRAM Solutions and Modules</p>",
                "description2": "<p>ASTRAM Solutions and Modules</p>",
                "image": "",
                "iconClass": "",
                "heading": "ASTRAM Solutions and Modules",
                "alt": "ASTRAM Solutions and Modules",
                "additional_data": []
            }
        ]
    });
    
    
    
    const [dataSourceDe, setDataSourceDE] = useState({
        "pageName": "Home Page",
        "metaTitle": "Home",
        "metaDescription": "Astram ",
        "metaKeyword": "Astram ",
        "slider_home_page": [
            {
                "cmsId": "30",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "15",
                "metaTitle": "",
                "metaDescriptions": "                         ",
                "metaKeyword": "                         ",
                "title": "Home Page Slider 1",
                "description": "<h2>Eine voll funktionsfähige, flexible <br />und zukunftsweisende B2B-Plattform, die</h2>\r\n<h3>an Ihre Bedürfnisse angepasst werden kann.</h3>\r\n<p>ASTRAM ist eine B2B-Plattform, die entwickelt wurde, um Unternehmen die Flexibilität zu geben, ihre Lösung auf ihre eigene Art und Weise zu entwickeln. Wir glauben, dass jede Organisation einzigartig ist und ihre eigene Art der Geschäftsabwicklung hat. Die Plattform soll Unternehmen dabei helfen, Transparenz und Verantwortlichkeit über alle Rollen innerhalb und außerhalb der Organisationsgrenzen hinweg einzuführen.</p>\r\n<h4>Einhaltung von <span>‘Best Practice’ – Anforderungen</span></h4>",
                "description2": "<h2>Eine voll funktionsfähige,flexible <br />und zukunftsweisende B2B-Plattform, die</h2>\r\n<h3>an Ihre Bedürfnisse angepasst werden kann.</h3>\r\n<p>ASTRAM ist eine B2B-Plattform, die entwickelt wurde, um Unternehmen die Flexibilität zu geben, ihre Lösung auf ihre eigene Art und Weise zu entwickeln. Wir glauben, dass jede Organisation einzigartig ist und ihre eigene Art der Geschäftsabwicklung hat. Die Plattform soll Unternehmen dabei helfen, Transparenz und Verantwortlichkeit über alle Rollen innerhalb und außerhalb der Organisationsgrenzen hinweg einzuführen.</p>\r\n<h4>Einhaltung von <span>‘Best Practice’ – Anforderungen</span></h4>",
                "image": require('../assets/img/cms/content/banner_11.png'),
                "iconClass": "",
                "heading": "Home Page Slider 1",
                "alt": "Home Page Slider 1",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "AWS gut strukturiertes Framework",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_1.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "PCI-DSS-Konformität",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/pvc.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "NIST-Compliance zur Cybersicherheit",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_3.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "ISO 27001-Konformität",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_5.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "HIPPA-Konformität",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_9.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "DSGVO-Konformität",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_10.png')
                        }
                    ]
                }
            },
            {
                "cmsId": "31",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "15",
                "metaTitle": "",
                "metaDescriptions": "            ",
                "metaKeyword": "            ",
                "title": "Home Page Slider 2",
                "description": "<h2>Eine voll funktionsfähige, flexible <br />und zukunftsweisende B2B-Plattform, die</h2>\r\n<h3>an Ihre Bedürfnisse angepasst werden kann.</h3>\r\n<p>ASTRAM ist eine B2B-Plattform, die entwickelt wurde, um Unternehmen die Flexibilität zu geben, ihre Lösung auf ihre eigene Art und Weise zu entwickeln. Wir glauben, dass jede Organisation einzigartig ist und ihre eigene Art der Geschäftsabwicklung hat. Die Plattform soll Unternehmen dabei helfen, Transparenz und Verantwortlichkeit über alle Rollen innerhalb und außerhalb der Organisationsgrenzen hinweg einzuführen.</p>\r\n<h4>Einhaltung von <span>‘Best Practice’ – Anforderungen</span></h4>",
                "description2":"<h2>Eine voll funktionsfähige, flexible <br />und zukunftsweisende B2B-Plattform, die</h2>\r\n<h3>an Ihre Bedürfnisse angepasst werden kann.</h3>\r\n<p>ASTRAM ist eine B2B-Plattform, die entwickelt wurde, um Unternehmen die Flexibilität zu geben, ihre Lösung auf ihre eigene Art und Weise zu entwickeln. Wir glauben, dass jede Organisation einzigartig ist und ihre eigene Art der Geschäftsabwicklung hat. Die Plattform soll Unternehmen dabei helfen, Transparenz und Verantwortlichkeit über alle Rollen innerhalb und außerhalb der Organisationsgrenzen hinweg einzuführen.</p>\r\n<h4>Einhaltung von <span>‘Best Practice’ – Anforderungen</span></h4>",
                "image": require('../assets/img/cms/content/banner_22.png'),
                "iconClass": "",
                "heading": "Home Page Slider 2",
                "alt": "Home Page Slider 2",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "30",
                            "cmsSlug": "aws-well-architected-framework",
                            "pageId": "4",
                            "title": "AWS gut strukturiertes Framework",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_1.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "pci-dss-compliance",
                            "pageId": "4",
                            "title": "PCI-DSS-Konformität",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/pvc.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "nist-cyber-security-compliance",
                            "pageId": "4",
                            "title": "NIST-Compliance zur Cybersicherheit",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_3.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "iso-27001-compliance",
                            "pageId": "4",
                            "title": "ISO 27001-Konformität",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_5.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "hippa-compliance",
                            "pageId": "4",
                            "title": "HIPPA-Konformität",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_9.png')
                        },
                        {
                            "cmsId": "30",
                            "cmsSlug": "gdpr-compliance",
                            "pageId": "4",
                            "title": "DSGVO-Konformität",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_10.png')
                        }
                    ]
                }
            }
        ],
        "about_us_content_home_page": [
            {
                "cmsId": "32",
                "cmsSlug": "uber-uns",
                "pageId": "4",
                "sectionId": "16",
                "metaTitle": "",
                "metaDescriptions": "                    ",
                "metaKeyword": "                    ",
                "title": "Über uns",
                "description": "<p>ASTRAM ist eine vielseitige Softwarel&ouml;sung, die entwickelt wurde, um Unternehmen dabei zu unterst&uuml;tzen, ihre Prozesse und Abl&auml;ufe f&uuml;r eine nachhaltigere und effizientere Zukunft zu optimieren. Mit ASTRAM k&ouml;nnen Unternehmen ihre technische Expertise mit Software verbinden, um die Fertigung, den Transport, die Energieerzeugung und andere Abl&auml;ufe zu optimieren. Basierend auf jahrzehntelanger Erfahrung ist das Expertenteam von ASTRAM bestrebt, Innovationen voranzutreiben und Unternehmen dabei zu helfen, ihre Abl&auml;ufe zum Besseren zu ver&auml;ndern.</p>",
                "description2": "<p>ASTRAM ist eine vielseitige Softwarel&ouml;sung, die entwickelt wurde, um Unternehmen dabei zu unterst&uuml;tzen, ihre Prozesse und Abl&auml;ufe f&uuml;r eine nachhaltigere und effizientere Zukunft zu optimieren. Mit ASTRAM k&ouml;nnen Unternehmen ihre technische Expertise mit Software verbinden, um die Fertigung, den Transport, die Stromerzeugung und andere Abl&auml;ufe zu optimieren. Basierend auf jahrzehntelanger Erfahrung ist das Expertenteam von ASTRAM bestrebt, Innovationen voranzutreiben und Unternehmen dabei zu helfen, ihre Abl&auml;ufe zum Besseren zu ver&auml;ndern.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Über uns",
                "alt": "Über uns",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "32",
                            "pageId": "4",
                            "title": "",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_3.png')
                        },
                        {
                            "cmsId": "32",
                            "pageId": "4",
                            "title": "",
                            "description": "",
                            "image": require('../assets/img/cms/additional_field/icon_1.png')
                        }
                    ]
                }
            }
        ],
        "about_us_block_home_page": [
            {
                "cmsId": "33",
                "cmsSlug": "unsere-ziele",
                "pageId": "4",
                "sectionId": "17",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Unsere Ziele",
                "description": "<p>Unser &uuml;bergeordnetes Ziel ist es, Unternehmen eine flexible und anpassungsf&auml;hige Softwarel&ouml;sung zur Verf&uuml;gung zu stellen, die auf ihre individuellen Bed&uuml;rfnisse und Anforderungen zugeschnitten werden kann. Indem wir eng mit unseren Kunden zusammenarbeiten und ihre spezifischen Herausforderungen und Ziele verstehen, wollen wir ma&szlig;geschneiderte L&ouml;sungen entwickeln, die ihnen helfen, ihre Ziele zu erreichen und langfristigen Erfolg zu erzielen.</p>",
                "description2": "<p>Unser &uuml;bergeordnetes Ziel ist es, Unternehmen eine flexible und anpassungsf&auml;hige Softwarel&ouml;sung zur Verf&uuml;gung zu stellen, die auf ihre individuellen Bed&uuml;rfnisse und Anforderungen zugeschnitten werden kann. Indem wir eng mit unseren Kunden zusammenarbeiten und ihre spezifischen Herausforderungen und Ziele verstehen, wollen wir ma&szlig;geschneiderte L&ouml;sungen entwickeln, die ihnen helfen, ihre Ziele zu erreichen und langfristigen Erfolg zu erzielen.</p>",
                "image": require('../assets/img/cms/content/a_032.png'),
                "iconClass": "",
                "heading": "Unsere Ziele",
                "alt": "Unsere Ziele",
                "additional_data": []
            },
            {
                "cmsId": "34",
                "cmsSlug": "technologie-und-digitale-transformation",
                "pageId": "4",
                "sectionId": "17",
                "metaTitle": "",
                "metaDescriptions": "       ",
                "metaKeyword": "       ",
                "title": "Technologie und digitale Transformation",
                "description": "<p>Unsere fortschrittliche Technologie und unsere Kompetenz zu digitalen L&ouml;sungen sind tief in unserer DNA verankert. Die Aufrechterhaltung und Verbesserung unserer Innovationsst&auml;rke und Kompetenz in digitalen Technologien ist eine strategische Priorit&auml;t des ASTRAM-Teams. Indem wir die Grenzen der Technologie erweitern, k&ouml;nnen wir unseren Kunden helfen, neue Leistungsniveaus zu erreichen und sich mit denL&ouml;sungen von ASTRAM am Markt zu differenzieren.</p>",
                "description2": "<p>Unsere fortschrittliche Technologie und unsere Kompetenz zu digitalen L&ouml;sungen sind tief in unserer DNA verankert. Die Aufrechterhaltung und Verbesserung unserer Innovationsst&auml;rke undKompetenz in digitalen Technologien ist eine strategische Priorit&auml;t des ASTRAM-Teams. Indem wir die Grenzen der Technologie erweitern, k&ouml;nnen wir unseren Kunden helfen, neue Leistungsniveaus zu erreichen und sich mit denLösungen von ASTRAM am Markt zu differenzieren.</p>",
                "image": require('../assets/img/cms/content/a_01.png'),
                "iconClass": "",
                "heading": "Technologie und digitale Transformation",
                "alt": "Technologie und digitale Transformation",
                "additional_data": []
            },
            {
                "cmsId": "35",
                "cmsSlug": "konnektivitat-zusammenarbeit-und-innovation",
                "pageId": "4",
                "sectionId": "17",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Konnektivität, Zusammenarbeit und Innovation",
                "description": "<p>ASTRAM's F&auml;higkeit zur Kommunikation mit Maschinen &uuml;ber das API-Gateway steht in enger Verbindung zur umfassenderen Ausrichtung der Software auf Konnektivit&auml;t.</p>",
                "description2": "<p>ASTRAM's F&auml;higkeit zur Kommunikation mit Maschinen &uuml;ber das API-Gateway steht in enger Verbindung zur umfassenderen Ausrichtung der Software auf Konnektivit&auml;t.</p>",
                "image": require('../assets/img/cms/content/a_02.png'),
                "iconClass": "",
                "heading": "Konnektivität, Zusammenarbeit und Innovation",
                "alt": "Konnektivität, Zusammenarbeit und Innovation",
                "additional_data": []
            },
            {
                "cmsId": "36",
                "cmsSlug": "nachhaltigkeit",
                "pageId": "4",
                "sectionId": "17",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Nachhaltigkeit",
                "description": "<p>Das ASTRAM-Team setzt sich f&uuml;r Nachhaltigkeit ein, was bedeutet, die Bed&uuml;rfnisse von Gesellschaft, Umwelt und Wirtschaft in Einklang zu bringen, um eine ges&uuml;ndere und sozialere Welt f&uuml;r zuk&uuml;nftige Generationen zu schaffen. Um dies zu erreichen, bettet das ASTRAM-Team nachhaltige Praktiken in seine gesamte Wertsch&ouml;pfungskette ein, verringert seinen &ouml;kologischen Fu&szlig;abdruck, verbessert seine soziale Positionierung und f&ouml;rdert Wachstum und Wohlstand f&uuml;r alle Beteiligten. Durch das Handeln als verantwortungsbewusstes Unternehmen baut ASTRAM Vertrauen und Glaubw&uuml;rdigkeit bei Kunden, Mitarbeitern, Investoren und anderen Stakeholdern auf und schafft so langfristige Werte f&uuml;r alle.</p>",
                "description2": "<p>Das ASTRAM-Team setzt sich f&uuml;r Nachhaltigkeit ein, was bedeutet, die Bed&uuml;rfnisse von Gesellschaft, Umwelt und Wirtschaft in Einklang zu bringen, um eine ges&uuml;ndere und sozialere Welt f&uuml;r zuk&uuml;nftige Generationen zu schaffen. Um dies zu erreichen, bettet das ASTRAM-Team nachhaltige Praktiken in seine gesamte Wertsch&ouml;pfungskette ein, verringert seinen &ouml;kologischen Fu&szlig;abdruck, verbessert seine soziale Positionierung und f&ouml;rdert Wachstum und Wohlstand f&uuml;r alle Beteiligten. Durch das Handeln als verantwortungsbewusstes Unternehmen baut ASTRAM Vertrauen und Glaubw&uuml;rdigkeit bei Kunden, Mitarbeitern, Investoren und anderen Stakeholdern auf und schafft so langfristige Werte f&uuml;r alle.</p>",
                "image": require('../assets/img/cms/content/a_03.png'),
                "iconClass": "",
                "heading": "Nachhaltigkeit",
                "alt": "Nachhaltigkeit",
                "additional_data": []
            }
        ],
        "our_solution_business_areas_home_page": [
            {
                "cmsId": "37",
                "cmsSlug": "astram-st-losungen-und-module",
                "pageId": "4",
                "sectionId": "18",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "ASTRAM-ST Lösungen und Module",
                "description": "<p>ASTRAM-ST bietet eine umfassende Suite von Basismodulen, um den unterschiedlichen Anforderungen von Unternehmen gerecht zu werden und die an die spezifischen Anforderungen dieser Branchen und Unternehmen angepasst werden k&ouml;nnen. Diese Module wurden entwickelt, um die Digitalisierung zu erm&ouml;glichen, Prozesse zu rationalisieren, die Zusammenarbeit zu verbessern und die Leistung zu optimieren. Diese Module k&ouml;nnen konfiguriert werden, um verschiedene Aspekte des Betriebs zu automatisieren und zu rationalisieren, vom Asset-Management &uuml;ber die Qualit&auml;tskontrolle bis hin zum Gesundheits- und Sicherheitsmanagement und vieles mehr. Jedes dieser Module ist hochgradig konfigurierbar und kann auf spezifische Anforderungen zugeschnitten werden. Mit seiner benutzerfreundlichen Oberfl&auml;che, robusten Funktionen und Echtzeit-Datenanalysen bietet ASTRAM-ST eine End-to-End-L&ouml;sung, die Unternehmen dabei hilft, die Effizienz zu verbessern, Kosten zu senken und das Wachstum voranzutreiben.</p>",
                "description2": "<p>ASTRAM-ST bietet eine umfassende Suite von Basismodulen, um den unterschiedlichen Anforderungen von Unternehmen gerecht zu werden und die an die spezifischen Anforderungen dieser Branchen und Unternehmen angepasst werden k&ouml;nnen. Diese Module wurden entwickelt, um die Digitalisierung zu erm&ouml;glichen, Prozesse zu rationalisieren, die Zusammenarbeit zu verbessern und die Leistung zu optimieren. Diese Module k&ouml;nnen konfiguriert werden, um verschiedene Aspekte des Betriebs zu automatisieren und zu rationalisieren, vom Asset-Management &uuml;ber die Qualit&auml;tskontrolle bis hin zum Gesundheits- und Sicherheitsmanagement und vieles mehr. Jedes dieser Module ist hochgradig konfigurierbar und kann auf spezifische Anforderungen zugeschnitten werden. Mit seiner benutzerfreundlichen Oberfl&auml;che, robusten Funktionen und Echtzeit-Datenanalysen bietet ASTRAM-ST eine End-to-End-L&ouml;sung, die Unternehmen dabei hilft, die Effizienz zu verbessern, Kosten zu senken und das Wachstum voranzutreiben.</p>",
                "image": "",
                "iconClass": "",
                "heading": "ASTRAM-ST Lösungen und Module",
                "alt": "ASTRAM-ST Lösungen und Module",
                "additional_data": []
            }
        ],
        "my_industry_title_home_page": [
            {
                "cmsId": "42",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "20",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Industry Applications",
                "description": "<h2 class=\"h3 pe-3 bg-white position-relative f600 text-uppercase d-inline-block mb-0\">Industry <span>Applications</span></h2>",
                "description2": "<h2 class=\"h3 pe-3 bg-white position-relative f600 text-uppercase d-inline-block mb-0\">Industry <span>Applications</span></h2>",
                "image": "",
                "iconClass": "",
                "heading": "Industry Applications",
                "alt": "Industry Applications",
                "additional_data": []
            }
        ],
        "my_industry_home_page": [
            {
                "cmsId": "38",
                "cmsSlug": "kerntechnik",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Kerntechnik",
                "description": "<p>Unternehmen in der Kerntechnik k&ouml;nnen ASTRAM-ST implementieren, um Assets zu verwalten, die Qualit&auml;t zu kontrollieren, Risiken zu managen, Audits zu erleichtern und Schulungen und Zertifizierungen zu steuern. Durch den Einsatz von ASTRAM-ST in der Nuklearindustrie k&ouml;nnen Unternehmen die Sicherheit verbessern, Kosten senken und die Effizienz steigern.</p>",
                "description2": "<p>Unternehmen in der Kerntechnik k&ouml;nnen ASTRAM-ST implementieren, um Assets zu verwalten, die Qualit&auml;t zu kontrollieren, Risiken zu managen, Audits zu erleichtern und Schulungen und Zertifizierungen zu steuern. Durch den Einsatz von ASTRAM-ST in der Nuklearindustrie k&ouml;nnen Unternehmen die Sicherheit verbessern, Kosten senken und die Effizienz steigern.</p>",
                "image": require('../assets/img/cms/content/01.png'),
                "iconClass": "",
                "heading": "Kerntechnik",
                "alt": "Kerntechnik",
                "additional_data": []
            },
            {
                "cmsId": "39",
                "cmsSlug": "kraftwerke-und-versorgungsunternehmen",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Kraftwerke und Versorgungsunternehmen",
                "description": "<p>ASTRAM-ST kann in allen Arten von Kraftwerken und Energieanlagen hilfreich sein, indem es Performance, Umweltkonformit&auml;t, Sicherheit, Qualit&auml;t und Wartung von Assets und Produktionsanlagen verwaltet. Durch den Einsatz von ASTRAM-ST in Kraftwerken und Energieanlagenkönnen Unternehmen die Sicherheit verbessern, Ausfallzeiten reduzieren, die Lebensdauer von Assets verl&auml;ngern und die Produktion optimieren.</p>",
                "description2": "<p>ASTRAM-ST kann in allen Arten von Kraftwerken und Energieanlagen hilfreich sein, indem es Performance, Umweltkonformit&auml;t, Sicherheit, Qualit&auml;t und Wartung von Assets und Produktionsanlagen verwaltet. Durch den Einsatz von ASTRAM-ST in Kraftwerken und Energieanlagenkönnen Unternehmen die Sicherheit verbessern, Ausfallzeiten reduzieren, die Lebensdauer von Assets verl&auml;ngern und die Produktion optimieren.</p>",
                "image": require('../assets/img/cms/content/02.png'),
                "iconClass": "",
                "heading": "Kraftwerke und Versorgungsunternehmen",
                "alt": "Kraftwerke und Versorgungsunternehmen",
                "additional_data": []
            },
            {
                "cmsId": "40",
                "cmsSlug": "bergbau",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Bergbau",
                "description": "<p>ASTRAM-ST kann im Bergbausektor hilfreich sein, indem es die Einhaltung der Anlagensicherheit und Umweltvertr&auml;glichkeit unterst&uuml;tzt, die Produktion optimiert und die Lieferkette verwaltet. Durch den Einsatz von ASTRAM-ST im Bergbau k&ouml;nnen Unternehmen die Sicherheit verbessern, Ausfallzeiten reduzieren, die Produktion optimieren und die Lieferkette effektiver verwalten.</p>",
                "description2": "<p>ASTRAM-ST kann im Bergbausektor hilfreich sein, indem es die Einhaltung der Anlagensicherheit und Umweltvertr&auml;glichkeit unterst&uuml;tzt, die Produktion optimiert und die Lieferkette verwaltet. Durch den Einsatz von ASTRAM-ST im Bergbau k&ouml;nnen Unternehmen die Sicherheit verbessern, Ausfallzeiten reduzieren, die Produktion optimieren und die Lieferkette effektiver verwalten.</p>",
                "image": require('../assets/img/cms/content/03.png'),
                "iconClass": "",
                "heading": "Bergbau",
                "alt": "Bergbau",
                "additional_data": []
            },
            {
                "cmsId": "41",
                "cmsSlug": "pharma-industrie",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Pharma-Industrie",
                "description": "<p>ASTRAM-ST kann in der pharmazeutischen Industrie eingesetzt werden, um Qualit&auml;tskontrolle, Compliance, Inventar, klinische Studien und Produktionsoptimierung zu verwalten. Durch den Einsatz von ASTRAM-ST in der pharmazeutischen Industrie k&ouml;nnen Unternehmen die Qualit&auml;t verbessern, das Compliance-Risiko reduzieren, das Bestandsmanagement optimieren, die F&amp;E-Prozesse inkl. Studien verbessern und die Produktionseffizienz verbessern.</p>",
                "description2": "<p>ASTRAM-ST kann in der pharmazeutischen Industrie eingesetzt werden, um Qualit&auml;tskontrolle, Compliance, Inventar, klinische Studien und Produktionsoptimierung zu verwalten. Durch den Einsatz von ASTRAM-ST in der pharmazeutischen Industrie k&ouml;nnen Unternehmen die Qualit&auml;t verbessern, das Compliance-Risiko reduzieren, das Bestandsmanagement optimieren, die F&amp;E-Prozesse inkl. Studien verbessern und die Produktionseffizienz verbessern.</p>",
                "image": require('../assets/img/cms/content/04.png'),
                "iconClass": "",
                "heading": "Pharma-Industrie",
                "alt": "Pharma-Industrie",
                "additional_data": []
            },
            {
                "cmsId": "107",
                "cmsSlug": "laboratories",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Laboratories",
                "description": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
                "description2": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
                "image": require('../assets/img/cms/content/Laboratory_Management1.png'),
                "iconClass": "",
                "heading": "Laboratories",
                "alt": "Laboratories",
                "additional_data": []
            },
            {
                "cmsId": "108",
                "cmsSlug": "international-trade",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "International Trade",
                "description": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions</p>",
                "description2": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions</p>",
                "image": require('../assets/img/cms/content/International_Trade1.png'),
                "iconClass": "",
                "heading": "International Trade",
                "alt": "International Trade",
                "additional_data": []
            },
            {
                "cmsId": "109",
                "cmsSlug": "accreditation-bodies",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Accreditation Bodies",
                "description": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
                "description2": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
                "image": require('../assets/img/cms/content/Accreditation_Management2.png'),
                "iconClass": "",
                "heading": "Accreditation Bodies",
                "alt": "Accreditation Bodies",
                "additional_data": []
            },
            {
                "cmsId": "110",
                "cmsSlug": "oil-and-gas-and-petrochemical",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Oil and Gas and Petrochemical",
                "description": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
                "description2": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
                "image": require('../assets/img/cms/content/Oil_and_Gas_and_Petrochemical.png'),
                "iconClass": "",
                "heading": "Oil and Gas and Petrochemical",
                "alt": "Oil and Gas and Petrochemical",
                "additional_data": []
            },
            {
                "cmsId": "111",
                "cmsSlug": "manufacturing",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Manufacturing",
                "description": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
                "description2": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
                "image": require('../assets/img/cms/content/Manufacturing.png'),
                "iconClass": "",
                "heading": "Manufacturing",
                "alt": "Manufacturing",
                "additional_data": []
            },
            {
                "cmsId": "112",
                "cmsSlug": "health-and-safety",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Health and Safety",
                "description": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
                "description2": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
                "image": require('../assets/img/cms/content/Health_and_Safety_Management1.png'),
                "iconClass": "",
                "heading": "Health and Safety",
                "alt": "Health and Safety",
                "additional_data": []
            },
            {
                "cmsId": "113",
                "cmsSlug": "hr-management",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "HR management",
                "description": "<p>Astram can be used for HR management in a variety of ways. Here are some examples</p>",
                "description2": "<p>Astram can be used for HR management in a variety of ways. Here are some examples</p>",
                "image": require('../assets/img/cms/content/HR_Management2.png'),
                "iconClass": "",
                "heading": "HR management",
                "alt": "HR management",
                "additional_data": []
            },
            {
                "cmsId": "114",
                "cmsSlug": "environmental-management",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Environmental Management",
                "description": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
                "description2": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
                "image": require('../assets/img/cms/content/Environmental_Management2.png'),
                "iconClass": "",
                "heading": "Environmental Management",
                "alt": "Environmental Management",
                "additional_data": []
            },
            {
                "cmsId": "115",
                "cmsSlug": "inspection-and-testing-companies",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Inspection and Testing Companies",
                "description": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
                "description2": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
                "image": require('../assets/img/cms/content/Inspection_and_Testing1.png'),
                "iconClass": "",
                "heading": "Inspection and Testing Companies",
                "alt": "Inspection and Testing Companies",
                "additional_data": []
            },
            {
                "cmsId": "116",
                "cmsSlug": "food-and-beverage",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Food and Beverage",
                "description": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products</p>",
                "description2": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products</p>",
                "image": require('../assets/img/cms/content/Food_and_Beverage.png'),
                "iconClass": "",
                "heading": "Food and Beverage",
                "alt": "Food and Beverage",
                "additional_data": []
            },
            {
                "cmsId": "117",
                "cmsSlug": "government-and-regulatory-bodies",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Government and Regulatory Bodies",
                "description": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
                "description2": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
                "image": require('../assets/img/cms/content/Governance_and_Regulatory_Compliance2.png'),
                "iconClass": "",
                "heading": "Government and Regulatory Bodies",
                "alt": "Government and Regulatory Bodies",
                "additional_data": []
            },
            {
                "cmsId": "118",
                "cmsSlug": "transportation-and-logistics",
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Transportation and Logistics",
                "description": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
                "description2": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
                "image": require('../assets/img/cms/content/Transportation_and_Logistics1.png'),
                "iconClass": "",
                "heading": "Transportation and Logistics",
                "alt": "Transportation and Logistics",
                "additional_data": []
            },
            {
                "cmsId": "119",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Construction",
                "description": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
                "description2": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
                "image": require('../assets/img/cms/content/Construction.png'),
                "iconClass": "",
                "heading": "Construction",
                "alt": "Construction",
                "additional_data": []
            }
        ],
        "astram_software_technology_home_page": [
            {
                "cmsId": "43",
                "cmsSlug": "astram-software-technology",
                "pageId": "4",
                "sectionId": "21",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "ASTRAM Software Technology",
                "description": "<p>ASTRAM Software-Technology (ST) ist ein vielseitiger Software-Hub, der es Unternehmen erm&ouml;glicht, ihre spezifischen Prozesse und Abl&auml;ufe zu optimieren und so Effizienz und Rentabilit&auml;t zu f&ouml;rdern. Mit der ASTRAM-STk&ouml;nnen Unternehmen ihre Prozesse ganzheitlich digital organisieren, um Herstellung, Transport, Nachhaltigkeit, Qualit&auml;t, Asset-Management und andere Aufgaben zu optimieren. ASTRAM-ST ist eine leistungsstarke und vielseitige Tool-Box, um Unternehmen dabei zu helfen, ihre Unternehmensziele durch flexible und anpassbare Softwarel&ouml;sungen zu erreichen. Basierend auf der ASTRAM-ST wurden bereits eine Reihe von spezifischen L&ouml;sungen entwickelt und gemeinsam mit unseren Kunden etabliert. Sie k&ouml;nnen diese ausw&auml;hlen oder sich f&uuml;r Ihre eigenenspezifischen L&ouml;sungen entscheiden!</p>",
                "description2": "<p>ASTRAM Software-Technology (ST) ist ein vielseitiger Software-Hub, der es Unternehmen erm&ouml;glicht, ihre spezifischen Prozesse und Abl&auml;ufe zu optimieren und so Effizienz und Rentabilit&auml;t zu f&ouml;rdern. Mit der ASTRAM-STk&ouml;nnen Unternehmen ihre Prozesse ganzheitlich digital organisieren, um Herstellung, Transport, Nachhaltigkeit, Qualit&auml;t, Asset-Management und andere Aufgaben zu optimieren. ASTRAM-ST ist eine leistungsstarke und vielseitige Tool-Box, um Unternehmen dabei zu helfen, ihre Unternehmensziele durch flexible und anpassbare Softwarel&ouml;sungen zu erreichen. Basierend auf der ASTRAM-ST wurden bereits eine Reihe von spezifischen L&ouml;sungen entwickelt und gemeinsam mit unseren Kunden etabliert. Sie k&ouml;nnen diese ausw&auml;hlen oder sich f&uuml;r Ihre eigenenspezifischen L&ouml;sungen entscheiden!</p>",
                "image": require('../assets/img/cms/content/about-img1.png'),
                "iconClass": "",
                "heading": "ASTRAM Software Technology",
                "alt": "ASTRAM Software Technology",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "43",
                            "pageId": "4",
                            "title": "Einleitung",
                            "description": "ASTRAM ist ein vielseitiger Software-Technologie-Hub, der es Unternehmen ermöglicht, ihre Prozesse und Abläufe zu optimieren und so Effizienz und Rentabilität zu fördern. Mit der ASTRAM-Softwaretechnologie können Unternehmen ihre Anlagen und Prozesse verbinden, um Fertigung, Transport, Energieerzeugung und andere Abläufe zu rationalisieren. ASTRAM ist ein leistungsstarkes Tool zur Reduzierung der Umweltauswirkungen eines Unternehmens und ASTRAM ist einzigartig positioniert, um Unternehmen dabei zu helfen, ihre Nachhaltigkeitsziele durch flexible und anpassbare Softwarelösungen zu erreichen. Basierend auf der ASTRAM Software-Technologie wurde bereits eine Reihe dedizierter Lösungen gemeinsam mit unseren Kunden entwickelt und etabliert. Sie können diese auswählen oder sich für Ihre eigene dedizierte Lösung entscheiden!",
                            "image": require('../assets/img/cms/additional_field/about-img1.png')
                        }
                    ]
                }
            }
        ],
        "our_services_home_page": [
            {
                "cmsId": "44",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Normenkonform",
                "description": "<p>Normenkonform</p>",
                "description2": "<p>Normenkonform</p>",
                "image": require('../assets/img/cms/content/icon_01.png'),
                "iconClass": "",
                "heading": "Normenkonform",
                "alt": "Normenkonform",
                "additional_data": []
            },
            {
                "cmsId": "45",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Regelm&auml;&szlig;ige Software-Updates",
                "description": "<p>Regelmä&szlig;ige Software-Updates</p>",
                "description2": "<p>Regelmä&szlig;ige Software-Updates</p>",
                "image": require('../assets/img/cms/content/icon_02.png'),
                "iconClass": "",
                "heading": "Regelmä&szlig;ige Software-Updates",
                "alt": "Regelmä&szlig;ige Software-Updates",
                "additional_data": []
            },
            {
                "cmsId": "46",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Informationen auf Knopfdruck",
                "description": "<p>Informationen auf Knopfdruck</p>",
                "description2": "<p>Informationen auf Knopfdruck</p>",
                "image": require('../assets/img/cms/content/icon_03.png'),
                "iconClass": "",
                "heading": "Informationen auf Knopfdruck",
                "alt": "Informationen auf Knopfdruck",
                "additional_data": []
            },
            {
                "cmsId": "47",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Flexible Wartung",
                "description": "<p>Flexible Wartung</p>",
                "description2": "<p>Flexible Wartung</p>",
                "image": require('../assets/img/cms/content/icon_04.png'),
                "iconClass": "",
                "heading": "Flexible Wartung",
                "alt": "Flexible Wartung",
                "additional_data": []
            },
            {
                "cmsId": "48",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Data Security",
                "description": "<p>Data Security</p>",
                "description2": "<p>Data Security</p>",
                "image": require('../assets/img/cms/content/icon_05.png'),
                "iconClass": "",
                "heading": "Data Security",
                "alt": "Data Security",
                "additional_data": []
            },
            {
                "cmsId": "49",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Multi Plattform Zugänge",
                "description": "<p>Multi Plattform Zugänge</p>",
                "description2": "<p>Multi Plattform Zugänge</p>",
                "image": require('../assets/img/cms/content/icon_06.png'),
                "iconClass": "",
                "heading": "Multi Plattform Zugänge",
                "alt": "Multi Plattform Zugänge",
                "additional_data": []
            },
            {
                "cmsId": "50",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Rollen- und Rechtesystem",
                "description": "<p>Rollen- und Rechtesystem</p>",
                "description2": "<p>Rollen- und Rechtesystem</p>",
                "image": require('../assets/img/cms/content/icon_07.png'),
                "iconClass": "",
                "heading": "Rollen- und Rechtesystem",
                "alt": "Rollen- und Rechtesystem",
                "additional_data": []
            },
            {
                "cmsId": "51",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "22",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Agile Entwicklung",
                "description": "<p>Agile Entwicklung</p>",
                "description2": "<p>Agile Entwicklung</p>",
                "image": require('../assets/img/cms/content/icon_08.png'),
                "iconClass": "",
                "heading": "Agile Entwicklung",
                "alt": "Agile Entwicklung",
                "additional_data": []
            }
        ],
        "astram_team_home_page": [
            {
                "cmsId": "52",
                "cmsSlug": "astram-team",
                "pageId": "4",
                "sectionId": "24",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "ASTRAM Team",
                "description": "<p>Auf Grundlage langjä hriger Erfahrung setzt sich das Expertenteam von ASTRAM daf&uuml;r ein, Innovationen voranzutreiben und Unternehmen dabei zu unterst&uuml;tzen, ihre Prozesse zu optimieren. Mit unserem Know-how und dem unserer dem unserer Kunden zielt das ASTRAM-Team darauf ab, in Prozessen wie Sicherheits- und Qualit&auml;tsmanagement, Inspektion und Auditierung, Supply-Chain Management u.v.a. f&uuml;r unterschiedliche Branchen wie Energie, Chemie, Pharma, Herstellung etc., durch fortschrittliche Softwarel&ouml;sungen einen Mehrwert zu schaffen. Mit dem Fokus auf Nachhaltigkeit und fortschrittliche Technologien setzt sich das ASTRAM-Team daf&uuml;r ein, die Grenzen des M&ouml;glichen zu verschieben und Unternehmen in die Lage zu versetzen, ihre Abl&auml;ufe zu optimieren und ihre digitale Transformation zu beschleunigen. Unser Team hat es sich zur Aufgabe gemacht, die Leistung auf ein neues Niveau zu heben und L&ouml;sungen in &Uuml;bereinstimmung mit unseren Leitprinzipien -dem &sbquo;ASTRAM-Way&lsquo;-, zu erarbeiten. Mit einem Fokus auf Technologie und digitale F&auml;higkeiten, Mitarbeiterkompetenz, Portfoliomanagement und Nachhaltigkeit ist das ASTRAM-Team einzigartig am Markt positioniert, um Unternehmen in einer Vielzahl von Branchen Unterst&uuml;tzung und Mehrwert zu bieten.</p>",
                "description2": "<p>Auf Grundlagelangj&auml;hriger Erfahrung setzt sich das Expertenteam von ASTRAM daf&uuml;r ein, Innovationen voranzutreiben und Unternehmen dabei zu unterst&uuml;tzen, ihre Prozesse zu optimieren. Mit unserem Know-how und dem unserer Kundenzielt das ASTRAM-Team darauf ab, in Prozessenwie Sicherheits- und Qualit&auml;tsmanagement, Inspektion und Auditierung, Supply-Chain Management u.v.a. f&uuml;r unterschiedliche Branchen wie Energie, Chemie, Pharma, Herstellung etc., durch fortschrittliche Softwarel&ouml;sungen einen Mehrwert zu schaffen. Mit dem Fokus auf Nachhaltigkeit und fortschrittliche Technologien setzt sich das ASTRAM-Team daf&uuml;r ein, die Grenzen des M&ouml;glichen zu verschieben und Unternehmen in die Lage zu versetzen, ihre Abl&auml;ufe zu optimieren und ihre digitale Transformation zu beschleunigen. Unser Team hat es sich zur Aufgabe gemacht, die Leistung auf ein neues Niveau zu heben und L&ouml;sungen in &Uuml;bereinstimmung mit unseren Leitprinzipien -dem &sbquo;ASTRAM-Way&lsquo;-, zu erarbeiten. Mit einem Fokus auf Technologie und digitale F&auml;higkeiten, Mitarbeiterkompetenz, Portfoliomanagement und Nachhaltigkeit ist das ASTRAM-Team einzigartig am Markt positioniert, um Unternehmen in einer Vielzahl von Branchen Unterst&uuml;tzung und Mehrwert zu bieten.</p>",
                "image": require('../assets/img/cms/content/team.jpg'),
                "iconClass": "",
                "heading": "ASTRAM Team",
                "alt": "ASTRAM Team",
                "additional_data": []
            }
        ],
        "client_testimonials_home_page": [
            {
                "cmsId": "53",
                "cmsSlug": "roedie-botes",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Roedie Botes",
                "description": "<p>Ich kann Astram auf jeden Fall als bevorzugtes Programm empfehlen. Es ist einfach zu bedienen, zuverlässig und der Backup-Service ist hervorragend. Auch meine Kunden finden das Programm sehr nützlich.</p>",
                "description2": "<p>Ich kann Astram auf jeden Fall als bevorzugtes Programm empfehlen. Es ist einfach zu bedienen, zuverlässig und der Backup-Service ist hervorragend. Auch meine Kunden finden das Programm sehr nützlich.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Roedie Botes",
                "alt": "Roedie Botes",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "53",
                            "pageId": "4",
                            "title": "Roedie Botes",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "53",
                            "pageId": "4",
                            "title": "Tzaneen Inspection services",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "54",
                "cmsSlug": "benjamin-cooper",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Benjamin Cooper",
                "description": "<p>ASTRAM ist ein vollständig anpassbares Tool, das auf allen Geräten funktioniert. Wir können Audits und Inspektionen von unterwegs auf einem Mobiltelefon oder Tablet durchführen. Die Synchronisierung erfolgt automatisch, sodass wir die Arbeit bei Bedarf am Computer fortsetzen können. Die Möglichkeit, Bilder und andere Dateien vom Standort mit GPS-Tag anzuhängen, erhöht die Glaubwürdigkeit des Inspektionsprozesses und der Berichte.</p>",
                "description2": "<p>ASTRAM ist ein vollständig anpassbares Tool, das auf allen Geräten funktioniert. Wir können Audits und Inspektionen von unterwegs auf einem Mobiltelefon oder Tablet durchführen. Die Synchronisierung erfolgt automatisch, sodass wir die Arbeit bei Bedarf am Computer fortsetzen können. Die Möglichkeit, Bilder und andere Dateien vom Standort mit GPS-Tag anzuhängen, erhöht die Glaubwürdigkeit des Inspektionsprozesses und der Berichte.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Benjamin Cooper",
                "alt": "Benjamin Cooper",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "54",
                            "pageId": "4",
                            "title": "Benjamin Cooper",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "54",
                            "pageId": "4",
                            "title": "Kwa Zulu Inspection",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "55",
                "cmsSlug": "swys-kotze",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Swys Kotze",
                "description": "<p>Obwohl sich unsere Branche mit Technologien auf höchstem Niveau beschäftigt, ist der Papierkram bei uns sehr hoch. Die Einführung der Digitalisierung verlief schleppend. ASTRAM hat frischen Wind in die digitale Technologie gebracht, um den Verzicht auf Stift und Papier zu erleichtern und Dinge in Echtzeit zu ermöglichen. Vielen Dank an das Team, das die Bedürfnisse der Branche berücksichtigt und ein Produkt entwickelt hat, das unsere Arbeit und unser Leben besser macht. Wir warten gespannt darauf, dass ihr Desktop-Offline-System fertig ist.</p>",
                "description2": "<p>Obwohl sich unsere Branche mit Technologien auf höchstem Niveau beschäftigt, ist der Papierkram bei uns sehr hoch. Die Einführung der Digitalisierung verlief schleppend. ASTRAM hat frischen Wind in die digitale Technologie gebracht, um den Verzicht auf Stift und Papier zu erleichtern und Dinge in Echtzeit zu ermöglichen. Vielen Dank an das Team, das die Bedürfnisse der Branche berücksichtigt und ein Produkt entwickelt hat, das unsere Arbeit und unser Leben besser macht. Wir warten gespannt darauf, dass ihr Desktop-Offline-System fertig ist.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Swys Kotze",
                "alt": "Swys Kotze",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "55",
                            "pageId": "4",
                            "title": "Swys Kotze",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "55",
                            "pageId": "4",
                            "title": "SGS South Africa",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "56",
                "cmsSlug": "riaan-swarts",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Riaan Swarts",
                "description": "<p>Die App spart mir viel Zeit bei der Berichterstellung, da die meisten Kundeninformationen vorab ausgefüllt sind. Die Dropdown-Funktion bietet einen großen Vorteil bei der wiederholten Inspektion desselben Gerätetyps. Die Sicherheit, da die App cloudbasiert ist, bietet den Vorteil, dass nie Informationen verloren gehen. Die App ist ziemlich benutzerfreundlich, sobald alle Kinderkrankheiten gelöst sind.</p>",
                "description2": "<p>Die App spart mir viel Zeit bei der Berichterstellung, da die meisten Kundeninformationen vorab ausgefüllt sind. Die Dropdown-Funktion bietet einen großen Vorteil bei der wiederholten Inspektion desselben Gerätetyps. Die Sicherheit, da die App cloudbasiert ist, bietet den Vorteil, dass nie Informationen verloren gehen. Die App ist ziemlich benutzerfreundlich, sobald alle Kinderkrankheiten gelöst sind.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Riaan Swarts",
                "alt": "Riaan Swarts",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "56",
                            "pageId": "4",
                            "title": "Riaan Swarts",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "56",
                            "pageId": "4",
                            "title": "SGS South Africa",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "138",
                "cmsSlug": "nic-kruger",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Nic Kruger",
                "description": "<p>Ich kann Astram als Inspektionsprogramm empfehlen. Es ist einfach zu bedienen und kann auf Mobiltelefonen oder Tablets verwendet werden. Die Möglichkeit, Bilder und andere Dateien anzuhängen, erhöht die Glaubwürdigkeit des Inspektionsprozesses und der Berichte.</p>",
                "description2": "<p>Ich kann Astram als Inspektionsprogramm empfehlen. Es ist einfach zu bedienen und kann auf Mobiltelefonen oder Tablets verwendet werden. Die Möglichkeit, Bilder und andere Dateien anzuhängen, erhöht die Glaubwürdigkeit des Inspektionsprozesses und der Berichte.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Nic Kruger",
                "alt": "Nic Kruger",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "138",
                            "pageId": "4",
                            "title": "Nic Kruger",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "138",
                            "pageId": "4",
                            "title": "Indserve",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "139",
                "cmsSlug": "david-abbot",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "David Abbot",
                "description": "<p>Die App ist mit Smartphones, Tablets und Computern kompatibel und synchronisiert. Intelligente Funktionen und Einstellungen ermöglichen es uns, uns auf die anstehende Aufgabe zu konzentrieren, während das Hochladen von Inspektionsdaten, die Berichterstellung und die Gerätespeicherverwaltung leise im Hintergrund erfolgen.</p>",
                "description2": "<p>Die App ist mit Smartphones, Tablets und Computern kompatibel und synchronisiert. Intelligente Funktionen und Einstellungen ermöglichen es uns, uns auf die anstehende Aufgabe zu konzentrieren, während das Hochladen von Inspektionsdaten, die Berichterstellung und die Gerätespeicherverwaltung leise im Hintergrund erfolgen.</p>",
                "image": "",
                "iconClass": "",
                "heading": "David Abbot",
                "alt": "David Abbot",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "139",
                            "pageId": "4",
                            "title": "David Abbot",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "139",
                            "pageId": "4",
                            "title": "Kwa Zulu Inspection",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "140",
                "cmsSlug": "donovan-coleman",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Donovan Coleman",
                "description": "<p>ASTRAM verkürzt die Inspektionszeit um bis zu 50 % – hilft bei der 100 %igen Einhaltung von Branchenvorschriften, erleichtert die Kommunikation zwischen Teammitgliedern und erstellt benutzerdefinierte Formulare innerhalb von Minuten.</p>",
                "description2": "<p>ASTRAM verkürzt die Inspektionszeit um bis zu 50 % – hilft bei der 100 %igen Einhaltung von Branchenvorschriften, erleichtert die Kommunikation zwischen Teammitgliedern und erstellt benutzerdefinierte Formulare innerhalb von Minuten.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Donovan Coleman",
                "alt": "Donovan Coleman",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "140",
                            "pageId": "4",
                            "title": "Donovan Coleman",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "140",
                            "pageId": "4",
                            "title": "Indserve",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "141",
                "cmsSlug": "gerson-arroe",
                "pageId": "4",
                "sectionId": "25",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Gerson Arroe",
                "description": "<p>ASTRAM ist eine sehr intuitive mobile App, mit der Sie Inspektionen und Audits effizient durchführen können, um die Qualität und Sicherheit in Ihrem Unternehmen zu verbessern. Es beschleunigte unsere Migration von der alten Schule zur Schule und zur Schule. Papierschecks in einen digitalisierten Prozess innerhalb einer App umwandeln.</p>",
                "description2": "<p>ASTRAM ist eine sehr intuitive mobile App, mit der Sie Inspektionen und Audits effizient durchführen können, um die Qualität und Sicherheit in Ihrem Unternehmen zu verbessern. Es beschleunigte unsere Migration von der alten Schule zur Schule und zur Schule. Papierschecks in einen digitalisierten Prozess innerhalb einer App umwandeln.</p>",
                "image": "",
                "iconClass": "",
                "heading": "Gerson Arroe",
                "alt": "Gerson Arroe",
                "additional_data": {
                    "author_name": [
                        {
                            "cmsId": "141",
                            "pageId": "4",
                            "title": "Gerson Arroe",
                            "description": "",
                            "image": ""
                        }
                    ],
                    "designation": [
                        {
                            "cmsId": "141",
                            "pageId": "4",
                            "title": "Kwa Zulu Inspection",
                            "description": "",
                            "image": ""
                        }
                    ]
                }
            },
           
        ],
        "about_partner_home_page": [
            {
                "cmsId": "57",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 1 ",
                "description": "<p>Partner 1&nbsp;</p>",
                "description2": "<p>Partner 1&nbsp;</p>",
                "image": require('../assets/img/cms/content/client-logo1.png'),
                "iconClass": "",
                "heading": "Partner 1 ",
                "alt": "Partner 1 ",
                "additional_data": []
            },
            {
                "cmsId": "58",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Partner 2",
                "description": "<p>Partner 2</p>",
                "description2": "<p>Partner 2</p>",
                "image": require('../assets/img/cms/content/client-logo2.png'),
                "iconClass": "",
                "heading": "Partner 2",
                "alt": "Partner 2",
                "additional_data": []
            },
            {
                "cmsId": "59",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 3",
                "description": "<p>Partner 3</p>",
                "description2": "<p>Partner 3</p>",
                "image": require('../assets/img/cms/content/client-logo3.png'),
                "iconClass": "",
                "heading": "Partner 3",
                "alt": "Partner 3",
                "additional_data": []
            },
            {
                "cmsId": "60",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 4",
                "description": "<p>Partner 4</p>",
                "description2": "<p>Partner 4</p>",
                "image": require('../assets/img/cms/content/client-logo4.png'),
                "iconClass": "",
                "heading": "Partner 4",
                "alt": "Partner 4",
                "additional_data": []
            },
            {
                "cmsId": "61",
                "cmsSlug": null,
                "pageId": "4",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 5",
                "description": "<p>Partner 5</p>",
                "description2": "<p>Partner 5</p>",
                "image": require('../assets/img/cms/content/client-logo5.png'),
                "iconClass": "",
                "heading": "Partner 5",
                "alt": "Partner 5",
                "additional_data": []
            }
        ],
        "astram_solutions_and_modules_block_home_page": [
            {
                "cmsId": "106",
                "cmsSlug": "asset-management",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "           ",
                "metaKeyword": "           ",
                "title": "Asset Management",
                "description": "<p>Dieses Modul erm&ouml;glicht die Nachverfolgung von Assets &uuml;ber ihren gesamten Lebenszyklus, vom Erwerb bis zur Entsorgung, wobei der Schwerpunkt auf die Erhaltung der Betriebsbereitschaft, der Energieeffizienz sowie der Einhaltung von Vorschriften zu Sicherheit und Konformit&auml;t liegt, z.B. f&uuml;r Druckger&auml;te, Maschinen, Aufz&uuml;gen und Energieanlagen.</p>",
                "description2": "<p>Dieses Modul erm&ouml;glicht die Nachverfolgung von Assets &uuml;ber ihren gesamten Lebenszyklus, vom Erwerb bis zur Entsorgung, wobei der Schwerpunkt auf die Erhaltung der Betriebsbereitschaft, der Energieeffizienz sowie der Einhaltung von Vorschriften zu Sicherheit und Konformit&auml;t liegt, z.B. f&uuml;r Druckger&auml;te, Maschinen, Aufz&uuml;gen und Energieanlagen.</p>",
                "image":  require('../assets/img/cms/content/Asset_Management.png'),
                "iconClass": "",
                "heading": "Asset Management",
                "alt": "Asset Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "106",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Verbesserte Anlagenzuverlässigkeit und -verfügbarkeit.</p>\r\n<p>Reduzierte Wartungskosten und Ausfallzeiten.</p>\r\n<p>Verbesserte Sicherheit und Einhaltung von Vorschriften.</p>\r\n<p>Bessere Entscheidungsfindung durch Datenanalyse und Reporting.</p>\r\n<p>Gesteigerte Effizienz und Produktivität.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "106",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>Automatisierte Nachverfolgung und Überwachung des Zustands und der Leistung von Anlagen.</p>\r\n<p>Anpassbare Dashboards und Berichte für die Datenanalyse und Entscheidungsfindung.</p>\r\n<p>Integration mit IoT-Sensoren und anderen Datenquellen für die Echtzeitüberwachung.</p>\r\n<p>Zentralisierte Datenbank für alle anlagenbezogenen Informationen, einschließlich Wartungsaufzeichnungen, Reparaturhistorie und anderer wichtiger Daten.</p>\r\n<p>Benutzerfreundliche Oberfläche für einfachen Zugriff und Nutzung durch mehrere Beteiligte.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "106",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "ASTRAM-ST bietet eine umfassende Asset-Management-Lösung, die Unternehmen dabei helfen kann, ihre Abläufe zu rationalisieren, Kosten zu senken und gleichzeitig die Leistung und Auslastung der Anlagen zu verbessern. Mit den anpassbaren Dashboards und Berichten, der Echtzeitverfolgung und der automatisierten Wartungsplanung können Unternehmen fundierte Entscheidungen über ihr Asset-Management treffen und das Risiko von Ausfallzeiten oder Compliance-Problemen reduzieren. Der Fokus von ASTRAM-ST auf Automatisierung und Anpassung ermöglicht es, die einzigartigen Anforderungen jeder Branche zu erfüllen, was es zu einem vielseitigen und wertvollen Asset-Management-Tool macht.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "85",
                "cmsSlug": "inspektionsmanagement",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "Inspection and Testing",
                "metaDescriptions": " Inspection and Testing                       ",
                "metaKeyword": " Inspection and Testing                       ",
                "title": "Inspektionsmanagement",
                "description": "<p>Dieses Modul erm&ouml;glicht die Erstellung von benutzerdefinierten Inspektions- und Pr&uuml;fpl&auml;nen, Analysen, Auswertungen und Berichte f&uuml;r die Bewertung des Betriebszustandes von technischen Einrichtungen aller Art.</p>",
                "description2": "<p>Dieses Modul erm&ouml;glicht die Erstellung von benutzerdefinierten Inspektions- und Pr&uuml;fpl&auml;nen, Analysen, Auswertungen und Berichte f&uuml;r die Bewertung des Betriebszustandes von technischen Einrichtungen aller Art.</p>",
                "image":  require('../assets/img/cms/content/Inspection_and_Testing.png'),
                "iconClass": "",
                "heading": "Inspektionsmanagement",
                "alt": "Inspektionsmanagement",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "85",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Verbesserte Effizienz und Genauigkeit von Inspektionen und Berichten.</p>\r\n<p>Geringeres Risiko von menschlichem Versagen und Nichteinhaltung von Vorschriften.</p>\r\n<p>Erh&ouml;hte Sichtbarkeit und Transparenz der Inspektionsdaten.</p>\r\n<p>Optimiertes Audit-Management und -Planung.</p>\r\n<p>Verbessertes Risikomanagement und Entscheidungsfindung.</p>\r\n<p>Verbesserte Kundenzufriedenheit durch zeitnahe und genaue Berichterstattung.</p>",
                            "image":  require('../assets/img/cms/additional_field/Governance and Regulatory Compliance.png')
                        },
                        {
                            "cmsId": "85",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>Automatisiertes Reporting und anpassbare Vorlagen.</p>\r\n<p>Integration mit ZfP-Tools und anderen Inspektionsger&auml;ten.</p>\r\n<p>Live-Updates und Echtzeit-Zugriff auf Inspektionsdaten.</p>\r\n<p>Risikobasiertes Inspektionssystem.</p>\r\n<p>Auditmanagement und -planung.</p>\r\n<p>Compliance-Nachverfolgung und -Verwaltung.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "85",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "Das Inspektions- und Prüfmodul von ASTRAM-ST-ST bietet eine umfassende Lösung für die Verwaltung von Inspektionsprozessen und die Nachverfolgung von Vorschriften. Die anpassbaren Berichtsvorlagen des Moduls und die Integration mit ZfP-Tools und anderen Inspektionsgeräten tragen dazu bei, den Inspektionsprozess zu rationalisieren und die Genauigkeit zu verbessern. Das risikobasierte Inspektionssystem und die Audit-Management-Funktionen bieten verbesserte Risikomanagement- und Entscheidungsmöglichkeiten. Insgesamt verbessert das Modul die Effizienz und Genauigkeit, reduziert das Risiko und erhöht die Kundenzufriedenheit.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "86",
                "cmsSlug": "zertifizierungs-akkreditierungsmanagement",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "            ",
                "metaKeyword": "            ",
                "title": "Zertifizierungs-Akkreditierungsmanagement",
                "description": "<p>Dieses Modul kann von Unternehmen verwendet werden, um die Erf&uuml;llung der Anforderungen zu Zertifizierungen und Akkreditierungen gegen&uuml;ber den Zertifizierungs-/Akkreditierungsstellen nachzuweisen und um den zugeh&ouml;rigenWorkflow zu verwalten, einschlie&szlig;lich Audits und Folgeprozesse.</p>",
                "description2": "<p>Dieses Modul kann von Unternehmen verwendet werden, um die Erf&uuml;llung der Anforderungen zu Zertifizierungen und Akkreditierungen gegen&uuml;ber den Zertifizierungs-/Akkreditierungsstellen nachzuweisen und um den zugeh&ouml;rigenWorkflow zu verwalten, einschlie&szlig;lich Audits und Folgeprozesse.</p>",
                "image":  require('../assets/img/cms/content/Accreditation_Management1.png'),
                "iconClass": "",
                "heading": "Zertifizierungs-Akkreditierungsmanagement",
                "alt": "Zertifizierungs-Akkreditierungsmanagement",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "86",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p><strong>Verbesserte Compliance:</strong> ASTRAM-ST bietet eine umfassende L&ouml;sung f&uuml;r die Verwaltung von Zertifizierungs- / Akkreditierungsprozessen, die die Einhaltung von Industriestandards und -vorschriften sicherstellt.</p>\r\n<p><strong>Gesteigerte Effizienz:</strong> Die Plattform rationalisiert Akkreditierungsprozesse, reduziert den manuellen Aufwand und steigert die Produktivit&auml;t.</p>\r\n<p><strong>Verbesserte Datengenauigkeit:</strong> ASTRAM-ST gew&auml;hrleistet genaue und aktuelle Zertifizierungs- / Akkreditierungsdaten und reduziert so das Risiko von Fehlern und Compliance-Problemen.</p>\r\n<p><strong>Verbesserte Kundenzufriedenheit:</strong> ASTRAM-ST bietet eine benutzerfreundliche Oberfl&auml;che f&uuml;r die Verwaltung von Zertifizierungs- /Akkreditierungsprozessen und verbessert so dieTransparenz f&uuml;r Kunden.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "86",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p><strong>Kundenmanagement:</strong> ASTRAM-ST bietet eine zentrale Plattform zur Verwaltung von Kundeninformationen, einschlie&szlig;lich Zertifizierungs- / Akkreditierungsstatus, Auditverlauf und Dokumentation.</p>\r\n<p><strong>Auditplanung:</strong> Die Plattform erm&ouml;glicht die Planung von Audits inkl.Terminbenachrichtigungen und Erinnerungen f&uuml;r Kunden und Auditoren.</p>\r\n<p><strong>Audit-Management:</strong> ASTRAM-ST bietet Tools zur Durchf&uuml;hrung von Audits, zur Verwaltung von Audit-Daten und zur Erstellung von Audit-Berichten.</p>\r\n<p><strong>Dokumentenmanagement:</strong> Die Plattform erm&ouml;glicht die Dokumentenverwaltung, einschlie&szlig;lich Versionskontrolle, Revisionshistorie und Dokumentengenehmigungs-Workflows.</p>\r\n<p><strong>Berichterstattung:</strong> ASTRAM-ST erm&ouml;glicht die Berichterstattung und Analyse von Akkreditierungsdaten in Echtzeit, sodass Unternehmen ihre Leistung verfolgen und Bereiche mit Verbesserungspotenzial identifizieren k&ouml;nnen.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "86",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "ASTRAM-ST bietet eine leistungsstarke Lösung für die Verwaltung von Zertifizierungs- /Akkreditierungsprozessen, die es Unternehmen ermöglicht, ihre Abläufe zu rationalisieren und die Einhaltung von Industriestandards und -vorschriften sicherzustellen. Mit seinen umfassenden Funktionen und Vorteilen ist ASTRAM-ST eine ideale Lösung für Organisationen, die ihre Zertifizierungs- / Akkreditierungs-Managementfähigkeiten verbessern möchten.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "90",
                "cmsSlug": "auftragsverwaltung",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Auftragsverwaltung",
                "description": "<p>Dieses Modul erm&ouml;glicht die Nachverfolgung von Auftr&auml;gen &uuml;ber den gesamten Prozess, von der Akquisition &uuml;ber die Ausf&uuml;hrung bis hin zur Abrechnung, und bietet einen optimierten Ansatz f&uuml;r die Projektabwicklung.</p>",
                "description2": "<p>Dieses Modul erm&ouml;glicht die Nachverfolgung von Auftr&auml;gen &uuml;ber den gesamten Prozess, von der Akquisition &uuml;ber die Ausf&uuml;hrung bis hin zur Abrechnung, und bietet einen optimierten Ansatz f&uuml;r die Projektabwicklung.</p>",
                "image":  require('../assets/img/cms/content/Job_Management.png'),
                "iconClass": "",
                "heading": "Auftragsverwaltung",
                "alt": "Auftragsverwaltung",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "90",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Verbesserte Effizienz und Produktivit&auml;t in Akquise- und Auftrags-Management-Prozessen.</p>\r\n<p>Weniger Fehler und Verz&ouml;gerungen bei der Auftragsverfolgung und -berichterstattung.</p>\r\n<p>Verbesserte Transparenz und Kontrolle &uuml;ber den Auftragsstatus und -fortschritt.</p>\r\n<p>Genaue und zeitnahe Abrechnung f&uuml;r auftragsbezogene Dienstleistungen.</p>\r\n<p>Verbesserte Kommunikation und Zusammenarbeit zwischen Teammitgliedern und mit Kunden.</p>\r\n<p>Optimiertes Dokumentenmanagement und -speicherung.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "90",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>Automatisiertes Tracking von Angeboten / Auftr&auml;gen von der Akquise bis zur Abrechnung.</p>\r\n<p>Anpassbare Angebots-/ Auftragsformulare f&uuml;r bestimmte Branchen und Arbeitsabl&auml;ufe.</p>\r\n<p>Echtzeit-Reporting und &Uuml;berwachung des Auftragsstatus.</p>\r\n<p>Schnittstelle zu Finanzverwaltungssystemen f&uuml;r genaue Abrechnung und Umsatzverfolgung.</p>\r\n<p>Automatisierte Benachrichtigungen und Erinnerungen f&uuml;r Auftragsfristen und -anforderungen.</p>\r\n<p>Dokumentenverwaltung und -ablage f&uuml;r auftragsbezogene Dateien.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "90",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "Durch den Einsatz von ASTRAM-ST für das Auftrags-Management kann die Effektivität und Effizienz von auftragsbezogenen Prozessen deutlich verbessert werden. Durch die Bereitstellung anpassbarer Formulare, automatisierter Nachverfolgung und Echtzeit-Berichterstellung trägt ASTRAM-ST dazu bei, die Arbeitsabläufe im Auftragsmanagement zu rationalisieren und Fehler und Verzögerungen zu reduzieren. Mit Funktionen wie Dokumentenmanagement, Finanzintegration und automatisierten Benachrichtigungen kann ASTRAM-ST auch dazu beitragen, die Kommunikation und Zusammenarbeit zwischen Teammitgliedern und mit Kunden zu verbessern. Insgesamt können die Auftrags-Management-Funktionen von ASTRAM-ST zu einer verbesserten Produktivität, Qualität und Kundenzufriedenheit führen.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "91",
                "cmsSlug": "zerstorungsfreie-prufung",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Zerstörungsfreie Prüfung",
                "description": "<p>Dieses Modul bietet automatisierte Formulare zur Reduzierung menschlicher Fehler bei der Berichterstellung, was besonders bei gro&szlig;en Projekten n&uuml;tzlich ist, bei denen die Berichterstellung einen Engpass bei der Projektabwicklung darstellt.</p>",
                "description2": "<p>Dieses Modul bietet automatisierte Formulare zur Reduzierung menschlicher Fehler bei der Berichterstellung, was besonders bei gro&szlig;en Projekten n&uuml;tzlich ist, bei denen die Berichterstellung einen Engpass bei der Projektabwicklung darstellt.</p>",
                "image":  require('../assets/img/cms/content/Non-Destructive_Testing_(1).png'),
                "iconClass": "",
                "heading": "Zerstörungsfreie Prüfung",
                "alt": "Zerstörungsfreie Prüfung",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "91",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Erh&ouml;hte Genauigkeit und Zuverl&auml;ssigkeit der Berichterstattung.</p>\r\n<p>Zeitersparnis durch Automatisierung und schlanke Prozesse.</p>\r\n<p>Verbesserte Kommunikation und Zusammenarbeit zwischen Inspektoren, Vorgesetzten und Kunden.</p>\r\n<p>Effizienteres Management gro&szlig;er ZfP-Projekte.</p>\r\n<p>Verbesserte Einhaltung von Industriestandards und -vorschriften.</p>\r\n<p>Bessere Transparenz &uuml;ber die Leistung einzelner Inspektoren und Teams.</p>\r\n<p>Bessere Datenanalyse und Entscheidungsfindung f&uuml;r kontinuierliche Verbesserungen.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "91",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>Automatisierte Formulare f&uuml;r die Berichterstellung, die menschliche Fehler reduzieren.</p>\r\n<p>Schnittstelle zu verschiedenen ZfP-Technologien zur Echtzeit-Datenerfassung.</p>\r\n<p>Live-MIS-Reporting (Management Information System) f&uuml;r sofortiges Feedback zum Status der Tests.</p>\r\n<p>Anpassbare Vorlagen f&uuml;r verschiedene Arten von ZfP-Pr&uuml;fungen.</p>\r\n<p>Digitale Signaturen f&uuml;r einfache Genehmigungen und schnellere Bearbeitung.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "91",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "Zusammenfassend lässt sich sagen, dass der Einsatz von ASTRAM-ST in der zerstörungsfreien Prüfung zu erheblichen Verbesserungen der Effizienz und Genauigkeit der Berichterstattung sowie zu einem besseren Projektmanagement und einer besseren Compliance insgesamt führt. Durch die Nutzung der Automatisierung und der digitalen Fähigkeiten von ASTRAM-ST können ZfP-Unternehmen ihre Prozesse verbessern und ihren Kunden qualitativ hochwertigere Ergebnisse liefern.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "92",
                "cmsSlug": "risikobasierte-inspektionen",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "       ",
                "metaKeyword": "       ",
                "title": "Risikobasierte Inspektionen",
                "description": "<p>Dieses Modul kann verwendet werden, um ein risikobasiertes Inspektionssystem in kritischen Branchen zu erstellen, um Risiken zu managen und Inspektions- und Testpl&auml;ne zu erstellen.</p>",
                "description2": "<p>Dieses Modul kann verwendet werden, um ein risikobasiertes Inspektionssystem in kritischen Branchen zu erstellen, um Risiken zu managen und Inspektions- und Testpl&auml;ne zu erstellen.</p>",
                "image":  require('../assets/img/cms/content/Risk_Based_Inspection.png'),
                "iconClass": "",
                "heading": "Risikobasierte Inspektionen",
                "alt": "Risikobasierte Inspektionen",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "92",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p><strong>Verbesserte Effizienz:</strong> Der risikobasierte Ansatz von ASTRAM-ST und die Echtzeit-Datenerfassung können dazu beitragen, potenzielle Probleme zu erkennen, bevor sie zu größeren Problemen werden, was zu einer verbesserten Effizienz und reduzierten Ausfallzeiten führt.</p>\r\n<p><strong>Bessere Entscheidungsfindung:</strong> Die automatisierte Berichterstattung von ASTRAM-ST und die Integration mit anderen Systemen können einen umfassenderen Überblick über den Betrieb bieten, was zu einer besseren Entscheidungsfindung führt.</p>\r\n<p><strong>Erhöhte Sicherheit: </strong>Der risikobasierte Ansatz von ASTRAM-ST kann dazu beitragen, potenzielle Sicherheitsprobleme zu erkennen und Unfälle zu vermeiden.</p>\r\n<p><strong>Reduzierte Kosten:</strong> Die Echtzeit-Datenerfassung und -analyse von ASTRAM-ST kann dazu beitragen, potenzielle Probleme frühzeitig zu erkennen und den Bedarf an kostspieligen Reparaturen oder Austausch zu reduzieren.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "92",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p><strong>Anpassbare Inspektionspl&auml;ne:</strong> ASTRAM-ST erm&ouml;glicht die Erstellung von anpassbaren Inspektionspl&auml;nen, die auf bestimmte Branchen oder Ger&auml;tetypen zugeschnitten werden k&ouml;nnen.</p>\r\n<p><strong>Risikobasierter Ansatz:</strong> ASTRAM-ST verwendet einen risikobasierten Ansatz f&uuml;r Inspektionen, der effizientere und effektivere Inspektionen kritischer Assets erm&ouml;glicht.</p>\r\n<p><strong>Echtzeitdaten:</strong> ASTRAM-ST erm&ouml;glicht die Datenerfassung und -analyse in Echtzeit, wodurch potenzielle Probleme identifiziert und ungeplante Ausfallzeiten vermieden werden k&ouml;nnen.</p>\r\n<p><strong>Automatisiertes Reporting:</strong> ASTRAM-ST automatisiert das Reporting und erleichtert so die Erstellung von Berichten und den Austausch von Informationen mit Stakeholdern.</p>\r\n<p><strong>Integration mit anderen Systemen:</strong> ASTRAM-ST kann in andere Systeme wie Asset-Management-Software oder ERP-Systeme integriert werden, um einen umfassenderen &Uuml;berblick &uuml;ber den Betrieb zu erhalten.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "92",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "ASTRAM-ST ist ein leistungsstarkes Tool für risikobasierte Inspektionen, das Echtzeit-Datenerfassung, automatisierte Berichterstattung und einen risikobasierten Ansatz für Inspektionen bietet. Durch die Integration mit anderen Systemen kann ASTRAM-ST einen umfassenderen Überblick über den Betrieb bieten, was zu einer besseren Entscheidungsfindung und einer verbesserten Effizienz führt. Mit seiner Fähigkeit, potenzielle Sicherheitsprobleme zu erkennen und Unfälle zu vermeiden, kann ASTRAM-ST dazu beitragen, Kosten zu senken und die allgemeine Sicherheit des Betriebs zu verbessern.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "93",
                "cmsSlug": "gesundheits-undarbeitssicherheitsmanagement",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Arbeitsicherheits- und Gesundheitsmanagement",
                "description": "<p>Dieses Modul erm&ouml;glicht die Verfolgung aller gesundheits- und arbeitssicherheitsrelevanten Parameter und die Berichterstattung &uuml;ber ein LIVE MIS.</p>",
                "description2": "<p>Dieses Modul erm&ouml;glicht die Verfolgung aller gesundheits- und arbeitssicherheitsrelevanten Parameter und die Berichterstattung &uuml;ber ein LIVE MIS.</p>",
                "image":  require('../assets/img/cms/content/Health_and_Safety_Management.png'),
                "iconClass": "",
                "heading": "Arbeitsicherheits- und Gesundheitsmanagement",
                "alt": "Arbeitsicherheits- und Gesundheitsmanagement",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "93",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Verbesserte Sicherheit f&uuml;r das Personal durch arbeitsplatzspezifische Risiko-Analysen.</p>\r\n<p>Reduziertes Risiko von Unf&auml;llen und Personalausf&auml;llen durch Steuerung der Vorsorge und Schutzma&szlig;nahmen.</p>\r\n<p>Effizienzsteigerung bei der Verwaltung von gesundheits- und arbeitssicherheitsrelevanten Prozessen.</p>\r\n<p>Erweiterte Berichtsfunktionen f&uuml;r Compliance-Nachweise und regulatorische Anforderungen.</p>\r\n<p>Verbesserte Transparenz und Prozesse im gesamten Unternehmen.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "93",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>M&ouml;glichkeit, alle gesundheits- und arbeitssicherheitsrelevanten Parameter in Echtzeit zu verfolgen und zu &uuml;berwachen.</p>\r\n<p>Automatisierte Warnungen und Benachrichtigungen bei kritischen Ereignissen, wie z. B. Vorf&auml;llen oder Nichteinhaltung von Vorschriften.</p>\r\n<p>M&ouml;glichkeit, benutzerdefinierte Formulare und Checklisten f&uuml;r Vorsorgema&szlig;nahmen, Unterweisungen und Schutzausr&uuml;stung zu erstellen.</p>\r\n<p>Einfacher Zugriff auf historische Daten f&uuml;r Trendanalysen und Berichte.</p>\r\n<p>Verkn&uuml;pfung mit anderen Modulen, wie z. B. Asset Management und Auftragsmanagement, um die Sicherheit von Einsatzpersonal und korrekte Zuordnung von Schutzausr&uuml;stung zu gew&auml;hrleisten.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "93",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "Der Einsatz von ASTRAM-ST für das Gesundheits- und Arbeitssicherheitsmanagement kann die Prozesse zur Sicherheit am Arbeitsplatz und zur Gesundheit von Personal erheblich verbessern und damit die Effizienz und Compliance messbar erhöhen. Mit der Möglichkeit, alle gesundheits- und sicherheitsrelevanten Parameter in Echtzeit zu verfolgen und zu überwachen, können Unternehmen potenzielle Risiken schnell identifizieren und angehen und so Vorfälle und Verstöße verhindern. Die Verknüpfung von ASTRAM-ST mit anderen Software-Modulen stellt sicher, dass Gesundheits- und Arbeitssicherheitsaspekte in andere betriebliche Prozesse der Organisation integriert werden.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "97",
                "cmsSlug": "qualitatskontrollepharma-industrie",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "Qualitätskontrolle Pharma-Industrie",
                "metaDescriptions": "        Qualitätskontrolle Pharma-Industrie ",
                "metaKeyword": "        Qualitätskontrolle Pharma-Industrie ",
                "title": "Qualitätskontrolle Pharma-Industrie",
                "description": "<p>Dieses Modul wurde speziell f&uuml;r die pharmazeutische Industrie entwickelt und erm&ouml;glicht die Qualit&auml;tskontrolle von pharmazeutischen Produkten.</p>",
                "description2": "<p>Dieses Modul wurde speziell f&uuml;r die pharmazeutische Industrie entwickelt und erm&ouml;glicht die Qualit&auml;tskontrolle von pharmazeutischen Produkten.</p>",
                "image":  require('../assets/img/cms/content/Pharmaceutical_Quality_Control.png'),
                "iconClass": "",
                "heading": "Qualitätskontrolle Pharma-Industrie",
                "alt": "Qualitätskontrolle Pharma-Industrie",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "97",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p><strong>Verbesserte Effizienz:</strong> ASTRAM-ST verbessert die Effizienz von Testverfahren und stellt sicher, dass Tests zielgerecht durchgef&uuml;hrt werden und die Ergebnisse zeitnah geliefert werden.</p>\r\n<p><strong>Compliance: </strong>ASTRAM-ST stellt die Einhaltung der GMP-Anforderungen sicher und stellt Pr&uuml;fpfade, elektronische Signaturen und Validierungsprotokolle bereit.</p>\r\n<p><strong>Datenintegrit&auml;t:</strong> ASTRAM-ST tr&auml;gt dazu bei, die Integrit&auml;t von Daten aufrechtzuerhalten, indem es sicheren Zugriff, R&uuml;ckverfolgbarkeit und Versionskontrolle bietet.</p>\r\n<p><strong>Reduzierte Fehler:</strong> ASTRAM-ST reduziert die Wahrscheinlichkeit von Fehlern durch den Einsatz automatisierter Prozesse, vordefinierter Verfahren und Protokolle.</p>\r\n<p><strong>Verbesserte R&uuml;ckverfolgbarkeit:</strong> ASTRAM-ST bietet eine vollst&auml;ndige R&uuml;ckverfolgbarkeit aller Testverfahren, Proben und Ergebnisse, wodurch es einfach ist, den Status der Tests zu verfolgen und sicherzustellen, dass alles nach den richtigen Verfahren durchgef&uuml;hrt wird.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "97",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p><strong>Anpassbare Workflows:</strong> ASTRAM-ST erm&ouml;glicht es Benutzern, Workflows zu erstellen, die auf ihre spezifischen Anforderungen zugeschnitten sind, um sicherzustellen, dass alle notwendigen Schritte in den Qualit&auml;tskontrollprozess einbezogen werden.</p>\r\n<p><strong>Automatisiertes Reporting:</strong> ASTRAM-ST kann automatisierte Berichte erstellen, die Echtzeit-Updates &uuml;ber den Status von Qualit&auml;tskontrollaktivit&auml;ten liefern, um Probleme zu identifizieren und sicherzustellen, dass sie umgehend behoben werden.</p>\r\n<p><strong>Dokumentenmanagement:</strong> Das Dokumentenmanagementsystem von ASTRAM-ST stellt sicher, dass alle relevanten Dokumente an einem zentralen Ort abgelegt werden und f&uuml;r autorisiertes Personal leicht zug&auml;nglich sind.</p>\r\n<p><strong>Audit-Trails:</strong> Die Audit-Trails von ASTRAM-ST bieten eine vollst&auml;ndige Aufzeichnung aller Qualit&auml;tskontrollaktivit&auml;ten, einschlie&szlig;lich der Personen, die die Aktivit&auml;t durchgef&uuml;hrt haben, wann sie durchgef&uuml;hrt wurde und was die Ergebnisse waren.</p>\r\n<p><strong>Integration in bestehende Systeme:</strong> ASTRAM-ST kann in bestehende Systeme wie Laborinformationsmanagementsysteme (LIMS) integriert werden, um den Qualit&auml;tskontrollprozess zu rationalisieren und die Effizienz zu verbessern.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "97",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "ASTRAM-ST ist eine effektive Lösung für das Management der Qualitätskontrolle in der pharmazeutischen Industrie. Es bietet eine umfassende Palette von Funktionen und Vorteilen, die sicherstellen, dass alle Aspekte des Testprozesses effektiv und effizient verwaltet werden. Durch den Einsatz von ASTRAM-ST können Pharmaunternehmen ihre Qualitätskontrollverfahren verbessern, die Einhaltung gesetzlicher Anforderungen sicherstellen, Fehler reduzieren und die Rückverfolgbarkeit verbessern.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "98",
                "cmsSlug": "supply-chain-management-expediting",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Supply Chain Management / Expediting",
                "description": "<p>Dieses Modul kann verwendet werden, um Supply Chains zu &uuml;berwachen, Inspektionen weltweit inkl. der automatischen Zuordnung von geographischen Koordinaten(geo-tagging) zu dokumentieren, um Lieferverz&ouml;gerungen rechtzeitig erkennen zu k&ouml;nnen und Ma&szlig;nahmen zu ergreifen.</p>",
                "description2": "<p>Dieses Modul kann verwendet werden, um Supply Chains zu &uuml;berwachen, Inspektionen weltweit inkl. der automatischen Zuordnung von geographischen Koordinaten(geo-tagging) zu dokumentieren, um Lieferverz&ouml;gerungen rechtzeitig erkennen zu k&ouml;nnen und Ma&szlig;nahmen zu ergreifen.</p>",
                "image":  require('../assets/img/cms/content/International_Trade.png'),
                "iconClass": "",
                "heading": "Supply Chain Management / Expediting",
                "alt": "Supply Chain Management / Expediting",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "98",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Reduzierter Zeit- und Kostenaufwand f&uuml;r Inspektionen und Expediting.</p>\r\n<p>Verbesserte Genauigkeit und Konsistenz bei der Berichterstellung.</p>\r\n<p>Verbesserte Einhaltung von Handelsvorschriften und -standards bei Im- und Export.</p>\r\n<p>Optimiertes Sendungsmanagement und -verfolgung.</p>\r\n<p>Verbesserte Transparenz und Kontrolle in der gesamten Lieferkette.</p>\r\n<p>Gesteigerte Produktivit&auml;t und Effizienz im technischen Einkauf.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "98",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>App-basierte Delegation von Inspektionen weltweit.</p>\r\n<p>Automatisierte Formulare und Checklisten f&uuml;r Inspektionen und Bewertungen.</p>\r\n<p>Echtzeit-Reporting mit Geotag-Fotos f&uuml;r eine verbesserte Entscheidungsfindung.</p>\r\n<p>Compliance-Tracking f&uuml;r Handelsvorschriften und -standards bei Im- /Export.</p>\r\n<p>Integration mit externen Logistikdienstleistern f&uuml;r ein optimiertes Liefermanagement.</p>\r\n<p>Anpassbare Workflows f&uuml;r die spezifischen Anforderungen des Handelsprozesses.</p>\r\n<p>Cloud-basierter Speicher und Zugriff f&uuml;r einfache Zusammenarbeit und Freigabe.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "98",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "ASTRAM-ST kann die Effizienz und Effektivität von internationalen Lieferketten erheblich verbessern, indem es eine optimierte und automatisierte Lösung für Inspektionen, Bewertungen und Compliance-Verfolgung bereitstellt. Mit Echtzeitberichten und mit Geotags versehenen Fotos kann die Entscheidungsfindung beschleunigt und die Genauigkeit der Maßnahmen verbessert werden. Darüber hinaus verbessern die Schnittstellen mit externen Logistikdienstleistern und anpassbare Workflows die Flexibilität und Benutzerfreundlichkeit des Systems weiter. Durch den Einsatz von ASTRAM-ST für den internationalen Handel können Unternehmen die Produktivität steigern, Kosten senken und die Transparenz und Compliance in der gesamten Lieferkette erhöhen.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "99",
                "cmsSlug": "labormanagement",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "        ",
                "metaKeyword": "        ",
                "title": "Labormanagement",
                "description": "<p>Dieses Modul kann verwendet werden, um den Laborbetrieb zu steuern, einschlie&szlig;lich Probenverfolgung, -pr&uuml;fung und -berichterstattung.</p>",
                "description2": "<p>Dieses Modul kann verwendet werden, um den Laborbetrieb zu steuern, einschlie&szlig;lich Probenverfolgung, -pr&uuml;fung und -berichterstattung.</p>",
                "image":  require('../assets/img/cms/content/Laboratory_Management.png'),
                "iconClass": "",
                "heading": "Labormanagement",
                "alt": "Labormanagement",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "99",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p><strong>Gesteigerte Effizienz:</strong> Die Automatisierung und Rationalisierung von Laborprozessen bei ASTRAM-ST tr&auml;gt dazu bei, die Effizienz zu steigern, Fehler zu reduzieren und Zeit zu sparen.</p>\r\n<p><strong>Verbesserte Genauigkeit:</strong> Mit den Qualit&auml;tskontroll- und Ergebnismanagementfunktionen von ASTRAM-ST k&ouml;nnen Labore sicherstellen, dass ihre Ergebnisse genau und konsistent sind.</p>\r\n<p><strong>Besserer Kundenservice:</strong> Die Probenverfolgungs- und Ergebnisverwaltungsfunktionen von ASTRAM-ST erm&ouml;glichen es Laboren, einen besseren Kundenservice zu bieten, indem sie Echtzeit-Updates und genaue Berichte bereitstellen.</p>\r\n<p><strong>Kosteneinsparungen:</strong> Durch die Rationalisierung von Prozessen und die Reduzierung von Fehlern kann ASTRAM-ST Laboren helfen, Geld f&uuml;r Wiederholungsanalysen zu sparen.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "99",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p><strong>Probenverfolgung:</strong> Die Probenverfolgungsfunktion von ASTRAM-ST ermöglicht es Laboren, Proben vom Eingang bis zur Lieferung mittels QR-Codes oder Tags zu verfolgen, um sicherzustellen, dass während des Testprozesses keine Proben verloren gehen oder falsch zugeordnet werden.</p>\r\n<p><strong>Ergebnismanagement:</strong> Die Ergebnismanagement-Funktion von ASTRAM-ST ermöglicht es Laboren, Testergebnisse einfach zu verwalten und Berichte für Kunden zu erstellen. Das System kann an die Anforderungen verschiedener Labore angepasst werden und kann sowohl manuelle als auch automatisierte Testprozesse verarbeiten.</p>\r\n<p><strong>Geräteverwaltung:</strong> Mit der Geräteverwaltungsfunktion von ASTRAM-ST können Labore ihren Gerätebestand verwalten, Wartungsarbeiten planen und die Nutzung verfolgen. Auf diese Weise können Labore sicherstellen, dass ihre Geräte ordnungsgemäß gewartet und kalibriert werden, wodurch das Risiko von Fehlern oder falschen Ergebnissen verringert wird.</p>\r\n<p><strong>Qualitätskontrolle:</strong> Die Qualitätskontrollfunktion von ASTRAM-ST ermöglicht es Laboren, ihre Qualitätskontrollverfahren zu verwalten und ihre Leistung im Laufe der Zeit zu verfolgen. Dies hilft Laboren, Verbesserungspotenziale zu identifizieren und sicherzustellen, dass ihre Ergebnisse genau und konsistent sind.</p>\r\n<p><strong>Abrechnung und Rechnungsstellung:</strong> Die Abrechnungs- und Fakturierungsfunktion von ASTRAM-ST ermöglicht es Laboren, ihre Abrechnungs- und Rechnungsprozesse auf optimierte Weise zu verwalten. Dies trägt dazu bei, Fehler und Verzögerungen bei der Zahlungsabwicklung zu reduzieren und sicherzustellen, dass die Labore umgehend für ihre Dienstleistungen bezahlt werden.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "99",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "Durch den Einsatz von ASTRAM-ST für das 10. Labormanagement können Unternehmen ihre Analyseabläufe optimieren, Kosten senken und die allgemeine Kundenzufriedenheit verbessern. Die Echtzeit-Nachverfolgung, die automatisierte Planung und die anpassbaren Berichtsfunktionen der Plattform ermöglichen es Unternehmen, ihre Ablaufprozesse im Prüflabor zu rationalisieren und pünktliche und genaue Probenauswertungen sicherzustellen. Darüber hinaus helfen die Compliance-Management-Funktionen von ASTRAM-ST Unternehmen, den Überblick über die regulatorischen Anforderungen für ihr Prüflabor zu behalten und kostspielige Konsequenzen zu vermeiden. Insgesamt bietet ASTRAM-ST eine umfassende Lösung für Prüflabore, die ihre Abläufe optimieren und die Kundenzufriedenheit verbessern möchten.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "100",
                "cmsSlug": "hr-management",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "         ",
                "metaKeyword": "         ",
                "title": "Personalmanagement",
                "description": "<p>Dieses Modul erm&ouml;glicht HR-Management-Funktionen wie Mitarbeiterdatenverwaltung, Leistungsbewertung und Schulungsmanagement.</p>",
                "description2": "<p>Dieses Modul erm&ouml;glicht HR-Management-Funktionen wie Mitarbeiterdatenverwaltung, Leistungsbewertung und Schulungsmanagement.</p>",
                "image":  require('../assets/img/cms/content/HR_Management.png'),
                "iconClass": "",
                "heading": "Personalmanagement",
                "alt": "Personalmanagement",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "100",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p><strong>Gesteigerte Effizienz:</strong> Die zentralisierte Datenbank von ASTRAM-ST und die Automatisierung von HR-Prozessen helfen, Zeit zu sparen und den Verwaltungsaufwand zu reduzieren, sodass sich die HR-Mitarbeiter auf strategischere Aufgaben konzentrieren k&ouml;nnen.</p>\r\n<p><strong>Verbesserte Genauigkeit:</strong> Durch den Wegfall manueller Dateneingabe und papierbasierter Prozesse tr&auml;gt ASTRAM-ST dazu bei, Fehler zu reduzieren und die Datengenauigkeit sicherzustellen.</p>\r\n<p><strong>Verbesserte Mitarbeitererfahrung:</strong> Die Leistungsmanagement-, Schulungs- und Entwicklungsmodule von ASTRAM-ST k&ouml;nnen dazu beitragen, das Engagement der Mitarbeiter und die Karriereentwicklung zu verbessern, was zu einer h&ouml;heren Mitarbeiterzufriedenheit und -bindung f&uuml;hrt.</p>\r\n<p><strong>Bessere Compliance:</strong> Das Arbeitszeit- und Urlaubsmanagement-Modul von ASTRAM-ST sorgt f&uuml;r die Einhaltung von Arbeitszeitgesetzen und -vorschriften und reduziert so das Risiko rechtlicher Haftung.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "100",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p><strong>Mitarbeiterdatenbank:</strong> ASTRAM-ST bietet eine zentralisierte Mitarbeiterdatenbank, in der HR-Mitarbeiter alle Mitarbeiterinformationen speichern k&ouml;nnen, einschlie&szlig;lich pers&ouml;nlicher Daten, Kontaktinformationen, Arbeitsverlauf, Leistungsbeurteilungen und mehr. Die Einrichtung der hierzu erforderlichen erweiterter Datenschutzma&szlig;nahmen wie E2E-Verschl&uuml;sselung und spezifischer Berechtigungsprozesse (&sbquo;Bring-Your-Own-Key etc.) kann durch ASTRAM-ST bereit gestellt werden.</p>\r\n<p><strong>Rekrutierungsmanagement:</strong> ASTRAM-ST hilft bei der Rationalisierung des Rekrutierungsprozesses, indem es HR-Mitarbeitern erm&ouml;glicht, Stellenangebote zu ver&ouml;ffentlichen, Bewerbungen zu pr&uuml;fen und Vorstellungsgespr&auml;che zu planen &ndash; alles an einem Ort.</p>\r\n<p><strong>Leistungsmanagement:</strong> Das Performance-Management-Modul von ASTRAM-ST erm&ouml;glicht es HR-Mitarbeitern, Ziele zu setzen, den Fortschritt der Mitarbeiter zu verfolgen und Feedback zu geben, was dazu beitr&auml;gt, das Engagement und die Produktivit&auml;t der Mitarbeiter zu verbessern.</p>\r\n<p><strong>Arbeitszeit- und Urlaubsmanagement:</strong> Das Urlaubsmanagement-Modul von ASTRAM-ST vereinfacht den Prozess der Verwaltung von Urlaubsantr&auml;gen von Mitarbeitern und erm&ouml;glicht es den HR-Mitarbeitern, die Urlaubssalden der Mitarbeiter einzusehen, Antr&auml;ge zu genehmigen oder abzulehnen und Abwesenheiten zu verfolgen.</p>\r\n<p><strong>Aus- und Weiterbildung:</strong> Das Schulungs- und Entwicklungsmodul von ASTRAM-ST erm&ouml;glicht es den HR-Mitarbeitern, die Mitarbeiterschulung zu planen und zu verfolgen, um sicherzustellen, dass die Mitarbeiter die notwendigen F&auml;higkeiten und Kenntnisse erhalten, um in ihren Rollen hervorragende Leistungen zu erbringen.</p>\r\n",
                            "image": ""
                        },
                        {
                            "cmsId": "100",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "Das HR-Management-Modul von ASTRAM-ST bietet eine umfassende Lösung für die Verwaltung von Mitarbeiterdaten, Rekrutierung, Leistung, Urlaub sowie Aus- und Weiterbildung. Durch die Steigerung von Effizienz, Genauigkeit und Mitarbeitererfahrung kann ASTRAM-ST Unternehmen dabei helfen, ihre HR-Prozesse zu verbessern und ihre strategischen Ziele zu erreichen.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "101",
                "cmsSlug": "umweltschutzmanagement",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Umweltschutzmanagement",
                "description": "<p>Dieses Modul erm&ouml;glicht die Verfolgung und Verwaltung von Umweltschutzparametern und die Berichterstattung &uuml;ber LIVE MIS.</p>",
                "description2": "<p>Dieses Modul erm&ouml;glicht die Verfolgung und Verwaltung von Umweltschutzparametern und die Berichterstattung &uuml;ber LIVE MIS.</p>",
                "image":  require('../assets/img/cms/content/Environmental_Management1.png'),
                "iconClass": "",
                "heading": "Umweltschutzmanagement",
                "alt": "Umweltschutzmanagement",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "101",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p><strong>Optimierte Abl&auml;ufe:</strong> ASTRAM-ST bietet eine zentralisierte Plattform zur Verwaltung aller Umweltaspekte eines Betriebes und erm&ouml;glicht so ein optimiertes Compliance-Management im Umweltschutz.</p>\r\n<p><strong>Risikominderung:</strong> ASTRAM-ST hilft bei der Identifizierung und dem Management potenzieller Umweltrisiken bzw. relevanten beh&ouml;rdlichen Auflagen, wodurch das Risiko von Verst&ouml;&szlig;en und Umweltvorf&auml;llen verringert wird.</p>\r\n<p><strong>Kosteneinsparungen: </strong>Durch ein effizientes Management und Monitoring von Umweltparameternkann ASTRAM-ST dazu beitragen, den Energieverbrauch, das Abfallaufkommen und die Emissionen zu reduzieren, was zur Einhaltung von Grenzwerten und zu Kosteneinsparungen f&uuml;hrt.</p>\r\n<p><strong>Verbesserte Reputation:</strong> Die Nachhaltigkeitsberichterstattung von ASTRAM-ST hilft Unternehmen, ihr Engagement f&uuml;r &ouml;kologische Nachhaltigkeit unter Beweis zu stellen und ihre Reputation bei den Stakeholdern zu verbessern.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "101",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p><strong>Compliance-Management:</strong> ASTRAM-ST hilft bei der Verfolgung und Verwaltung von Umwelt-Compliance-Anforderungen gem&auml;&szlig; den gesetzlichen Vorschriften.</p>\r\n<p><strong>Umwelt&uuml;berwachung: </strong>ASTRAM-ST erm&ouml;glicht die &Uuml;berwachung und Verfolgung von umweltrelevanten Parametern und gibt Warnungen im Falle einer Abweichung heraus.</p>\r\n<p><strong>Emissions- und Abfallmanagement: </strong>ASTRAM-ST bietet eine umfassende L&ouml;sung f&uuml;r das Management von Emissionen und Abf&auml;llen, die w&auml;hrend des Produktionsprozesses entstehen.</p>\r\n<p><strong>Audit-Management:</strong> ASTRAM-ST bietet eine Plattform zur Durchf&uuml;hrung von Umweltpr&uuml;fungen, zur Verfolgung von Nichtkonformit&auml;ten und zum Ergreifen von Korrekturma&szlig;nahmen.</p>\r\n<p><strong>Nachhaltigkeitsberichterstattung: </strong>ASTRAM-ST hilft bei der Erstellung von Berichten zur &ouml;kologischen Nachhaltigkeit, einschlie&szlig;lich Kohlenstoff-Fu&szlig;abdruck, Energieverbrauch und Wasserverbrauch.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "101",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "ASTRAM-ST bietet eine umfassende Lösung für das Umweltmanagement, die es Unternehmen ermöglicht, regulatorische Anforderungen zu erfüllen, Umweltparameter zu überwachen, Emissionen und Abfälle zu verwalten, Audits durchzuführen und Nachhaltigkeitsberichte zu erstellen. Durch den Einsatz von ASTRAM-ST können Unternehmen ihr Umweltmanagement rationalisieren, potenzielle Risiken reduzieren, Kosten sparen und ihren Ruf verbessern.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "102",
                "cmsSlug": "compliance-management",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Compliance- Management",
                "description": "<p>Dieses Modul kann von Organisationen verwendet werden, um die Einhaltung von Vorschriften und Standards zu verwalten.</p>",
                "description2": "<p>Dieses Modul kann von Organisationen verwendet werden, um die Einhaltung von Vorschriften und Standards zu verwalten.</p>",
                "image":  require('../assets/img/cms/content/Governance_and_Regulatory_Compliance.png'),
                "iconClass": "",
                "heading": "Compliance- Management",
                "alt": "Compliance- Management",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "102",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Verbesserte Compliance und geringeres Risiko von Strafen oder Bu&szlig;geldern.</p>\r\n<p>Optimierte Compliance-Management-Prozesse, die Zeit und Ressourcen sparen.</p>\r\n<p>Verbesserte Transparenz des Compliance-Status und potenzieller Probleme.</p>\r\n<p>Verbesserte Datengenauigkeit und -konsistenz.</p>\r\n<p>Verbesserte Zusammenarbeit und Kommunikation zwischen den Abteilungen bzgl. Ma&szlig;nahmen und Aktivit&auml;ten.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "102",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>Kataster der zutreffenden Vorschriften und Anforderungen mit automatischer Pflichtenverwaltung.&nbsp;</p>\r\n<p>Anpassbare Formulare und Workflows f&uuml;r das Compliance-Management.</p>\r\n<p>Automatisierte Berichterstattung und Dokumentation f&uuml;r beh&ouml;rdliche Einreichungen.</p>\r\n<p>Echtzeit-&Uuml;berwachung und Warnungen bei Compliance-Verst&ouml;&szlig;en.</p>\r\n<p>Audit-Trails zur Nachverfolgung des Compliance-Verlaufs.</p>\r\n<p>Schnittstellen zu anderen Systemen f&uuml;r ein nahtloses Datenmanagement.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "102",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "Die Verwendung von ASTRAM-ST für die Governance – Prozesse und die Einhaltung gesetzlicher Vorgaben kann Unternehmen dabei helfen, ihre Compliance-Verpflichtungen effektiv zu verwalten und das Risiko der Nichteinhaltung zu verringern. Die anpassbaren Formulare und Workflows, die Echtzeitüberwachung und die automatisierten Berichtsfunktionen tragen dazu bei, Compliance-Management-Prozesse zu rationalisieren und einen besseren Einblick in den Compliance-Status zu bieten. Durch die Schnittstellenzu anderen Software-Modulen ermöglicht ASTRAM-ST ein nahtloses Datenmanagement und verbessert die Zusammenarbeit und Kommunikation zwischen den Abteilungen. Letztendlich trägt ASTRAM-ST dazu bei, die Compliance zu verbessern und das Risiko von Abweichungen und Folgekosten zu verringern und gleichzeitig Zeit und Ressourcen zu sparen.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "103",
                "cmsSlug": "transportation-and-logistics",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Transport und Logistik",
                "description": "<p>Dieses Modul kann verwendet werden, um logistische Vorg&auml;nge zu steuern, einschlie&szlig;lich Transportmanagement, Planung und Berichterstattung.</p>",
                "description2": "<p>Dieses Modul kann verwendet werden, um logistische Vorg&auml;nge zu steuern, einschlie&szlig;lich Transportmanagement, Planung und Berichterstattung.</p>",
                "image":  require('../assets/img/cms/content/Transportation_and_Logistics.png'),
                "iconClass": "",
                "heading": "Transport und Logistik",
                "alt": "Transport und Logistik",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "103",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Verbesserte betriebliche Effizienz und Kosteneinsparungen.</p>\r\n<p>Verbesserte Transparenz und Kontrolle über Sendungen und Logistik.</p>\r\n<p>Weniger Fehler und Verzögerungen durch Automatisierung und Echtzeit-Tracking.</p>\r\n<p>Erhöhte Kundenzufriedenheit durch genaue und pünktliche Lieferungen sowie automatisierte Kommunikation über den Status der Lieferung im Vorfeld.</p>\r\n<p>Vereinfachtes Compliance-Management für regulatorische Anforderungen.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "103",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>Echtzeit-Tracking von Sendungen und Fahrzeugen.</p>\r\n<p>Automatisierte Planung von Lieferrouten.</p>\r\n<p>Integration mit externen Logistikdienstleistern.</p>\r\n<p>Anpassbare Berichte und Analysen.</p>\r\n<p>Elektronischer Zustellnachweis und Unterschriftenerfassung.</p>\r\n<p>Compliance-Management für regulatorische Anforderungen.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "103",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "Durch den Einsatz von ASTRAM-ST für das Transport- und Logistikmanagement können Unternehmen ihre Logistikabläufe optimieren, Kosten senken und die allgemeine Kundenzufriedenheit verbessern. Die Echtzeit-Nachverfolgung, die automatisierte Planung und die anpassbaren Berichtsfunktionen der Plattform ermöglichen es Unternehmen, ihre Logistikprozesse zu rationalisieren und pünktliche und genaue Lieferungen sicherzustellen. Darüber hinaus helfen die Compliance-Management-Funktionen von ASTRAM-ST Unternehmen, den Überblick über die regulatorischen Anforderungen zu behalten und kostenrelevante Konsequenzen zu vermeiden. Insgesamt bietet ASTRAM-ST eine umfassende Lösung für Transport- und Logistikunternehmen, die ihre Abläufe optimieren und die Kundenzufriedenheit verbessern möchten.",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "104",
                "cmsSlug": "f-e-management-und-studien",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "F&E Management und Studien",
                "description": "<p>Im Bereich der Forschung und Entwicklung (F&amp;E) sind Studien zur Erforschung eines Untersuchungsgegenstandesein Kernelement, um die Sicherheit und Verwendbarkeit neuer Produkte, Medikamente oder Behandlungsmethoden zu gew&auml;hrleisten. Mit ASTRAM-STk&ouml;nnen alle Aspekte des Managements solcher Studien verwaltet werden. ASTRAM-ST kann dazu beitragen, den Entwicklungsprozess und die zugeh&ouml;rigen Studien zu rationalisieren, Fehler zu reduzieren und die Effizienz zu verbessern, was letztendlich zu einer schnelleren Markteinf&uuml;hrung neuer Produkte, Medikamente oder Behandlungsmethoden f&uuml;hrt.</p>",
                "description2": "<p>Im Bereich der Forschung und Entwicklung (F&amp;E) sind Studien zur Erforschung eines Untersuchungsgegenstandesein Kernelement, um die Sicherheit und Verwendbarkeit neuer Produkte, Medikamente oder Behandlungsmethoden zu gew&auml;hrleisten. Mit ASTRAM-STk&ouml;nnen alle Aspekte des Managements solcher Studien verwaltet werden. ASTRAM-ST kann dazu beitragen, den Entwicklungsprozess und die zugeh&ouml;rigen Studien zu rationalisieren, Fehler zu reduzieren und die Effizienz zu verbessern, was letztendlich zu einer schnelleren Markteinf&uuml;hrung neuer Produkte, Medikamente oder Behandlungsmethoden f&uuml;hrt.</p>",
                "image":  require('../assets/img/cms/content/Clinical_Trial_Management.png'),
                "iconClass": "",
                "heading": "F&E Management und Studien",
                "alt": "F&E Management und Studien",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "104",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>Das Managementsystem von Studien mitASTRAM-ST kann Ihnen helfen, Zeit und Ressourcen zu sparen, Fehler und Inkonsistenzen zu reduzieren und die Einhaltung gesetzlicher Anforderungen sicherzustellen.</p>\r\n<p>Durch den Einsatz von ASTRAM-ST erhalten Sie einen besseren Einblick in den Prozess der Studien, was es einfacher macht, den Fortschritt zu &uuml;berwachen und potenzielle Probleme fr&uuml;hzeitig zu erkennen.</p>\r\n<p>ASTRAM-ST kann Ihnen dabei helfen, die Zusammenarbeit zwischen den Beteiligten zu verbessern, einschlie&szlig;lich Forschern, Entwicklern, Probanden, Aufsichtsbeh&ouml;rden und anderen Stakeholdern.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "104",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>ASTRAM-ST bietet eine Reihe von funktionalen Modulen f&uuml;r das Management von Studien, einschlie&szlig;lich Prozessdatenverwaltung, Probandenrekrutierung, Terminplanung, Protokolleinhaltung, Einhaltung gesetzlicher Vorschriften, Berichterstattung etc. .</p>\r\n<p>Mit ASTRAM-ST k&ouml;nnen Sie viele der sich wiederholenden Aufgaben in Studien automatisieren, die Arbeitsbelastung des Personals reduzieren und das Fehlerrisiko minimieren.</p>\r\n<p>ASTRAM-ST kann Ihnen helfen, Daten aus Studien sicher und effizient zu verwalten, mit einer leistungsstarken Berichts-Engine, die anpassbare standardisierte Berichte f&uuml;r alle Beteiligten bereitstellt.</p>\r\n<p>Die Einrichtung der im Bereich personenbezogener Daten erforderlichen erweiterter Datenschutzma&szlig;nahmen wie E2E-Verschl&uuml;sselung und spezifischer Berechtigungsprozesse (&sbquo;Bring-Your-Own-Key etc.) kann durch ASTRAM-ST bereitgestellt werden.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "104",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "<p>ASTRAM-ST ist eine leistungsstarke Softwarel&ouml;sung, mit der alle Aspekte des Managements von Studien verwaltet werden k&ouml;nnen, von der Patientenregistrierung bis hin zur Datenverwaltung und Berichterstattung.</p>\r\n<p>Durch den Einsatz von ASTRAM-ST k&ouml;nnen Sie Ihren Studienprozess rationalisieren, Fehler reduzieren und die Einhaltung gesetzlicher Anforderungen sicherstellen, was letztendlich zu einer schnelleren Markteinf&uuml;hrung neuerProdukte, Medikamente oder Behandlungsmethodenf&uuml;hrt.</p>",
                            "image": ""
                        }
                    ]
                }
            },
            {
                "cmsId": "105",
                "cmsSlug": "16-produktionsoptimierung",
                "pageId": "7",
                "sectionId": "39",
                "metaTitle": "",
                "metaDescriptions": "       ",
                "metaKeyword": "       ",
                "title": "Produktionsoptimierung",
                "description": "<p>Das ASTRAM-ST Production Optimization Modul wurde entwickelt, um die Prozesse von Produktionsanlagen zu verbessern. Durch die Nutzung fortschrittlicher Datenanalyse- und Machine-Learning-Algorithmen kann ASTRAM-ST Unternehmen dabei helfen, ihre Produktionsprozesse zu optimieren, Ausfallzeiten zu minimieren und Ausschuss zu reduzieren. Die L&ouml;sung bietet eine Echtzeit&uuml;berwachung kritischer Produktionsparameter, sodass Unternehmen Probleme schnell erkennen und Korrekturma&szlig;nahmen ergreifen k&ouml;nnen. Dar&uuml;ber hinaus bietet ASTRAM-ST umfassende Analyse- und Berichtsfunktionen, die es Unternehmen erm&ouml;glichen, Einblicke in ihre Produktionsprozesse zu gewinnen und datengesteuerte Entscheidungen zu treffen. Mit ASTRAM-ST Production Optimization k&ouml;nnen Unternehmen die Effizienz steigern, Kosten senken und ihre allgemeineWettbewerbsf&auml;higkeit auf dem Markt verbessern.</p>",
                "description2": "<p>Das ASTRAM-ST Production Optimization Modul wurde entwickelt, um die Prozesse von Produktionsanlagen zu verbessern. Durch die Nutzung fortschrittlicher Datenanalyse- und Machine-Learning-Algorithmen kann ASTRAM-ST Unternehmen dabei helfen, ihre Produktionsprozesse zu optimieren, Ausfallzeiten zu minimieren und Ausschuss zu reduzieren. Die L&ouml;sung bietet eine Echtzeit&uuml;berwachung kritischer Produktionsparameter, sodass Unternehmen Probleme schnell erkennen und Korrekturma&szlig;nahmen ergreifen k&ouml;nnen. Dar&uuml;ber hinaus bietet ASTRAM-ST umfassende Analyse- und Berichtsfunktionen, die es Unternehmen erm&ouml;glichen, Einblicke in ihre Produktionsprozesse zu gewinnen und datengesteuerte Entscheidungen zu treffen. Mit ASTRAM-ST Production Optimization k&ouml;nnen Unternehmen die Effizienz steigern, Kosten senken und ihre allgemeineWettbewerbsf&auml;higkeit auf dem Markt verbessern.</p>",
                "image":  require('../assets/img/cms/content/Production_optimization.png'),
                "iconClass": "",
                "heading": "Produktionsoptimierung",
                "alt": "Produktionsoptimierung",
                "additional_data": {
                    "listing": [
                        {
                            "cmsId": "105",
                            "pageId": "7",
                            "title": "Nutzen",
                            "description": "<p>ASTRAM-ST kann bei der Produktionsoptimierung helfen, indem es die Echtzeit&uuml;berwachung und -steuerung von Ger&auml;ten und Prozessen erm&ouml;glicht.</p>\r\n<p>Dies erm&ouml;glicht es Unternehmen, Engp&auml;sse und Ineffizienzen zu erkennen und zu behebensobald sie auftreten, um sicherzustellen, dass die Produktion so reibungslos wie m&ouml;glich l&auml;uft.</p>\r\n<p>ASTRAM-ST kann auch detaillierte Leistungskennzahlen und Analysen bereitstellen, um Unternehmen dabei zu helfen, Verbesserungspotenziale zu identifizieren und datengesteuerte Entscheidungen zur Optimierung ihrer Produktionsprozesse zu treffen.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "105",
                            "pageId": "7",
                            "title": "Hauptmerkmale",
                            "description": "<p>Die Kernfunktionen von ASTRAM-STbetreffen die F&auml;higkeit, wichtige Elemente der Produktionskette zu automatisieren, wie z. B. Planung, Qualit&auml;tskontrolle und Lieferkettenmanagement.</p>\r\n<p>Dies reduziert das Potenzial f&uuml;r menschliches Versagen und erm&ouml;glicht es Unternehmen, effizienter zu arbeiten, mit weniger Verz&ouml;gerungen und Unterbrechungen.</p>\r\n<p>Zus&auml;tzlich zu diesen Kernfunktionen bietet ASTRAM-ST auch eine Reihe von spezialisierten Modulen an, um spezifische Bed&uuml;rfnisse und Herausforderungen im Produktionsprozess zu adressieren. Beispielsweise kann das Modul in Echtzeit kritische Produktionsparameter &uuml;berwachen, sodass Unternehmen Probleme schnell erkennen und Korrekturma&szlig;nahmen ergreifen k&ouml;nnen.</p>\r\n<p>Gleichzeitig hilft ASTRAM-ST durch die vorausschauende Wartung Ger&auml;teausf&auml;lle zu antizipieren und zu verhindern.Das Bestandsverwaltungsmodul tr&auml;gtdazu bei, sicherzustellen, dass die richtigen Materialien und Komponenten verf&uuml;gbar sind, wenn sie ben&ouml;tigt werden.</p>",
                            "image": ""
                        },
                        {
                            "cmsId": "105",
                            "pageId": "7",
                            "title": "Schlussfolgerung",
                            "description": "ASTRAM-ST bietet eine leistungsstarke Suite von Tools zur Produktionsoptimierung mit Funktionen und Modulen, die auf die spezifischen Bedürfnisse und Herausforderungen jedes Unternehmens zugeschnitten werden können. Durch die Nutzung dieser Tools und Funktionen können Unternehmen ihre Effizienz verbessern, Verschwendung und Kosten reduzieren und letztendlich ihren Gewinn steigern.",
                            "image": ""
                        }
                    ]
                }
            }
        ],
        "astram_solutions_and_modules_title_home_page": [
            {
                "cmsId": "83",
                "cmsSlug": "astram-st-losungen-und-module",
                "pageId": "4",
                "sectionId": "38",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "ASTRAM-ST Lösungen und Module",
                "description": "<p>ASTRAM-ST L&ouml;sungen und Module</p>",
                "description2": "<p>ASTRAM-ST L&ouml;sungen und Module</p>",
                "image": "",
                "iconClass": "",
                "heading": "ASTRAM-ST Lösungen und Module",
                "alt": "ASTRAM-ST Lösungen und Module",
                "additional_data": []
            }
        ]
    });
  const [dataSource, setDataSource] = useState({
    "pageName": "Home Page",
    "metaTitle": "Home",
    "metaDescription": "Astram ",
    "metaKeyword": "Astram ",
    "slider_home_page": [
        {
            "cmsId": "30",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "15",
            "metaTitle": "",
            "metaDescriptions": "                         ",
            "metaKeyword": "                         ",
            "title": "Home Page Slider 1",
            "description": "<h2>A fully functional flexible <br />and futuristic application that can</h2>\r\n<h3>be tailored to suit your needs.</h3>\r\n<p>ASTRAM is a B2B platform developed to provide businesses the flexibility to create their solution in their own way. We believe that every organization is unique and has its own way of doing business. The platform is designed to help businesses introduce transparency and accountability across roles within and outside organizational boundaries.</p>\r\n<h4>Compliance with it best <span>Practices</span></h4> ",
            "description2": "<h2>A fully functional flexible <br />and futuristic application that can</h2>\r\n<h3>be tailored to suit your needs.</h3>\r\n<p>ASTRAM is a B2B platform developed to provide businesses the flexibility to create their solution in their own way. We believe that every organization is unique and has its own way of doing business. The platform is designed to help businesses introduce transparency and accountability across roles within and outside organizational boundaries.</p>\r\n<h4>Compliance with it best <span>Practices</span></h4> ",
            "image": require('../assets/img/cms/content/banner_11.png'),
            "iconClass": "",
            "heading": "Home Page Slider 1",
            "alt": "Home Page Slider 1",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "30",
                        "pageId": "4",
                        "title": "AWS well architected Framework",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_1.png')
                    },
                    {
                        "cmsId": "30",
                        "pageId": "4",
                        "title": "PCI DSS Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/pvc.png')
                    },
                    {
                        "cmsId": "30",
                        "pageId": "4",
                        "title": "NIST Cyber Security Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_3.png')
                    },
                    {
                        "cmsId": "30",
                        "pageId": "4",
                        "title": "ISO 27001 Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_5.png')
                    },
                    {
                        "cmsId": "30",
                        "pageId": "4",
                        "title": "HIPPA Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_9.png')
                    },
                    {
                        "cmsId": "30",
                        "pageId": "4",
                        "title": "GDPR Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_10.png')
                    }
                ]
            }
        },
        {
            "cmsId": "31",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "15",
            "metaTitle": "",
            "metaDescriptions": "            ",
            "metaKeyword": "            ",
            "title": "Home Page Slider 2",
            "description": "<h2>A fully functional flexible <br />and futuristic application that can</h2>\r\n<h3>be tailored to suit your needs.</h3>\r\n<p>ASTRAM is a B2B platform developed to provide businesses the flexibility to create their solution in their own way. We believe that every organization is unique and has its own way of doing business. The platform is designed to help businesses introduce transparency and accountability across roles within and outside organizational boundaries.</p>\r\n<h4>Compliance with it best <span>Practices</span></h4>",
            "description2": "<h2>A fully functional flexible <br />and futuristic application that can</h2>\r\n<h3>be tailored to suit your needs.</h3>\r\n<p>ASTRAM is a B2B platform developed to provide businesses the flexibility to create their solution in their own way. We believe that every organization is unique and has its own way of doing business. The platform is designed to help businesses introduce transparency and accountability across roles within and outside organizational boundaries.</p>\r\n<h4>Compliance with it best <span>Practices</span></h4>",
            "image": require('../assets/img/cms/content/banner_22.png'),
            "iconClass": "",
            "heading": "Home Page Slider 2",
            "alt": "Home Page Slider 2",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "31",
                        "pageId": "4",
                        "title": "AWS well architected Framework",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_1.png')
                    },
                    {
                        "cmsId": "31",
                        "pageId": "4",
                        "title": "PCI DSS Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/pvc.png')
                    },
                    {
                        "cmsId": "31",
                        "pageId": "4",
                        "title": "NIST Cyber Security Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_3.png')
                    },
                    {
                        "cmsId": "31",
                        "pageId": "4",
                        "title": "ISO 27001 Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_5.png')
                    },
                    {
                        "cmsId": "31",
                        "pageId": "4",
                        "title": "GDPR Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_10.png')
                    },
                    {
                        "cmsId": "31",
                        "pageId": "4",
                        "title": "HIPPA Compliance Compliance",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_9.png')
                    }
                ]
            }
        }
    ],
    "about_us_content_home_page": [
        {
            "cmsId": "32",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "16",
            "metaTitle": "ASTRAM Inspection Automation & Tracking Software",
            "metaDescriptions": "Enhance your inspection processes with ASTRAM's tracking software. Serving Pretoria, Johannesburg, Cape Town, Durban, Bengaluru, Chennai, Hyderabad, Pune, Mumbai, Kolkata, Berlin, Munich, Frankfurt, Hamburg, Cologne, Düsseldorf, and more.",
            "metaKeyword": "                   ",
            "title": "About Us",
            "description": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">ASTRAM is a versatile software solution designed to enable businesses to optimize their processes and operations for a more sustainable and efficient future. With ASTRAM, businesses can connect their engineering expertise with software to streamline manufacturing, transportation, power generation, and other operations. Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping usinesses transform their operations for the better.</span></p>",
            "description2": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">ASTRAM is a versatile software solution designed to enable businesses to optimize their processes and operations for a more sustainable and efficient future. With ASTRAM, businesses can connect their engineering expertise with software to streamline manufacturing, transportation, power generation, and other operations. Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping usinesses transform their operations for the better.</span></p>",
            "image": "",
            "iconClass": "",
            "heading": "About Us",
            "alt": "About Us",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "32",
                        "pageId": "4",
                        "title": "",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_3.png')
                    },
                    {
                        "cmsId": "32",
                        "pageId": "4",
                        "title": "",
                        "description": "",
                        "image": require('../assets/img/cms/additional_field/icon_1.png')
                    }
                ]
            }
        }
    ],
    "about_us_block_home_page": [
        {
            "cmsId": "33",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Our Vision",
            "description": "<p>Our overall goal is to provide businesses with a flexible and adaptable software solution that can be tailored to their unique needs and..</p>",
            "description2": "<p>Our overall goal is to provide businesses with a flexible and adaptable software solution that can be tailored to their unique needs and..</p>",
            "image": require('../assets/img/cms/content/a_032.png'),
            "iconClass": "",
            "heading": "Our Vision",
            "alt": "Our Vision",
            "additional_data": []
        },
        {
            "cmsId": "34",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "Technology and digital transformation",
            "description": "<p>Our world-class technology and domain-led digital capabilities are deeply embedded in our DNA. Maintaining and improving ..</p>",
            "description2": "<p>Our world-class technology and domain-led digital capabilities are deeply embedded in our DNA. Maintaining and improving ..</p>",
            "image": require('../assets/img/cms/content/a_01.png'),
            "iconClass": "",
            "heading": "Technology and digital transformation",
            "alt": "Technology and digital transformation",
            "additional_data": []
        },
        {
            "cmsId": "35",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Connectivity Collaboration and Innovation ",
            "description": "<p>ASTRAM's ability to communicate with machines through its API gateway is closely linked to the software's broader focus on connectivity..</p>",
            "description2": "<p>ASTRAM's ability to communicate with machines through its API gateway is closely linked to the software's broader focus on connectivity..</p>",
            "image": require('../assets/img/cms/content/a_02.png'),
            "iconClass": "",
            "heading": "Connectivity Collaboration and Innovation ",
            "alt": "Connectivity Collaboration and Innovation ",
            "additional_data": []
        },
        {
            "cmsId": "36",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Sustainability",
            "description": "<p>ASTRAM Team is committed to sustainability,which means balancing the needs of society, the environment,and the economy to create ..</p>",
            "description2": "<p>ASTRAM Team is committed to sustainability,which means balancing the needs of society, the environment,and the economy to create ..</p>",
            "image": require('../assets/img/cms/content/a_03.png'),
            "iconClass": "",
            "heading": "Sustainability",
            "alt": "Sustainability",
            "additional_data": []
        }
    ],
    "our_solution_business_areas_home_page": [
        {
            "cmsId": "37",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "18",
            "metaTitle": "ASTRAM Equipment Maintenance Management Software",
            "metaDescriptions": "Optimize equipment maintenance with ASTRAM's software. Serving Pretoria, Johannesburg, Cape Town, Durban, Bengaluru, Chennai, Hyderabad, Pune, Mumbai, Kolkata, Berlin, Munich, Frankfurt, Hamburg, Cologne, Düsseldorf, and more.",
            "metaKeyword": "  ",
            "title": "Our Solution & Business areas",
            "description": "<p>ASTRAM offers a comprehensive suite of modules to meet the diverse needs of industries modules that can be adapted to suit the specific requirements of these industries and businesses. These modules are designed to enable digitization, streamline processes, enhance collaboration, and optimize performance. These Modules can be configured to automate and streamline various aspects of operations, from asset management to quality control to health and safety management and more. Each of these modules are highly configurable and can be tailored to meet specific requirements. With its user-friendly interface, robust functionalities, and real-time data analytics, ASTRAM provides an end-to-end solution to help businesses improve efficiency, reduce costs, and drive growth</p>",
            "description2": "<p>ASTRAM offers a comprehensive suite of modules to meet the diverse needs of industries modules that can be adapted to suit the specific requirements of these industries and businesses. These modules are designed to enable digitization, streamline processes, enhance collaboration, and optimize performance. These Modules can be configured to automate and streamline various aspects of operations, from asset management to quality control to health and safety management and more. Each of these modules are highly configurable and can be tailored to meet specific requirements. With its user-friendly interface, robust functionalities, and real-time data analytics, ASTRAM provides an end-to-end solution to help businesses improve efficiency, reduce costs, and drive growth</p>",
            "image": "",
            "iconClass": "",
            "heading": "Our Solution & Business areas",
            "alt": "Our Solution & Business areas",
            "additional_data": []
        }
    ],
    "my_industry_title_home_page": [
        {
            "cmsId": "42",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "20",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Industry Applications",
            "description": "<h2 class=\"h3 pe-3 bg-white position-relative f600 text-uppercase d-inline-block mb-0\">Industry <span>Applications</span></h2>",
            "description2": "<h2 class=\"h3 pe-3 bg-white position-relative f600 text-uppercase d-inline-block mb-0\">Industry <span>Applications</span></h2>",
            "image": "",
            "iconClass": "",
            "heading": "Industry Applications",
            "alt": "Industry Applications",
            "additional_data": []
        }
    ],
    "my_industry_home_page": [
        {
            "cmsId": "38",
            "cmsSlug": "nuclear",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Nuclear",
            "description": "<p>Nuclear Power plants can implement ASTRAM to manage assets, control quality, manage risk, facilitate audits and manage training and certification. By using ASTRAM in the nuclear industry, companies can improve safety, reduce costs, and increase efficiency.</p> ",
            "description2": "<p>Nuclear Power plants can implement ASTRAM to manage assets, control quality, manage risk, facilitate audits and manage training and certification. By using ASTRAM in the nuclear industry, companies can improve safety, reduce costs, and increase efficiency.</p> ",
            "image": require('../assets/img/cms/content/01.png'),
            "iconClass": "",
            "heading": "Nuclear",
            "alt": "Nuclear",
            "additional_data": []
        },
        {
            "cmsId": "39",
            "cmsSlug": "power-plants-and-utilities",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Power Plants and Utilities",
            "description": "<p>ASTRAM can be helpful in all kinds of power plants and energy installations by managing maintenance, environmental compliance, safety, production optimization, and asset management. By using ASTRAM in power plants and energy installations, companies can improve safety, reduce downtime, extend the lifespan of equipment, and optimize production.</p>",
            "description2": "<p>ASTRAM can be helpful in all kinds of power plants and energy installations by managing maintenance, environmental compliance, safety, production optimization, and asset management. By using ASTRAM in power plants and energy installations, companies can improve safety, reduce downtime, extend the lifespan of equipment, and optimize production.</p>",
            "image": require('../assets/img/cms/content/02.png'),
            "iconClass": "",
            "heading": "Power Plants and Utilities",
            "alt": "Power Plants and Utilities",
            "additional_data": []
        },
        {
            "cmsId": "40",
            "cmsSlug": "mining",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Mining",
            "description": "<p>ASTRAM can be helpful in the mining sector by managing asset, safety, and environmental compliance, optimizing production, and managing the supply chain. By using ASTRAM in mining operations, companies can improve safety, reduce downtime, optimize production, and manage the supply chain more effectively.</p>",
            "description2": "<p>ASTRAM can be helpful in the mining sector by managing asset, safety, and environmental compliance, optimizing production, and managing the supply chain. By using ASTRAM in mining operations, companies can improve safety, reduce downtime, optimize production, and manage the supply chain more effectively.</p>",
            "image": require('../assets/img/cms/content/03.png'),
            "iconClass": "",
            "heading": "Mining",
            "alt": "Mining",
            "additional_data": []
        },
        {
            "cmsId": "41",
            "cmsSlug": "pharmaceutical",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Pharmaceutical",
            "description": "<p>ASTRAM can be used in the pharmaceutical sector to manage quality control, compliance, inventory, clinical trials, and production optimization. By using ASTRAM in the pharmaceutical industry, companies can improve quality, reduce compliance risk, optimize inventory management, improve clinical trial processes, and improve production efficiency.</p>",
            "description2": "<p>ASTRAM can be used in the pharmaceutical sector to manage quality control, compliance, inventory, clinical trials, and production optimization. By using ASTRAM in the pharmaceutical industry, companies can improve quality, reduce compliance risk, optimize inventory management, improve clinical trial processes, and improve production efficiency.</p>",
            "image": require('../assets/img/cms/content/04.png'),
            "iconClass": "",
            "heading": "Pharmaceutical",
            "alt": "Pharmaceutical",
            "additional_data": []
        },
        {
            "cmsId": "107",
            "cmsSlug": "laboratories",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Laboratories",
            "description": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
            "description2": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
            "image": require('../assets/img/cms/content/Laboratory_Management1.png'),
            "iconClass": "",
            "heading": "Laboratories",
            "alt": "Laboratories",
            "additional_data": []
        },
        {
            "cmsId": "108",
            "cmsSlug": "international-trade",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "International Trade",
            "description": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions</p>",
            "description2": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions</p>",
            "image": require('../assets/img/cms/content/International_Trade1.png'),
            "iconClass": "",
            "heading": "International Trade",
            "alt": "International Trade",
            "additional_data": []
        },
        {
            "cmsId": "109",
            "cmsSlug": "accreditation-bodies",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Accreditation Bodies",
            "description": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
            "description2": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
            "image": require('../assets/img/cms/content/Accreditation_Management2.png'),
            "iconClass": "",
            "heading": "Accreditation Bodies",
            "alt": "Accreditation Bodies",
            "additional_data": []
        },
        {
            "cmsId": "110",
            "cmsSlug": "oil-and-gas-and-petrochemical",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Oil and Gas and Petrochemical",
            "description": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
            "description2": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
            "image": require('../assets/img/cms/content/Oil_and_Gas_and_Petrochemical.png'),
            "iconClass": "",
            "heading": "Oil and Gas and Petrochemical",
            "alt": "Oil and Gas and Petrochemical",
            "additional_data": []
        },
        {
            "cmsId": "111",
            "cmsSlug": "manufacturing",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Manufacturing",
            "description": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
            "description2": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
            "image": require('../assets/img/cms/content/Manufacturing.png'),
            "iconClass": "",
            "heading": "Manufacturing",
            "alt": "Manufacturing",
            "additional_data": []
        },
        {
            "cmsId": "112",
            "cmsSlug": "health-and-safety",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Health and Safety",
            "description": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
            "description2": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
            "image": require('../assets/img/cms/content/Health_and_Safety_Management1.png'),
            "iconClass": "",
            "heading": "Health and Safety",
            "alt": "Health and Safety",
            "additional_data": []
        },
        {
            "cmsId": "113",
            "cmsSlug": "hr-management",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "HR management",
            "description": "<p>Astram can be used for HR management in a variety of ways. Here are some examples</p>",
            "description2": "<p>Astram can be used for HR management in a variety of ways. Here are some examples</p>",
            "image": require('../assets/img/cms/content/HR_Management2.png'),
            "iconClass": "",
            "heading": "HR management",
            "alt": "HR management",
            "additional_data": []
        },
        {
            "cmsId": "114",
            "cmsSlug": "environmental-management",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Environmental Management",
            "description": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
            "description2": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
            "image": require('../assets/img/cms/content/Environmental_Management2.png'),
            "iconClass": "",
            "heading": "Environmental Management",
            "alt": "Environmental Management",
            "additional_data": []
        },
        {
            "cmsId": "115",
            "cmsSlug": "inspection-and-testing-companies",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Inspection and Testing Companies",
            "description": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
            "description2": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
            "image": require('../assets/img/cms/content/Inspection_and_Testing1.png'),
            "iconClass": "",
            "heading": "Inspection and Testing Companies",
            "alt": "Inspection and Testing Companies",
            "additional_data": []
        },
        {
            "cmsId": "116",
            "cmsSlug": "food-and-beverage",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Food and Beverage",
            "description": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products</p>",
            "description2": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products</p>",
            "image": require('../assets/img/cms/content/Food_and_Beverage.png'),
            "iconClass": "",
            "heading": "Food and Beverage",
            "alt": "Food and Beverage",
            "additional_data": []
        },
        {
            "cmsId": "117",
            "cmsSlug": "government-and-regulatory-bodies",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Government and Regulatory Bodies",
            "description": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
            "description2": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
            "image": require('../assets/img/cms/content/Governance_and_Regulatory_Compliance2.png'),
            "iconClass": "",
            "heading": "Government and Regulatory Bodies",
            "alt": "Government and Regulatory Bodies",
            "additional_data": []
        },
        {
            "cmsId": "118",
            "cmsSlug": "transportation-and-logistics",
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Transportation and Logistics",
            "description": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
            "description2": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
            "image": require('../assets/img/cms/content/Transportation_and_Logistics1.png'),
            "iconClass": "",
            "heading": "Transportation and Logistics",
            "alt": "Transportation and Logistics",
            "additional_data": []
        },
        {
            "cmsId": "119",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Construction",
            "description": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
            "description2": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
            "image": require('../assets/img/cms/content/Construction.png'),
            "iconClass": "",
            "heading": "Construction",
            "alt": "Construction",
            "additional_data": []
        }
    ],
    "astram_software_technology_home_page": [
        {
            "cmsId": "43",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "21",
            "metaTitle": "ASTRAM Data Analytics Software",
            "metaDescriptions": "Unlock insights with ASTRAM's data analytics software. Serving Pretoria, Johannesburg, Cape Town, Durban, Bengaluru, Chennai, Hyderabad, Pune, Mumbai, Kolkata, Berlin, Munich, Frankfurt, Hamburg, Cologne, Düsseldorf, and more.",
            "metaKeyword": "     ",
            "title": "ASTRAM Software Technology",
            "description": "<p>ASTRAM is a versatile software technology hub designed to enable businesses to optimize their processes and operations fostering efficiency and profitability. With ASTRAM Software Technology, businesses can connect their assets and processes to streamline manufacturing, transportation, power generation and other operations. ASTRAM is a powerful tool for reducing a business's environmental impact, and ASTRAM is uniquely positioned to help businesses achieve their sustainability goals through flexible and customizable software solutions. Based on ASTRAM Software technology a set of dedicated solutions have already been developed and established together with our clients. You can choose those or go for your own dedicated solution!</p>",
            "description2": "<p>ASTRAM is a versatile software technology hub designed to enable businesses to optimize their processes and operations fostering efficiency and profitability. With ASTRAM Software Technology, businesses can connect their assets and processes to streamline manufacturing, transportation, power generation and other operations. ASTRAM is a powerful tool for reducing a business's environmental impact, and ASTRAM is uniquely positioned to help businesses achieve their sustainability goals through flexible and customizable software solutions. Based on ASTRAM Software technology a set of dedicated solutions have already been developed and established together with our clients. You can choose those or go for your own dedicated solution!</p>",
            "image": require('../assets/img/cms/content/about-img1.png'),
            "iconClass": "",
            "heading": "ASTRAM Software Technology",
            "alt": "ASTRAM Software Technology",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "43",
                        "pageId": "4",
                        "title": "Introduction",
                        "description": "ASTRAM is a versatile software technology hub designed to enable businesses to optimize their processes and operations fostering efficiency and profitability. With ASTRAM Software Technology, businesses can connect their assets and processes to streamline manufacturing, transportation, power generation and other operations. ASTRAM is a powerful tool for reducing a business's environmental impact, and ASTRAM is uniquely positioned to help businesses achieve their sustainability goals through flexible and customizable software solutions. Based on ASTRAM Software technology a set of dedicated solutions have already been developed and established together with our clients. You can choose those or go for your own dedicated solution!",
                        "image": require('../assets/img/cms/additional_field/about-img1.png')
                    }
                ]
            }
        }
    ],
    "our_services_home_page": [
        {
            "cmsId": "44",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "22",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Legal Compliance",
            "description": "<p>Legal Compliance</p>",
            "description2": "<p>Legal Compliance</p>",
            "image": require('../assets/img/cms/content/icon_01.png'),
            "iconClass": "",
            "heading": "Legal Compliance",
            "alt": "Legal Compliance",
            "additional_data": []
        },
        {
            "cmsId": "45",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "22",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "REGULAR SOFTWARE UPGRADE",
            "description": "<p>REGULAR SOFTWARE UPGRADE</p>",
            "description2": "<p>REGULAR SOFTWARE UPGRADE</p>",
            "image": require('../assets/img/cms/content/icon_02.png'),
            "iconClass": "",
            "heading": "REGULAR SOFTWARE UPGRADE",
            "alt": "REGULAR SOFTWARE UPGRADE",
            "additional_data": []
        },
        {
            "cmsId": "46",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "22",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "INFORMATION AT YOUR FINGER TIPS",
            "description": "<p>INFORMATION AT YOUR FINGER TIPS</p>",
            "description2": "<p>INFORMATION AT YOUR FINGER TIPS</p>",
            "image": require('../assets/img/cms/content/icon_03.png'),
            "iconClass": "",
            "heading": "INFORMATION AT YOUR FINGER TIPS",
            "alt": "INFORMATION AT YOUR FINGER TIPS",
            "additional_data": []
        },
        {
            "cmsId": "47",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "22",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "FLEXI MAINTENANCE PLAN",
            "description": "<p>FLEXI MAINTENANCE PLAN</p>",
            "description2": "<p>FLEXI MAINTENANCE PLAN</p>",
            "image": require('../assets/img/cms/content/icon_04.png'),
            "iconClass": "",
            "heading": "FLEXI MAINTENANCE PLAN",
            "alt": "FLEXI MAINTENANCE PLAN",
            "additional_data": []
        },
        {
            "cmsId": "48",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "22",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "DATA SECCURITY",
            "description": "<p>DATA SECCURITY</p>",
            "description2": "<p>DATA SECCURITY</p>",
            "image": require('../assets/img/cms/content/icon_05.png'),
            "iconClass": "",
            "heading": "DATA SECCURITY",
            "alt": "DATA SECCURITY",
            "additional_data": []
        },
        {
            "cmsId": "49",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "22",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "MULTI PLATFORM ACCESS",
            "description": "<p>MULTI PLATFORM ACCESS</p>",
            "description2": "<p>MULTI PLATFORM ACCESS</p>",
            "image": require('../assets/img/cms/content/icon_06.png'),
            "iconClass": "",
            "heading": "MULTI PLATFORM ACCESS",
            "alt": "MULTI PLATFORM ACCESS",
            "additional_data": []
        },
        {
            "cmsId": "50",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "22",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "LOGICAL WORK ALLOCATION",
            "description": "<p>LOGICAL WORK ALLOCATION</p>",
            "description2": "<p>LOGICAL WORK ALLOCATION</p>",
            "image": require('../assets/img/cms/content/icon_07.png'),
            "iconClass": "",
            "heading": "LOGICAL WORK ALLOCATION",
            "alt": "LOGICAL WORK ALLOCATION",
            "additional_data": []
        },
        {
            "cmsId": "51",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "22",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "AGILE DEPLOYMENT",
            "description": "<p>AGILE DEPLOYMENT</p>",
            "description2": "<p>AGILE DEPLOYMENT</p>",
            "image": require('../assets/img/cms/content/icon_08.png'),
            "iconClass": "",
            "heading": "AGILE DEPLOYMENT",
            "alt": "AGILE DEPLOYMENT",
            "additional_data": []
        }
    ],
    "astram_team_home_page": [
        {
            "cmsId": "52",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "24",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "ASTRAM Team",
            "description": "<p>Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better. Building on our own and clients&rsquo; expertise in Inspection &amp; Auditing, Power, Petro Chemical, Laboratories, and manufacturing sectors, ASTRAM Team aims to create superior value for all stakeholders by advanced software solutions. With a focus on sustainability and cutting-edge technology, ASTRAM Team is committed to pushing the boundaries of what is possible, enabling businesses to optimize their operations and accelerate their digital transformation. Our team is dedicated to driving performance to new levels, operating in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. With a focus on market leadership, technology and digital capabilities, people, portfolio management, and sustainability, ASTRAM Team is uniquely positioned to deliver superior value and performance to businesses across a variety of industries</p>",
            "description2": "<p>Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better. Building on our own and clients&rsquo; expertise in Inspection &amp; Auditing, Power, Petro Chemical, Laboratories, and manufacturing sectors, ASTRAM Team aims to create superior value for all stakeholders by advanced software solutions. With a focus on sustainability and cutting-edge technology, ASTRAM Team is committed to pushing the boundaries of what is possible, enabling businesses to optimize their operations and accelerate their digital transformation. Our team is dedicated to driving performance to new levels, operating in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. With a focus on market leadership, technology and digital capabilities, people, portfolio management, and sustainability, ASTRAM Team is uniquely positioned to deliver superior value and performance to businesses across a variety of industries</p>",
            "image": require('../assets/img/cms/content/team.jpg'),
            "iconClass": "",
            "heading": "ASTRAM Team",
            "alt": "ASTRAM Team",
            "additional_data": []
        }
    ],
    "client_testimonials_home_page": [
        {
            "cmsId": "53",
            "cmsSlug": "roedie-botes",
            "pageId": "4",
            "sectionId": "25",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Roedie Botes",
            "description": "<p>I can definitely recommend Astram to be used as the preferred program. It&rsquo;s easy to use, reliable and the back-up service is outstanding. My clients also find the program very useful.</p>",
            "description2": "<p>I can definitely recommend Astram to be used as the preferred program. It&rsquo;s easy to use, reliable and the back-up service is outstanding. My clients also find the program very useful.</p>",
            "image": "",
            "iconClass": "",
            "heading": "Roedie Botes",
            "alt": "Roedie Botes",
            "additional_data": {
                "author_name": [
                    {
                        "cmsId": "53",
                        "pageId": "4",
                        "title": "Roedie Botes",
                        "description": "",
                        "image": ""
                    }
                ],
                "designation": [
                    {
                        "cmsId": "53",
                        "pageId": "4",
                        "title": "Tzaneen Inspection services",
                        "description": "",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "54",
            "cmsSlug": "benjamin-cooper",
            "pageId": "4",
            "sectionId": "25",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Benjamin Cooper",
            "description": "<p>ASTRAM is a fully customizable tool that works on all devices. We can conduct audits and inspections on the go on a mobile or tablet. It syncs automatically, so we can continue the work on a computer if required. The capability to attach images and other files from the site with GPS tag adds lots of credibility to the inspections process and reports.</p>",
            "description2": "<p>ASTRAM is a fully customizable tool that works on all devices. We can conduct audits and inspections on the go on a mobile or tablet. It syncs automatically, so we can continue the work on a computer if required. The capability to attach images and other files from the site with GPS tag adds lots of credibility to the inspections process and reports.</p>",
            "image": "",
            "iconClass": "",
            "heading": "Benjamin Cooper",
            "alt": "Benjamin Cooper",
            "additional_data": {
                "author_name": [
                    {
                        "cmsId": "54",
                        "pageId": "4",
                        "title": "Benjamin Cooper",
                        "description": "",
                        "image": ""
                    }
                ],
                "designation": [
                    {
                        "cmsId": "54",
                        "pageId": "4",
                        "title": "Kwa Zulu Inspection",
                        "description": "",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "55",
            "cmsSlug": "swys-kotze",
            "pageId": "4",
            "sectionId": "25",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Swys Kotze",
            "description": "<p>Though our industry deals with highest level of technologies, yet we are heavy on paperwork. Introduction of digitization have been slow. ASTRAM has brought in a fresh air of digital technology to aid the removal of pen and paper and make things real time. Thanks to the team for considering the needs of the industry and coming up with a product that makes our work and life better. We are eagerly waiting for their Desktop offline system to be ready.</p>",
            "description2": "<p>Though our industry deals with highest level of technologies, yet we are heavy on paperwork. Introduction of digitization have been slow. ASTRAM has brought in a fresh air of digital technology to aid the removal of pen and paper and make things real time. Thanks to the team for considering the needs of the industry and coming up with a product that makes our work and life better. We are eagerly waiting for their Desktop offline system to be ready.</p>",
            "image": "",
            "iconClass": "",
            "heading": "Swys Kotze",
            "alt": "Swys Kotze",
            "additional_data": {
                "author_name": [
                    {
                        "cmsId": "55",
                        "pageId": "4",
                        "title": "Swys Kotze",
                        "description": "",
                        "image": ""
                    }
                ],
                "designation": [
                    {
                        "cmsId": "55",
                        "pageId": "4",
                        "title": "SGS South Africa",
                        "description": "",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "56",
            "cmsSlug": "riaan-swarts",
            "pageId": "4",
            "sectionId": "25",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Riaan Swarts",
            "description": "<p>The app safe me substantial amount of time on reporting, as most of the Client information is prepopulated. The drop down function has a great advantage when doing repetitive inspection of the same type of equipment. The security with the app being cloud based hold the advantage never losing information. The app is fairly user friendly once all the teething problems is sorted.</p>",
            "description2": "<p>The app safe me substantial amount of time on reporting, as most of the Client information is prepopulated. The drop down function has a great advantage when doing repetitive inspection of the same type of equipment. The security with the app being cloud based hold the advantage never losing information. The app is fairly user friendly once all the teething problems is sorted.</p>",
            "image": "",
            "iconClass": "",
            "heading": "Riaan Swarts",
            "alt": "Riaan Swarts",
            "additional_data": {
                "author_name": [
                    {
                        "cmsId": "56",
                        "pageId": "4",
                        "title": "Riaan Swarts",
                        "description": "",
                        "image": ""
                    }
                ],
                "designation": [
                    {
                        "cmsId": "56",
                        "pageId": "4",
                        "title": "SGS South Africa",
                        "description": "",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "138",
            "cmsSlug": "nic-kruger",
            "pageId": "4",
            "sectionId": "25",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Nic Kruger",
            "description": "<p>I can recommend Astram to be used as an inspection program. It is easy to use and it can be used on mobile phones or tablets. The capability to attach images and other files adds credibility to the inspections process and reports.</p>",
            "description2": "<p>I can recommend Astram to be used as an inspection program. It is easy to use and it can be used on mobile phones or tablets. The capability to attach images and other files adds credibility to the inspections process and reports.</p>",
            "image": "",
            "iconClass": "",
            "heading": "Nic Kruger",
            "alt": "Nic Kruger",
            "additional_data": {
                "author_name": [
                    {
                        "cmsId": "138",
                        "pageId": "4",
                        "title": "Nic Kruger",
                        "description": "",
                        "image": ""
                    }
                ],
                "designation": [
                    {
                        "cmsId": "138",
                        "pageId": "4",
                        "title": "Indserve",
                        "description": "",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "139",
            "cmsSlug": "david-abbot",
            "pageId": "4",
            "sectionId": "25",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "David Abbot",
            "description": "<p>The app is compatible and synced with smartphones and tablets and computers. Smart features and settings allow us to focus on the task at hand, while inspection data upload, report creation and device storage management happens quietly in the background.</p>",
            "description2": "<p>The app is compatible and synced with smartphones and tablets and computers. Smart features and settings allow us to focus on the task at hand, while inspection data upload, report creation and device storage management happens quietly in the background.</p>",
            "image": "",
            "iconClass": "",
            "heading": "David Abbot",
            "alt": "David Abbot",
            "additional_data": {
                "author_name": [
                    {
                        "cmsId": "139",
                        "pageId": "4",
                        "title": "David Abbot",
                        "description": "",
                        "image": ""
                    }
                ],
                "designation": [
                    {
                        "cmsId": "139",
                        "pageId": "4",
                        "title": "Kwa Zulu Inspection",
                        "description": "",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "140",
            "cmsSlug": "donovan-coleman",
            "pageId": "4",
            "sectionId": "25",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Donovan Coleman",
            "description": "<p>ASTRAM cuts inspection time by up to 50% - helps to comply 100% with industry regulations, facilitates communication between team members, create custom forms within minutes.</p>",
            "description2": "<p>ASTRAM cuts inspection time by up to 50% - helps to comply 100% with industry regulations, facilitates communication between team members, create custom forms within minutes.</p>",
            "image": "",
            "iconClass": "",
            "heading": "Donovan Coleman",
            "alt": "Donovan Coleman",
            "additional_data": {
                "author_name": [
                    {
                        "cmsId": "140",
                        "pageId": "4",
                        "title": "Donovan Coleman",
                        "description": "",
                        "image": ""
                    }
                ],
                "designation": [
                    {
                        "cmsId": "140",
                        "pageId": "4",
                        "title": "Indserve",
                        "description": "",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "141",
            "cmsSlug": "gerson-arroe",
            "pageId": "4",
            "sectionId": "25",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Gerson Arroe",
            "description": "<p>ASTRAM is a very intuitive mobile app that allows you to efficiently conduct inspections and audits to improve quality and safety in your organization. It fast-tracked our migration from the old-school pen &amp; paper checks to a digitized process within one app.</p>",
            "description2": "<p>ASTRAM is a very intuitive mobile app that allows you to efficiently conduct inspections and audits to improve quality and safety in your organization. It fast-tracked our migration from the old-school pen &amp; paper checks to a digitized process within one app.</p>",
            "image": "",
            "iconClass": "",
            "heading": "Gerson Arroe",
            "alt": "Gerson Arroe",
            "additional_data": {
                "author_name": [
                    {
                        "cmsId": "141",
                        "pageId": "4",
                        "title": "Gerson Arroe",
                        "description": "",
                        "image": ""
                    }
                ],
                "designation": [
                    {
                        "cmsId": "141",
                        "pageId": "4",
                        "title": "Kwa Zulu Inspection",
                        "description": "",
                        "image": ""
                    }
                ]
            }
        }
       
    ],
    "about_partner_home_page": [
        {
            "cmsId": "57",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 1 ",
            "description": "<p>Partner 1&nbsp;</p>",
            "description2": "<p>Partner 1&nbsp;</p>",
            "image": require('../assets/img/cms/content/client-logo1.png'),
            "iconClass": "",
            "heading": "Partner 1 ",
            "alt": "Partner 1 ",
            "additional_data": []
        },
        {
            "cmsId": "58",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Partner 2",
            "description": "<p>Partner 2</p>",
            "description2": "<p>Partner 2</p>",
            "image": require('../assets/img/cms/content/client-logo2.png'),
            "iconClass": "",
            "heading": "Partner 2",
            "alt": "Partner 2",
            "additional_data": []
        },
        {
            "cmsId": "59",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 3",
            "description": "<p>Partner 3</p>",
            "description2": "<p>Partner 3</p>",
            "image": require('../assets/img/cms/content/client-logo3.png'),
            "iconClass": "",
            "heading": "Partner 3",
            "alt": "Partner 3",
            "additional_data": []
        },
        {
            "cmsId": "60",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 4",
            "description": "<p>Partner 4</p>",
            "description2": "<p>Partner 4</p>",
            "image": require('../assets/img/cms/content/client-logo4.png'),
            "iconClass": "",
            "heading": "Partner 4",
            "alt": "Partner 4",
            "additional_data": []
        },
        {
            "cmsId": "61",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 5",
            "description": "<p>Partner 5</p>",
            "description2": "<p>Partner 5</p>",
            "image": require('../assets/img/cms/content/client-logo5.png'),
            "iconClass": "",
            "heading": "Partner 5",
            "alt": "Partner 5",
            "additional_data": []
        }
    ],
    "astram_solutions_and_modules_block_home_page": [
        {
            "cmsId": "106",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Asset Management",
            "description": "<p>This module allows for tracking of assets throughout their lifecycle, from acquisition to disposal, with a focus on compliance in industries such as pressure equipment, lifts, and explosives.</p>",
            "description2": "<p>This module allows for tracking of assets throughout their lifecycle, from acquisition to disposal, with a focus on compliance in industries such as pressure equipment, lifts, and explosives.</p>",
            "image": require('../assets/img/cms/content/Asset_Management.png'),
            "iconClass": "",
            "heading": "Asset Management",
            "alt": "Asset Management",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "106",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Improved asset reliability and availability Reduced maintenance costs and downtime Improved safety and compliance with regulations\r\nBetter decision making through data analysis and reportingIncreased efficiency and productivity\r\n",
                        "image": ""
                    },
                    {
                        "cmsId": "106",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Automated tracking and monitoring of asset health and performance Customizable dashboards and reports for data analysis and decision \r\nmaking Integration with IoT sensors and other data sources for real-time monitoring Centralized database for all asset-related information, including maintenance records, repair history, and other important data User-friendly interface for easy access and use by multiple stakeholders",
                        "image": ""
                    },
                    {
                        "cmsId": "106",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "ASTRAM provides a comprehensive asset management solution that can help businesses streamline their operations and reduce \r\ncosts while improving asset performance and utilization. With its customizable dashboards and reports, real-time tracking and automated maintenance scheduling, businesses can make informed decisions about their asset management and reduce the risk of downtime or compliance issues. ASTRAM's focus on automation and customization allows it to meet the unique needs of any industry, making it a versatile and valuable asset management tool.",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "85",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "Inspection and Testing",
            "metaDescriptions": " Inspection and Testing                    ",
            "metaKeyword": " Inspection and Testing                    ",
            "title": "Inspection and Testing",
            "description": "<p>This module enables creation of custom calculation reports for fitness for service assessment on pressure vessels, tanks, and reactor vessels.</p>",
            "description2": "<p>This module enables creation of custom calculation reports for fitness for service assessment on pressure vessels, tanks, and reactor vessels.</p>",
            "image": require('../assets/img/cms/content/Inspection_and_Testing.png'),
            "iconClass": "",
            "heading": "Inspection and Testing",
            "alt": "Inspection and Testing",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "85",
                        "pageId": "4",
                        "title": "Benefits",
                        "description": "Inspection and Testing: This module enables creation of custom calculation reports for fitness for service assessment on pressure vessels, tanks, and reactor vessels.\r\n",
                        "image": require('../assets/img/cms/additional_field/Governance and Regulatory Compliance.png')
                    },
                    {
                        "cmsId": "85",
                        "pageId": "4",
                        "title": "Key Features",
                        "description": "Automated reporting and customizable templates Integration with NDT tools and other inspection equipment Live updates and real-time access to inspection data Risk-based inspection system Audit management and scheduling Compliance tracking and management",
                        "image": ""
                    },
                    {
                        "cmsId": "85",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "Astram's Inspection and Testing module provides a comprehensive solution for managing inspection processes and compliance tracking. The module's customizable reporting templates and integration with NDT tools and other inspection equipment help to streamline the inspection process and improve accuracy. The riskbased inspection system and audit management features provide enhanced risk management and decision-making capabilities. Overall, the module improves efficiency and accuracy, reduces risk, and enhances customer satisfaction.",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "86",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Accreditation Management",
            "description": "<p>This module can be used by accreditation bodies to manage their entire accreditation workflow, including customer management, scheduling audits, and more.</p>",
            "description2": "<p>This module can be used by accreditation bodies to manage their entire accreditation workflow, including customer management, scheduling audits, and more.</p>",
            "image": require('../assets/img/cms/content/Accreditation_Management1.png'),
            "iconClass": "",
            "heading": "Accreditation Management",
            "alt": "Accreditation Management",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "86",
                        "pageId": "4",
                        "title": "Benefits",
                        "description": "Improved compliance: Astram provides a comprehensive solution for managing accreditation processes, ensuring compliance with industry standards and regulations.Increased efficiency: The platform streamlines accreditation processes, reducing manual effort and increasing productivity.\r\nEnhanced data accuracy: Astram ensures accurate and up-todate accreditation data, reducing the risk of errors and compliance issues.Improved customer satisfaction: Astram provides auserfriendly interface for managing accreditation processes, improving the customer experience.\r\n",
                        "image": ""
                    },
                    {
                        "cmsId": "86",
                        "pageId": "4",
                        "title": "Key Features",
                        "description": "Customer management: Astram provides a centralized platform to manage customer information, including accreditation status, audit history, and documentation.Audit scheduling: The platform allows scheduling of audits, including notifications and reminders to clients and auditors.Audit management: Astram provides tools for conducting audits, managing audit data, and generating audit reports.\r\nDocument management: The platform allows document management, including version control, revision history, and document approval workflows.Reporting: Astram enables real-time reporting and analysis of accreditation data, enabling organizations to track their performance and identify areas \r\nfor improvement.",
                        "image": ""
                    },
                    {
                        "cmsId": "86",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "Astram provides a powerful solution for managing accreditation processes, enabling organizations to streamline their operations and ensure compliance with industry standards and regulations. With its comprehensive set of features and benefits, Astram is an ideal solution for organizations seeking to enhance their accreditation management capabilities.\r\n",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "90",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Job Management",
            "description": "<p>This module enables tracking of jobs through the entire process, from acquisition to execution to billing, providing a streamlined approach to project delivery.</p>",
            "description2": "<p>This module enables tracking of jobs through the entire process, from acquisition to execution to billing, providing a streamlined approach to project delivery.</p>",
            "image": require('../assets/img/cms/content/Job_Management.png'),
            "iconClass": "",
            "heading": "Job Management",
            "alt": "Job Management",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "90",
                        "pageId": "4",
                        "title": "Benefits",
                        "description": "Improved efficiency and productivity in job management processes.\r\nReduced errors and delays in job tracking and reporting.Increased visibility and control over job status and progress.Accurate and timely billing for job-related services.Improved communication and collaboration among team members and with clients.Streamlined document management and storage",
                        "image": ""
                    },
                    {
                        "cmsId": "90",
                        "pageId": "4",
                        "title": "Key Features",
                        "description": "Automated tracking of jobs from acquisition to billing.Customizable job forms for specific industries and workflows.Real-time reporting and monitoring of job status.Integration with financial systems for accurate billing and revenue tracking.Automated notifications and reminders for job deadlines and requirements.Document management and storage for job-related files.",
                        "image": ""
                    },
                    {
                        "cmsId": "90",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "Using ASTRAM for job management can significantly improve the effectiveness and efficiency of job-related processes. By providing customizable forms, automated tracking, and real-time reporting, ASTRAM helps streamline job management workflows and reduce errors and delays. With features like document management, financial integration, and automated notifications, ASTRAM can also help improve communication and collaboration among team members and with clients. Overall, ASTRAM's job management capabilities can lead to improved productivity, accuracy, and customer satisfaction.",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "91",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Non-Destructive Testing",
            "description": "<p>This module offers automated forms to reduce human error in reporting, particularly useful in large projects where reporting is a bottleneck in project delivery.</p>",
            "description2": "<p>This module offers automated forms to reduce human error in reporting, particularly useful in large projects where reporting is a bottleneck in project delivery.</p>",
            "image": require('../assets/img/cms/content/Non-Destructive_Testing_(1).png'),
            "iconClass": "",
            "heading": "Non-Destructive Testing",
            "alt": "Non-Destructive Testing",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "91",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Increased accuracy and reliability of reporting Time savings due to automation and streamlined processes\r\nImproved communication and collaboration between inspectors, supervisors, and clientsMore efficient management of large NDT projectsImproved compliance with industry standards and regulationsGreater visibility into the performance of individual inspectors and teams Better data analysis and decision-making for ongoing improvements",
                        "image": ""
                    },
                    {
                        "cmsId": "91",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Automated forms for reporting that reduce human errorIntegration with various NDT technologies for real-time data captureLive MIS (Management Information System) reporting for immediate feedback on the status of testingCustomizable templates for various types of NDT testsDigital signatures for easy approvals and faster processing",
                        "image": ""
                    },
                    {
                        "cmsId": "91",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "In conclusion, the use of ASTRAM in NDT can lead to significant improvements in the efficiency and accuracy of reporting, as well as better overall project management and compliance. By leveraging ASTRAM's automation and digital capabilities, NDT companies can enhance their processes and deliver higher quality results for their clients.\r\n",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "92",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Risk Based Inspection",
            "description": "<p>This module can be used to create a risk-based inspection system in critical industries to manage risks and create inspection and test plans.</p>",
            "description2": "<p>This module can be used to create a risk-based inspection system in critical industries to manage risks and create inspection and test plans.</p>",
            "image": require('../assets/img/cms/content/Risk_Based_Inspection.png'),
            "iconClass": "",
            "heading": "Risk Based Inspection",
            "alt": "Risk Based Inspection",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "92",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Improved efficiency: ASTRAM’s risk-based approach and realtime data collection can help to identify potential issues \r\nbefore they become major problems, leading to improved \r\nefficiency and reduced downtime.\r\nBetter decision-making: ASTRAM’s automated reporting and \r\nintegration with other systems can provide a more \r\ncomprehensive view of operations, leading to better decisionmaking.\r\nIncreased safety: ASTRAM’s risk-based approach can help to \r\nidentify potential safety issues and prevent accidents.\r\nReduced costs: ASTRAM’s real-time data collection and analysis can help to identify potential issues early on, reducing the need for costly repairs or replacements",
                        "image": ""
                    },
                    {
                        "cmsId": "92",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Customizable inspection plans: ASTRAM allows for the creation of \r\ncustomizable inspection plans that can be tailored to fit specific industries \r\nor equipment types.\r\nRisk-based approach: ASTRAM uses a risk-based approach to inspections, \r\nwhich allows for more efficient and effective inspections of critical assets.\r\nReal-time data: ASTRAM allows for real-time data collection and analysis, \r\nwhich helps to identify potential issues and prevent unplanned downtime.\r\nAutomated reporting: ASTRAM automates reporting, making it easier to \r\ngenerate reports and share information with stakeholders.\r\nIntegration with other systems: ASTRAM can integrate with other systems, such as asset management software or ERP systems, to provide a more comprehensive view of operations",
                        "image": ""
                    },
                    {
                        "cmsId": "92",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "ASTRAM is a powerful tool for Risk Based Inspections that provides real-time data collection, automated reporting, and a risk-based approach to inspections. By integrating with other systems, ASTRAM can provide a more comprehensive view of  operations, leading to better decision-making and improved efficiency. With its ability to identify potential safety issues and prevent accidents, ASTRAM can help to reduce costs and improve the overall safety of operations",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "93",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Health and Safety Management",
            "description": "<p>This module allows for tracking of all health and safety-related parameters and reporting on LIVE MIS.</p>",
            "description2": "<p>This module allows for tracking of all health and safety-related parameters and reporting on LIVE MIS.</p>",
            "image": require('../assets/img/cms/content/Health_and_Safety_Management.png'),
            "iconClass": "",
            "heading": "Health and Safety Management",
            "alt": "Health and Safety Management",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "93",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Improved safety for personnel and equipment.Reduced risk of incidents and non-compliances.Increased efficiency in managing health and safety-related processes.Enhanced reporting capabilities for compliance and regulatory requirements.Improved visibility and transparency across the organization.",
                        "image": ""
                    },
                    {
                        "cmsId": "93",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Ability to track and monitor all health and safety-related parameters in real-time.Automated alerts and notifications for critical events, such a incidents or non-compliances.Ability to create custom forms and checklists for inspections and audits.Easy access to historical data for trend analysis and reporting.Integration with other modules, such as asset management and job management, to ensure the safety of personnel and equipment",
                        "image": ""
                    },
                    {
                        "cmsId": "93",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "Using ASTRAM for health and safety management can significantly improve the safety and well-being of personnel and equipment while increasing efficiency and compliance. With the ability to track and \r\nmonitor all health and safety-related parameters in real-time, organizations can quickly identify and address potential risks, preventing incidents and non-compliances. ASTRAM's integration \r\nwith other modules also ensures that health and safety considerations are integrated into all aspects of the organization's operations.\r\n",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "97",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Pharmaceutical Quality Control",
            "description": "<p>This module is specifically designed for the pharmaceutical industry, enabling quality control of products</p>",
            "description2": "<p>This module is specifically designed for the pharmaceutical industry, enabling quality control of products</p>",
            "image": require('../assets/img/cms/content/Pharmaceutical_Quality_Control.png'),
            "iconClass": "",
            "heading": "Pharmaceutical Quality Control",
            "alt": "Pharmaceutical Quality Control",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "97",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Improved Efficiency: Astram improves the efficiency of testing \r\nprocedures, ensuring that tests are performed accurately, and results \r\nare delivered in a timely manner.\r\nCompliance: Astram ensures compliance with GMP requirements, \r\nproviding audit trails, electronic signatures and validation protocols.\r\nData Integrity: Astram helps to maintain the integrity of data by \r\nproviding secure access, traceability, and version control.\r\nReduced Errors: Astram reduces the likelihood of errors through the \r\nuse of automated processes, pre-defined procedures and protocols.\r\nImproved Traceability: Astram provides complete traceability of all \r\ntesting procedures, samples, and results, making it easy to track the \r\nstatus of testing and ensure that everything is completed according \r\nto the correct procedures.\r\n",
                        "image": ""
                    },
                    {
                        "cmsId": "97",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Customizable workflows: ASTRAM allows users to create workflows \r\nthat are tailored to their specific requirements, ensuring that all \r\nnecessary steps are included in the quality control process.\r\nAutomated reporting: ASTRAM can generate automated reports that \r\nprovide real-time updates on the status of quality control activities, \r\nhelping to identify any issues and ensure that they are addressed \r\npromptly.\r\nDocument management: ASTRAM's document management system \r\nensures that all relevant documents are stored in a centralized \r\nlocation and are easily accessible by authorized personnel.\r\nAudit trails: ASTRAM's audit trails provide a complete record of all \r\nquality control activities, including who performed the activity, when\r\nit was performed, and what the results were.\r\nIntegration with existing systems: ASTRAM can be integrated with \r\nexisting systems, such as laboratory information management \r\nsystems (LIMS), to streamline the quality control process and improve \r\nefficiency.",
                        "image": ""
                    },
                    {
                        "cmsId": "97",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "Astram is an effective solution for the management of quality control \r\nin the pharmaceutical industry. It provides a comprehensive range of \r\nfeatures and benefits, ensuring that all aspects of the testing process \r\nare managed effectively and efficiently. By using Astram, \r\npharmaceutical companies can improve their quality control \r\nprocedures, ensure compliance with regulatory requirements, reduce \r\nerrors and improve traceability.",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "98",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "International Trade",
            "description": "<p>This module can be used to carry out inspection and report instantly with geotagged images to avoid delays in decision making.</p>",
            "description2": "<p>This module can be used to carry out inspection and report instantly with geotagged images to avoid delays in decision making.</p>",
            "image": require('../assets/img/cms/content/International_Trade.png'),
            "iconClass": "",
            "heading": "International Trade",
            "alt": "International Trade",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "98",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Reduced time and cost of inspections and assessments Improved accuracy and consistency in reporting Enhanced compliance with trade regulations and standards Streamlined shipment management and tracking  Improved transparency and visibility across the supply chainIncreased productivity and efficiency in the trade process",
                        "image": ""
                    },
                    {
                        "cmsId": "98",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Automated forms and checklists for inspections and assessments Real-time reporting with geotagged images for improved decision making Compliance tracking for trade regulations and standards Integration with third-party logistics providers for streamlined shipment management Customizable workflows to fit the specific needs of the trade process Cloud-based storage and access for easy collaboration and sharing",
                        "image": ""
                    },
                    {
                        "cmsId": "98",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "ASTRAM can significantly improve the efficiency and effectiveness of international trade operations by providing a streamlined and automated solution for inspections, assessments, and compliance tracking. With real-time reporting and geotagged images, decision making can be accelerated and accuracy can be improved. Additionally, integration with third-party logistics providers and customizable workflows further enhance the flexibility and usability of the system. By utilizing ASTRAM for international trade, businesses can improve productivity, reduce costs, and increase transparency and compliance across the supply chain.",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "99",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Laboratory Management",
            "description": "<p>This module can be used to manage laboratory operations, including sample tracking, testing, and reporting.</p>",
            "description2": "<p>This module can be used to manage laboratory operations, including sample tracking, testing, and reporting.</p>",
            "image": require('../assets/img/cms/content/Laboratory_Management.png'),
            "iconClass": "",
            "heading": "Laboratory Management",
            "alt": "Laboratory Management",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "99",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Increased efficiency: Astram's automation and streamlining of laboratory processes helps to increase efficiency, reduce errors, and save time.Improved accuracy: With Astram's quality control and result management features, labs can ensure that their results are accurate and consistent.Better customer service: Astram's sample tracking and result management features enable labs to provide better customer service by providing real-time updates and accurate reports.Cost savings: By streamlining processes and reducing errors, Astram can help labs to save money on labor and retesting costs.",
                        "image": ""
                    },
                    {
                        "cmsId": "99",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Sample tracking: Astram's sample tracking feature enables labs to track samples from the moment they are received to the moment they are delivered, ensuring that no samples are lost or misplaced during the testing process.Result management: Astram's result management feature enables labs to easily manage test results and generate reports for customers. The system can be customized to meet the needs of different labs and can handle both manual and automated testing processes.Equipment management: With Astram's equipment management feature, labs can manage their equipment inventory, schedule maintenance, and track usage. This helps labs to ensure that their equipment is properly maintained and calibrated, reducing the risk of errors or incorrect results.Quality control: Astram's quality control feature enables labs to manage their quality control procedures and track their performance over time. \r\nThis helps labs to identify areas for improvement and ensure that their results are accurate and consistent.Billing and invoicing: Astram's billing and invoicing feature enables labs to manage their billing and invoicing processes in a streamlined manner. This helps to reduce errors and delays in payment processing, ensuring that labs are paid promptly for their services.",
                        "image": ""
                    },
                    {
                        "cmsId": "99",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "By leveraging ASTRAM for transportation and logistics management, companies can optimize their logistics operations, reduce costs, and improve overall customer satisfaction. The platform's real-time tracking, automated scheduling, and customizable reporting capabilities enable companies to streamline their logistics processes and ensure timely and accurate deliveries. Additionally, ASTRAM's compliance management features help companies stay on top of regulatory requirements and avoid costly penalties. Overall, ASTRAM provides a comprehensive solution for transportation and logistics companies looking to optimize their operations and improve customer satisfaction",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "100",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "HR Management",
            "description": "<p>This module enables HR management functions such as employee data management, performance evaluation, and training management</p>",
            "description2": "<p>This module enables HR management functions such as employee data management, performance evaluation, and training management</p>",
            "image": require('../assets/img/cms/content/HR_Management.png'),
            "iconClass": "",
            "heading": "HR Management",
            "alt": "HR Management",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "100",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Increased efficiency: Astram's centralized database and automation of HR processes help save time and reduce administrative workload, allowing HR personnel to focus on more strategic tasks.Improved accuracy: By eliminating manual data entry and paperbased processes, Astram helps reduce errors and ensure data \r\naccuracy.Enhanced employee experience: Astram's performance management and training and development modules can help improve employee engagement and career development, leading to higher employee satisfaction and retention.Better compliance: Astram's leave management module ensures compliance with labour laws and regulations, reducing the risk of legal liabilities.",
                        "image": ""
                    },
                    {
                        "cmsId": "100",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Employee database: Astram provides a centralized employee database where HR personnel can store all employee information, including personal details, contact information, work history, performance reviews, and more.Recruitment management: Astram helps streamline the recruitment process by enabling HR personnel to post job openings, screen applications, and schedule interviews, all in one place.Performance management: Astram's performance management module allows HR personnel to set goals, track employee progress, and provide feedback, helping to improve employee engagement \r\nand productivity.Leave management: Astram's leave management module simplifies the process of managing employee leave requests, allowing HR \r\npersonnel to view employee leave balances, approve or deny requests, and track absences.Training and development: Astram's training and development module enables HR personnel to plan and track employee training, ensuring that employees receive the necessary skills and knowledge to excel in their roles.",
                        "image": ""
                    },
                    {
                        "cmsId": "100",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "Astram's HR management module provides a comprehensive solution for managing employee data, recruitment, performance, leave, and training and development. By increasing efficiency, accuracy, and employee experience, Astram can help organizations improve their HR processes and achieve their strategic goals.\r\n",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "101",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Environmental Management",
            "description": "<p>This module enables tracking and management of environmental parameters and reporting on LIVE MIS.</p>",
            "description2": "<p>This module enables tracking and management of environmental parameters and reporting on LIVE MIS.</p>",
            "image": require('../assets/img/cms/content/Environmental_Management1.png'),
            "iconClass": "",
            "heading": "Environmental Management",
            "alt": "Environmental Management",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "101",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Streamlined Operations: ASTRAM provides a centralized platform to manage all environmental aspects, enabling streamlined operations and compliance management.Risk Reduction: ASTRAM helps in identifying and managing potential \r\nenvironmental risks, reducing the risk of non-compliance and environmental incidents.Cost Savings: By managing environmental parameters efficiently, ASTRAM can help in reducing energy consumption, waste generation, and emissions, leading to cost savings.Improved Reputation: ASTRAM's sustainability reporting capabilities help organizations demonstrate their commitment to environmental sustainability, improving their reputation among stakeholders.",
                        "image": ""
                    },
                    {
                        "cmsId": "101",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Compliance Management: ASTRAM helps in tracking and managing environmental compliance requirements as per the regulatory norms.Environmental Monitoring: ASTRAM enables the monitoring and tracking of environmental parameters and provides alerts in case of any deviation.Emissions and Waste Management: ASTRAM provides a comprehensive solution to manage emissions and waste generated during the production process.Audit Management: ASTRAM provides a platform to conduct environmental audits, track non-conformances, and take corrective actions.Sustainability Reporting: ASTRAM helps in generating reports related to environmental sustainability, including carbon footprint, energy consumption, and water usage.",
                        "image": ""
                    },
                    {
                        "cmsId": "101",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "ASTRAM provides a comprehensive solution for environmental management, enabling organizations to comply with regulatory requirements, monitor environmental parameters, manage emissions and waste, conduct audits, and generate sustainability reports. By using ASTRAM, organizations can streamline their environmental management operations, reduce potential risks, save costs, and improve their reputation.",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "102",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Governance and Regulatory Compliance",
            "description": "<p>This module can be used by government and regulatory bodies to manage compliance with regulations and standards.</p>",
            "description2": "<p>This module can be used by government and regulatory bodies to manage compliance with regulations and standards.</p>",
            "image": require('../assets/img/cms/content/Governance_and_Regulatory_Compliance.png'),
            "iconClass": "",
            "heading": "Governance and Regulatory Compliance",
            "alt": "Governance and Regulatory Compliance",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "102",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Improved compliance and reduced risk of penalties or fines Streamlined compliance management processes, saving time and resources Increased visibility into compliance status and potential issues Improved data accuracy and consistency Improved collaboration and communication across departments\r\n",
                        "image": ""
                    },
                    {
                        "cmsId": "102",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Customizable forms and workflows for compliance management Automated reporting and documentation for regulatory submissions Real-time monitoring and alerts for compliance violations Audit trails to track compliance history Integration with other systems for seamless data management",
                        "image": ""
                    },
                    {
                        "cmsId": "102",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "Using ASTRAM for governance and regulatory compliance can help companies to effectively manage their compliance obligations and reduce the risk of non-compliance. The customizable forms and workflows, real-time monitoring and automated reporting features help to streamline compliance management processes and provide greater visibility into compliance status. By integrating with other systems, ASTRAM enables seamless data management and improves collaboration and communication across departments. Ultimately, ASTRAM helps to improve compliance and reduce the risk of penalties or fines, while also saving time and resources.",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "103",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Transportation and Logistics",
            "description": "<p>This module can be used to manage logistics operations, including transportation management, scheduling, and reporting.</p>",
            "description2": "<p>This module can be used to manage logistics operations, including transportation management, scheduling, and reporting.</p>",
            "image": require('../assets/img/cms/content/Transportation_and_Logistics.png'),
            "iconClass": "",
            "heading": "Transportation and Logistics",
            "alt": "Transportation and Logistics",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "103",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Improved operational efficiency and cost savings Enhanced visibility and control over shipments and logistics Reduced errors and delays through automation and real-time tracking Increased customer satisfaction through accurate and timely deliveries Simplified compliance management for regulatory requirements",
                        "image": ""
                    },
                    {
                        "cmsId": "103",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Real-time tracking of shipments and vehicles Automated scheduling of delivery routes Integration with third-party logistics providers Customizable reporting and analytics Electronic proof of delivery and signature capture Compliance management for regulatory requirements",
                        "image": ""
                    },
                    {
                        "cmsId": "103",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "By leveraging ASTRAM for transportation and logistics management, companies can optimize their logistics operations, reduce costs, and improve overall customer satisfaction. The platform's real-time tracking, automated scheduling, and customizable reporting capabilities enable companies to streamline their logistics processes and ensure timely and accurate deliveries. Additionally, ASTRAM's compliance management features help companies stay on top of regulatory requirements and avoid costly penalties. Overall, ASTRAM provides a comprehensive solution for transportation and logistics companies looking to optimize their operations and improve customer satisfaction.\r\n",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "104",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Clinical Trial Management",
            "description": "<p>Clinical trials are a critical aspect of the pharmaceutical industry to ensure the safety and efficacy of new drugs and treatments. Astram, a comprehensive software solution, can be used to manage all aspects of clinical trials management. Astram can help streamline the clinical trial process, reduce errors, and improve efficiency, ultimately leading to faster time-to-market for new drugs and treatments.</p>",
            "description2": "<p>Clinical trials are a critical aspect of the pharmaceutical industry to ensure the safety and efficacy of new drugs and treatments. Astram, a comprehensive software solution, can be used to manage all aspects of clinical trials management. Astram can help streamline the clinical trial process, reduce errors, and improve efficiency, ultimately leading to faster time-to-market for new drugs and treatments.</p>",
            "image": require('../assets/img/cms/content/Clinical_Trial_Management.png'),
            "iconClass": "",
            "heading": "Clinical Trial Management",
            "alt": "Clinical Trial Management",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "104",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Astram's clinical trial management system can help you save time and resources, reduce errors and inconsistencies, and ensure \r\n\r\ncompliance with regulatory requirements.By using Astram, you can gain greater visibility into the clinical trial process, making it easier to monitor progress and identify potential issues early on.\r\n\r\nAstram can help you improve collaboration between stakeholders, including investigators, site staff, regulatory authorities, and sponsors.",
                        "image": ""
                    },
                    {
                        "cmsId": "104",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "Astram offers a suite of modules for clinical trial management, including patient enrolment, scheduling, protocol adherence, regulatory compliance, adverse event reporting, and data management.\r\n\r\nWith Astram, you can automate many of the repetitive tasks involved in clinical trials, reducing the workload on staff and minimizing the risk of errors.\r\n\r\nAstram can help you manage clinical trial data securely and efficiently, with a powerful reporting engine that provides customizable, standardized reports for all stakeholders",
                        "image": ""
                    },
                    {
                        "cmsId": "104",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "Astram is a powerful software solution that can be used to manage all aspects of clinical trials management, from patient enrolment to data management and reporting.\r\n\r\nBy using Astram, you can streamline your clinical trial process, reduce errors, and ensure compliance with regulatory requirements, ultimately leading to faster time-to-market for new drugs and treatments",
                        "image": ""
                    }
                ]
            }
        },
        {
            "cmsId": "105",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "39",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Production optimization",
            "description": "<p>ASTRAM Production Optimization in Astram is designed to enhance the production processes of manufacturing plants. By leveraging advanced data analytics and machine learning algorithms, ASTRAM can help businesses optimize their production processes, minimize downtime, and reduce waste. The solution offers real-time monitoring of critical production parameters, enabling businesses to quickly identify issues and take corrective actions. Additionally, ASTRAM provides comprehensive analytics and reporting features that allow businesses to gain insights into their production processes and make data-driven decisions. With ASTRAM Production Optimization, businesses can increase efficiency, reduce costs, and improve their overall competitiveness in the market.</p>",
            "description2": "<p>ASTRAM Production Optimization in Astram is designed to enhance the production processes of manufacturing plants. By leveraging advanced data analytics and machine learning algorithms, ASTRAM can help businesses optimize their production processes, minimize downtime, and reduce waste. The solution offers real-time monitoring of critical production parameters, enabling businesses to quickly identify issues and take corrective actions. Additionally, ASTRAM provides comprehensive analytics and reporting features that allow businesses to gain insights into their production processes and make data-driven decisions. With ASTRAM Production Optimization, businesses can increase efficiency, reduce costs, and improve their overall competitiveness in the market.</p>",
            "image": require('../assets/img/cms/content/Production_optimization.png'),
            "iconClass": "",
            "heading": "Production optimization",
            "alt": "Production optimization",
            "additional_data": {
                "listing": [
                    {
                        "cmsId": "105",
                        "pageId": "4",
                        "title": "Benifits",
                        "description": "Another key feature of ASTRAM is its ability to automate key aspects of production, such as scheduling, quality control, and supply chain management. \r\n\r\nThis reduces the potential for human error and allows companies to operate more efficiently, with fewer delays and disruptions.\r\n\r\nIn addition to these core functions, ASTRAM also offers a range of specialized modules to address specific needs and challenges in the production process. For example, the predictive maintenance module can help companies anticipate and prevent equipment failures, while the inventory management module can help ensure that the right materials and components are available when they are needed.",
                        "image": ""
                    },
                    {
                        "cmsId": "105",
                        "pageId": "4",
                        "title": "Key Feature",
                        "description": "ASTRAM can help with production optimization is by providing real-time monitoring and control of equipment and processes. \r\n\r\nThis allows companies to identify and address bottlenecks and inefficiencies as they occur, ensuring that production runs as smoothly as possible. \r\n\r\nASTRAM can also provide detailed performance metrics and analytics to help companies identify areas for improvement and make data-driven decisions to optimize their production processes.",
                        "image": ""
                    },
                    {
                        "cmsId": "105",
                        "pageId": "4",
                        "title": "Inference",
                        "description": "ASTRAM offers a powerful suite of tools for production optimization, with features and modules that can be tailored to meet the specific needs and challenges of any business. By leveraging these tools and capabilities, companies can improve their efficiency, reduce waste and costs, and ultimately boost their bottom line.\r\n",
                        "image": ""
                    }
                ]
            }
        }
    ],
    "astram_solutions_and_modules_title_home_page": [
        {
            "cmsId": "83",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "38",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "ASTRAM Solutions and Modules",
            "description": "<p>ASTRAM Solutions and Modules</p>",
            "description2": "<p>ASTRAM Solutions and Modules</p>",
            "image": "",
            "iconClass": "",
            "heading": "ASTRAM Solutions and Modules",
            "alt": "ASTRAM Solutions and Modules",
            "additional_data": []
        }
    ]
});
const [language, setLanguage] = useState('ENGLISH');

  //let imageName = require('../assets/img/cms/content/Health_and_Safety_Management.png')

  const [isPageLoaded, setIsPageLoaded] =useState(false);
useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    var languageH = localStorage.getItem('language'); 

    if(languageH!=undefined && languageH!=null && languageH!=''){
        setLanguage(languageH);
    }
   
    if(languageH=='GERMAN'){
        setDataSource(dataSourceDe) ;
        
    }else{
        setDataSource(dataSourceEn);
    }

    const fetchMyAPI = async () => {
      try {


        await window.extraScript();
           /* var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
           

            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            const response = await fetch(
            'http://localhost/astram2/list-cms-content/4', requestOptions
            );
            const json = await response.json();
            setDataSource(json);*/
            //console.log(json);
            //console.log('Image Chinmoy',json['home-page-slider-background'][0]);
            
       
      }catch (error) {
        console.log(error);
      } 
    }
    setIsPageLoaded(true);
    //fetchMyAPI()
    return () => {
      console.log("login cleaned up");
    };
  }, [])



 


  return (
   
    <>
    {isPageLoaded===true &&
   <>

      <Helmet>
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js" type="text/javascript" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" type="text/javascript" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js" type="text/javascript" />
        <title>ASTRAM - {dataSource.metaTitle}</title>
      </Helmet>

     
      {/* Header Navigation */}
      <Nav language={language} data={dataSource}/>
      {/* end Header Navigation */}

      {/* Banner */}
      <Banner language={language} data={dataSource}/>
      {/* end Banner */}

      {/* about us section */}
      <AboutUsSection language={language} data={dataSource} />
      {/* end about us section */}

      {/* Our Solutions And Business */}
      <OurSolutionsAndBusiness language={language} data={dataSource} />
      {/* end Solutions And Business */}

      {/* Industry Appliations  */}
      <IndustrySection language={language} data={dataSource} />
      {/* end Industry Appliations */}

      <AstramSoftwareTechnology language={language} data={dataSource} />
      <AboutServices language={language} data={dataSource} />
      <AstramSolutionsAndMoule language={language} data={dataSource} />
      <AboutTeam language={language} data={dataSource} />
      <TestimonialSection language={language} data={dataSource} />
      <AboutClientTestimonials language={language} data={dataSource}/>
      <Footer language={language} data={dataSource}/>
      
      </>
}
</>

  );
}

export default Home;
