
//import AboutImg3 from "../../../assets/img/module-img.png";

import { useContext } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import Card from 'react-bootstrap/Card';

const PINK = 'rgba(255, 192, 203, 0.6)';
const BLUE = 'rgba(0, 0, 255, 0.6)';

function ContextAwareToggle({ children, eventKey, callback,rowData,rowIndex }) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
   
           <h2 className="accordion-header" >
              <button onClick={decoratedOnClick}
            
              
              className={`accordion-button accordion-parent-button ${isCurrentEventKey ? "" : "collapsed"} `} type="button" >
                <span>0{rowIndex+1}.&nbsp;</span> {rowData?.title}:
                <span className="accordion-pointer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="800px" height="800px" viewBox="0 0 24 24">
                    <path fillRule="evenodd" clipRule="evenodd" d="M12 4C12.5523 4 13 4.44772 13 5V11H19C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13H13V19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19V13H5C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11H11V5C11 4.44772 11.4477 4 12 4Z" />
                  </svg>
                </span>
              </button>
            </h2>
            
  );
}

function AstramSolutionsAndModule({data,language}) {
  return (
    <>
     {(data?.astram_solutions_and_modules_head_content_technology_hub_page || data?.astram_solutions_and_modules_block_home_page  ) &&
    <div className="technology-solutions py-5 mb-4">
        <div className="container px-xl-5 pb-lg-5 pb-md-4 pb-sm-3">
        
          
          {language=='ENGLISH' &&
                      <h4 className="text-center h2 mb-4 f500 pt-md-4">ASTRAM Solutions and <span>Modules</span></h4>
                    }
                    {language=='GERMAN' &&
                    <h4 className="text-center h2 mb-4 f500 pt-md-4">ASTRAM-ST Lösungen und <span>Module</span></h4>
                    }
          
          <div className="cms text-center">
            <p dangerouslySetInnerHTML={{__html: data?.astram_solutions_and_modules_head_content_technology_hub_page[0]?.description}}></p>
          </div>


         
    


    <Accordion id={"th0"} className="accordion accordion-parent" defaultActiveKey="s0">
    {data?.astram_solutions_and_modules_block_home_page.map((row, index) => (
      <Card  className="accordion-item accordion-parent-item">
        <Card.Header >
          <ContextAwareToggle rowData={row} rowIndex={index} eventKey={"s"+index}>{index}</ContextAwareToggle>
        </Card.Header >
        <Accordion.Collapse eventKey={"s"+index}>
          <Card.Body>

                <div className="row pt-lg-4 pb-sm-3">
                  <div className="col-lg-7 mx-auto cms text-center">
                    <p dangerouslySetInnerHTML={{__html: row?.description}}></p>
                  </div>                                    
                </div>

                <div className="row align-items-center pb-md-5 mb-lg-5 pb-2">
                  <div className="col-lg-5">
                    <img src={row?.image} alt={row?.title} width={561} height={474} />
                  </div>
                  <div className="col-lg-7">
                    <Accordion defaultActiveKey={index+"sub0"}>
                    {row?.additional_data?.listing && row?.additional_data?.listing.map((rowf, indexF) => (
                        <Accordion.Item  eventKey={index+"sub"+indexF}>
                          <Accordion.Header>{rowf?.title}</Accordion.Header>
                          <Accordion.Body>
                            <div className="accordion-body cms">
                                <p dangerouslySetInnerHTML={{__html: rowf?.description}}></p>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </div>
                </div>
            
           

          </Card.Body>
        </Accordion.Collapse>
      </Card>
    ))}
    </Accordion>
        


      




           
          
        </div>
      </div>
      }
      </>

  );
}
export default AstramSolutionsAndModule;
