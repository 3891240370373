import React , { useState,useEffect} from 'react';
import Nav from "../layouts/header";
import Footer from "../layouts/footer";

import InnerTopBanner from "../components/inner-page-banner/ban-01";
import AboutContent from "../components/about-content";
import AboutTeam from "../components/about-team/about-team-01";
import AboutTeamMember from "../components/about-team-member";
import AboutPurpose from "../components/about-purpose";

import OurValues from "../components/our-valuse";


import AboutClient from "../components/about-client-testimonials";
import {Helmet} from "react-helmet";





function AboutUs() {
    const [isPageLoaded, setIsPageLoaded] =useState(false);
const [dataSourceEn, setDataSourceEn] = useState({
    "pageName": "About Us Page",
    "metaTitle": "Inspection Automation & Tracking Software India, South Africa, Germany",
    "metaDescription": "Enhance your inspection processes with ASTRAM's tracking software. Serving Pretoria, Johannesburg, Cape Town, Durban, Bengaluru, Chennai, Hyderabad, Pune, Mumbai, Kolkata, Berlin, Munich, Frankfurt, Hamburg, Cologne, Düsseldorf, and more.",
    "metaKeyword": "About Us",
    "about_us_content_about_us_page": [
        {
            "cmsId": "62",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "27",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "  ",
            "title": "About Us",
            "description": "<p><b>ASTRAM is a versatile software solution designed to enable businesses to optimize their processes and operations for a more sustainable </b> and efficient future. With ASTRAM, businesses can connect their engineering expertise with software to streamline manufacturing, transportation, power generation, and other operations. Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better.</p>",
            "description2": "<p><b>ASTRAM is a versatile software solution designed to enable businesses to optimize their processes and operations for a more sustainable </b> and efficient future. With ASTRAM, businesses can connect their engineering expertise with software to streamline manufacturing, transportation, power generation, and other operations. Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better.</p>",
            "image": "",
            "iconClass": "",
            "heading": "About Us",
            "alt": "About Us",
            "additional_data": []
        }
    ],
    "about_us_block_home_page": [
        {
            "cmsId": "33",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "Our Vision",
            "description": "<p>Our overall goal is to provide businesses with a flexible and adaptable software solution that can be tailored to their unique needs and requirements. By working closely with our clients and understanding their specific challenges and goals, we aim to develop customized solutions that help them achieve their objectives and drive long-term success.</p>",
            "description2": "<p>Our overall goal is to provide businesses with a flexible and adaptable software solution that can be tailored to their unique needs and requirements. By working closely with our clients and understanding their specific challenges and goals, we aim to develop customized solutions that help them achieve their objectives and drive long-term success.</p>",
            "image": require('../assets/img/cms/content/a_032.png'),
            "iconClass": "",
            "heading": "Our Vision",
            "alt": "Our Vision",
            "additional_data": []
        },
        {
            "cmsId": "34",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "       ",
            "metaKeyword": "       ",
            "title": "Technology and digital transformation",
            "description": "<p>Our world-class technology and domain-led digital capabilities are deeply embedded in our DNA. Maintaining and improving our innovation, technology and&nbsp;<br />digital leadership is a strategic priority across ASTRAM. Pushing the boundaries of technology enables us to help our customers attain new levels of performance and clearly differentiate ASTRAM's offerings.</p>",
            "description2": "<p>Our world-class technology and domain-led digital capabilities are deeply embedded in our DNA. Maintaining and improving our innovation, technology and&nbsp;<br />digital leadership is a strategic priority across ASTRAM. Pushing the boundaries of technology enables us to help our customers attain new levels of performance and clearly differentiate ASTRAM's offerings.</p>",
            "image": require('../assets/img/cms/content/a_01.png'),
            "iconClass": "",
            "heading": "Technology and digital transformation",
            "alt": "Technology and digital transformation",
            "additional_data": []
        },
        {
            "cmsId": "35",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Connectivity Collaboration and Innovation ",
            "description": "<p>ASTRAM's ability to communicate with machines through its API gateway is closely linked to the software's broader focus on connectivity, collaboration, and innovation.</p>",
            "description2": "<p>ASTRAM's ability to communicate with machines through its API gateway is closely linked to the software's broader focus on connectivity, collaboration, and innovation.</p>",
            "image": require('../assets/img/cms/content/a_02.png'),
            "iconClass": "",
            "heading": "Connectivity Collaboration and Innovation ",
            "alt": "Connectivity Collaboration and Innovation ",
            "additional_data": []
        },
        {
            "cmsId": "36",
            "cmsSlug": null,
            "pageId": "4",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Sustainability",
            "description": "<p>ASTRAM Team is committed to sustainability, which means balancing the needs of society, the environment, and the economy to create a healthier and more&nbsp;<br />prosperous world for future generations. To achieve this, ASTRAM Team embeds sustainable practices across its entire value chain, reducing its environmental&nbsp;<br />footprint, enhancing its social impact, and driving economic growth and prosperity for all stakeholders. By acting as a responsible corporate citizen, ASTRAM&nbsp;<br />builds trust and credibility with customers, employees, investors, and other stakeholders, creating long-term value for all.</p>",
            "description2": "<p>ASTRAM Team is committed to sustainability, which means balancing the needs of society, the environment, and the economy to create a healthier and more&nbsp;<br />prosperous world for future generations. To achieve this, ASTRAM Team embeds sustainable practices across its entire value chain, reducing its environmental&nbsp;<br />footprint, enhancing its social impact, and driving economic growth and prosperity for all stakeholders. By acting as a responsible corporate citizen, ASTRAM&nbsp;<br />builds trust and credibility with customers, employees, investors, and other stakeholders, creating long-term value for all.</p>",
            "image": require('../assets/img/cms/content/a_03.png'),
            "iconClass": "",
            "heading": "Sustainability",
            "alt": "Sustainability",
            "additional_data": []
        }
    ],
    "astram_team_home_page": [
        {
            "cmsId": "52",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "24",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "ASTRAM Team",
            "description": "<p>Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better. Building on our own and clients&rsquo; expertise in Inspection &amp; Auditing, Power, Petro Chemical, Laboratories, and manufacturing sectors, ASTRAM Team aims to create superior value for all stakeholders by advanced software solutions. With a focus on sustainability and cutting-edge technology, ASTRAM Team is committed to pushing the boundaries of what is possible, enabling businesses to optimize their operations and accelerate their digital transformation. Our team is dedicated to driving performance to new levels, operating in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. With a focus on market leadership, technology and digital capabilities, people, portfolio management, and sustainability, ASTRAM Team is uniquely positioned to deliver superior value and performance to businesses across a variety of industries.</p>",
            "description2": "<p>Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better. Building on our own and clients&rsquo; expertise in Inspection &amp; Auditing, Power, Petro Chemical, Laboratories, and manufacturing sectors, ASTRAM Team aims to create superior value for all stakeholders by advanced software solutions. With a focus on sustainability and cutting-edge technology, ASTRAM Team is committed to pushing the boundaries of what is possible, enabling businesses to optimize their operations and accelerate their digital transformation. Our team is dedicated to driving performance to new levels, operating in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. With a focus on market leadership, technology and digital capabilities, people, portfolio management, and sustainability, ASTRAM Team is uniquely positioned to deliver superior value and performance to businesses across a variety of industries.</p>",
            "image": require('../assets/img/cms/content/team.jpg'),
            "iconClass": "",
            "heading": "ASTRAM Team",
            "alt": "ASTRAM Team",
            "additional_data": []
        }
    ],
    "meet_our_team_about_us_page": [
        {
            "cmsId": "68",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "30",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Member 1",
            "description": "<p>Meet our Team</p>",
            "description2": "<p>Meet our Team</p>",
            "image": require('../assets/img/cms/content/team-member.jpg'),
            "iconClass": "",
            "heading": "Member 1",
            "alt": "Member 1",
            "additional_data": []
        },
        {
            "cmsId": "69",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "30",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Member 2",
            "description": "<p>Member 2</p>",
            "description2": "<p>Member 2</p>",
            "image": require('../assets/img/cms/content/banner_21.png'),
            "iconClass": "",
            "heading": "Member 2",
            "alt": "Member 2",
            "additional_data": []
        },
        {
            "cmsId": "70",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "30",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Member 3",
            "description": "<p>Member 3</p>",
            "description2": "<p>Member 3</p>",
            "image": require('../assets/img/cms/content/team-member1.jpg'),
            "iconClass": "",
            "heading": "Member 3",
            "alt": "Member 3",
            "additional_data": []
        }
    ],
    "our_purpose_about_us_page": [
        {
            "cmsId": "71",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "31",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Our Purpose",
            "description": "<p>Our purpose is to help businesses accelerate their digital transformation and optimize their operations for greater efficiency, accuracy, and sustainability. We believe that by leveraging cutting-edge technology and innovative solutions, businesses can unlock new opportunities for growth and performance, while also reducing their environmental impact and improving their social responsibility.</p>\r\n<p>In pursuit of our purpose, we are committed to operate in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. These principles include a focus on sustainability, cutting-edge technology, and continuous improvement, as well as a commitment to diversity, inclusion, and equal opportunity.</p>\r\n<p>By operating with purpose and staying true to our guiding principles, we believe that with ASTRAM Software Technology we can make a meaningful difference for businesses and society as a whole.</p>",
            "description2": "<p>Our purpose is to help businesses accelerate their digital transformation and optimize their operations for greater efficiency, accuracy, and sustainability. We believe that by leveraging cutting-edge technology and innovative solutions, businesses can unlock new opportunities for growth and performance, while also reducing their environmental impact and improving their social responsibility.</p>\r\n<p>In pursuit of our purpose, we are committed to operate in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. These principles include a focus on sustainability, cutting-edge technology, and continuous improvement, as well as a commitment to diversity, inclusion, and equal opportunity.</p>\r\n<p>By operating with purpose and staying true to our guiding principles, we believe that with ASTRAM Software Technology we can make a meaningful difference for businesses and society as a whole.</p>",
            "image": require('../assets/img/cms/content/pourpous.jpg'),
            "iconClass": "",
            "heading": "Our Purpose",
            "alt": "Our Purpose",
            "additional_data": []
        }
    ],
    "about_partner_home_page": [
        {
            "cmsId": "57",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 1 ",
            "description": "<p>Partner 1&nbsp;</p>",
            "description2": "<p>Partner 1&nbsp;</p>",
            "image": require('../assets/img/cms/content/client-logo1.png'),
            "iconClass": "",
            "heading": "Partner 1 ",
            "alt": "Partner 1 ",
            "additional_data": []
        },
        {
            "cmsId": "58",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Partner 2",
            "description": "<p>Partner 2</p>",
            "description2": "<p>Partner 2</p>",
            "image": require('../assets/img/cms/content/client-logo2.png'),
            "iconClass": "",
            "heading": "Partner 2",
            "alt": "Partner 2",
            "additional_data": []
        },
        {
            "cmsId": "59",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 3",
            "description": "<p>Partner 3</p>",
            "description2": "<p>Partner 3</p>",
            "image": require('../assets/img/cms/content/client-logo3.png'),
            "iconClass": "",
            "heading": "Partner 3",
            "alt": "Partner 3",
            "additional_data": []
        },
        {
            "cmsId": "60",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 4",
            "description": "<p>Partner 4</p>",
            "description2": "<p>Partner 4</p>",
            "image": require('../assets/img/cms/content/client-logo4.png'),
            "iconClass": "",
            "heading": "Partner 4",
            "alt": "Partner 4",
            "additional_data": []
        },
        {
            "cmsId": "61",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 5",
            "description": "<p>Partner 5</p>",
            "description2": "<p>Partner 5</p>",
            "image": require('../assets/img/cms/content/client-logo5.png'),
            "iconClass": "",
            "heading": "Partner 5",
            "alt": "Partner 5",
            "additional_data": []
        }
    ],
    "inner_top_banner_home_page": [
        {
            "cmsId": "87",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "40",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "About Us",
            "description": "<p><span style=\"color: #2d2d2d; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "description2": "<p><span style=\"color: #2d2d2d; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "image": require('../assets/img/cms/content/about-banner.jpg'),
            "iconClass": "",
            "heading": "About Us",
            "alt": "About Us",
            "additional_data": []
        }
    ],
    "our_values_section": [
        {
            "cmsId": "151",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "49",
            "metaTitle": "Our Values",
            "metaDescriptions": " Our Values",
            "metaKeyword": " Our Values",
            "title": "Our Values",
            "description": "<p>At ASTRAM, our values of excellence, integrity, collaboration, and customer-centricity drive our actions and shape our company culture. We are committed to delivering high-quality solutions, upholding ethical standards, fostering teamwork, and putting our clients' needs at the center of everything we do.</p>",
            "description2": "<p>At ASTRAM, our values of excellence, integrity, collaboration, and customer-centricity drive our actions and shape our company culture. We are committed to delivering high-quality solutions, upholding ethical standards, fostering teamwork, and putting our clients' needs at the center of everything we do.</p>",
            "image": require('../assets/img/cms/content/OurValues1.png'),
            "iconClass": "",
            "heading": "Our Values",
            "alt": "Our Values",
            "additional_data": []
        }
    ]
});


const [dataSourceDe, setDataSourceDE] = useState({
    "pageName": "Über uns",
    "metaTitle": "Über uns",
    "metaDescription": "   ",
    "metaKeyword": "   ",
    "about_us_content_about_us_page": [
        {
            "cmsId": "62",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "27",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": " ",
            "title": "About Us",
            "description": "<p>ASTRAM ist eine vielseitige Softwarelösung, die entwickelt wurde, um Unternehmen dabei zu unterstützen, ihre Prozesse und Abläufe für eine nachhaltigere und effizientere Zukunft zu optimieren. Mit ASTRAM können Unternehmen ihre technische Expertise mit Software verbinden, um die Fertigung, den Transport, die Stromerzeugung und andere Abläufe zu optimieren. Basierend auf jahrzehntelanger Erfahrung ist das Expertenteam von ASTRAM bestrebt, Innovationen voranzutreiben und Unternehmen dabei zu helfen, ihre Abläufe zum Besseren zu verändern.</p>",
            "description2": "<p>ASTRAM ist eine vielseitige Softwarelösung, die entwickelt wurde, um Unternehmen dabei zu unterstützen, ihre Prozesse und Abläufe für eine nachhaltigere und effizientere Zukunft zu optimieren. Mit ASTRAM können Unternehmen ihre technische Expertise mit Software verbinden, um die Fertigung, den Transport, die Stromerzeugung und andere Abläufe zu optimieren. Basierend auf jahrzehntelanger Erfahrung ist das Expertenteam von ASTRAM bestrebt, Innovationen voranzutreiben und Unternehmen dabei zu helfen, ihre Abläufe zum Besseren zu verändern.</p>",
            "image": "",
            "iconClass": "",
            "heading": "About Us",
            "alt": "About Us",
            "additional_data": []
        }
    ],
    "about_us_block_home_page": [
        {
            "cmsId": "33",
            "cmsSlug": "unsere-ziele",
            "pageId": "5",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "Unsere Ziele",
            "description": "<p>Unser &uuml;bergeordnetes Ziel ist es, Unternehmen eine flexible und anpassungsf&auml;hige Softwarel&ouml;sung zur Verf&uuml;gung zu stellen, die auf ihre individuellen Bed&uuml;rfnisse und Anforderungen zugeschnitten werden kann. Indem wir eng mit unseren Kunden zusammenarbeiten und ihre spezifischen Herausforderungen und Ziele verstehen, wollen wir ma&szlig;geschneiderte L&ouml;sungen entwickeln, die ihnen helfen, ihre Ziele zu erreichen und langfristigen Erfolg zu erzielen.</p>",
            "description2": "<p>Unser &uuml;bergeordnetes Ziel ist es, Unternehmen eine flexible und anpassungsf&auml;hige Softwarel&ouml;sung zur Verf&uuml;gung zu stellen, die auf ihre individuellen Bed&uuml;rfnisse und Anforderungen zugeschnitten werden kann. Indem wir eng mit unseren Kunden zusammenarbeiten und ihre spezifischen Herausforderungen und Ziele verstehen, wollen wir ma&szlig;geschneiderte L&ouml;sungen entwickeln, die ihnen helfen, ihre Ziele zu erreichen und langfristigen Erfolg zu erzielen.</p>",
            "image": require('../assets/img/cms/content/a_032.png'),
            "iconClass": "",
            "heading": "Unsere Ziele",
            "alt": "Unsere Ziele",
            "additional_data": []
        },
        {
            "cmsId": "34",
            "cmsSlug": "technologie-und-digitale-transformation",
            "pageId": "5",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "       ",
            "metaKeyword": "       ",
            "title": "Technologie und digitale Transformation",
            "description": "<p>Unsere fortschrittliche Technologie und unsere Kompetenz zu digitalen L&ouml;sungen sind tief in unserer DNA verankert. Die Aufrechterhaltung und Verbesserung unserer Innovationsst&auml;rke und Kompetenz in digitalen Technologien ist eine strategische Priorit&auml;t des ASTRAM-Teams. Indem wir die Grenzen der Technologie erweitern, k&ouml;nnen wir unseren Kunden helfen, neue Leistungsniveaus zu erreichen und sich mit denL&ouml;sungen von ASTRAM am Markt zu differenzieren.</p>",
            "description2": "<p>Unsere fortschrittliche Technologie und unsere Kompetenz zu digitalen L&ouml;sungen sind tief in unserer DNA verankert. Die Aufrechterhaltung und Verbesserung unserer Innovationsst&auml;rke undKompetenz in digitalen Technologien ist eine strategische Priorit&auml;t des ASTRAM-Teams. Indem wir die Grenzen der Technologie erweitern, k&ouml;nnen wir unseren Kunden helfen, neue Leistungsniveaus zu erreichen und sich mit denLösungen von ASTRAM am Markt zu differenzieren.</p>",
            "image": require('../assets/img/cms/content/a_01.png'),
            "iconClass": "",
            "heading": "Technologie und digitale Transformation",
            "alt": "Technologie und digitale Transformation",
            "additional_data": []
        },
        {
            "cmsId": "35",
            "cmsSlug": "konnektivitat-zusammenarbeit-und-innovation",
            "pageId": "5",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Konnektivität, Zusammenarbeit und Innovation",
            "description": "<p>ASTRAM's F&auml;higkeit zur Kommunikation mit Maschinen &uuml;ber das API-Gateway steht in enger Verbindung zur umfassenderen Ausrichtung der Software auf Konnektivit&auml;t.</p>",
            "description2": "<p>ASTRAM's F&auml;higkeit zur Kommunikation mit Maschinen &uuml;ber das API-Gateway steht in enger Verbindung zur umfassenderen Ausrichtung der Software auf Konnektivit&auml;t.</p>",
            "image": require('../assets/img/cms/content/a_02.png'),
            "iconClass": "",
            "heading": "Konnektivität, Zusammenarbeit und Innovation",
            "alt": "Konnektivität, Zusammenarbeit und Innovation",
            "additional_data": []
        },
        {
            "cmsId": "36",
            "cmsSlug": "nachhaltigkeit",
            "pageId": "5",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Nachhaltigkeit",
            "description": "<p>Das ASTRAM-Team setzt sich f&uuml;r Nachhaltigkeit ein, was bedeutet, die Bed&uuml;rfnisse von Gesellschaft, Umwelt und Wirtschaft in Einklang zu bringen, um eine ges&uuml;ndere und sozialere Welt f&uuml;r zuk&uuml;nftige Generationen zu schaffen. Um dies zu erreichen, bettet das ASTRAM-Team nachhaltige Praktiken in seine gesamte Wertsch&ouml;pfungskette ein, verringert seinen &ouml;kologischen Fu&szlig;abdruck, verbessert seine soziale Positionierung und f&ouml;rdert Wachstum und Wohlstand f&uuml;r alle Beteiligten. Durch das Handeln als verantwortungsbewusstes Unternehmen baut ASTRAM Vertrauen und Glaubw&uuml;rdigkeit bei Kunden, Mitarbeitern, Investoren und anderen Stakeholdern auf und schafft so langfristige Werte f&uuml;r alle.</p>",
            "description2": "<p>Das ASTRAM-Team setzt sich f&uuml;r Nachhaltigkeit ein, was bedeutet, die Bed&uuml;rfnisse von Gesellschaft, Umwelt und Wirtschaft in Einklang zu bringen, um eine ges&uuml;ndere und sozialere Welt f&uuml;r zuk&uuml;nftige Generationen zu schaffen. Um dies zu erreichen, bettet das ASTRAM-Team nachhaltige Praktiken in seine gesamte Wertsch&ouml;pfungskette ein, verringert seinen &ouml;kologischen Fu&szlig;abdruck, verbessert seine soziale Positionierung und f&ouml;rdert Wachstum und Wohlstand f&uuml;r alle Beteiligten. Durch das Handeln als verantwortungsbewusstes Unternehmen baut ASTRAM Vertrauen und Glaubw&uuml;rdigkeit bei Kunden, Mitarbeitern, Investoren und anderen Stakeholdern auf und schafft so langfristige Werte f&uuml;r alle.</p>",
            "image": require('../assets/img/cms/content/a_03.png'),
            "iconClass": "",
            "heading": "Nachhaltigkeit",
            "alt": "Nachhaltigkeit",
            "additional_data": []
        }
    ],
    "astram_team_home_page": [
        {
            "cmsId": "52",
            "cmsSlug": "astram-team",
            "pageId": "4",
            "sectionId": "24",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "ASTRAM Team",
            "description": "<p><span style=\"color: #2d2d2d; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Auf Grundlage langj&auml; hriger Erfahrung setzt sich das Expertenteam von ASTRAM daf&uuml;r ein, Innovationen voranzutreiben und Unternehmen dabei zu unterst&uuml;tzen, ihre Prozesse zu optimieren. Mit unserem Know-how und dem unserer dem unserer Kunden zielt das ASTRAM-Team darauf ab, in Prozessen wie Sicherheits- und Qualit&auml;tsmanagement, Inspektion und Auditierung, Supply-Chain Management u.v.a. f&uuml;r unterschiedliche Branchen wie Energie, Chemie, Pharma, Herstellung etc., durch fortschrittliche Softwarel&ouml;sungen einen Mehrwert zu schaffen. Mit dem Fokus auf Nachhaltigkeit und fortschrittliche Technologien setzt sich das ASTRAM-Team daf&uuml;r ein, die Grenzen des M&ouml;glichen zu verschieben und Unternehmen in die Lage zu versetzen, ihre Abl&auml;ufe zu optimieren und ihre digitale Transformation zu beschleunigen. Unser Team hat es sich zur Aufgabe gemacht, die Leistung auf ein neues Niveau zu heben und L&ouml;sungen in &Uuml;bereinstimmung mit unseren Leitprinzipien -dem &sbquo;ASTRAM-Way&lsquo;-, zu erarbeiten. Mit einem Fokus auf Technologie und digitale F&auml;higkeiten, Mitarbeiterkompetenz, Portfoliomanagement und Nachhaltigkeit ist das ASTRAM-Team einzigartig am Markt positioniert, um Unternehmen in einer Vielzahl von Branchen Unterst&uuml;tzung und Mehrwert zu bieten.</span></p>",
            "description2": "<p><span style=\"color: #2d2d2d; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: left; text-indent: 0px; text-transform: none; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; white-space: normal; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Auf Grundlage langj&auml; hriger Erfahrung setzt sich das Expertenteam von ASTRAM daf&uuml;r ein, Innovationen voranzutreiben und Unternehmen dabei zu unterst&uuml;tzen, ihre Prozesse zu optimieren. Mit unserem Know-how und dem unserer dem unserer Kunden zielt das ASTRAM-Team darauf ab, in Prozessen wie Sicherheits- und Qualit&auml;tsmanagement, Inspektion und Auditierung, Supply-Chain Management u.v.a. f&uuml;r unterschiedliche Branchen wie Energie, Chemie, Pharma, Herstellung etc., durch fortschrittliche Softwarel&ouml;sungen einen Mehrwert zu schaffen. Mit dem Fokus auf Nachhaltigkeit und fortschrittliche Technologien setzt sich das ASTRAM-Team daf&uuml;r ein, die Grenzen des M&ouml;glichen zu verschieben und Unternehmen in die Lage zu versetzen, ihre Abl&auml;ufe zu optimieren und ihre digitale Transformation zu beschleunigen. Unser Team hat es sich zur Aufgabe gemacht, die Leistung auf ein neues Niveau zu heben und L&ouml;sungen in &Uuml;bereinstimmung mit unseren Leitprinzipien -dem &sbquo;ASTRAM-Way&lsquo;-, zu erarbeiten. Mit einem Fokus auf Technologie und digitale F&auml;higkeiten, Mitarbeiterkompetenz, Portfoliomanagement und Nachhaltigkeit ist das ASTRAM-Team einzigartig am Markt positioniert, um Unternehmen in einer Vielzahl von Branchen Unterst&uuml;tzung und Mehrwert zu bieten.</span></p>",
            "image": require('../assets/img/cms/content/team.jpg'),
            "iconClass": "",
            "heading": "ASTRAM Team",
            "alt": "ASTRAM Team",
            "additional_data": []
        }
    ],
    "meet_our_team_about_us_page": [
        {
            "cmsId": "68",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "30",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Member 1",
            "description": "<p>Meet our Team</p>",
            "description2": "<p>Meet our Team</p>",
            "image": require('../assets/img/cms/content/team-member.jpg'),
            "iconClass": "",
            "heading": "Member 1",
            "alt": "Member 1",
            "additional_data": []
        },
        {
            "cmsId": "69",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "30",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Member 2",
            "description": "<p>Member 2</p>",
            "description2": "<p>Member 2</p>",
            "image": require('../assets/img/cms/content/banner_21.png'),
            "iconClass": "",
            "heading": "Member 2",
            "alt": "Member 2",
            "additional_data": []
        },
        {
            "cmsId": "70",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "30",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Member 3",
            "description": "<p>Member 3</p>",
            "description2": "<p>Member 3</p>",
            "image": require('../assets/img/cms/content/team-member1.jpg'),
            "iconClass": "",
            "heading": "Member 3",
            "alt": "Member 3",
            "additional_data": []
        }
    ],
    "our_purpose_about_us_page": [
        {
            "cmsId": "71",
            "cmsSlug": "unser-ziel",
            "pageId": "5",
            "sectionId": "31",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Unser Ziel",
            "description": "<p>Unser Ziel ist es, Unternehmen dabei zu helfen, ihre digitale Transformation zu beschleunigen und ihre Abläufe für mehr Effizienz, Genauigkeit und Nachhaltigkeit zu optimieren. Wir glauben, dass Unternehmen durch den Einsatz modernster Technologie und innovativer Lösungen neue Wachstums- und Leistungschancen erschließen und gleichzeitig ihre Auswirkungen auf die Umwelt verringern und ihre soziale Verantwortung verbessern können.Bei der Verfolgung unseres Ziels sind wir bestrebt, im Einklang mit dem ASTRAM Way zu handeln – unseren Leitprinzipien, die unsere Arbeit und Interaktion mit unseren Kunden prägen. Zu diesen Grundsätzen gehören ein Fokus auf Nachhaltigkeit, Spitzentechnologie und kontinuierliche Verbesserung sowie die Verpflichtung zu Vielfalt, Inklusion und Chancengleichheit.Indem wir zielstrebig handeln und unseren Leitprinzipien treu bleiben, glauben wir, dass wir mit ASTRAM Software Technology einen bedeutenden Unterschied für Unternehmen und die Gesellschaft insgesamt bewirken können.</p>",
            "description2": "<p>Unser Ziel ist es, Unternehmen dabei zu helfen, ihre digitale Transformation zu beschleunigen und damit ihre Prozessehinsichtlich Effizienz, Qualit&auml;t und Nachhaltigkeit zu optimieren. Wir sind davon &uuml;berzeugt, dass Unternehmen durch den Einsatz modernster Technologien und innovativer L&ouml;sungen neue Wachstums- und Marktchancen erschlie&szlig;en und gleichzeitig ihre Umweltbelastung reduzieren sowie ihre soziale Verantwortung verbessern k&ouml;nnen. Dazu stellen wir Unternehmen flexible und anpassungsf&auml;hige Softwarel&ouml;sungen zur Verf&uuml;gung, die auf ihre individuellen Bed&uuml;rfnisse und Anforderungen zugeschnitten sind. Indem wir eng mit unseren Kunden zusammenarbeiten und ihre spezifischen.</p>",
            "image": require('../assets/img/cms/content/pourpous.jpg'),
            "iconClass": "",
            "heading": "Unser Ziel",
            "alt": "Unser Ziel",
            "additional_data": []
        }
    ],
    "about_partner_home_page": [
        {
            "cmsId": "57",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 1 ",
            "description": "<p>Partner 1&nbsp;</p>",
            "description2": "<p>Partner 1&nbsp;</p>",
            "image": require('../assets/img/cms/content/client-logo1.png'),
            "iconClass": "",
            "heading": "Partner 1 ",
            "alt": "Partner 1 ",
            "additional_data": []
        },
        {
            "cmsId": "58",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Partner 2",
            "description": "<p>Partner 2</p>",
            "description2": "<p>Partner 2</p>",
            "image": require('../assets/img/cms/content/client-logo2.png'),
            "iconClass": "",
            "heading": "Partner 2",
            "alt": "Partner 2",
            "additional_data": []
        },
        {
            "cmsId": "59",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 3",
            "description": "<p>Partner 3</p>",
            "description2": "<p>Partner 3</p>",
            "image": require('../assets/img/cms/content/client-logo3.png'),
            "iconClass": "",
            "heading": "Partner 3",
            "alt": "Partner 3",
            "additional_data": []
        },
        {
            "cmsId": "60",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 4",
            "description": "<p>Partner 4</p>",
            "description2": "<p>Partner 4</p>",
            "image": require('../assets/img/cms/content/client-logo4.png'),
            "iconClass": "",
            "heading": "Partner 4",
            "alt": "Partner 4",
            "additional_data": []
        },
        {
            "cmsId": "61",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 5",
            "description": "<p>Partner 5</p>",
            "description2": "<p>Partner 5</p>",
            "image": require('../assets/img/cms/content/client-logo5.png'),
            "iconClass": "",
            "heading": "Partner 5",
            "alt": "Partner 5",
            "additional_data": []
        }
    ],
    "inner_top_banner_home_page": [
        {
            "cmsId": "87",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "40",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "About Us",
            "description": "<p><span style=\"color: #2d2d2d; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "description2": "<p><span style=\"color: #2d2d2d; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "image": require('../assets/img/cms/content/about-banner.jpg'),
            "iconClass": "",
            "heading": "About Us",
            "alt": "About Us",
            "additional_data": []
        },
    ],
    "our_values_section": [
        {
            "cmsId": "147",
            "cmsSlug": null,
            "pageId": "5",
            "sectionId": "48",
            "metaTitle": "Unsere Werte",
            "metaDescriptions": " Unsere Werte ",
            "metaKeyword": " Unsere Werte ",
            "title": "Unsere Werte",
            "description": "<p>Bei ASTRAM treiben uns Werte wie Exzellenz, Integrit&auml;t, Zusammenarbeit und Kundenzentrierung an. Diese Werte pr&auml;gen unsere Handlungen und formen unsere Unternehmenskultur. Wir setzen uns f&uuml;r hochwertige L&ouml;sungen ein, halten ethische Standards ein, f&ouml;rdern Teamarbeit und stellen die Bed&uuml;rfnisse unserer Kunden in den Mittelpunkt unseres Handelns.&nbsp;</p>",
            "description2": "<p>Bei ASTRAM treiben uns Werte wie Exzellenz, Integrit&auml;t, Zusammenarbeit und Kundenzentrierung an. Diese Werte pr&auml;gen unsere Handlungen und formen unsere Unternehmenskultur. Wir setzen uns f&uuml;r hochwertige L&ouml;sungen ein, halten ethische Standards ein, f&ouml;rdern Teamarbeit und stellen die Bed&uuml;rfnisse unserer Kunden in den Mittelpunkt unseres Handelns.&nbsp;</p>",
            "image": require('../assets/img/cms/content/OurValues.png'),
            "iconClass": "",
            "heading": "Unsere Werte",
            "alt": "Unsere Werte",
            "additional_data": []
        }
    ]
});

const [dataSource, setDataSource] = useState({
    "pageName": "About Us Page",
    "metaTitle": "About Us",
    "metaDescription": "   ",
    "metaKeyword": "   ",
    "about_us_content_about_us_page": [
        {
            "cmsId": "62",
            "pageId": "5",
            "sectionId": "27",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": " ",
            "title": "About Us",
            "description": "<p><b>ASTRAM is a versatile software solution designed to enable businesses to optimize their processes and operations for a more sustainable </b> and efficient future. With ASTRAM, businesses can connect their engineering expertise with software to streamline manufacturing, transportation, power generation, and other operations. Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping usinesses transform their operations for the better.</p>",
            "description2": "<p><b>ASTRAM is a versatile software solution designed to enable businesses to optimize their processes and operations for a more sustainable </b> and efficient future. With ASTRAM, businesses can connect their engineering expertise with software to streamline manufacturing, transportation, power generation, and other operations. Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping usinesses transform their operations for the better.</p>",
            "image": "",
            "iconClass": "",
            "heading": "About Us",
            "alt": "About Us",
            "additional_data": []
        }
    ],
    "about_us_block_home_page": [
        {
            "cmsId": "33",
            "pageId": "5",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Our Vision",
            "description": "<p>Our overall goal is to provide businesses with a flexible and adaptable software solution that can be tailored to their unique needs and..</p>",
            "description2": "<p>Our overall goal is to provide businesses with a flexible and adaptable software solution that can be tailored to their unique needs and..</p>",
            "image": require('../assets/img/cms/content/a_032.png'),
            "iconClass": "",
            "heading": "Our Vision",
            "alt": "Our Vision",
            "additional_data": []
        },
        {
            "cmsId": "34",
            "pageId": "5",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "Technology and digital transformation",
            "description": "<p>Our world-class technology and domain-led digital capabilities are deeply embedded in our DNA. Maintaining and improving ..</p>",
            "description2": "<p>Our world-class technology and domain-led digital capabilities are deeply embedded in our DNA. Maintaining and improving ..</p>",
            "image": require('../assets/img/cms/content/a_01.png'),
            "iconClass": "",
            "heading": "Technology and digital transformation",
            "alt": "Technology and digital transformation",
            "additional_data": []
        },
        {
            "cmsId": "35",
            "pageId": "5",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Connectivity Collaboration and Innovation ",
            "description": "<p>ASTRAM's ability to communicate with machines through its API gateway is closely linked to the software's broader focus on connectivity..</p>",
            "description2": "<p>ASTRAM's ability to communicate with machines through its API gateway is closely linked to the software's broader focus on connectivity..</p>",
            "image": require('../assets/img/cms/content/a_02.png'),
            "iconClass": "",
            "heading": "Connectivity Collaboration and Innovation ",
            "alt": "Connectivity Collaboration and Innovation ",
            "additional_data": []
        },
        {
            "cmsId": "36",
            "pageId": "5",
            "sectionId": "17",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Sustainability",
            "description": "<p>ASTRAM Team is committed to sustainability,which means balancing the needs of society, the environment,and the economy to create ..</p>",
            "description2": "<p>ASTRAM Team is committed to sustainability,which means balancing the needs of society, the environment,and the economy to create ..</p>",
            "image": require('../assets/img/cms/content/a_03.png'),
            "iconClass": "",
            "heading": "Sustainability",
            "alt": "Sustainability",
            "additional_data": []
        }
    ],
    "astram_team_home_page": [
        {
            "cmsId": "52",
            "pageId": "5",
            "sectionId": "24",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "ASTRAM Team",
            "description": "<p>Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better. Building on our own and clients&rsquo; expertise in Inspection &amp; Auditing, Power, Petro Chemical, Laboratories, and manufacturing sectors, ASTRAM Team aims to create superior value for all stakeholders by advanced software solutions. With a focus on sustainability and cutting-edge technology, ASTRAM Team is committed to pushing the boundaries of what is possible, enabling businesses to optimize their operations and accelerate their digital transformation. Our team is dedicated to driving performance to new levels, operating in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. With a focus on market leadership, technology and digital capabilities, people, portfolio management, and sustainability, ASTRAM Team is uniquely positioned to deliver superior value and performance to businesses across a variety of industries</p>",
            "description2": "<p>Drawing from decades of experience, ASTRAM's team of experts is committed to driving innovation and helping businesses transform their operations for the better. Building on our own and clients&rsquo; expertise in Inspection &amp; Auditing, Power, Petro Chemical, Laboratories, and manufacturing sectors, ASTRAM Team aims to create superior value for all stakeholders by advanced software solutions. With a focus on sustainability and cutting-edge technology, ASTRAM Team is committed to pushing the boundaries of what is possible, enabling businesses to optimize their operations and accelerate their digital transformation. Our team is dedicated to driving performance to new levels, operating in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. With a focus on market leadership, technology and digital capabilities, people, portfolio management, and sustainability, ASTRAM Team is uniquely positioned to deliver superior value and performance to businesses across a variety of industries</p>",
            "image": require('../assets/img/cms/content/team.jpg'),
            "iconClass": "",
            "heading": "ASTRAM Team",
            "alt": "ASTRAM Team",
            "additional_data": []
        }
    ],
    "meet_our_team_about_us_page": [
        {
            "cmsId": "68",
            "pageId": "5",
            "sectionId": "30",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Member 1",
            "description": "<p>Meet our Team</p>",
            "description2": "<p>Meet our Team</p>",
            "image": require('../assets/img/cms/content/team-member.jpg'),
            "iconClass": "",
            "heading": "Member 1",
            "alt": "Member 1",
            "additional_data": []
        }
       
       
    ],
    "our_purpose_about_us_page": [
        {
            "cmsId": "71",
            "pageId": "5",
            "sectionId": "31",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Our Purpose",
            "description": "<p>Our purpose is to help businesses accelerate their digital transformation and optimize their operations for greater efficiency, accuracy, and sustainability. We believe that by leveraging cutting-edge technology and innovative solutions, businesses can unlock new opportunities for growth and performance, while also reducing their environmental impact and improving their social responsibility.</p>\r\n<p>In pursuit of our purpose, we are committed to operate in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. These principles include a focus on sustainability, cutting-edge technology, and continuous improvement, as well as a commitment to diversity, inclusion, and equal opportunity.</p>\r\n<p>By operating with purpose and staying true to our guiding principles, we believe that with ASTRAM Software Technology we can make a meaningful difference for businesses and society as a whole.</p>",
            "description2": "<p>Our purpose is to help businesses accelerate their digital transformation and optimize their operations for greater efficiency, accuracy, and sustainability. We believe that by leveraging cutting-edge technology and innovative solutions, businesses can unlock new opportunities for growth and performance, while also reducing their environmental impact and improving their social responsibility.</p>\r\n<p>In pursuit of our purpose, we are committed to operate in accordance with the ASTRAM Way - our guiding principles that inform how we work and interact with our clients. These principles include a focus on sustainability, cutting-edge technology, and continuous improvement, as well as a commitment to diversity, inclusion, and equal opportunity.</p>\r\n<p>By operating with purpose and staying true to our guiding principles, we believe that with ASTRAM Software Technology we can make a meaningful difference for businesses and society as a whole.</p>",
            "image": require('../assets/img/cms/content/pourpous.jpg'),
            "iconClass": "",
            "heading": "Our Purpose",
            "alt": "Our Purpose",
            "additional_data": []
        }
    ],
    "about_partner_home_page": [
        {
            "cmsId": "57",
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 1 ",
            "description": "<p>Partner 1&nbsp;</p>",
            "description2": "<p>Partner 1&nbsp;</p>",
            "image": require('../assets/img/cms/content/client-logo1.png'),
            "iconClass": "",
            "heading": "Partner 1 ",
            "alt": "Partner 1 ",
            "additional_data": []
        },
        {
            "cmsId": "58",
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Partner 2",
            "description": "<p>Partner 2</p>",
            "description2": "<p>Partner 2</p>",
            "image": require('../assets/img/cms/content/client-logo2.png'),
            "iconClass": "",
            "heading": "Partner 2",
            "alt": "Partner 2",
            "additional_data": []
        },
        {
            "cmsId": "59",
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 3",
            "description": "<p>Partner 3</p>",
            "description2": "<p>Partner 3</p>",
            "image": require('../assets/img/cms/content/client-logo3.png'),
            "iconClass": "",
            "heading": "Partner 3",
            "alt": "Partner 3",
            "additional_data": []
        },
        {
            "cmsId": "60",
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 4",
            "description": "<p>Partner 4</p>",
            "description2": "<p>Partner 4</p>",
            "image": require('../assets/img/cms/content/client-logo4.png'),
            "iconClass": "",
            "heading": "Partner 4",
            "alt": "Partner 4",
            "additional_data": []
        },
        {
            "cmsId": "61",
            "pageId": "5",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 5",
            "description": "<p>Partner 5</p>",
            "description2": "<p>Partner 5</p>",
            "image": require('../assets/img/cms/content/client-logo5.png'),
            "iconClass": "",
            "heading": "Partner 5",
            "alt": "Partner 5",
            "additional_data": []
        }
    ],
    "inner_top_banner_home_page": [
        {
            "cmsId": "87",
            "pageId": "5",
            "sectionId": "40",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "About Us",
            "description": "<p><span style=\"color: #2d2d2d; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "description2": "<p><span style=\"color: #2d2d2d; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: center; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "image": require('../assets/img/cms/content/about-banner.jpg'),
            "iconClass": "",
            "heading": "About Us",
            "alt": "About Us",
            "additional_data": []
        }
    ]
});

const [language, setLanguage] = useState('ENGLISH');

useEffect(() => {
   
    var languageH = localStorage.getItem('language'); 
    if(languageH!=undefined && languageH!=null && languageH!=''){
        setLanguage(languageH);
    }

    if(languageH=='GERMAN'){
        setDataSource(dataSourceDe) ;
        
    }else{
        setDataSource(dataSourceEn);
    }
    setIsPageLoaded(true)

   
    const fetchMyAPI = async () => {
      try {


        await window.extraScript();
           /* var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
           

            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            const response = await fetch(
            'http://localhost/astram2/list-cms-content/4', requestOptions
            );
            const json = await response.json();
            setDataSource(json);*/
            //console.log(json);
            //console.log('Image Chinmoy',json['home-page-slider-background'][0]);
            
            window.scrollTo({top: 10, left: 0, behavior: 'smooth'});
      }catch (error) {
        console.log(error);
      } 
    }
   
    fetchMyAPI()
    
   return () => {
    
  };
  }, [])

  return (
   
 
   <>
    <Helmet>
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js" type="text/javascript" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" type="text/javascript" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js" type="text/javascript" />
        <title>ASTRAM - {dataSource.metaTitle}</title>
      </Helmet>
      {/* Header Navigation */}
      <Nav language={language} data={dataSource}/>
      {/* end Header Navigation */}
      <InnerTopBanner language={language} data={dataSource}/>
      <AboutContent language={language} data={dataSource}/>
      <AboutTeam language={language} data={dataSource}/>
      {/* <AboutTeamMember language={language} data={dataSource}/>*/}
      <OurValues  language={language} data={dataSource}></OurValues>
      <AboutPurpose language={language} data={dataSource}/>
      <AboutClient language={language} data={dataSource}/>
      {/* Footer Navigation */}
      <Footer language={language} data={dataSource} />
      {/* end Footer Navigation */}
      </>


  );
}

export default AboutUs;
