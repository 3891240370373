

//import AbtIcon1 from "../../assets/img/ico/icon_01.png";
//import AbtIcon2 from "../../assets/img/ico/icon_02.png";
//import AbtIcon3 from "../../assets/img/ico/icon_03.png";
//import AbtIcon4 from "../../assets/img/ico/icon_04.png";

//import AbtIcon5 from "../../assets/img/ico/icon_05.png";
//import AbtIcon6 from "../../assets/img/ico/icon_06.png";
//import AbtIcon7 from "../../assets/img/ico/icon_07.png";
//import AbtIcon8 from "../../assets/img/ico/icon_08.png";




function AboutServices({data}) {
  return (
   

        <>

        {data?.my_industry_home_page &&
        <div className="about-services pt-4 pb-5">
        <div className="container px-lg-3 px-0">
        <div className="about-services--slider">

            {data?.our_services_home_page.map((row, index) => (
            <div className="about-services-col">
                <div className="about-services-img">
                    <img src={row?.image} width={83} height={94} alt={row?.alt}/>
                </div>
                <h6 className="text-uppercase text-center f200" dangerouslySetInnerHTML={{__html:row?.title }}></h6>
            </div>
            ))}
           
        </div>
        </div>
        </div>
        }
        </>

  );
}

export default AboutServices;
