import {Link } from "react-router-dom";


function OurSolutionsAndBusiness({data,language}) {
  return (
    <>

    {(data?.our_solutions_and_business_areas_title_industry_applications_page || data?.my_industry_home_page ) &&
    <div class="industryApplication-page pb-md-3 pb-5">
    <div class="container">
        <div class="pt-md-5 pt-4 pb-3 text-center">
            

                    {language=='ENGLISH' &&
                    <h2 class="f600">Our Business Areas and <span>Industry application</span></h2>
                    }
                    {language=='GERMAN' &&
                   <h2 class="f600">Unsere Lösungen für spezifische Geschäftsfelder und <span>Industrieanwendungen</span></h2>
                    }

             
        </div>
        <div class="row">
        {data?.my_industry_home_page && data?.my_industry_home_page.map((row, index) => (
            <div key={'key09873o'+index} class="col-md-6 industryApplication-col mb-md-4 pb-lg-2 mb-3">
                <img src={row?.image} width="570" height="316" alt={row?.title}/>
                <div class="industryApplication-col-text">
                    <h3 class="f600 text-uppercase">{row?.title}</h3>
                    <p dangerouslySetInnerHTML={{__html: row?.description}}></p>
                    {language=='ENGLISH' &&
                        <Link class="btn btn-bg-main-light f500" to={"/"+row?.cmsSlug}>Read More</Link>
                    }
                    {language=='GERMAN' &&
                        <Link class="btn btn-bg-main-light f500" to={"/"+row?.cmsSlug}>Mehr lesen</Link>
                    }
                    
                </div>
            </div>
            ))}
        </div>   
    </div>
</div>
 }
 </>

  );
}
export default OurSolutionsAndBusiness;
