
import {Link } from "react-router-dom";
import AboutImg3 from "../../assets/img/industry.webp";

function CmsContent({data,dataCms}) {
  return (
    <>
    {data?.our_security_section &&
      <div className="container cms">
       
        <p dangerouslySetInnerHTML={{__html: dataCms?.description}}/>
      </div>
    }
  </>

  );
}
export default CmsContent;
