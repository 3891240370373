
function TestimonialSection({data,language}) {
  return (
    <>
    {data?.client_testimonials_home_page &&
    <div className="testimonial-section py-5">
    
          {language=='ENGLISH' &&
              <h2 className="f600 mb-5 text-center">Client <span>Testimonials</span></h2>
          }
          {language=='GERMAN' &&
              <h2 className="f600 mb-5 text-center">Kundenbewertungen</h2>
          }
        <div className="testimonial-section-slide">
          {data?.client_testimonials_home_page.map((row, index) => (
              <div className="testimonial-col">
                  <p dangerouslySetInnerHTML={{__html: row?.description}}></p>
                  <div className="f500 fxxl fmain">- {row?.additional_data?.author_name[0]?.title!=undefined && row?.additional_data?.author_name[0]?.title}</div>
                  <div className="f500">({row?.additional_data?.designation[0]?.title!=undefined && row?.additional_data?.designation[0]?.title})</div>
              </div>
            ))}
        </div>
     </div>
}
</>
  );
}

export default TestimonialSection;
