
//import ThumbImg from "../../assets/img/pourpous.jpg";
function AboutPurpose({data}) {
  return (
    <>
    {data?.our_values_section && 
        <div class="about-purpose py-md-5 py-4">
        <div class="container px-lg-3">
            <div class="row" id="our-values">
                <div class="col-lg-7 about-purpose-img ms-lg-auto px-lg-3 px-0">
                {data?.our_values_section[0]?.image!='' && data?.our_values_section[0]?.image!=undefined &&    <img src={data?.our_values_section[0]?.image} width="764" height="652" alt=""/> }
                </div>
                <div class="col-lg-6 about-purpose-text cms top-50 pt-3 pt-lg-0">
                    <h3 class="f600 mb-3">{data?.our_values_section[0]?.title}</h3>
                    <p dangerouslySetInnerHTML={{__html: data?.our_values_section[0]?.description}}></p>
                  

                </div>
            </div>

            
        </div>
    </div>
    }
    </>

    

    
  );
}
export default AboutPurpose;
