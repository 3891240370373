import {Link } from "react-router-dom";
import BgImage from "../../assets/img/com-bg.webp";
import ThumbImage from "../../assets/img/about-img2.png";


function AstramSoftwareTechnology({data,language}) {
  return (
   
    <div className="about-us pt-5 pb-3 my-md-5 my-4" style={{ 
        backgroundImage: `url(${BgImage})` 
      }}>
    <div className="container">
    {language=='ENGLISH' &&
                    <h2 className="text-center mb-5 f600">ASTRAM Software <span>Technology</span></h2>
                    }
                    {language=='GERMAN' &&
                     <h2 className="text-center mb-5 f600">ASTRAM-Software <span>Technology</span></h2>
                    }
       
        <div className="row">
            <div className="col md-6">
                <img src={ data?.astram_software_technology_home_page[0]?.image} width="578" height="523" alt={ data?.astram_software_technology_home_page[0]?.title}/>
            </div>
            {data?.astram_software_technology_home_page[0]?.additional_data?.listing &&

                data?.astram_software_technology_home_page[0]?.additional_data?.listing.map((rowBpc, indexBpc) => (
            <div className="col-md-6 about-us-intro">
                <h3>{rowBpc?.title}</h3>
                <div className="about-us-intro-text cms">
                    <p>{rowBpc?.description}</p>
                </div>
              {language=='ENGLISH' &&
                <Link className="btn btn-bg-main-light f500" to="/technology-hub">Discover More</Link>    
              }  
              {language=='GERMAN' &&
                <Link className="btn btn-bg-main-light f500" to="/technology-hub">Mehr lesen</Link>
              }           
            </div>
            ))
          }
        </div>
    </div>
   </div>
  );
}

export default AstramSoftwareTechnology;
