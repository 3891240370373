

//import ClientLogo1 from "../../assets/img/client-logo1.png";
//import ClientLogo2 from "../../assets/img/client-logo2.png";
//import ClientLogo3 from "../../assets/img/client-logo3.png";
//import ClientLogo4 from "../../assets/img/client-logo4.png";
//import ClientLogo5 from "../../assets/img/client-logo5.png";

function AboutClientTestimonials({data}) {
  return (
   <>
   {data?.about_partner_home_page &&
    <div className="about-client py-lg-5 pb-5">
        <div className="container px-lg-3 px-0">
            <div className="about-client--slide d-lg-flex flex-wrap justify-content-lg-center">
            {data?.about_partner_home_page.map((row, index) => (
                <div className="about-client-col">
                    <img src={row?.image} alt=""/>
                </div>
))}
               
            </div>
        </div>
    </div>
    }
    </>
  );
}

export default AboutClientTestimonials;
