function AboutTeam({data}) {
  return (
   
    <>
     {data?.astram_team_home_page &&
        <div id="team" class="about-team py-5">
        <div class="text-center pb-3 pb-md-4 mb-lg-2">
            <div class="hdn-bg hdn-bg-gry">
                <h2 class="d-inline-block bg-white px-lg-5 px-4 mb-0 position-relative f600">ASTRAM <span>Team</span></h2>
            </div>
        </div>
        <div class="container pb-lg-5 pt-lg-3 pb-lg-5">
            <div class="row align-items-center">
                <div class="col-md-6">
                    <img src={data?.astram_team_home_page[0]?.image} width={724} height={406} alt={data?.astram_team_home_page[0]?.alt}/>
                </div>
                <div class="col-md-6 cms pt-4 pt-md-0 text-md-left text-center" dangerouslySetInnerHTML={{__html: data?.astram_team_home_page[0]?.description}}>
                    
                </div>
            </div>
        </div>
    </div>
    }
    </>
  );
}
export default AboutTeam;
