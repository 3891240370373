
import {Link } from "react-router-dom";
function OurSolutionsAndBusiness({data,language}) {
  return (
   
    <>
        {data?.our_solution_business_areas_home_page &&
        <div className="bussineSol-section py-4 py-lg-5 my-md-4">
            <div className="text-center pb-3 pb-md-4 mb-lg-2">
                <div className="hdn-bg hdn-bg-gry">
                    {language=='ENGLISH' &&
                    <h2 className="d-inline-block bg-white px-lg-5 px-4 mb-0 position-relative f600">Our Solution & Business <span>areas</span></h2>
                    }
                    {language=='GERMAN' &&
                    <h2 className="d-inline-block bg-white px-lg-5 px-4 mb-0 position-relative f600">Unsere Lösungen & <span>Geschäftsbereiche</span></h2>
                    }
                    </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-7 cms text-center mx-auto">
                        <p dangerouslySetInnerHTML={{__html:data?.our_solution_business_areas_home_page[0]?.description }}></p>
                       
                    
                    {language=='ENGLISH' &&
                    <Link className="btn btn-sm btn-bg-main-light f500" to="/our-solutions-and-business">Read More</Link>
                    }

                    {language=='GERMAN' &&
                      <Link className="btn btn-sm btn-bg-main-light f500" to="/our-solutions-and-business">Mehr lesen</Link>
                    }
                    
                    
                    </div>
                </div>
            </div>
        </div>
        }
    </>
  );
}

export default OurSolutionsAndBusiness;
