import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Nav from "../layouts/header";
import Footer from "../layouts/footer";
import InnerTopBanner from "../components/inner-page-banner/ban-08";
import BlogDetailsContent from "../components/blog-details";
import { sanitizeTitle } from "../utils";

const apiUrl = process.env.REACT_APP_API_URL;

const BlogDetails = () => {
	const [language, setLanguage] = useState("ENGLISH");
	const [blogData, setBlogData] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const location = useLocation();
	const slug = location.pathname;
	console.log(slug);

	useEffect(() => {
		var languageH = localStorage.getItem("language");
		if (languageH !== undefined && languageH !== null && languageH !== "") {
			setLanguage(languageH);
		}
		fetchData();
	}, [slug]);

	const fetchData = async () => {
		try {
			const response = await fetch(`${apiUrl}/get-post${slug}`);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			if (data) {
				setBlogData(data);
			} else {
				throw new Error("No data returned");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};
	const metaTitle =
		blogData !== null
			? language === "ENGLISH"
				? blogData.metaTitle || blogData.title
				: blogData.germanMetaTitle || blogData.germanTitle
			: "";
	const sanitizedTitle = sanitizeTitle(metaTitle);

	return (
		<>
			<Helmet>
				<title>ASTRAM - {sanitizedTitle || "Loading"}</title>
				<meta
					name="description"
					content={
						blogData !== null
							? language === "ENGLISH"
								? blogData.metaDescription
								: blogData.germanMetaDescription
							: ""
					}
				/>
				<meta
					name="keywords"
					content={
						blogData !== null
							? language === "ENGLISH"
								? blogData.metaKeywords
								: blogData.germanMetaKeywords
							: "Loading"
					}
				/>
			</Helmet>
			{/* Header Navigation */}
			<Nav language={language} data={blogData} />
			{/* end Header Navigation */}
			<InnerTopBanner language={language} data={blogData} />
			<BlogDetailsContent
				language={language}
				data={blogData}
				isLoading={isLoading}
				error={error}
			/>

			{/* Footer Navigation */}
			<Footer language={language} data={blogData} />
			{/* end Footer Navigation */}
		</>
	);
};

export default BlogDetails;
