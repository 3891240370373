import {Link } from "react-router-dom";
//import industryImage01 from "../../../assets/img/industry.webp";


function OurSolutionsAndBusiness({data,language}) {
  return (
   
    <>

    {(data?.my_industry_home_page ) &&
    <div className="industryApplication-slider mb-5">
        {data?.my_industry_home_page && data?.my_industry_home_page.slice(0, 4).map((row, index) => (
        <div className="industryApplication-col">
          <img src={row?.image} alt="" width={570} height={316} />
          
          <div className="industryApplication-col-text">
            <h3 className="f600 text-uppercase">{row?.title}</h3>
            <p dangerouslySetInnerHTML={{__html: row?.description}}></p>
          

            {language=='ENGLISH' &&
             <Link className="btn btn-bg-main-light f500" to={"/"+row?.cmsSlug}>Read More</Link>
            }

            {language=='GERMAN' &&
              <Link className="btn btn-bg-main-light f500" to={"/"+row?.cmsSlug}>Mehr lesen</Link>
              
            }
          </div>
        </div>
        ))}
       
      </div>
      }
      </>

  );
}
export default OurSolutionsAndBusiness;
