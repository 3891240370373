import BlogCategories from "../blog-categories";
import BlogRecentPosts from "../blog-recent-posts";
import BlogTags from "../blog-tags";

function BlogSidebar({ data, language, isLoading, error }) {

	return (
		<>
			<div className="sidebar">
				<BlogRecentPosts />

				<BlogCategories />

				<BlogTags />
			</div>
		</>
	);
}
export default BlogSidebar;
