import React, { useState } from "react";
import { Helmet } from "react-helmet";
import Nav from "../layouts/header";
import Footer from "../layouts/footer";
import InnerTopBanner from "../components/inner-page-banner/ban-09";
import { Link } from "react-router-dom";

function NotFound() {
	const [language, setLanguage] = useState("ENGLISH");
	const [dataSource, setDataSource] = useState(null);
	return (
		<>
			<Helmet>
				<title>
					ASTRAM ||{" "}
					{language === "ENGLISH" ? "Page not found" : "Seite nicht gefunden"}
				</title>
			</Helmet>
			{/* Header Navigation */}
			<Nav language={language} data={dataSource} />
			{/* end Header Navigation */}
			<InnerTopBanner language={language} data={dataSource} />
			<div class="about-page">
				<div class="container">
					<div className="pt-md-5 pt-4 pb-3 text-center">
						<h2 className="f600">
							404 - <span>Page Not Found</span>
						</h2>
						<p>Sorry, the page you are looking for does not exist.</p>
						<p>
							Go back to the <Link to="/">home page</Link>.
						</p>
					</div>
				</div>
			</div>

			{/* Footer Navigation */}
			<Footer language={language} data={dataSource} />
			{/* end Footer Navigation */}
		</>
	);
}
export default NotFound;
