import SkeletonLoader from "../skeleton-loader";
import NotFound from "../../pages/NotFound";
import BlogSidebar from "../blog-sidebar";
import { formatLongDate } from "../../utils";

function BlogDetailsContent({ data, language, isLoading, error }) {

	return (
		<>
			<section id="blog" className="blog pt-md-5 py-4">
				<div
					className="container pt-lg-3"
					data-aos="fade-up"
					data-aos-delay="100"
				>
					<div className="row g-5">
						<div className="col-lg-8">
							{isLoading ? (
								<SkeletonLoader />
							) : error || !data ? (
								<NotFound />
							) : (
								<>
									<article className="blog-details">
										<div className="post-img">
											<img src={data.picture} alt="" className="img-fluid" />
										</div>
										{language === "ENGLISH" && (
											<h2 className="title">{data.title}</h2>
										)}
										{language === "GERMAN" && (
											<h2 className="title">{data.germanTitle}</h2>
										)}

										<div className="meta-top">
											<ul>
												<li className="d-flex align-items-center">
													<i className="bi bi-person"></i> {data.author}
												</li>
												<li className="d-flex align-items-center">
													<i className="bi bi-clock"></i>{" "}
													<time datetime={data.createDate}>
														{formatLongDate(data.createDate)}
													</time>
												</li>
											</ul>
										</div>

										{language === "ENGLISH" && (
											<div
												className="content"
												dangerouslySetInnerHTML={{ __html: data.description }}
											/>
										)}
										{language === "GERMAN" && (
											<div
												className="content"
												dangerouslySetInnerHTML={{
													__html: data.germanDescription,
												}}
											/>
										)}

										<div className="meta-bottom">
											<i className="bi bi-folder"></i>
											<ul className="cats">
												{language === "ENGLISH" && (
													<li>
														<a>{data.category}</a>
													</li>
												)}
												{language === "GERMAN" && (
													<li>
														<a>{data.germanCategory}</a>
													</li>
												)}
											</ul>
											{data.tags.length > 0 && <></>}
											{data.tags.length > 0 && (
												<>
													<i className="bi bi-tags"></i>
													<ul className="tags">
														{data.tags.split(",").map((tag, index) => (
															<li key={index}>
																<a>{tag.trim()}</a>
															</li>
														))}
													</ul>
												</>
											)}
										</div>
									</article>
								</>
							)}
						</div>

						<div className="col-lg-4">
							<BlogSidebar />
						</div>
					</div>
				</div>
			</section>
		</>
	);
}
export default BlogDetailsContent;
