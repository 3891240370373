import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SkeletonLoader from "../skeleton-loader";
import noPostsFoundImg from "../../assets/img/no-posts-found.png";
import { formatLongDate } from "../../utils";

const apiUrl = process.env.REACT_APP_API_URL;

const BlogRecentPosts = () => {
	const [language, setLanguage] = useState("ENGLISH");
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [recentPosts, setRecentPosts] = useState([]);
	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		var languageH = localStorage.getItem("language");
		if (languageH !== undefined && languageH !== null && languageH !== "") {
			setLanguage(languageH);
		}
		fetchPosts();
	}, [searchTerm]);

	const fetchPosts = async () => {
		try {
			const response = await fetch(
				`${apiUrl}/recent-posts?search=${searchTerm}`
			);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			if (data) {
				setRecentPosts(data);
			} else {
				throw new Error("No data returned");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSearchChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleSearchClick = () => {
		setIsLoading(true);
		fetchPosts();
	};

	return (
		<>
			{isLoading ? (
				<div className="py-4">
					<SkeletonLoader />
				</div>
			) : error || typeof recentPosts === "undefined" ? (
				<div className="text-center">
					<img src={noPostsFoundImg} alt="No Posts Found" />
				</div>
			) : (
				<>
					<div className="sidebar-item search-form">
						<h3 className="sidebar-title">Search</h3>
						<form action="" className="mt-3">
							<input
								type="text"
								value={searchTerm}
								onChange={handleSearchChange}
							/>
							<button type="button" onClick={handleSearchClick}>
								<i className="bi bi-search"></i>
							</button>
						</form>
					</div>

					<div className="sidebar-item recent-posts">
						<h3 className="sidebar-title">Recent Posts</h3>

						<div className="mt-3">
							{recentPosts.map((post, index) => (
								<div key={index} className="post-item">
									<img src={post.picture} alt="" />
									<div>
										<h4>
											<Link smooth to={"/" + post?.slug}>
												{language === "ENGLISH" && <>{post?.title}</>}
												{language === "GERMAN" && <>{post?.germanTitle}</>}
											</Link>
										</h4>
										<time datetime={post.createDate}>
											{formatLongDate(post.createDate)}
										</time>
									</div>
								</div>
							))}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default BlogRecentPosts;
