import { Link } from "react-router-dom";
import LocationMapMobImg from "../../assets/img/location-mob.webp";
import LocationMapImg from "../../assets/img/map.png";
import ContacttInfoImg from "../../assets/img/contactinfo-bg.jpg";
import emailjs from "emailjs-com";

//const sgMail = require('@sendgrid/mail');
//sgMail.setApiKey('fghfjkhglkgyuiyuoyuopyp');

function ContactForm({ data, language }) {
	const config = {
		Host: "smtp.chinmoyc00.com",
		Username: "chinmoyc000",
		Password: "",
		To: "chinmoyc000@gmail.com",
		From: "you@isp.com",
		Subject: "This is the subject",
		Body: "And this is the body",
	};

	const sendEmailSendgrid = async () => {
		//  alert(JSON.stringify(fields));
		/* const msg = {
        to: 'test@example.com',
        from: 'test@example.com', // Use the email address or domain you verified above
        subject: 'Sending with Twilio SendGrid is Fun',
        text: 'and easy to do anywhere, even with Node.js',
        html: '<strong>and easy to do anywhere, even with Node.js</strong>',
      };

        sgMail
        .send(msg)
        .then(() => {}, error => {
            console.error(error);

            if (error.response) {
            console.error(error.response.body)
            }
        });*/
	};

	const sendEmail = async (e) => {
		//alert('ok');
		e.preventDefault(); //This is important, i'm not sure why, but the email won't send without it
		emailjs
			.sendForm(
				"service_05jewpj",
				"template_144kb2e",
				e.target,
				"SIwbX5kQZvnWBlBGc"
			)
			.then(
				(result) => {
					window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
				},
				(error) => {
					console.log(error.text);
				}
			);
	};

	return (
		<>
			{data?.contact_us_content_contact_us_page && (
				<div className="contact-page">
					<div className="container">
						<div className="pt-md-5 pt-4 pb-3 text-center">
							{language == "ENGLISH" ? (
								<h2 className="f600 mb-0">
									Please contact us with any <span>Questions.</span>
								</h2>
							) : (
								<h2 className="f600 mb-0">
									Bitte kontaktieren Sie uns bei <span>Fragen.</span>
								</h2>
							)}
							{language == "ENGLISH" ? (
								<p
									className="fxl-lg fxl"
									dangerouslySetInnerHTML={{
										__html:
											data?.contact_us_content_contact_us_page[0]?.description,
									}}
								></p>
							) : (
								<p className="fxl-lg fxl">
									Wir werden Ihnen zeitnah antworten. Wir freuen uns auch über
									Ihr Feedback.
								</p>
							)}
						</div>
						<div className="row pb-5">
							<div className="col-lg-7 col-md-6">
								<div className="contact-form">
									{language == "ENGLISH" && (
										<h3 className="fxxl f600">
											Contact <span>Form</span>
										</h3>
									)}

									{language == "GERMAN" && (
										<h3 className="fxxl f600">
											Kontakt<span>formular</span>
										</h3>
									)}

									<form className="form row mb-0" onSubmit={sendEmailSendgrid}>
										<div className="col-12 col-lg-6 col-md-12 col-sm-6 mb-2">
											<div className="form-group">
												{language == "ENGLISH" ? (
													<label className="fmd-lg fmd" for="name">
														Name: <span>*</span>
													</label>
												) : (
													<label className="fmd-lg fmd" for="name">
														Name: <span>*</span>
													</label>
												)}
												<input
													type="text"
													name="from_name"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-md-12 col-sm-6 mb-2">
											<div className="form-group">
												<label className="fmd-lg fmd" for="email">
													Email: <span>*</span>
												</label>
												<input
													type="email"
													name="from_email"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-md-12 col-sm-6 mb-2">
											<div className="form-group">
												{language == "ENGLISH" ? (
													<label className="fmd-lg fmd" for="tel">
														Phone:
													</label>
												) : (
													<label className="fmd-lg fmd" for="name">
														Telefon: <span>*</span>
													</label>
												)}

												<input
													type="tel"
													name="contact_number"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-12 col-lg-6 col-md-12 col-sm-6 mb-2">
											<div className="form-group">
												{language == "ENGLISH" ? (
													<label className="fmd-lg fmd" for="company">
														Company:
													</label>
												) : (
													<label className="fmd-lg fmd" for="company">
														Unternehmen:
													</label>
												)}
												<input
													type="text"
													name="company"
													className="form-control"
												/>
											</div>
										</div>
										<div className="col-12">
											<div className="form-group">
												{language == "ENGLISH" ? (
													<label className="fmd-lg fmd" for="msg">
														Message: <span>*</span>
													</label>
												) : (
													<label className="fmd-lg fmd" for="msg">
														Nachricht: <span>*</span>
													</label>
												)}
												<textarea
													name="message"
													cols="30"
													rows="10"
													className="form-control"
												></textarea>
											</div>
										</div>
										<div className="col-12 mt-4">
											{language == "ENGLISH" ? (
												<button
													type="submit"
													className="btn btn-sm btn-bg-main f500"
												>
													Submit
												</button>
											) : (
												<button
													type="submit"
													className="btn btn-sm btn-bg-main f500"
												>
													Einreichen
												</button>
											)}
										</div>
									</form>
								</div>
							</div>
							<div className="col-lg-5 col-md-6 mt-4 mt-md-0">
								<div
									className="contact-info position-relative p-5"
									style={{ "--background": `url(${ContacttInfoImg})` }}
								>
									<div className="contact-info-icon position-relative">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											xmlnsXlink="http://www.w3.org/1999/xlink"
											height="800px"
											width="800px"
											version="1.1"
											id="Capa_1"
											viewBox="0 0 58.064 58.064"
											xmlSpace="preserve"
										>
											<polygon
												style={{ fill: "#E7ECED" }}
												points="22,30.532 0,23.324 58.064,7.532 36,50.532 "
											/>
											<polygon
												style={{ fill: "#AFB6BB" }}
												points="22,30.532 20,46.532 28.594,39.952 "
											/>
											<path
												style={{ fill: "#AFB6BB" }}
												d="M21.462,29.689c-0.203,0.129-0.329,0.324-0.398,0.536L22,30.532l0.574,0.82L57.189,9.237l0.875-1.705  l-3.345,0.91L21.462,29.689z"
											/>
										</svg>
									</div>
									<div className="text-center fxxl-lg fxxl fwhite position-relative mb-2">
										{
											data?.contact_us_content_contact_us_page[0]
												?.additional_data?.email[0]?.title
										}
										:{" "}
										<Link
											className="fwhite"
											to={
												"mailto:" +
												data?.contact_us_content_contact_us_page[0]
													?.additional_data?.email[0]?.description
											}
										>
											{
												data?.contact_us_content_contact_us_page[0]
													?.additional_data?.email[0]?.description
											}
										</Link>
									</div>

									{language == "ENGLISH" ? (
										<h4 className="f400 text-center position-relative mb-2">
											Call Now
										</h4>
									) : (
										<h4 className="f400 text-center position-relative mb-2">
											Jetzt anrufen
										</h4>
									)}

									<Link
										className="h2 fwhite f500 text-center d-block position-relative mb-0"
										to={
											"tel:" +
											data?.contact_us_content_contact_us_page[0]
												?.additional_data?.Phone[0]?.description
										}
									>
										{" "}
										{
											data?.contact_us_content_contact_us_page[0]
												?.additional_data?.Phone[0]?.description
										}
									</Link>

									{language == "ENGLISH" ? (
										<h4 className="f400 text-center position-relative mb-2">
											OR
										</h4>
									) : (
										<h4 className="f400 text-center position-relative mb-2">
											tel. no.:
										</h4>
									)}
									<Link
										className="h2 fwhite f500 text-center d-block position-relative mb-0"
										to={"tel:+49 (0) 2461690380"}
									>
										{" "}
										{"+49 (0) 2461690380"}
									</Link>
								</div>
							</div>
						</div>
					</div>
					<div className="contact-location py-md-5 py-4 text-center">
						<picture className="container px-0 px-md-3 d-flex">
							<source media="(min-width:768px)" srcset={LocationMapImg} />
							<img
								className="w-100"
								src={LocationMapMobImg}
								alt="map"
								loading="lazy"
								width="590"
								height="579"
							/>
						</picture>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3589.8917586544317!2d28.12595637540412!3d-25.87303967728212!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95658754770f89%3A0xd8e92edd6adcbc9!2sAstram%20Technologies%20(PTY)%20Ltd!5e0!3m2!1sen!2sin!4v1702881725193!5m2!1sen!2sin" width="600" height="450" style={{width: '100%', border: '0'}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
					</div>
				</div>
			)}
		</>
	);
}
export default ContactForm;
