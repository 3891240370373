import React, { useState, useEffect } from "react";
import Nav from "../layouts/header";
import Footer from "../layouts/footer";
import InnerTopBanner from "../components/inner-page-banner/ban-07";
import BlogListing from "../components/blog-listing";

import { Helmet } from "react-helmet";
import NotFound from "./NotFound";
import SkeletonLoader from "../components/skeleton-loader";
const apiUrl = process.env.REACT_APP_API_URL;

function Blog() {
	const [language, setLanguage] = useState("ENGLISH");
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		let languageH = localStorage.getItem("language");
		if (languageH !== undefined && languageH !== null && languageH !== "") {
			setLanguage(languageH);
		}

		fetchData(currentPage);
	}, [currentPage]);

	const fetchData = async (page) => {
		try {
			const response = await fetch(`${apiUrl}/list-posts?page=${page}`);
			if (!response.ok) {
				throw new Error(`HTTP error! Status: ${response.status}`);
			}
			const data = await response.json();
			if (data) {
				setPosts(data.data);
                setCurrentPage(data.current_page);
                setTotalPages(data.last_page); // Update state with the fetched data
			} else {
				throw new Error("No data returned");
			}
		} catch (err) {
			setError(err.message);
		} finally {
			setIsLoading(false);
		}
	};

	const handlePageChange = (page) => {
		setIsLoading(true);
        setCurrentPage(page);
    };

	if (error || !posts) {
		return <NotFound />;
	}

	return (
		<>
			<Helmet>
				<title>ASTRAM - Blog</title>
			</Helmet>
			{/* Header Navigation */}
			<Nav language={language} data={posts} />
			{/* end Header Navigation */}
			<InnerTopBanner language={language} data={posts} />
			{/* Footer Navigation */}
			<BlogListing
				language={language}
				isLoading={isLoading}
				posts={posts}
				currentPage={currentPage}
				totalPages={totalPages}
				onPageChange={handlePageChange}
			/>
			<Footer language={language} data={posts} />
			{/* end Footer Navigation */}
		</>
	);
}

export default Blog;
