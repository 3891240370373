import {Link } from "react-router-dom";

function InnerBanner({data,language}) {
  return (
    <>
      {data?.inner_top_banner_industry_application_page &&
      <div class="banner banner-inner" style={{ 
        backgroundImage: `url(${data?.inner_top_banner_industry_application_page[0]?.image})` 
      }}>
      <div class="container d-flex flex-wrap py-md-4 pt-4 pb-0">
          <div class="page-title ms-md-auto me-md-0 mx-auto d-inline-block text-md-right text-center py-3 w-100">
             
              {language=='ENGLISH' ?(
              <h1 class="f600 mb-0">{/*data?.inner_top_banner_industry_application_page[0]?.title*/}Our Solutions & Business <span>Areas</span></h1>
              ):(
                <h1 class="f600 mb-0">{/*data?.inner_top_banner_industry_application_page[0]?.title*/}Unsere Lösungen &  <span>Geschäftsfelder</span></h1>  
              )}
              </div>
          <ul class="nav-breadcrumbs d-flex flex-wrap">
          {language=='ENGLISH' ?(
              <li><Link class="fdark" to="/">Home</Link></li>
              ):(
                <li><Link class="fdark" to="/">Home</Link></li>
              )}
               {language=='ENGLISH' ?(
              <li><span class="fmain">{data?.inner_top_banner_industry_application_page[0]?.title}</span></li>
              ):( <li><span class="fmain">Unsere Lösungen & Geschäftsfelder</span></li>)}
          </ul>
      </div>
      </div>
     }
    </>
  );
}
export default InnerBanner;
