
import AboutImg1 from "../../assets/img/about-img2.png";
function AboutContent({data}) {
  return (
   
    <>
    {(data?.about_us_content_about_us_page || data?.about_us_block_home_page  ) &&
    <div class="about-page">
        <div class="container">
            <div class="about-page-intro text-center pt-md-5 pt-4 pb-3 cms">
                <p dangerouslySetInnerHTML={{__html: data?.about_us_content_about_us_page[0]?.description}}></p>
                 
            </div>

            <div class="about-page-context">
            {data?.about_us_block_home_page.map((row, index) => (
                <div id={"s"+index} class="row mb-4 mx-md-0 about-page-row align-items-md-center">
                    <div class="col-md-5 about-page-img px-0">
                        <img src={row?.image?row?.image:AboutImg1} width={539} height={344} alt={row?.alt}/>
                    </div>
                    <div class="col-md-7 cms py-md-4">
                        <h3 class="fxxl fxxl-lg f600">{row?.title}</h3>
                        <p dangerouslySetInnerHTML={{__html: row?.description}}></p>
                    </div>
                </div>
            ))}
            </div>
        </div>
    </div>
}
    </>
  );
}
export default AboutContent;
