


import React , { useState,useEffect} from 'react';
import Nav from "../layouts/header";
import Footer from "../layouts/footer";
import InnerTopBanner from "../components/inner-page-banner/ban-02";
//import OurSolutionsAndBusiness from "../components/industry-section";
import OurSolutionsAndBusiness from "../components/our-solutions-and-business/module-01";


import AboutClient from "../components/about-client-testimonials";
import {Helmet} from "react-helmet";



function OurSolutions() {

    const [dataSourceEn, setDataSourceEn] = useState({
        "pageName": "Industry Page",
        "metaTitle": "Equipment Maintenance Management Software India, South Africa, Germany",
        "metaDescription": "Optimize equipment maintenance with ASTRAM's software. Serving Pretoria, Johannesburg, Cape Town, Durban, Bengaluru, Chennai, Hyderabad, Pune, Mumbai, Kolkata, Berlin, Munich, Frankfurt, Hamburg, Cologne, Düsseldorf, and more.",
        "metaKeyword": "Equipment Management, Quality Control System, Asset Lifecycle Management, NDT Inspection Software, Inventory Management, Compliance Management, Data Analytics, Inspection Automation, Preventive Maintenance",
        "our_solutions_and_business_areas_title_industry_applications_page": [
            {
                "cmsId": "72",
                "cmsSlug": null,
                "pageId": "6",
                "sectionId": "32",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Our Business Areas and Industry application",
                "description": "<p>Our Business Areas and Industry application</p>",
                "description2": "<p>Our Business Areas and Industry application</p>",
                "image": "",
                "iconClass": "",
                "heading": "Our Business Areas and Industry application",
                "alt": "Our Business Areas and Industry application",
                "additional_data": []
            }
        ],
        "my_industry_home_page": [
            {
                "cmsId": "38",
                "cmsSlug": "nuclear",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Nuclear",
                "description": "<p>Nuclear Power plants can implement ASTRAM to manage assets, control quality, manage risk, facilitate audits and manage training and certification. By using ASTRAM in the nuclear industry, companies can improve safety, reduce costs, and increase efficiency.</p> ",
                "description2": "<p>Nuclear Power plants can implement ASTRAM to manage assets, control quality, manage risk, facilitate audits and manage training and certification. By using ASTRAM in the nuclear industry, companies can improve safety, reduce costs, and increase efficiency.</p> ",
                "image": require('../assets/img/cms/content/01.png'),
                "iconClass": "",
                "heading": "Nuclear",
                "alt": "Nuclear",
                "additional_data": []
            },
            {
                "cmsId": "39",
                "cmsSlug": "power-plants-and-utilities",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Power Plants and Utilities",
                "description": "<p>ASTRAM can be helpful in all kinds of power plants and energy installations by managing maintenance, environmental compliance, safety, production optimization, and asset management. By using ASTRAM in power plants and energy installations, companies can improve safety, reduce downtime, extend the lifespan of equipment, and optimize production.</p>",
                "description2": "<p>ASTRAM can be helpful in all kinds of power plants and energy installations by managing maintenance, environmental compliance, safety, production optimization, and asset management. By using ASTRAM in power plants and energy installations, companies can improve safety, reduce downtime, extend the lifespan of equipment, and optimize production.</p>",
                "image": require('../assets/img/cms/content/02.png'),
                "iconClass": "",
                "heading": "Power Plants and Utilities",
                "alt": "Power Plants and Utilities",
                "additional_data": []
            },
            {
                "cmsId": "40",
                "cmsSlug": "mining",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Mining",
                "description": "<p>ASTRAM can be helpful in the mining sector by managing asset, safety, and environmental compliance, optimizing production, and managing the supply chain. By using ASTRAM in mining operations, companies can improve safety, reduce downtime, optimize production, and manage the supply chain more effectively.</p>",
                "description2": "<p>ASTRAM can be helpful in the mining sector by managing asset, safety, and environmental compliance, optimizing production, and managing the supply chain. By using ASTRAM in mining operations, companies can improve safety, reduce downtime, optimize production, and manage the supply chain more effectively.</p>",
                "image": require('../assets/img/cms/content/03.png'),
                "iconClass": "",
                "heading": "Mining",
                "alt": "Mining",
                "additional_data": []
            },
            {
                "cmsId": "41",
                "cmsSlug": "pharmaceutical",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "     ",
                "metaKeyword": "     ",
                "title": "Pharmaceutical",
                "description": "<p>ASTRAM can be used in the pharmaceutical sector to manage quality control, compliance, inventory, clinical trials, and production optimization. By using ASTRAM in the pharmaceutical industry, companies can improve quality, reduce compliance risk, optimize inventory management, improve clinical trial processes, and improve production efficiency.</p>",
                "description2": "<p>ASTRAM can be used in the pharmaceutical sector to manage quality control, compliance, inventory, clinical trials, and production optimization. By using ASTRAM in the pharmaceutical industry, companies can improve quality, reduce compliance risk, optimize inventory management, improve clinical trial processes, and improve production efficiency.</p>",
                "image": require('../assets/img/cms/content/04.png'),
                "iconClass": "",
                "heading": "Pharmaceutical",
                "alt": "Pharmaceutical",
                "additional_data": []
            },
            {
                "cmsId": "107",
                "cmsSlug": "laboratories",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Laboratories",
                "description": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
                "description2": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
                "image": require('../assets/img/cms/content/Laboratory_Management1.png'),
                "iconClass": "",
                "heading": "Laboratories",
                "alt": "Laboratories",
                "additional_data": []
            },
            {
                "cmsId": "108",
                "cmsSlug": "international-trade",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "International Trade",
                "description": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions.</p>",
                "description2": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions.</p>",
                "image": require('../assets/img/cms/content/International_Trade1.png'),
                "iconClass": "",
                "heading": "International Trade",
                "alt": "International Trade",
                "additional_data": []
            },
            {
                "cmsId": "109",
                "cmsSlug": "accreditation-bodies",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Accreditation Bodies",
                "description": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
                "description2": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
                "image": require('../assets/img/cms/content/Accreditation_Management2.png'),
                "iconClass": "",
                "heading": "Accreditation Bodies",
                "alt": "Accreditation Bodies",
                "additional_data": []
            },
            {
                "cmsId": "110",
                "cmsSlug": "oil-and-gas-and-petrochemical",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Oil and Gas and Petrochemical",
                "description": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
                "description2": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
                "image": require('../assets/img/cms/content/Oil_and_Gas_and_Petrochemical.png'),
                "iconClass": "",
                "heading": "Oil and Gas and Petrochemical",
                "alt": "Oil and Gas and Petrochemical",
                "additional_data": []
            },
            {
                "cmsId": "111",
                "cmsSlug": "manufacturing",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Manufacturing",
                "description": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
                "description2": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
                "image": require('../assets/img/cms/content/Manufacturing.png'),
                "iconClass": "",
                "heading": "Manufacturing",
                "alt": "Manufacturing",
                "additional_data": []
            },
            {
                "cmsId": "112",
                "cmsSlug": "health-and-safety",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "Health and Safety",
                "description": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
                "description2": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
                "image": require('../assets/img/cms/content/Health_and_Safety_Management1.png'),
                "iconClass": "",
                "heading": "Health and Safety",
                "alt": "Health and Safety",
                "additional_data": []
            },
            {
                "cmsId": "113",
                "cmsSlug": "hr-management",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "    ",
                "metaKeyword": "    ",
                "title": "HR management",
                "description": "<p>Astram can be used for HR management in a variety of ways. Here are some examples.</p>",
                "description2": "<p>Astram can be used for HR management in a variety of ways. Here are some examples.</p>",
                "image": require('../assets/img/cms/content/HR_Management2.png'),
                "iconClass": "",
                "heading": "HR management",
                "alt": "HR management",
                "additional_data": []
            },
            {
                "cmsId": "114",
                "cmsSlug": "environmental-management",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Environmental Management",
                "description": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
                "description2": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
                "image": require('../assets/img/cms/content/Environmental_Management2.png'),
                "iconClass": "",
                "heading": "Environmental Management",
                "alt": "Environmental Management",
                "additional_data": []
            },
            {
                "cmsId": "115",
                "cmsSlug": "inspection-and-testing-companies",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Inspection and Testing Companies",
                "description": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
                "description2": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
                "image": require('../assets/img/cms/content/Inspection_and_Testing1.png'),
                "iconClass": "",
                "heading": "Inspection and Testing Companies",
                "alt": "Inspection and Testing Companies",
                "additional_data": []
            },
            {
                "cmsId": "116",
                "cmsSlug": "food-and-beverage",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "      ",
                "metaKeyword": "      ",
                "title": "Food and Beverage",
                "description": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products.</p>",
                "description2": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products.</p>",
                "image": require('../assets/img/cms/content/Food_and_Beverage.png'),
                "iconClass": "",
                "heading": "Food and Beverage",
                "alt": "Food and Beverage",
                "additional_data": []
            },
            {
                "cmsId": "117",
                "cmsSlug": "government-and-regulatory-bodies",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Government and Regulatory Bodies",
                "description": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
                "description2": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
                "image": require('../assets/img/cms/content/Governance_and_Regulatory_Compliance2.png'),
                "iconClass": "",
                "heading": "Government and Regulatory Bodies",
                "alt": "Government and Regulatory Bodies",
                "additional_data": []
            },
            {
                "cmsId": "118",
                "cmsSlug": "transportation-and-logistics",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Transportation and Logistics",
                "description": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
                "description2": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
                "image": require('../assets/img/cms/content/Transportation_and_Logistics1.png'),
                "iconClass": "",
                "heading": "Transportation and Logistics",
                "alt": "Transportation and Logistics",
                "additional_data": []
            },
            {
                "cmsId": "119",
                "cmsSlug": "construction",
                "pageId": "6",
                "sectionId": "19",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Construction",
                "description": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
                "description2": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
                "image": require('../assets/img/cms/content/Construction.png'),
                "iconClass": "",
                "heading": "Construction",
                "alt": "Construction",
                "additional_data": []
            }
        ],
        "about_partner_home_page": [
            {
                "cmsId": "57",
                "cmsSlug": null,
                "pageId": "6",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 1 ",
                "description": "<p>Partner 1&nbsp;</p>",
                "description2": "<p>Partner 1&nbsp;</p>",
                "image": require('../assets/img/cms/content/client-logo1.png'),
                "iconClass": "",
                "heading": "Partner 1 ",
                "alt": "Partner 1 ",
                "additional_data": []
            },
            {
                "cmsId": "58",
                "cmsSlug": null,
                "pageId": "6",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": "   ",
                "metaKeyword": "   ",
                "title": "Partner 2",
                "description": "<p>Partner 2</p>",
                "description2": "<p>Partner 2</p>",
                "image": require('../assets/img/cms/content/client-logo2.png'),
                "iconClass": "",
                "heading": "Partner 2",
                "alt": "Partner 2",
                "additional_data": []
            },
            {
                "cmsId": "59",
                "cmsSlug": null,
                "pageId": "6",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 3",
                "description": "<p>Partner 3</p>",
                "description2": "<p>Partner 3</p>",
                "image": require('../assets/img/cms/content/client-logo3.png'),
                "iconClass": "",
                "heading": "Partner 3",
                "alt": "Partner 3",
                "additional_data": []
            },
            {
                "cmsId": "60",
                "cmsSlug": null,
                "pageId": "6",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 4",
                "description": "<p>Partner 4</p>",
                "description2": "<p>Partner 4</p>",
                "image": require('../assets/img/cms/content/client-logo4.png'),
                "iconClass": "",
                "heading": "Partner 4",
                "alt": "Partner 4",
                "additional_data": []
            },
            {
                "cmsId": "61",
                "cmsSlug": null,
                "pageId": "6",
                "sectionId": "26",
                "metaTitle": "",
                "metaDescriptions": " ",
                "metaKeyword": " ",
                "title": "Partner 5",
                "description": "<p>Partner 5</p>",
                "description2": "<p>Partner 5</p>",
                "image": require('../assets/img/cms/content/client-logo5.png'),
                "iconClass": "",
                "heading": "Partner 5",
                "alt": "Partner 5",
                "additional_data": []
            }
        ],
        "inner_top_banner_industry_application_page": [
            {
                "cmsId": "88",
                "cmsSlug": null,
                "pageId": "6",
                "sectionId": "41",
                "metaTitle": "",
                "metaDescriptions": "  ",
                "metaKeyword": "  ",
                "title": "Our Solutions & Business Areas",
                "description": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
                "description2": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
                "image": require('../assets/img/cms/content/about-banner1.jpg'),
                "iconClass": "",
                "heading": "Our Solutions & Business Areas",
                "alt": "Our Solutions & Business Areas",
                "additional_data": []
            }
        ]
    });
const [dataSourceDe, setDataSourceDe] = useState({
    "pageName": "Industry Page",
    "metaTitle": "Unsere Lösungen & Geschäftsfelder",
    "metaDescription": "   Unsere Lösungen & Geschäftsfelder",
    "metaKeyword": "   Unsere Lösungen & Geschäftsfelder",
    "our_solutions_and_business_areas_title_industry_applications_page": [
        {
            "cmsId": "72",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "32",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Our Business Areas and Industry application",
            "description": "<p>Our Business Areas and Industry application</p>",
            "description2": "<p>Our Business Areas and Industry application</p>",
            "image": "",
            "iconClass": "",
            "heading": "Our Business Areas and Industry application",
            "alt": "Our Business Areas and Industry application",
            "additional_data": []
        }
    ],
    "my_industry_home_page": [
        {
            "cmsId": "38",
            "cmsSlug": "nuclear",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "       ",
            "metaKeyword": "       ",
            "title": "Kerntechnik",
            "description": "<p>Unternehmen in der Kerntechnik k&ouml;nnen ASTRAM-ST implementieren, um Assets zu verwalten, die Qualit&auml;t zu kontrollieren, Risiken zu managen, Audits zu erleichtern und Schulungen und Zertifizierungen zu steuern. Durch den Einsatz von ASTRAM-ST in der Nuklearindustrie k&ouml;nnen Unternehmen die Sicherheit verbessern, Kosten senken und die Effizienz steigern.</p>",
            "description2": "<p>Unternehmen in der Kerntechnik k&ouml;nnen ASTRAM-ST implementieren, um Assets zu verwalten, die Qualit&auml;t zu kontrollieren, Risiken zu managen, Audits zu erleichtern und Schulungen und Zertifizierungen zu steuern. Durch den Einsatz von ASTRAM-ST in der Nuklearindustrie k&ouml;nnen Unternehmen die Sicherheit verbessern, Kosten senken und die Effizienz steigern.</p>",
            "image": require('../assets/img/cms/content/01.png'),
            "iconClass": "",
            "heading": "Kerntechnik",
            "alt": "Kerntechnik",
            "additional_data": []
        },
        {
            "cmsId": "39",
            "cmsSlug": "power-plants-and-utilities",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "Kraftwerke und Versorgungsunternehmen",
            "description": "<p>ASTRAM-ST kann in allen Arten von Kraftwerken und Energieanlagen hilfreich sein, indem es Performance, Umweltkonformit&auml;t, Sicherheit, Qualit&auml;t und Wartung von Assets und Produktionsanlagen verwaltet. Durch den Einsatz von ASTRAM-ST in Kraftwerken undEnergieanlagenk&ouml;nnen Unternehmen die Sicherheit verbessern, Ausfallzeiten reduzieren, die Lebensdauer von Assets verl&auml;ngern und die Produktion optimieren.</p>",
            "description2": "<p>ASTRAM-ST kann in allen Arten von Kraftwerken und Energieanlagen hilfreich sein, indem es Performance, Umweltkonformit&auml;t, Sicherheit, Qualit&auml;t und Wartung von Assets und Produktionsanlagen verwaltet. Durch den Einsatz von ASTRAM-ST in Kraftwerken undEnergieanlagenk&ouml;nnen Unternehmen die Sicherheit verbessern, Ausfallzeiten reduzieren, die Lebensdauer von Assets verl&auml;ngern und die Produktion optimieren.</p>",
            "image": require('../assets/img/cms/content/02.png'),
            "iconClass": "",
            "heading": "Kraftwerke und Versorgungsunternehmen",
            "alt": "Kraftwerke und Versorgungsunternehmen",
            "additional_data": []
        },
        {
            "cmsId": "40",
            "cmsSlug": "mining",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "Bergbau",
            "description": "<p>ASTRAM-ST kann im Bergbausektor hilfreich sein, indem es die Einhaltung der Anlagensicherheit und Umweltvertr&auml;glichkeit unterst&uuml;tzt, die Produktion optimiert und die Lieferkette verwaltet. Durch den Einsatz von ASTRAM-ST im Bergbau k&ouml;nnen Unternehmen die Sicherheit verbessern, Ausfallzeiten reduzieren, die Produktion optimieren und die Lieferkette effektiver verwalten.</p>",
            "description2": "<p>ASTRAM-ST kann im Bergbausektor hilfreich sein, indem es die Einhaltung der Anlagensicherheit und Umweltvertr&auml;glichkeit unterst&uuml;tzt, die Produktion optimiert und die Lieferkette verwaltet. Durch den Einsatz von ASTRAM-ST im Bergbau k&ouml;nnen Unternehmen die Sicherheit verbessern, Ausfallzeiten reduzieren, die Produktion optimieren und die Lieferkette effektiver verwalten.</p>",
            "image": require('../assets/img/cms/content/03.png'),
            "iconClass": "",
            "heading": "Bergbau",
            "alt": "Bergbau",
            "additional_data": []
        },
        {
            "cmsId": "41",
            "cmsSlug": "pharmaceutical",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "       ",
            "metaKeyword": "       ",
            "title": "Pharma-Industrie",
            "description": "<p>ASTRAM-ST kann in der pharmazeutischen Industrie eingesetzt werden, um Qualit&auml;tskontrolle, Compliance, Inventar, klinische Studien und Produktionsoptimierung zu verwalten. Durch den Einsatz von ASTRAM-ST in der pharmazeutischen Industrie k&ouml;nnen Unternehmen die Qualit&auml;t verbessern, das Compliance-Risiko reduzieren, das Bestandsmanagement optimieren, die F&amp;E-Prozesse inkl. Studien verbessern und die Produktionseffizienz verbessern.</p>",
            "description2": "<p>ASTRAM-ST kann in der pharmazeutischen Industrie eingesetzt werden, um Qualit&auml;tskontrolle, Compliance, Inventar, klinische Studien und Produktionsoptimierung zu verwalten. Durch den Einsatz von ASTRAM-ST in der pharmazeutischen Industrie k&ouml;nnen Unternehmen die Qualit&auml;t verbessern, das Compliance-Risiko reduzieren, das Bestandsmanagement optimieren, die F&amp;E-Prozesse inkl. Studien verbessern und die Produktionseffizienz verbessern.</p>",
            "image": require('../assets/img/cms/content/04.png'),
            "iconClass": "",
            "heading": "Pharma-Industrie",
            "alt": "Pharma-Industrie",
            "additional_data": []
        },
        {
            "cmsId": "107",
            "cmsSlug": "laboratories",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Laboratorien",
            "description": "<p>ASTRAM-ST kann Laboratorien dabei helfen, die Effizienz zu verbessern, Fehler zu reduzieren, Vorschriften einzuhalten und die Zusammenarbeit zwischen den Mitarbeitern zu erleichtern. Durch die Implementierung von ASTRAM-ST k&ouml;nnen Labore ihre Prozesse optimieren, das Datenmanagement verbessern und letztendlich bessere Ergebnisse f&uuml;r ihre Kunden liefern.</p>",
            "description2": "<p>ASTRAM-ST kann Laboratorien dabei helfen, die Effizienz zu verbessern, Fehler zu reduzieren, Vorschriften einzuhalten und die Zusammenarbeit zwischen den Mitarbeitern zu erleichtern. Durch die Implementierung von ASTRAM-ST k&ouml;nnen Labore ihre Prozesse optimieren, das Datenmanagement verbessern und letztendlich bessere Ergebnisse f&uuml;r ihre Kunden liefern.</p>",
            "image": require('../assets/img/cms/content/Laboratory_Management1.png'),
            "iconClass": "",
            "heading": "Laboratorien",
            "alt": "Laboratorien",
            "additional_data": []
        },
        {
            "cmsId": "108",
            "cmsSlug": "international-trade",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Handel",
            "description": "<p>ASTRAM-ST kann dazu beitragen, die Effizienz und Transparenz der internationalen Lieferketten zu verbessern und gleichzeitig die mit grenz&uuml;berschreitenden Transaktionen verbundenen Risiken zu verringern und Pflichten bei Im- / Export zu unterst&uuml;tzen.</p>",
            "description2": "<p>ASTRAM-ST kann dazu beitragen, die Effizienz und Transparenz der internationalen Lieferketten zu verbessern und gleichzeitig die mit grenz&uuml;berschreitenden Transaktionen verbundenen Risiken zu verringern und Pflichten bei Im- / Export zu unterst&uuml;tzen.</p>",
            "image": require('../assets/img/cms/content/International_Trade1.png'),
            "iconClass": "",
            "heading": "Handel",
            "alt": "Handel",
            "additional_data": []
        },
        {
            "cmsId": "109",
            "cmsSlug": "accreditation-bodies",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Zertifizierungs-und Akkreditierungsstellen",
            "description": "<p>ASTRAM-ST kann Zertifizierungs- und Akkreditierungsstellen dabei helfen, ihre Abl&auml;ufe zu rationalisieren, die Qualit&auml;t ihrer Dienstleistungen zu verbessern und ihre Gesamteffizienz zu steigern.</p>",
            "description2": "<p>ASTRAM-ST kann Zertifizierungs- und Akkreditierungsstellen dabei helfen, ihre Abl&auml;ufe zu rationalisieren, die Qualit&auml;t ihrer Dienstleistungen zu verbessern und ihre Gesamteffizienz zu steigern.</p>",
            "image": require('../assets/img/cms/content/Accreditation_Management2.png'),
            "iconClass": "",
            "heading": "Zertifizierungs-und Akkreditierungsstellen",
            "alt": "Zertifizierungs-und Akkreditierungsstellen",
            "additional_data": []
        },
        {
            "cmsId": "110",
            "cmsSlug": "oil-and-gas-and-petrochemical",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "Öl und Gas Industrie",
            "description": "<p>ASTRAM-ST kann dem &Ouml;l- und Gassektor helfen, seine betriebliche Effizienz zu verbessern, Kosten zu senken und die Einhaltung gesetzlicher Anforderungen sicherzustellen und gleichzeitig den Sicherheitsstatus zu verbessern und die Umweltbelastung zu reduzieren.</p>",
            "description2": "<p>ASTRAM-ST kann dem &Ouml;l- und Gassektor helfen, seine betriebliche Effizienz zu verbessern, Kosten zu senken und die Einhaltung gesetzlicher Anforderungen sicherzustellen und gleichzeitig den Sicherheitsstatus zu verbessern und die Umweltbelastung zu reduzieren.</p>",
            "image": require('../assets/img/cms/content/Oil_and_Gas_and_Petrochemical.png'),
            "iconClass": "",
            "heading": "Öl und Gas Industrie",
            "alt": "Öl und Gas Industrie",
            "additional_data": []
        },
        {
            "cmsId": "111",
            "cmsSlug": "manufacturing",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "      ",
            "metaKeyword": "      ",
            "title": "Herstellungsindustrie",
            "description": "<p>Produzierende Unternehmen k&ouml;nnen ASTRAM-ST auf verschiedene Weise nutzen, um ihre Abl&auml;ufe zu verbessern und ihre Effizienz zu steigern.</p>",
            "description2": "<p>Produzierende Unternehmen k&ouml;nnen ASTRAM-ST auf verschiedene Weise nutzen, um ihre Abl&auml;ufe zu verbessern und ihre Effizienz zu steigern.</p>",
            "image": require('../assets/img/cms/content/Manufacturing.png'),
            "iconClass": "",
            "heading": "Herstellungsindustrie",
            "alt": "Herstellungsindustrie",
            "additional_data": []
        },
        {
            "cmsId": "112",
            "cmsSlug": "health-and-safety",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "Arbeitssicherheits- und Gesundheitsmanagement",
            "metaDescriptions": "Arbeitssicherheits- und Gesundheitsmanagement   ",
            "metaKeyword": "Arbeitssicherheits- und Gesundheitsmanagement   ",
            "title": "Arbeitssicherheits- und Gesundheitsmanagement",
            "description": "<p>DerEinsatz von ASTRAM-ST in der Gesundheits- und Arbeitssicherheitsbranche kann die Ablaufprozesse verbessern, Vorf&auml;lle und Unf&auml;lle reduzieren und die Einhaltung von Vorsorge- und Sicherheitsvorschriften gew&auml;hrleisten.</p>",
            "description2": "<p>DerEinsatz von ASTRAM-ST in der Gesundheits- und Arbeitssicherheitsbranche kann die Ablaufprozesse verbessern, Vorf&auml;lle und Unf&auml;lle reduzieren und die Einhaltung von Vorsorge- und Sicherheitsvorschriften gew&auml;hrleisten.</p>",
            "image": require('../assets/img/cms/content/Health_and_Safety_Management1.png'),
            "iconClass": "",
            "heading": "Arbeitssicherheits- und Gesundheitsmanagement",
            "alt": "Arbeitssicherheits- und Gesundheitsmanagement",
            "additional_data": []
        },
        {
            "cmsId": "113",
            "cmsSlug": "hr-management",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Personalmanagement",
            "description": "<p>ASTRAM-ST kann auf vielf&auml;ltige Weise f&uuml;r das Personalmanagement eingesetzt werden. Hier sind einige Beispiele.</p>",
            "description2": "<p>ASTRAM-ST kann auf vielf&auml;ltige Weise f&uuml;r das Personalmanagement eingesetzt werden. Hier sind einige Beispiele.</p>",
            "image": require('../assets/img/cms/content/HR_Management2.png'),
            "iconClass": "",
            "heading": "Personalmanagement",
            "alt": "Personalmanagement",
            "additional_data": []
        },
        {
            "cmsId": "114",
            "cmsSlug": "environmental-management",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Umweltmanagement",
            "description": "<p>ASTRAM-ST kann f&uuml;r das Umweltmanagement verwendet werden, indem es eine zentrale Plattform f&uuml;r die Verfolgung und Berichterstattung &uuml;ber Umweltparameter wie Energieverbrauch, Abfallerzeugung, Emissionen und Wasserverbrauch bereitstellt. Durch die Integration mit Sensoren und anderen &Uuml;berwachungsger&auml;ten kann ASTRAM-ST Echtzeitdaten zur Umweltleistung liefern, die es Unternehmen erm&ouml;glichen, Verbesserungspotenziale zu identifizieren und proaktive Ma&szlig;nahmen zur Reduzierung ihrer Umweltauswirkungen zu ergreifen.</p>",
            "description2": "<p>ASTRAM-ST kann f&uuml;r das Umweltmanagement verwendet werden, indem es eine zentrale Plattform f&uuml;r die Verfolgung und Berichterstattung &uuml;ber Umweltparameter wie Energieverbrauch, Abfallerzeugung, Emissionen und Wasserverbrauch bereitstellt. Durch die Integration mit Sensoren und anderen &Uuml;berwachungsger&auml;ten kann ASTRAM-ST Echtzeitdaten zur Umweltleistung liefern, die es Unternehmen erm&ouml;glichen, Verbesserungspotenziale zu identifizieren und proaktive Ma&szlig;nahmen zur Reduzierung ihrer Umweltauswirkungen zu ergreifen.</p>",
            "image": require('../assets/img/cms/content/Environmental_Management2.png'),
            "iconClass": "",
            "heading": "Umweltmanagement",
            "alt": "Umweltmanagement",
            "additional_data": []
        },
        {
            "cmsId": "115",
            "cmsSlug": "inspection-and-testing-companies",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Inspektions- und Prüfunternehmen",
            "description": "<p>Inspektions- und Testunternehmen k&ouml;nnen ASTRAM-ST nutzen, um ihre Inspektionsprozesse zu rationalisieren, menschliche Fehler zu reduzieren und die Effizienz zu verbessern. Die Software kann verwendet werden, um Inspektionsformulare zu erstellen und zu verwalten, Inspektionen zu planen und den Fortschritt von Inspektionen zu verfolgen. ASTRAM-ST kann auch verwendet werden, um automatisierte Berichte zu erstellen, einschlie&szlig;lich Nichtkonformit&auml;tsberichte, Korrekturma&szlig;nahmenberichte und Management-Review-Berichte.</p>",
            "description2": "<p>Inspektions- und Testunternehmen k&ouml;nnen ASTRAM-ST nutzen, um ihre Inspektionsprozesse zu rationalisieren, menschliche Fehler zu reduzieren und die Effizienz zu verbessern. Die Software kann verwendet werden, um Inspektionsformulare zu erstellen und zu verwalten, Inspektionen zu planen und den Fortschritt von Inspektionen zu verfolgen. ASTRAM-ST kann auch verwendet werden, um automatisierte Berichte zu erstellen, einschlie&szlig;lich Nichtkonformit&auml;tsberichte, Korrekturma&szlig;nahmenberichte und Management-Review-Berichte.</p>",
            "image": require('../assets/img/cms/content/Inspection_and_Testing1.png'),
            "iconClass": "",
            "heading": "Inspektions- und Prüfunternehmen",
            "alt": "Inspektions- und Prüfunternehmen",
            "additional_data": []
        },
        {
            "cmsId": "116",
            "cmsSlug": "food-and-beverage",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "       ",
            "metaKeyword": "       ",
            "title": "Lebensmittel und Getränke Industrie ",
            "description": "<p>Lebensmittel- und Getr&auml;nkeunternehmen k&ouml;nnen ASTRAM-ST f&uuml;r eine Vielzahl von Zwecken einsetzen, darunter Qualit&auml;tskontrolle, R&uuml;ckverfolgbarkeit, Compliance und Sicherheitsmanagement. Die flexiblen Formulare und die Drag-and-Drop-Funktionalit&auml;t von ASTRAM-ST erm&ouml;glichen es Lebensmittel- und Getr&auml;nkeunternehmen, benutzerdefinierte Formulare f&uuml;r ihre spezifischen Bed&uuml;rfnisse zu erstellen. Diese Formulare k&ouml;nnen verwendet werden, um Daten &uuml;ber Inhaltsstoffe, Prozesse und Endprodukte zu erfassen.</p>",
            "description2": "<p>Lebensmittel- und Getr&auml;nkeunternehmen k&ouml;nnen ASTRAM-ST f&uuml;r eine Vielzahl von Zwecken einsetzen, darunter Qualit&auml;tskontrolle, R&uuml;ckverfolgbarkeit, Compliance und Sicherheitsmanagement. Die flexiblen Formulare und die Drag-and-Drop-Funktionalit&auml;t von ASTRAM-ST erm&ouml;glichen es Lebensmittel- und Getr&auml;nkeunternehmen, benutzerdefinierte Formulare f&uuml;r ihre spezifischen Bed&uuml;rfnisse zu erstellen. Diese Formulare k&ouml;nnen verwendet werden, um Daten &uuml;ber Inhaltsstoffe, Prozesse und Endprodukte zu erfassen.</p>",
            "image": require('../assets/img/cms/content/Food_and_Beverage.png'),
            "iconClass": "",
            "heading": "Lebensmittel und Getränke Industrie ",
            "alt": "Lebensmittel und Getränke Industrie ",
            "additional_data": []
        },
        {
            "cmsId": "117",
            "cmsSlug": "government-and-regulatory-bodies",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Regierungs- und Aufsichtsbehörden",
            "description": "<p>Regierungs- und Aufsichtsbeh&ouml;rden k&ouml;nnen ASTRAM-ST f&uuml;r eine Vielzahl von Zwecken verwenden, einschlie&szlig;lich, aber nicht beschr&auml;nkt auf Compliance-&Uuml;berwachung, Qualit&auml;tskontrolle und Datenanalyse.</p>",
            "description2": "<p>Regierungs- und Aufsichtsbeh&ouml;rden k&ouml;nnen ASTRAM-ST f&uuml;r eine Vielzahl von Zwecken verwenden, einschlie&szlig;lich, aber nicht beschr&auml;nkt auf Compliance-&Uuml;berwachung, Qualit&auml;tskontrolle und Datenanalyse.</p>",
            "image": require('../assets/img/cms/content/Governance_and_Regulatory_Compliance2.png'),
            "iconClass": "",
            "heading": "Regierungs- und Aufsichtsbehörden",
            "alt": "Regierungs- und Aufsichtsbehörden",
            "additional_data": []
        },
        {
            "cmsId": "118",
            "cmsSlug": "transportation-and-logistics",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Transport und Logistik",
            "description": "<p>Transport- und Logistikunternehmen k&ouml;nnen von der Verwendung von ASTRAM-ST stark profitieren, um ihre Abl&auml;ufe zu rationalisieren und ihre Gesamteffizienz zu verbessern. ASTRAM-ST kann verwendet werden, um verschiedene Aspekte der Transport- und Logistikbranche zu verwalten, einschlie&szlig;lich Sendungsverfolgung, Bestandsverwaltung und Qualit&auml;tskontrolle.</p>",
            "description2": "<p>Transport- und Logistikunternehmen k&ouml;nnen von der Verwendung von ASTRAM-ST stark profitieren, um ihre Abl&auml;ufe zu rationalisieren und ihre Gesamteffizienz zu verbessern. ASTRAM-ST kann verwendet werden, um verschiedene Aspekte der Transport- und Logistikbranche zu verwalten, einschlie&szlig;lich Sendungsverfolgung, Bestandsverwaltung und Qualit&auml;tskontrolle.</p>",
            "image": require('../assets/img/cms/content/Transportation_and_Logistics1.png'),
            "iconClass": "",
            "heading": "Transport und Logistik",
            "alt": "Transport und Logistik",
            "additional_data": []
        },
        {
            "cmsId": "119",
            "cmsSlug": "construction",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Ingenieurbüros",
            "description": "<p>ASTRAM-ST ist eine flexible L&ouml;sung, die an die spezifischen Anforderungen jedes Projekts bei Planung, Design und Errichtung angepasst werden kann.</p>",
            "description2": "<p>ASTRAM-ST ist eine flexible L&ouml;sung, die an die spezifischen Anforderungen jedes Projekts bei Planung, Design und Errichtung angepasst werden kann.</p>",
            "image": require('../assets/img/cms/content/Construction.png'),
            "iconClass": "",
            "heading": "Ingenieurbüros",
            "alt": "Ingenieurbüros",
            "additional_data": []
        }
    ],
    "about_partner_home_page": [
        {
            "cmsId": "57",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 1 ",
            "description": "<p>Partner 1&nbsp;</p>",
            "description2": "<p>Partner 1&nbsp;</p>",
            "image": require('../assets/img/cms/content/client-logo1.png'),
            "iconClass": "",
            "heading": "Partner 1 ",
            "alt": "Partner 1 ",
            "additional_data": []
        },
        {
            "cmsId": "58",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Partner 2",
            "description": "<p>Partner 2</p>",
            "description2": "<p>Partner 2</p>",
            "image": require('../assets/img/cms/content/client-logo2.png'),
            "iconClass": "",
            "heading": "Partner 2",
            "alt": "Partner 2",
            "additional_data": []
        },
        {
            "cmsId": "59",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 3",
            "description": "<p>Partner 3</p>",
            "description2": "<p>Partner 3</p>",
            "image": require('../assets/img/cms/content/client-logo3.png'),
            "iconClass": "",
            "heading": "Partner 3",
            "alt": "Partner 3",
            "additional_data": []
        },
        {
            "cmsId": "60",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 4",
            "description": "<p>Partner 4</p>",
            "description2": "<p>Partner 4</p>",
            "image": require('../assets/img/cms/content/client-logo4.png'),
            "iconClass": "",
            "heading": "Partner 4",
            "alt": "Partner 4",
            "additional_data": []
        },
        {
            "cmsId": "61",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 5",
            "description": "<p>Partner 5</p>",
            "description2": "<p>Partner 5</p>",
            "image": require('../assets/img/cms/content/client-logo5.png'),
            "iconClass": "",
            "heading": "Partner 5",
            "alt": "Partner 5",
            "additional_data": []
        }
    ],
    "inner_top_banner_industry_application_page": [
        {
            "cmsId": "88",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "41",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Our Solutions & Business Areas",
            "description": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "description2": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "image": require('../assets/img/cms/content/about-banner1.jpg'),
            "iconClass": "",
            "heading": "Our Solutions & Business Areas",
            "alt": "Our Solutions & Business Areas",
            "additional_data": []
        }
    ]
});

const [dataSource, setDataSource] = useState({
    "pageName": "Industry Page",
    "metaTitle": "Our Solutions & Business Areas",
    "metaDescription": "Our Solutions & Business Areas",
    "metaKeyword": "Our Solutions & Business Areas",
    "our_solutions_and_business_areas_title_industry_applications_page": [
        {
            "cmsId": "72",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "32",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Our Business Areas and Industry application",
            "description": "<p>Our Business Areas and Industry application</p>",
            "description2": "<p>Our Business Areas and Industry application</p>",
            "image": "",
            "iconClass": "",
            "heading": "Our Business Areas and Industry application",
            "alt": "Our Business Areas and Industry application",
            "additional_data": []
        }
    ],
    "my_industry_home_page": [
        {
            "cmsId": "38",
            "cmsSlug": "nuclear",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Nuclear",
            "description": "<p>Nuclear Power plants can implement ASTRAM to manage assets, control quality, manage risk, facilitate audits and manage training and certification. By using ASTRAM in the nuclear industry, companies can improve safety, reduce costs, and increase efficiency.</p> ",
            "description2": "<p>Nuclear Power plants can implement ASTRAM to manage assets, control quality, manage risk, facilitate audits and manage training and certification. By using ASTRAM in the nuclear industry, companies can improve safety, reduce costs, and increase efficiency.</p> ",
            "image": require('../assets/img/cms/content/01.png'),
            "iconClass": "",
            "heading": "Nuclear",
            "alt": "Nuclear",
            "additional_data": []
        },
        {
            "cmsId": "39",
            "cmsSlug": "power-plants-and-utilities",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Power Plants and Utilities",
            "description": "<p>ASTRAM can be helpful in all kinds of power plants and energy installations by managing maintenance, environmental compliance, safety, production optimization, and asset management. By using ASTRAM in power plants and energy installations, companies can improve safety, reduce downtime, extend the lifespan of equipment, and optimize production.</p>",
            "description2": "<p>ASTRAM can be helpful in all kinds of power plants and energy installations by managing maintenance, environmental compliance, safety, production optimization, and asset management. By using ASTRAM in power plants and energy installations, companies can improve safety, reduce downtime, extend the lifespan of equipment, and optimize production.</p>",
            "image": require('../assets/img/cms/content/02.png'),
            "iconClass": "",
            "heading": "Power Plants and Utilities",
            "alt": "Power Plants and Utilities",
            "additional_data": []
        },
        {
            "cmsId": "40",
            "cmsSlug": "mining",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Mining",
            "description": "<p>ASTRAM can be helpful in the mining sector by managing asset, safety, and environmental compliance, optimizing production, and managing the supply chain. By using ASTRAM in mining operations, companies can improve safety, reduce downtime, optimize production, and manage the supply chain more effectively.</p>",
            "description2": "<p>ASTRAM can be helpful in the mining sector by managing asset, safety, and environmental compliance, optimizing production, and managing the supply chain. By using ASTRAM in mining operations, companies can improve safety, reduce downtime, optimize production, and manage the supply chain more effectively.</p>",
            "image": require('../assets/img/cms/content/03.png'),
            "iconClass": "",
            "heading": "Mining",
            "alt": "Mining",
            "additional_data": []
        },
        {
            "cmsId": "41",
            "cmsSlug": "pharmaceutical",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Pharmaceutical",
            "description": "<p>ASTRAM can be used in the pharmaceutical sector to manage quality control, compliance, inventory, clinical trials, and production optimization. By using ASTRAM in the pharmaceutical industry, companies can improve quality, reduce compliance risk, optimize inventory management, improve clinical trial processes, and improve production efficiency.</p>",
            "description2": "<p>ASTRAM can be used in the pharmaceutical sector to manage quality control, compliance, inventory, clinical trials, and production optimization. By using ASTRAM in the pharmaceutical industry, companies can improve quality, reduce compliance risk, optimize inventory management, improve clinical trial processes, and improve production efficiency.</p>",
            "image": require('../assets/img/cms/content/04.png'),
            "iconClass": "",
            "heading": "Pharmaceutical",
            "alt": "Pharmaceutical",
            "additional_data": []
        },
        {
            "cmsId": "107",
            "cmsSlug": "laboratories",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Laboratories",
            "description": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
            "description2": "<p>ASTRAM can help laboratories improve efficiency, reduce errors, comply with regulations, and facilitate collaboration between personnel. By implementing ASTRAM, laboratories can optimize their processes, improve data management, and ultimately deliver better results for their customers.</p>",
            "image": require('../assets/img/cms/content/Laboratory_Management1.png'),
            "iconClass": "",
            "heading": "Laboratories",
            "alt": "Laboratories",
            "additional_data": []
        },
        {
            "cmsId": "108",
            "cmsSlug": "international-trade",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "International Trade",
            "description": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions</p>",
            "description2": "<p>ASTRAM can help to improve the efficiency and transparency of international trade, while reducing the risks associated with cross-border transactions</p>",
            "image": require('../assets/img/cms/content/International_Trade1.png'),
            "iconClass": "",
            "heading": "International Trade",
            "alt": "International Trade",
            "additional_data": []
        },
        {
            "cmsId": "109",
            "cmsSlug": "accreditation-bodies",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Accreditation Bodies",
            "description": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
            "description2": "<p>ASTRAM can help accreditation bodies to streamline their operations, improve the quality of their services, and enhance their overall efficiency.</p>",
            "image": require('../assets/img/cms/content/Accreditation_Management2.png'),
            "iconClass": "",
            "heading": "Accreditation Bodies",
            "alt": "Accreditation Bodies",
            "additional_data": []
        },
        {
            "cmsId": "110",
            "cmsSlug": "oil-and-gas-and-petrochemical",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Oil and Gas and Petrochemical",
            "description": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
            "description2": "<p>ASTRAM can help the oil and gas sector to improve their operational efficiency, reduce costs, and ensure compliance with regulatory requirements, while also improving safety outcomes and reducing environmental impact.</p>",
            "image": require('../assets/img/cms/content/Oil_and_Gas_and_Petrochemical.png'),
            "iconClass": "",
            "heading": "Oil and Gas and Petrochemical",
            "alt": "Oil and Gas and Petrochemical",
            "additional_data": []
        },
        {
            "cmsId": "111",
            "cmsSlug": "manufacturing",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Manufacturing",
            "description": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
            "description2": "<p>Manufacturing companies can use ASTRAM in several ways to improve their operations and increase their efficiency.</p>",
            "image": require('../assets/img/cms/content/Manufacturing.png'),
            "iconClass": "",
            "heading": "Manufacturing",
            "alt": "Manufacturing",
            "additional_data": []
        },
        {
            "cmsId": "112",
            "cmsSlug": "health-and-safety",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "    ",
            "metaKeyword": "    ",
            "title": "Health and Safety",
            "description": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
            "description2": "<p>The use of Astram in the Health and Safety industry can improve safety performance, reduce incidents and accidents, and ensure compliance with safety regulations.</p>",
            "image": require('../assets/img/cms/content/Health_and_Safety_Management1.png'),
            "iconClass": "",
            "heading": "Health and Safety",
            "alt": "Health and Safety",
            "additional_data": []
        },
        {
            "cmsId": "113",
            "cmsSlug": "hr-management",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "HR management",
            "description": "<p>Astram can be used for HR management in a variety of ways. Here are some examples</p>",
            "description2": "<p>Astram can be used for HR management in a variety of ways. Here are some examples</p>",
            "image": require('../assets/img/cms/content/HR_Management2.png'),
            "iconClass": "",
            "heading": "HR management",
            "alt": "HR management",
            "additional_data": []
        },
        {
            "cmsId": "114",
            "cmsSlug": "environmental-management",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Environmental Management",
            "description": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
            "description2": "<p>Astram can be used for environmental management by providing a centralized platform for tracking and reporting on environmental parameters such as energy consumption, waste generation, emissions, and water usage. By integrating with sensors and other monitoring devices, Astram can provide real-time data on environmental performance, enabling organizations to identify areas for improvement and take proactive measures to reduce their environmental impact..</p>",
            "image": require('../assets/img/cms/content/Environmental_Management2.png'),
            "iconClass": "",
            "heading": "Environmental Management",
            "alt": "Environmental Management",
            "additional_data": []
        },
        {
            "cmsId": "115",
            "cmsSlug": "inspection-and-testing-companies",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Inspection and Testing Companies",
            "description": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
            "description2": "<p>nspection and testing companies can use Astram to streamline their inspection processes, reduce human errors, and improve efficiency. The software can be used to create and manage inspection forms, schedule inspections, and track the progress of inspections. Astram can also be used to generate automated reports, including non-conformance reports, corrective action reports, and management review reports.</p> ",
            "image": require('../assets/img/cms/content/Inspection_and_Testing1.png'),
            "iconClass": "",
            "heading": "Inspection and Testing Companies",
            "alt": "Inspection and Testing Companies",
            "additional_data": []
        },
        {
            "cmsId": "116",
            "cmsSlug": "food-and-beverage",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "     ",
            "metaKeyword": "     ",
            "title": "Food and Beverage",
            "description": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products</p>",
            "description2": "<p>Food and beverage companies can use ASTRAM for a variety of purposes, including quality control, traceability, compliance, and safety management. ASTRAM's flexible forms and drag-and-drop functionality allow food and beverage companies to create custom forms for their specific needs. These forms can be used to capture data about ingredients, processes, and final products</p>",
            "image": require('../assets/img/cms/content/Food_and_Beverage.png'),
            "iconClass": "",
            "heading": "Food and Beverage",
            "alt": "Food and Beverage",
            "additional_data": []
        },
        {
            "cmsId": "117",
            "cmsSlug": "government-and-regulatory-bodies",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Government and Regulatory Bodies",
            "description": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
            "description2": "<p>Government and regulatory bodies can use Astram for a wide range of purposes, including but not limited to compliance monitoring, quality control, and data analysis.</p>",
            "image": require('../assets/img/cms/content/Governance_and_Regulatory_Compliance2.png'),
            "iconClass": "",
            "heading": "Government and Regulatory Bodies",
            "alt": "Government and Regulatory Bodies",
            "additional_data": []
        },
        {
            "cmsId": "118",
            "cmsSlug": "transportation-and-logistics",
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Transportation and Logistics",
            "description": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
            "description2": "<p>Transportation and logistics companies can benefit greatly from using ASTRAM to streamline their operations and improve their overall efficiency. ASTRAM can be used to manage various aspects of the transportation and logistics industry, including shipment tracking, inventory management, and quality control.</p>",
            "image": require('../assets/img/cms/content/Transportation_and_Logistics1.png'),
            "iconClass": "",
            "heading": "Transportation and Logistics",
            "alt": "Transportation and Logistics",
            "additional_data": []
        },
        {
            "cmsId": "119",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "19",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Construction",
            "description": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
            "description2": "<p>Astram is a flexible solution that can be customized to meet the specific needs of any construction project.</p>",
            "image": require('../assets/img/cms/content/Construction.png'),
            "iconClass": "",
            "heading": "Construction",
            "alt": "Construction",
            "additional_data": []
        }
    ],
    "about_partner_home_page": [
        {
            "cmsId": "57",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 1 ",
            "description": "<p>Partner 1&nbsp;</p>",
            "description2": "<p>Partner 1&nbsp;</p>",
            "image": require('../assets/img/cms/content/client-logo1.png'),
            "iconClass": "",
            "heading": "Partner 1 ",
            "alt": "Partner 1 ",
            "additional_data": []
        },
        {
            "cmsId": "58",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": "   ",
            "metaKeyword": "   ",
            "title": "Partner 2",
            "description": "<p>Partner 2</p>",
            "description2": "<p>Partner 2</p>",
            "image": require('../assets/img/cms/content/client-logo2.png'),
            "iconClass": "",
            "heading": "Partner 2",
            "alt": "Partner 2",
            "additional_data": []
        },
        {
            "cmsId": "59",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 3",
            "description": "<p>Partner 3</p>",
            "description2": "<p>Partner 3</p>",
            "image": require('../assets/img/cms/content/client-logo3.png'),
            "iconClass": "",
            "heading": "Partner 3",
            "alt": "Partner 3",
            "additional_data": []
        },
        {
            "cmsId": "60",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 4",
            "description": "<p>Partner 4</p>",
            "description2": "<p>Partner 4</p>",
            "image": require('../assets/img/cms/content/client-logo4.png'),
            "iconClass": "",
            "heading": "Partner 4",
            "alt": "Partner 4",
            "additional_data": []
        },
        {
            "cmsId": "61",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "26",
            "metaTitle": "",
            "metaDescriptions": " ",
            "metaKeyword": " ",
            "title": "Partner 5",
            "description": "<p>Partner 5</p>",
            "description2": "<p>Partner 5</p>",
            "image": require('../assets/img/cms/content/client-logo5.png'),
            "iconClass": "",
            "heading": "Partner 5",
            "alt": "Partner 5",
            "additional_data": []
        }
    ],
    "inner_top_banner_industry_application_page": [
        {
            "cmsId": "88",
            "cmsSlug": null,
            "pageId": "6",
            "sectionId": "41",
            "metaTitle": "",
            "metaDescriptions": "  ",
            "metaKeyword": "  ",
            "title": "Our Solutions & Business Areas",
            "description": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "description2": "<p><span style=\"color: #292d31; font-family: Montserrat, sans-serif; font-size: 14px; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 300; letter-spacing: normal; orphans: 2; text-align: right; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; background-color: #ffffff; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; display: inline !important; float: none;\">Welcome Astram</span></p>",
            "image": require('../assets/img/cms/content/about-banner1.jpg'),
            "iconClass": "",
            "heading": "Our Solutions & Business Areas",
            "alt": "Our Solutions & Business Areas",
            "additional_data": []
        }
    ]
});
  
const [language, setLanguage] = useState('ENGLISH');

const [isPageLoaded, setIsPageLoaded] =useState(false);
useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    var languageH = localStorage.getItem('language');
   // alert(languageH); 

    if(languageH!=undefined && languageH!=null && languageH!=''){
            setLanguage(languageH);
    }
    if(languageH=='GERMAN'){
        setDataSource(dataSourceDe) ;
    }else{
        setDataSource(dataSourceEn);
    }

    const fetchMyAPI = async () => {
      try {


        await window.extraScript();
           /* var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
           

            var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
            };

            const response = await fetch(
            'http://localhost/astram2/list-cms-content/4', requestOptions
            );
            const json = await response.json();
            setDataSource(json);*/
            //console.log(json);
            //console.log('Image Chinmoy',json['home-page-slider-background'][0]);
            
       
      }catch (error) {
        console.log(error);
      } 
    }
    setIsPageLoaded(true);
    //fetchMyAPI()
    return () => {
      console.log("login cleaned up");
    };
  }, [])



  return (
   
    <>
    {isPageLoaded===true &&
   <>
     <Helmet>
     <script src="https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js" type="text/javascript" />
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js" type="text/javascript" />
        <script src="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.js" type="text/javascript" />
      <title>ASTRAM - {dataSource.metaTitle}</title>
    </Helmet>
      {/* Header Navigation */}
      <Nav data={dataSource} />
      {/* end Header Navigation */}

      <InnerTopBanner language={language} data={dataSource} />
      <OurSolutionsAndBusiness language={language} data={dataSource} />
      <AboutClient language={language} data={dataSource} />



       {/* Header Navigation */}
       <Footer language={language} data={dataSource} />
      {/* end Header Navigation */}

     
      </>
}
</>

  );
}

export default OurSolutions;
