import { Link } from "react-router-dom";
import bgImg from "../../../assets/img/cms/content/about-banner.jpg";

function InnerBanner({ data, language }) {
  return (
    <>
      {data && (
        <div
          class="banner banner-inner"
          style={{
            backgroundImage: `url(${bgImg})`,
          }}
        >
          <div class="container d-flex flex-wrap py-4">
            <div class="page-title ms-md-auto me-md-0 mx-auto d-inline-block text-md-right text-center py-3 w-100">
              <h1 class="f600 mb-0">
                {language === "ENGLISH" ? data?.title : data?.germanTitle}
              </h1>
            </div>
            <ul class="nav-breadcrumbs d-flex flex-wrap position-absolute">
              {language === "ENGLISH" ? (
                <li>
                  <Link class="fdark" to="/">
                    Home
                  </Link>
                </li>
              ) : (
                <li>
                  <Link class="fdark" to="/">
                    Home
                  </Link>
                </li>
              )}

              <li>
                <span class="fmain">{language === "ENGLISH" ? data?.title : data?.germanTitle}</span>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}
export default InnerBanner;
