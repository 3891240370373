


import {Link } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
function AboutUsSection({data,language}) {
  return (
    <>
    {data?.about_us_content_home_page &&
    <div className="about-section pt-md-5 pt-4">
    <div className="about-section-heading text-center mb-3 mt-lg-4 mt-md-2">
        <h2 className="h5 text-uppercase f700 mb-0 d-inline-block px-4 bg-white" dangerouslySetInnerHTML={{__html: data?.about_us_content_home_page[0]?.title.split(" ")[0]+' <span>'+data?.about_us_content_home_page[0]?.title.split(" ")[1]+'</span>'}}></h2>
    </div>
    <div className="container about-section-intro pt-lg-3">
        <div className="row">
            <div className="col-lg-9 col-md-6 cms text-center mx-auto" dangerouslySetInnerHTML={{__html: data?.about_us_content_home_page[0]?.description}}>
              
            </div>
        </div>

        {data?.about_us_block_home_page &&
       
        <div className="about-cols--slider d-xl-flex flex-wrap pt-3">
             {data?.about_us_block_home_page.map((row, index) => (
                <div className="about-col text-center">
                    <Link className="media media-about about-col-img d-block" to="/">
                        <img src={row?.image} alt={row?.alt} width={302} height={215}/>
                    </Link>
                    <div className="about-col-text">
                        <h3 className="f600 mt-4">{row?.title}</h3>
                        {language=='ENGLISH' &&
                            <p dangerouslySetInnerHTML={{__html:row?.description.substring(0, 150) }}></p>
                        }
                        {language=='GERMAN' &&
                            <p dangerouslySetInnerHTML={{__html:row?.description.substring(0, 150)+'..' }}></p>
                        } 

                        {language=='ENGLISH' &&
                
                <HashLink className="btn btn-full btn-bg-white btn-sm" smooth to={"/about-us#s"+index}>Read More</HashLink> 
              }  
              {language=='GERMAN' &&
               <HashLink className="btn btn-full btn-bg-white btn-sm" smooth to={"/about-us#s"+index}>Mehr lesen</HashLink> 
               
              } 
                       
                        
                    </div>
                </div>
             ))}
            
        </div>
        }
    </div>
</div>
}
</>
  );
}
export default AboutUsSection;
