import { Link } from "react-router-dom";
import noPostsFoundImg from "../../assets/img/no-posts-found.png";
import SkeletonLoader from "../skeleton-loader";
import BlogSidebar from "../blog-sidebar";
import BlogPagination from "../blog-pagination";
import { formatShortDate } from "../../utils";

function BlogListing({ language, isLoading, posts, currentPage, totalPages, onPageChange }) {
	
	return (
		<>
			<section id="blog" className="blog pt-md-5 pt-4 pb-4">
				<div
					className="container pt-lg-3"
					data-aos="fade-up"
					data-aos-delay="100"
				>
					{isLoading ? (
						<SkeletonLoader />
					) : posts.length > 0 ? (
						<div className="row g-5">
							<div className="col-lg-8">
								<div className="row gy-4 posts-list">
									{posts.map((row, index) => (
										<div key={index} className="col-md-6">
											<div className="post-item position-relative h-100">
												<div className="post-img position-relative overflow-hidden" style={{minHeight: '31.5px'}}>
													<img
														src={row.picture}
														className="img-fluid"
														alt=""
													/>
													<span className="post-date">
														{formatShortDate(row.createDate)}
													</span>
												</div>

												<div className="post-content d-flex flex-column">
													{language === "ENGLISH" && (
														<h3 className="post-title">{row?.title}</h3>
													)}
													{language === "GERMAN" && (
														<h3 className="post-title">{row?.germanTitle}</h3>
													)}

													<div className="meta d-flex align-items-center">
														<div className="d-flex align-items-center">
															<i className="bi bi-person"></i>{" "}
															<span className="ps-2">John Doe</span>
														</div>
														<span className="px-3 text-black-50">/</span>
														<div className="d-flex align-items-center">
															<i className="bi bi-folder2"></i>{" "}
															<span className="ps-2">Politics</span>
														</div>
													</div>

													{language === "ENGLISH" && (
														<p
															dangerouslySetInnerHTML={{
																__html:
																	row?.description.substring(0, 150) + "...",
															}}
														></p>
													)}
													{language === "GERMAN" && (
														<p
															dangerouslySetInnerHTML={{
																__html:
																	row?.germanDescription.substring(0, 150) +
																	"...",
															}}
														></p>
													)}

													<hr />
													{language === "ENGLISH" && (
														<Link
															className="readmore stretched-link"
															smooth
															to={"/" + row?.slug}
														>
															Read More
															<i className="bi bi-arrow-right"></i>
														</Link>
													)}
													{language === "GERMAN" && (
														<Link
															className="readmore stretched-link"
															smooth
															to={"/" + row?.slug}
														>
															Mehr lesen
															<i className="bi bi-arrow-right"></i>
														</Link>
													)}
												</div>
											</div>
										</div>
									))}
								</div>

								<BlogPagination
									currentPage={currentPage}
									totalPages={totalPages}
									onPageChange={onPageChange}
								/>
							</div>

							<div className="col-lg-4">
								<BlogSidebar />
							</div>
						</div>
					) : (
						// Render the "No Posts Found" image only when not loading and data is empty
						<div className="text-center">
							<img src={noPostsFoundImg} alt="No Posts Found" />
						</div>
					)}
				</div>
			</section>
		</>
	);
}
export default BlogListing;
